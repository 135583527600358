import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from './theme/styled-components';
import { createRoot } from 'react-dom/client';
import { theme } from './theme/theme';
import App from './pages/App';
import AppProvider from './containers/AppProvider';
import GlobalStyle from './theme/globalStyles';
import configureStore from './configureStore';

const store = configureStore();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Router>
          <AppProvider>
            <App />
          </AppProvider>
        </Router>
      </>
    </ThemeProvider>
  </Provider>,
);
