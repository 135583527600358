import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { __ } from '../../utilities/';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';

interface IOwnProps {}

type Props = IOwnProps;

export const FORM_NAME = 'static-content-form';
export interface IFormData {
  name: string;
  cid: string;
}

const C: React.FC<Props> = () => {
  return (
    <>
      <Row>
        <Col xs={12} s={6} m={4}>
          <Field
            component={FormField}
            type="text"
            name="name"
            label={__('Názov')}
          />
        </Col>
        <Col xs={12} s={6} m={4}>
          <Field
            component={FormField}
            type="text"
            name="cid"
            label={__('Identifikátor')}
          />
        </Col>
      </Row>
    </>
  );
};

export default reduxForm({
  form: FORM_NAME,
})(C);
