import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getListNextOffset,
  hasCommitFailed,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { load } from './actions';
import { urlmapSelector } from './selectors';
import { useSelector } from 'react-redux';
import Api, { URLMapUrl } from '../../Api';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import Form from './form';
import HeaderBar from '@sportnet/ui/HeaderBar';
import List from '../../components/UrlmapList';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import constants from './constants';
import useQuery from '@sportnet/query-hoc/useQuery';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

interface IOwnProps {}

const mapStateToProps = (state: RootState) => ({
  items: urlmapSelector(state),
  isLoading: isCommiting(constants.LIST_NAME)(state),
  hasCommitFailed: hasCommitFailed(constants.LIST_NAME)(state),
  nextOffset: getListNextOffset(constants.LIST_NAME)(state),
});

const mapDispatchToProps = {
  load,
  initialize,
  setParams,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps &
  RouteComponentProps &
  WithPopupsProps;

const C: React.FC<Props> = ({
  items,
  load,
  isLoading,
  location: { search, pathname },
  hasCommitFailed,
  initialize,
  history,
  nextOffset,
  alert,
}) => {
  const { query, setQuery } = useQuery(
    search,
    (serializedQuery) => {
      history.push({
        search: serializedQuery,
      });
    },
    constants.QUERY_HOC_CONFIG,
  );

  // Initialize redux-list
  React.useEffect(() => {
    const parameters = {
      ...query,
    };
    initialize({
      listName: constants.LIST_NAME,
      initialParams: parameters,
    });
    setParams({
      listName: constants.LIST_NAME,
      parameters,
    });
    load();
  }, [initialize, query, load]);

  // add url / edit url
  const [editingUrl, setEditingUrl] = React.useState<URLMapUrl | null>();

  function render() {
    if (hasCommitFailed) {
      return (
        <NotFound
          title={__('Ups! Nastala chyba pri načítaní')}
          icon="error"
          actionLabel={__('Skúsiť znova')}
          onClickAction={() => load()}
        />
      );
    }

    if (isLoading && items.length === 0) {
      return <ContentLoader />;
    }

    if (items.length === 0) {
      return <NotFound title={__('Žiadne presmerovania')} icon="search" />;
    }

    return <List items={items} onClickRow={(item) => setEditingUrl(item)} />;
  }

  const { appId, appSpace, contentDivider } = useSelector(appSetupSelector);
  const [currentApiOperation, setCurrentApiOperation] = React.useState<
    'submit' | 'delete' | null
  >(null);

  const handleSubmit = async (urlMapUrl: URLMapUrl) => {
    try {
      setCurrentApiOperation('submit');
      if (editingUrl?.url) {
        await Api.editUrlmapUrl(
          appId,
          appSpace,
          contentDivider,
          { url: urlMapUrl.url },
          { location: urlMapUrl.location!, permanent: !!urlMapUrl.permanent! },
        );
      } else {
        await Api.createUrlmapUrl(
          appId,
          appSpace,
          contentDivider,
          {},
          {
            url: urlMapUrl.url,
            location: urlMapUrl.location!,
            permanent: urlMapUrl.permanent!,
          },
        );
      }
      setEditingUrl(null);
      load();
    } catch (e) {
      if ((e as any).details?.code === 409) {
        alert(__('Zadaná URL už existuje'));
      } else {
        alert(__('Nastala neočakávaná chyba'));
      }
    } finally {
      setCurrentApiOperation(null);
    }
  };

  const handleDelete = async (urlMapUrl: URLMapUrl) => {
    try {
      setCurrentApiOperation('delete');
      await Api.deleteUrlmapUrl(appId, appSpace, contentDivider, {
        url: urlMapUrl.url,
      });
      setEditingUrl(null);
      load();
    } catch (err) {
      console.error(err);
      if ((err as any).details?.code === 409) {
        alert(__('Zadaná URL už existuje'));
      } else {
        alert(__('Nastala neočakávaná chyba'));
      }
    } finally {
      setCurrentApiOperation(null);
    }
  };

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Zoznam presmerovaní')}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={query.offset}
              limit={constants.LIST_LIMIT}
              nextOffset={nextOffset}
              onChangeOffset={(e: number) => {
                setQuery({
                  offset: e,
                });
              }}
              loading={!!isLoading}
            />
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              onClick={() => {
                setEditingUrl({
                  url: '',
                  location: '',
                  permanent: false,
                  urltype: 'redirect',
                });
              }}
            >
              {__('Pridať nové presmerovanie')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment noBottomGutter raised loading={!!isLoading}>
          {render()}
        </Segment>
      </Segment>
      {editingUrl && (
        <Sidebar
          visible={true}
          onClose={() => setEditingUrl(null)}
          header={__('Presmerovanie')}
        >
          <Form
            urlmapUrl={editingUrl}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            currentApiOperation={currentApiOperation}
          />
        </Sidebar>
      )}
    </ScrollLayout>
  );
};

export default compose(
  withRouter,
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C) as any as React.FC<IOwnProps>;
