import {
  ArrayParam,
  IQueryResult,
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';
import { SmarttagProps } from '@sportnet/ui/library/Smarttag';
import { smarttagsToUrl } from '@sportnet/tagmanager-connector';
import { urlToSmarttags } from '@sportnet/tagmanager-connector';

const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
    q: StringParam(''),
    qopts: StringParam(''),
    ownerSportnetId: StringParam(''),
    authorId: StringParam(''),
    smarttag: {
      deserialize: (value: string) => urlToSmarttags(value) as SmarttagProps[],
      serialize: (value: SmarttagProps[]) => smarttagsToUrl(value),
    },
    sorter: StringParam(''),
    sectionid: ArrayParam(NumberParam(0), []),
    validFrom: StringParam(''),
    validTo: StringParam(''),
    hasDraft: StringParam(''),
    isPrivate: StringParam(''),
    doctype: ArrayParam(StringParam(''), []),
  },
};

const constants = {
  LIST_NAME: 'articles_list',
  LIST_LIMIT: 50,
  QUERY_HOC_CONFIG,
};

export default constants;

export type IQuery = IQueryResult<typeof QUERY_HOC_CONFIG>['query'];
