/* tslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Article_Author = {
  sportnetId?: string;

  name: string;
};
export type Allowed_Users_List = {
  users?: Array<{
    user_id?: string;

    subsection_access?: boolean;
  }>;
};
export type Section_Path = {
  contentspace?: string;

  perex?: string | null;

  name?: string;

  parent?: Array<number>;

  show?: boolean;

  is_private?: boolean;

  url?: string | null;

  content?: Content;

  uniqid_key?: string;

  redirecturl?: string | null;

  _id?: number;

  order?: number;

  uniqid?: string;

  settings?: Variables;
};
export type Section_Path_List = {
  path?: Array<Section_Path>;
};
export type URL_Map = {
  url?: string | null;

  urltype?: string;

  contentspace?: string;

  object_id?: number;

  expanded_object?: {};

  location?: string;

  permanent?: boolean;
};
export type Smarttag = {
  key: string;

  title?: string;

  values: Array<{
    key: string;

    title?: string | null;
  }>;
};
export type Article_Draft = {
  created?: string;

  createdBy?: {
    id?: string | null;

    displayName?: string | null;
  };
};
export type Article_Discussion_Stats = {
  _id?: number;

  contentspace?: string;

  externalIds?: Array<{
    service?: string;

    externalId?: string;

    lastUpdate?: string;

    error?: string;
  }>;

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };
};
export type Article = {
  _id?: number;

  owner?: {
    sportnetId?: string | null;

    name?: string | null;
  };

  authors?: Array<Article_Author>;

  contentspace?: string;

  doctype?: string;

  doctypeExpanded?: Doctype;

  is_private?: boolean;

  locked?: boolean;

  name?: string;

  created?: string;

  modified?: string | null;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | number | null;

  stats_view_total?: number;

  sfz?: Array<{}>;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  tags?: Array<{}>;

  url?: string | null;

  valid_from?: string | null;

  updated_at?: string | null;

  widgets?: Content;

  draft?: Article_Draft;

  layout?: string;

  externalIds?: Array<{
    service?: string;

    externalId?: string;

    lastUpdate?: string;

    error?: string;
  }>;

  meta?: {
    title?: string;

    description?: string;
  };

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };

  section?: Section;

  locks?: Array<Article_Lock>;

  highlights?: Array<{
    path?: string;

    score?: number;

    texts?: Array<{
      value?: string;

      type?: 'hit' | 'text';
    }>;
  }>;
};
export type Article_Public_List = {
  _id?: number;

  authors?: Array<Article_Author>;

  contentspace?: string;

  doctype?: string;

  doctypeExpanded?: Doctype;

  is_private?: boolean;

  locked?: boolean;

  name?: string;

  modified?: string | null;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | number | null;

  stats_view_total?: number;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  valid_from?: string | null;

  meta?: {
    title?: string;

    description?: string;
  };

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };
};
export type ArticleLite = {
  _id?: number;

  owner?: {
    sportnetId?: string | null;

    name?: string | null;
  };

  authors?: Array<Article_Author>;

  contentspace?: string;

  doctype?: string;

  doctypeExpanded?: Doctype;

  is_private?: boolean;

  locked?: boolean;

  name?: string;

  created?: string;

  modified?: string | null;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | number | null;

  stats_view_total?: number;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  valid_from?: string | null;

  meta?: {
    title?: string;

    description?: string;
  };

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };
};
export type Widget = {
  type: string;
};
export type Admin_Articles_List = {
  articles: Array<{
    _id?: number;

    owner?: {
      sportnetId?: string | null;

      name?: string | null;
    };

    authors?: Array<Article_Author>;

    contentspace?: string;

    doctype?: string;

    doctypeExpanded?: Doctype;

    is_private?: boolean;

    locked?: boolean;

    name?: string;

    created?: string;

    modified?: string | null;

    perex?: string | null;

    picture?: Picture;

    publication_groups?: Array<string>;

    redirect?: string | null;

    sectionid?: Array<number> | number | null;

    stats_view_total?: number;

    sfz?: Array<{}>;

    smarttags?: Array<Smarttag>;

    source?: string | null;

    tags?: Array<{}>;

    url?: string | null;

    valid_from?: string | null;

    updated_at?: string | null;

    widgets?: Content;

    draft?: Article_Draft;

    layout?: string;

    externalIds?: Array<{
      service?: string;

      externalId?: string;

      lastUpdate?: string;

      error?: string;
    }>;

    meta?: {
      title?: string;

      description?: string;
    };

    discussion?: {
      type?: string;

      discussionId?: number;

      postCount?: number;

      active?: boolean;

      url?: string;
    };

    section?: Section;

    locks?: Array<Article_Lock>;

    highlights?: Array<{
      path?: string;

      score?: number;

      texts?: Array<{
        value?: string;

        type?: 'hit' | 'text';
      }>;
    }>;

    note?: string;
  }>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Articles_List = {
  articles: Array<Article>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Articles_Public_List = {
  articles: Array<Article_Public_List>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Articles_List_Lite = {
  articles: Array<ArticleLite>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Article_Post = {
  doctype?: string;

  is_private?: boolean;

  locked?: boolean;

  name: string;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | null;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  urlSuggestion?: string;

  valid_from?: string | null;

  updated_at?: string | null;

  widgets?: Content;

  layout?: string;

  authors?: Array<Article_Author>;

  note?: string;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Article_Put = {
  doctype?: string;

  locked?: boolean;

  name?: string;

  perex?: string | null;

  picture?: Picture;

  is_private?: boolean;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | null;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  valid_from?: string | null;

  updated_at?: string | null;

  widgets?: Content;

  layout?: string;

  authors?: Array<Article_Author>;

  note?: string;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Section = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  heading?: string | null;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  content?: Content;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  publication_groups?: Array<string>;

  settings?: Variables;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Variables = {};
export type SectionPublic = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  heading?: string | null;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  settings?: Variables;

  sections?: Array<{}>;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type SectionPublicDetail = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  heading?: string | null;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  settings?: Variables;

  sections?: Array<{}>;

  meta?: {
    title?: string;

    description?: string;
  };

  sectionArticles?: SectionArticles;

  content?: Content;

  publication_groups?: Array<string>;
};
export type Section_List = {
  sections?: Array<Section>;
};
export type SectionPublic_List = {
  sections?: Array<SectionPublic>;
};
export type SectionPublic_Tree = {
  tree?: Array<SectionPublic>;
};
export type SectionAdmin_Tree = {
  tree?: Array<Section>;
};
export type Section_Post = {
  is_private?: boolean;

  name: string;

  heading?: string | null;

  parent?: number | null;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url: string;

  publication_groups?: Array<string>;

  content: Content;

  settings?: Variables;
};
export type Section_Put = {
  _id?: number;

  is_private?: boolean;

  name?: string;

  heading?: string | null;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  publication_groups?: Array<string>;

  newParent?: number | null;

  content?: Content;

  settings?: Variables;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Sections_Order = {
  order?: Array<Array<number>>;
};
export type Static_Content_Public = Static_Content;
export type Static_Content = {
  _id?: string;

  cid?: string;

  name?: string;

  content?: Content;
};
export type Content = Array<{}>;
export type Static_Contents_List = {
  static_content?: Array<{
    cid?: string;

    name?: string;
  }>;
};
export type Static_Content_PostPut = {
  name: string;

  cid: string;

  content: Content;
};
export type Doctype = {
  id: string;

  title: string;

  defaultPicture?: Picture;

  overrideAuthorBySmarttagKey?: string;

  showCreatedBy?: boolean;

  top?: {
    showAuthor?: boolean;

    showId?: boolean;

    showDate?: boolean;

    showSmarttags?: boolean;
  };

  bottom?: {
    showAuthor?: boolean;

    showSmarttags?: boolean;
  };

  social?: {};

  relatedArticles?: {
    show?: boolean;

    limit?: number;
  };

  defaultWidgets?: Content;
};
export type Doctype_Put = {
  id?: string;

  title?: string;

  defaultPicture?: Picture;

  overrideAuthorBySmarttagKey?: string;

  showCreatedBy?: boolean;

  top?: {
    showAuthor?: boolean;

    showId?: boolean;

    showDate?: boolean;

    showSmarttags?: boolean;

    showSocial?: boolean;
  };

  bottom?: {
    showAuthor?: boolean;

    showSmarttags?: boolean;

    showSocial?: boolean;
  };

  social?: {};

  relatedArticles?: {
    show?: boolean;

    limit?: number;
  };

  defaultWidgets?: Content;
};
export type Doctypes = Array<Doctype>;
export type Preview_Post = {
  type: 'article';

  valid_to: string;

  data: {};
};
export type Preview = {
  type: 'article';

  valid_to: string;

  data: {};

  _id: string;
};
export type Confirmation_Message = {
  message: string;
};
export type ListingType = 'system' | 'article-tabs';
export type Listing_Patch = {
  name?: string;

  type?: ListingType;

  markers?: Array<number>;

  articles?: Array<{
    _id: number;

    name?: string;
  }>;

  articleFilter?: Array<{
    type: string;
  }>;

  priority?: number | null;
};
export type Listing_Post = {
  listingId: string;

  name: string;

  markers?: Array<number>;

  type?: ListingType;

  articles?: Array<{
    _id: number;

    name?: string;
  }>;

  articleFilter?: Array<{
    type: string;
  }>;
};
export type Listing = {
  _id: string;

  appSpace?: string;

  listingId: string;

  contentspace?: string;

  name?: string;

  type?: ListingType;

  markers?: Array<number>;

  articles?: Array<{
    _id: number;

    name?: string;
  }>;

  articleFilter?: Array<{
    type: string;
  }>;

  created?: string;

  modified?: string | null;

  priority?: number;
};
export type Listing_Fixed_Articles = {
  articles: Array<{
    _id?: number;

    owner?: {
      sportnetId?: string | null;

      name?: string | null;
    };

    authors?: Array<Article_Author>;

    contentspace?: string;

    doctype?: string;

    doctypeExpanded?: Doctype;

    is_private?: boolean;

    locked?: boolean;

    name?: string;

    created?: string;

    modified?: string | null;

    perex?: string | null;

    picture?: Picture;

    publication_groups?: Array<string>;

    redirect?: string | null;

    sectionid?: Array<number> | number | null;

    stats_view_total?: number;

    sfz?: Array<{}>;

    smarttags?: Array<Smarttag>;

    source?: string | null;

    tags?: Array<{}>;

    url?: string | null;

    valid_from?: string | null;

    updated_at?: string | null;

    widgets?: Content;

    draft?: Article_Draft;

    layout?: string;

    externalIds?: Array<{
      service?: string;

      externalId?: string;

      lastUpdate?: string;

      error?: string;
    }>;

    meta?: {
      title?: string;

      description?: string;
    };

    discussion?: {
      type?: string;

      discussionId?: number;

      postCount?: number;

      active?: boolean;

      url?: string;
    };

    section?: Section;

    locks?: Array<Article_Lock>;

    highlights?: Array<{
      path?: string;

      score?: number;

      texts?: Array<{
        value?: string;

        type?: 'hit' | 'text';
      }>;
    }>;

    listingName?: string;
  }>;
};
export type Listings_List = {
  listings: Array<Listing>;

  nextOffset?: number | null;

  total: number;

  limit: number;

  offset: number;
};
export type URLMap_List = {
  urlmap: Array<URLMapUrl>;

  nextOffset?: number | null;

  total: number;

  limit: number;

  offset: number;
};
export type URLMapUrl = {
  url: string;

  urltype: string;

  location?: string;

  permanent?: boolean;
};
export type URLMapUrl_Put = {
  location: string;

  permanent: boolean;
};
export type URLMapUrl_Post = {
  url: string;

  location: string;

  permanent: boolean;
};
export type Picture = {
  public_url?: string;

  media_url?: string;

  author?: string;

  source?: string | null;
};
export type SectionArticles = {
  header?: string;

  display?: string;

  contextual?: boolean;

  articleFilter?: Array<{
    type: string;
  }>;
};
export type Expand_Content = {
  content: Content;
};
export type Error = {
  code: number;

  name: string;

  description: string | null;

  payload?: object | null;

  userinfo?: string;
};
export type Error_Private_Article = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: Article;
  };

  userinfo?: string;
};
export type Error_Private_Section = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: Section;
  };

  userinfo?: string;
};
export type Error_Private_URL_Map = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: URL_Map;
  };

  userinfo?: string;
};
export type Article_Lock = {
  sportnetId?: string;

  displayName?: string;

  lockedTo?: string | null;
};
export type Article_Locks_List = {
  locks: Array<{
    sportnetId?: string;

    displayName?: string;

    lockedTo?: string | null;
  }>;

  editEnabled?: boolean;
};
export type External_Article = {
  _id?: string;

  externalArticleId?: string;

  state?: 'new' | 'deleted' | 'taken';

  name?: string;

  perex?: string | null;

  externalSource?: 'TASR' | 'SITA';

  published?: string;

  html?: string;

  contentSpace?: string;
};
export type External_Articles_List = {
  externalArticles: Array<External_Article>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type External_Article_Put = {
  state: 'new' | 'deleted' | 'taken';
};
export type External_Articles_Count = {
  externalArticlesCount?: number;
};
export type Articles_Bulk_Update = {
  smarttags?: Array<Smarttag>;

  sectionId?: Array<number> | null;
};
export type Articles_Bulk_Update_Response = {
  modifiedCount?: number;

  matchedCount?: number;
};
export type Translator_TextToTranslate_Item = {
  sourceLang?: string;

  targetLang: string;

  name: string;

  text: string;

  contentType: 'string' | 'html';
};
export type Translator_TranslatedData_Item = {
  name: string;

  text: string;

  detectedSourceLanguage: string;

  translated: string;
};
export type Translator_Translate_Request_Body = {
  textToTranslateList: Array<Translator_TextToTranslate_Item>;
};
export type Translator_TranslatedData = {
  bigTable: boolean;

  totalCount: number;

  data: Array<Translator_TranslatedData_Item>;
};
export type Translator_LanguageSettings_Item = {
  lang: string;

  name: string;
};
export type Translator_LanguageSettings = {
  sourceLang: Array<Translator_LanguageSettings_Item>;

  targetLang: Array<Translator_LanguageSettings_Item>;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class Api {
  protected baseUrl: string = 'https://cms.sportnet.online/api/v3';
  protected headers: { name: string; value: string }[] = [];
  protected token: string = '';
  protected apiKey: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setToken(token: string) {
    this.token = token;
  }

  public setApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    if (this.apiKey) {
      headers.append('', this.apiKey);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });

    if (response.status === 204) return response;

    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      }
      return response;
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Get Doctypes for Contentspace
   * @method
   * @name Api#getDoctypes
   */
  getDoctypes(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<Doctypes> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/doctypes';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Create Contentspace Doctype
   * @method
   * @name Api#postDoctype
   */
  postDoctype(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Doctype,
  ): Promise<Doctype> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/doctypes';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Contentspace Doctype
   * @method
   * @name Api#deleteDoctype
   */
  deleteDoctype(
    appId: string,
    appSpace: string,
    contentDivider: string,
    doctypeId: string,
    parameters: {} = {},
  ): Promise<Doctype> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/doctypes/{doctype_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{doctype_id}', doctypeId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Contentspace Doctype
   * @method
   * @name Api#editDoctype
   */
  editDoctype(
    appId: string,
    appSpace: string,
    contentDivider: string,
    doctypeId: string,
    parameters: {} = {},
    body: Doctype_Put,
  ): Promise<Doctype> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/doctypes/{doctype_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{doctype_id}', doctypeId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
    * Returns List of Articles with possibility to offset and limit results.
Also possible to filter results by smarttags and doctype.

    * @method
    * @name Api#getArticles
    */
  getArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      offset?: number;
      limit?: number;
      doctype?: Array<string>;
      smarttags?: Array<string>;
      sectionId?: string;
      ids?: string;
      excludeIds?: string;
      ownerSportnetId?: string;
      authorId?: string;
      q?: string;
      qopts?: string;
      sorter?:
        | 'created'
        | '-created'
        | 'modified'
        | '-modified'
        | 'name'
        | '-name'
        | 'valid_from'
        | '-valid_from';
      validFrom?: string;
      validTo?: string;
      hasDraft?: boolean;
      isPrivate?: boolean;
      output?: 'json' | 'xlsx' | 'xlsx-json';
      articleUrlPattern?: string;
    } = {},
  ): Promise<Admin_Articles_List> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['doctype'] = [];

    if (typeof parameters['doctype'] !== 'undefined') {
      queryParameters['doctype'] = parameters['doctype'];
    }

    queryParameters['doctype'] = this.transformParameter(
      queryParameters['doctype'],
      'joinUsingPipes',
    );

    queryParameters['smarttags'] = [];

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['sectionId'] !== 'undefined') {
      queryParameters['sectionId'] = parameters['sectionId'];
    }

    queryParameters['sectionId'] = this.transformParameter(
      queryParameters['sectionId'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['excludeIds'] = parameters['excludeIds'];
    }

    queryParameters['excludeIds'] = this.transformParameter(
      queryParameters['excludeIds'],
    );

    if (typeof parameters['ownerSportnetId'] !== 'undefined') {
      queryParameters['ownerSportnetId'] = parameters['ownerSportnetId'];
    }

    queryParameters['ownerSportnetId'] = this.transformParameter(
      queryParameters['ownerSportnetId'],
    );

    if (typeof parameters['authorId'] !== 'undefined') {
      queryParameters['authorId'] = parameters['authorId'];
    }

    queryParameters['authorId'] = this.transformParameter(
      queryParameters['authorId'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['qopts'] = 'name:fulltext';

    if (typeof parameters['qopts'] !== 'undefined') {
      queryParameters['qopts'] = parameters['qopts'];
    }

    queryParameters['qopts'] = this.transformParameter(
      queryParameters['qopts'],
    );

    queryParameters['sorter'] = '-created';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['validFrom'] !== 'undefined') {
      queryParameters['validFrom'] = parameters['validFrom'];
    }

    queryParameters['validFrom'] = this.transformParameter(
      queryParameters['validFrom'],
    );

    if (typeof parameters['validTo'] !== 'undefined') {
      queryParameters['validTo'] = parameters['validTo'];
    }

    queryParameters['validTo'] = this.transformParameter(
      queryParameters['validTo'],
    );

    if (typeof parameters['hasDraft'] !== 'undefined') {
      queryParameters['hasDraft'] = parameters['hasDraft'];
    }

    queryParameters['hasDraft'] = this.transformParameter(
      queryParameters['hasDraft'],
    );

    if (typeof parameters['isPrivate'] !== 'undefined') {
      queryParameters['isPrivate'] = parameters['isPrivate'];
    }

    queryParameters['isPrivate'] = this.transformParameter(
      queryParameters['isPrivate'],
    );

    queryParameters['output'] = 'json';

    if (typeof parameters['output'] !== 'undefined') {
      queryParameters['output'] = parameters['output'];
    }

    queryParameters['output'] = this.transformParameter(
      queryParameters['output'],
    );

    if (typeof parameters['articleUrlPattern'] !== 'undefined') {
      queryParameters['articleUrlPattern'] = parameters['articleUrlPattern'];
    }

    queryParameters['articleUrlPattern'] = this.transformParameter(
      queryParameters['articleUrlPattern'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Insert New Article with Given Properties
   * @method
   * @name Api#postArticle
   */
  postArticle(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Article_Post,
  ): Promise<Article> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Bulk update of articles.
   * @method
   * @name Api#updateArticles
   */
  updateArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      ids?: string;
    } = {},
    body: Articles_Bulk_Update,
  ): Promise<Articles_Bulk_Update_Response> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'PATCH',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Article Draft
   * @method
   * @name Api#editArticleDraft
   */
  editArticleDraft(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {} = {},
    body: Article_Put,
  ): Promise<Article> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}/draft';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Article Draft
   * @method
   * @name Api#deleteArticleDraft
   */
  deleteArticleDraft(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {} = {},
  ): Promise<Confirmation_Message> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}/draft';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Returns Article By ID
   * @method
   * @name Api#getArticle
   */
  getArticle(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {
      expandWidgets?: boolean;
    } = {},
  ): Promise<{
    _id?: number;

    owner?: {
      sportnetId?: string | null;

      name?: string | null;
    };

    authors?: Array<Article_Author>;

    contentspace?: string;

    doctype?: string;

    doctypeExpanded?: Doctype;

    is_private?: boolean;

    locked?: boolean;

    name?: string;

    created?: string;

    modified?: string | null;

    perex?: string | null;

    picture?: Picture;

    publication_groups?: Array<string>;

    redirect?: string | null;

    sectionid?: Array<number> | number | null;

    stats_view_total?: number;

    sfz?: Array<{}>;

    smarttags?: Array<Smarttag>;

    source?: string | null;

    tags?: Array<{}>;

    url?: string | null;

    valid_from?: string | null;

    updated_at?: string | null;

    widgets?: Content;

    draft?: Article_Draft;

    layout?: string;

    externalIds?: Array<{
      service?: string;

      externalId?: string;

      lastUpdate?: string;

      error?: string;
    }>;

    meta?: {
      title?: string;

      description?: string;
    };

    discussion?: {
      type?: string;

      discussionId?: number;

      postCount?: number;

      active?: boolean;

      url?: string;
    };

    section?: Section;

    locks?: Array<Article_Lock>;

    highlights?: Array<{
      path?: string;

      score?: number;

      texts?: Array<{
        value?: string;

        type?: 'hit' | 'text';
      }>;
    }>;

    editEnabled?: boolean;

    note?: string;
  }> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Article By ID
   * @method
   * @name Api#editArticle
   */
  editArticle(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {
      expandWidgets?: boolean;
    } = {},
    body: Article_Put,
  ): Promise<Article> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Article By ID
   * @method
   * @name Api#deleteArticle
   */
  deleteArticle(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {
      expandWidgets?: boolean;
    } = {},
  ): Promise<Confirmation_Message> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Insert duplicated Article by ID
   * @method
   * @name Api#postDuplicatedArticle
   */
  postDuplicatedArticle(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {} = {},
  ): Promise<Article> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}/duplicate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Share Article
   * @method
   * @name Api#shareArticle
   */
  shareArticle(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {} = {},
    body: {
      subject?: string;

      emails?: Array<string>;

      articleUrlPattern?: string;
    },
  ): Promise<Article> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}/share';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Lock Article by ID
   * @method
   * @name Api#lockArticle
   */
  lockArticle(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {} = {},
  ): Promise<Article_Locks_List> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}/locks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Remove Lock From Article For Given User
   * @method
   * @name Api#deleteArticleLock
   */
  deleteArticleLock(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {} = {},
  ): Promise<Confirmation_Message> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/articles/{article_id}/locks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Returns Article By ID
   * @method
   * @name Api#getExternalAppArticle
   */
  getExternalAppArticle(
    appId: string,
    appSpace: string,
    contentDivider: string,
    articleId: number,
    parameters: {
      expandWidgets?: boolean;
    } = {},
  ): Promise<{
    _id?: number;

    owner?: {
      sportnetId?: string | null;

      name?: string | null;
    };

    authors?: Array<Article_Author>;

    contentspace?: string;

    doctype?: string;

    doctypeExpanded?: Doctype;

    is_private?: boolean;

    locked?: boolean;

    name?: string;

    created?: string;

    modified?: string | null;

    perex?: string | null;

    picture?: Picture;

    publication_groups?: Array<string>;

    redirect?: string | null;

    sectionid?: Array<number> | number | null;

    stats_view_total?: number;

    sfz?: Array<{}>;

    smarttags?: Array<Smarttag>;

    source?: string | null;

    tags?: Array<{}>;

    url?: string | null;

    valid_from?: string | null;

    updated_at?: string | null;

    widgets?: Content;

    draft?: Article_Draft;

    layout?: string;

    externalIds?: Array<{
      service?: string;

      externalId?: string;

      lastUpdate?: string;

      error?: string;
    }>;

    meta?: {
      title?: string;

      description?: string;
    };

    discussion?: {
      type?: string;

      discussionId?: number;

      postCount?: number;

      active?: boolean;

      url?: string;
    };

    section?: Section;

    locks?: Array<Article_Lock>;

    highlights?: Array<{
      path?: string;

      score?: number;

      texts?: Array<{
        value?: string;

        type?: 'hit' | 'text';
      }>;
    }>;

    note?: string;
  }> {
    let path =
      '/external-app/{appId}:{appSpace}:{contentDivider}/articles/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{article_id}', articleId.toString());

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Returns List of Sections
   * @method
   * @name Api#getSections
   */
  getSections(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<Section_List> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/sections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Insert New Section with Given Properties
   * @method
   * @name Api#postSection
   */
  postSection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Section_Post,
  ): Promise<Section> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/sections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Returns Detail of Section
   * @method
   * @name Api#getSection
   */
  getSection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
  ): Promise<Section> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Insert New Section with Given Properties As Subsection to Parent ID
   * @method
   * @name Api#postSubsection
   */
  postSubsection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
    body: Section_Post,
  ): Promise<Section> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Section
   * @method
   * @name Api#editSection
   */
  editSection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
    body: Section_Put,
  ): Promise<Section> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Section
   * @method
   * @name Api#deleteSection
   */
  deleteSection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
  ): Promise<Confirmation_Message> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get List of Subsections for Given Parent Section ID
   * @method
   * @name Api#getSubsections
   */
  getSubsections(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
  ): Promise<Section_List> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}/sections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Admin Sections Tree
   * @method
   * @name Api#getAdminSectionsTree
   */
  getAdminSectionsTree(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<SectionAdmin_Tree> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/sections-tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Change Ordering of Sections In Current Level
   * @method
   * @name Api#changeOrderOfSections
   */
  changeOrderOfSections(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Sections_Order,
  ): Promise<Confirmation_Message> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/sections-order';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Provide List of Static Contents
   * @method
   * @name Api#getStaticContents
   */
  getStaticContents(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<Static_Contents_List> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/static-content';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Insert New Static Content
   * @method
   * @name Api#postStaticContent
   */
  postStaticContent(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Static_Content_PostPut,
  ): Promise<Static_Content> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/static-content';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Provide Detail of Static Content
   * @method
   * @name Api#getStaticContent
   */
  getStaticContent(
    appId: string,
    appSpace: string,
    contentDivider: string,
    cid: string,
    parameters: {} = {},
  ): Promise<Static_Content> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/static-content/{cid}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{cid}', cid.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Static Content
   * @method
   * @name Api#deleteStaticContent
   */
  deleteStaticContent(
    appId: string,
    appSpace: string,
    contentDivider: string,
    cid: string,
    parameters: {} = {},
  ): Promise<Confirmation_Message> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/static-content/{cid}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{cid}', cid.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Creates new preview
   * @method
   * @name Api#postPreview
   */
  postPreview(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Preview_Post,
  ): Promise<Preview> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/previews';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   *
   * @method
   * @name Api#expandWidgets
   */
  expandWidgets(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Expand_Content,
  ): Promise<Expand_Content> {
    let path = '/public/{appId}:{appSpace}:{contentDivider}/expand-widgets';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * List of Listings objects
   * @method
   * @name Api#getListings
   */
  getListings(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      offset?: number;
      limit?: number;
      type?: string;
      sorter?: 'name' | '-name';
      q?: string;
    } = {},
  ): Promise<Listings_List> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/listings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Creates new listing
   * @method
   * @name Api#createListing
   */
  createListing(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Listing_Post,
  ): Promise<Listing> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/listings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Listing detail
   * @method
   * @name Api#getListingDetail
   */
  getListingDetail(
    appId: string,
    appSpace: string,
    contentDivider: string,
    listingId: string,
    parameters: {} = {},
  ): Promise<Listing> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/listings/{listingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{listingId}', listingId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Listing
   * @method
   * @name Api#editListing
   */
  editListing(
    appId: string,
    appSpace: string,
    contentDivider: string,
    listingId: string,
    parameters: {} = {},
    body: Listing_Patch,
  ): Promise<Listing> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/listings/{listingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{listingId}', listingId.toString());

    return this.request(
      'PATCH',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Listing
   * @method
   * @name Api#deleteListing
   */
  deleteListing(
    appId: string,
    appSpace: string,
    contentDivider: string,
    listingId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/listings/{listingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{listingId}', listingId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fixed articles from specified listing
   * @method
   * @name Api#getListingFixedArticles
   */
  getListingFixedArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    listingId: string,
    parameters: {
      sorter?:
        | 'created'
        | '-created'
        | 'modified'
        | '-modified'
        | 'date'
        | '-date'
        | 'name'
        | '-name'
        | 'valid_from'
        | '-valid_from';
    } = {},
  ): Promise<Listing_Fixed_Articles> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/listings/{listingId}/fixed-articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['sorter'] = '-created';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{listingId}', listingId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * List of user defined URLMAP objects
   * @method
   * @name Api#getUrlmap
   */
  getUrlmap(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<URLMap_List> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/urlmap';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Creates new urlmap url
   * @method
   * @name Api#createUrlmapUrl
   */
  createUrlmapUrl(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: URLMapUrl_Post,
  ): Promise<URLMapUrl> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/urlmap';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Get URLMapUrl detail
   * @method
   * @name Api#getUrlmapUrl
   */
  getUrlmapUrl(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      url: string;
    },
  ): Promise<URLMapUrl> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/urlmap/url';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Edit urlmap url
   * @method
   * @name Api#editUrlmapUrl
   */
  editUrlmapUrl(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      url: string;
    },
    body: URLMapUrl_Put,
  ): Promise<URLMapUrl> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/urlmap/url';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete urlmap url
   * @method
   * @name Api#deleteUrlmapUrl
   */
  deleteUrlmapUrl(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      url: string;
    },
  ): Promise<{}> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/urlmap/url';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get update for onlajny.eu event
   * @method
   * @name Api#getOnlajnyByUrl
   */
  getOnlajnyByUrl(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      url: string;
    },
  ): Promise<{
    data: {};
  }> {
    let path = '/public/{appId}:{appSpace}:{contentDivider}/onlajny';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Prekladač jazykov.
   * @method
   * @name Api#getTranslation
   */
  getTranslation(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {},
    body: Translator_Translate_Request_Body,
  ): Promise<{
    translated: Translator_TranslatedData;
  }> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/translate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Ciselnik podporovanych jazykov pre preklad.
   * @method
   * @name Api#getTranslationLanguageSettings
   */
  getTranslationLanguageSettings(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<{
    languages: Translator_LanguageSettings;
  }> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/translate/language-settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Import
   * @method
   * @name Api#importExternalArticles
   */
  importExternalArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    source: 'TASR' | 'SITA' | 'TASR-videoservis',
    parameters: {
      roles?: Array<string>;
    },
    body: {},
  ): Promise<void> {
    let path =
      '/external-app/{appId}:{appSpace}:{contentDivider}/externalArticles/{source}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/xml');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{source}', source.toString());

    queryParameters['roles'] = [];

    if (typeof parameters['roles'] !== 'undefined') {
      queryParameters['roles'] = parameters['roles'];
    }

    queryParameters['roles'] = this.transformParameter(
      queryParameters['roles'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * List of external articles
   * @method
   * @name Api#getExternalArticles
   */
  getExternalArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {
      offset?: number;
      limit?: number;
      state?: 'new' | 'deleted' | 'taken';
      externalSource?: string;
      publishedFrom?: string;
      publishedTo?: string;
      q?: string;
      sorter?: 'name' | '-name' | 'published' | '-published';
    } = {},
  ): Promise<External_Articles_List> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/externalArticles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['externalSource'] !== 'undefined') {
      queryParameters['externalSource'] = parameters['externalSource'];
    }

    queryParameters['externalSource'] = this.transformParameter(
      queryParameters['externalSource'],
    );

    if (typeof parameters['publishedFrom'] !== 'undefined') {
      queryParameters['publishedFrom'] = parameters['publishedFrom'];
    }

    queryParameters['publishedFrom'] = this.transformParameter(
      queryParameters['publishedFrom'],
    );

    if (typeof parameters['publishedTo'] !== 'undefined') {
      queryParameters['publishedTo'] = parameters['publishedTo'];
    }

    queryParameters['publishedTo'] = this.transformParameter(
      queryParameters['publishedTo'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Count of new external articles
   * @method
   * @name Api#getCountNewExternalArticles
   */
  getCountNewExternalArticles(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<External_Articles_Count> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/externalArticlesNewCount';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Edit External Article State
   * @method
   * @name Api#editExternalArticleState
   */
  editExternalArticleState(
    appId: string,
    appSpace: string,
    contentDivider: string,
    externalArticleId: string,
    parameters: {} = {},
    body: External_Article_Put,
  ): Promise<External_Article_Put> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/externalArticles/{external_article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{external_article_id}', externalArticleId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
}

export default new Api();
export { Api };
