import { removeAccents } from '@sportnet/utilities';
import React from 'react';

const useSearch = () => {
  const [search, setSearch] = React.useState<{
    searchString: string;
    searchFocusIndex: number;
    searchFoundCount: number;
  }>({
    searchString: '',
    searchFocusIndex: 0,
    searchFoundCount: 0,
  });

  const handleSelectPrevMatch = React.useCallback(() => {
    setSearch((prev) => ({
      ...prev,
      searchFocusIndex:
        prev.searchFoundCount > 0
          ? (prev.searchFoundCount + prev.searchFocusIndex - 1) %
            prev.searchFoundCount
          : 0,
    }));
  }, []);

  const handleSelectNextMatch = React.useCallback(() => {
    setSearch((prev) => ({
      ...prev,
      searchFocusIndex:
        prev.searchFoundCount > 0
          ? (prev.searchFocusIndex + 1) % prev.searchFoundCount
          : 0,
    }));
  }, []);

  const handleSearchResult = React.useCallback((matches: any[]) => {
    setSearch((prev) => ({
      ...prev,
      searchFoundCount: matches.length,
      searchFocusIndex:
        prev.searchFocusIndex < matches.length ? prev.searchFocusIndex : 0,
    }));
  }, []);

  const handleSearchStringChange = React.useCallback((searchString: string) => {
    setSearch((prev) => ({
      ...prev,
      searchString,
    }));
  }, []);

  const handleSelectMatch = React.useCallback((searchFocusIndex: number) => {
    setSearch((prev) => ({
      ...prev,
      searchFocusIndex,
    }));
  }, []);

  const searchStringWithoutAccents = React.useMemo(() => {
    return removeAccents(search.searchString);
  }, [search.searchString]);

  return {
    ...search,
    searchStringWithoutAccents,
    handleSelectPrevMatch,
    handleSelectNextMatch,
    handleSearchResult,
    handleSearchStringChange,
    handleSelectMatch,
  };
};

export default useSearch;
