import * as React from 'react';
import Button from '@sportnet/ui/Button';
import Segment from '@sportnet/ui/Segment';
import styled from '../../theme/styled-components';

const Wrapper = styled(Segment)`
  display: flex;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  padding: 0;
  > div {
    margin-bottom: 0;
  }
`;

const ActionButton = styled(Button)``;

interface OwnProps {
  onClickAction?: () => void;
  actionActive?: boolean;
  children?: React.ReactNode;
}

const C: React.FC<OwnProps> = ({
  children,
  onClickAction,
  actionActive = false,
}) => (
  <Wrapper noBottomGutter>
    {children}
    {actionActive ? (
      <ActionButton onClick={onClickAction} icon="arrow-right" />
    ) : (
      <ActionButton
        onClick={onClickAction}
        icon="arrow-left"
        data-testid="CMS_Articles_Icon_Left"
      />
    )}
  </Wrapper>
);

export default C;
