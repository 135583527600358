import { ApiError } from '../../library/Api';
import { ArticleId } from '../../library/App';
import { Articles_Bulk_Update } from '../../Api';
import { FORM_NAME, IArticlesUpdateSidebarSegmentType } from './fields';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getResultMessage, getSelectedCountArticlesLabel } from './utils';
import { initialize } from 'redux-form';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { updateArticles } from '../../containers/ArticleList/actions';
import Form, { IFormData } from './form';
import React from 'react';
import Sidebar from '@sportnet/ui/Sidebar';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const mapDispatchToProps = {
  initialize,
  updateArticles: thunkToAction(updateArticles),
};

interface OwnProps {
  onClose: () => void;
  opened: boolean;
  openedSegment?: IArticlesUpdateSidebarSegmentType;
  selectedArticleIds: ArticleId[];
  onAfterSave?: () => void;
}

type Props = OwnProps & typeof mapDispatchToProps & WithPopupsProps;

export const ArticlesUpdateSidebar: React.FC<Props> = ({
  initialize,
  opened,
  onClose,
  openedSegment = '',
  selectedArticleIds,
  updateArticles,
  onAfterSave,
  alert,
  confirm,
}) => {
  const [submitting, setSubmitting] = React.useState(true);

  React.useEffect(() => {
    initialize(FORM_NAME, {
      articleIds: [],
      smarttags: [],
      sectionid: [],
      clearSections: false,
      clearSmarttags: false,
    });
  }, [initialize]);

  const getPayloadFromFormValues = (values: IFormData) => {
    const payload: Articles_Bulk_Update = {};

    if (values.clearSections) {
      payload.sectionId = [];
    } else if (values.sectionid.length > 0) {
      payload.sectionId = values.sectionid;
    }

    if (values.clearSmarttags) {
      payload.smarttags = [];
    } else if (values.smarttags.length > 0) {
      payload.smarttags = values.smarttags;
    }
    return payload;
  };

  const handleSubmit = async (values: IFormData) => {
    const text =
      values.articleIds.length > 0
        ? __('Označili ste') +
          ' ' +
          getSelectedCountArticlesLabel(values.articleIds.length) +
          '. ' +
          __(`Želáte si upraviť vybrané články?`)
        : __(
            `POZOR: Ak nie sú vybrané žiadne články, upravia sa všetky články, ktoré spadajú do podmienok filtra! Pokračovať?`,
          );
    confirm(text, async () => {
      setSubmitting(true);
      try {
        const payload = getPayloadFromFormValues(values);
        if (Object.keys(payload).length > 0) {
          const result = await updateArticles({
            payload,
            articleIds: values.articleIds,
          });
          alert(getResultMessage(result));
        }
      } catch (e) {
        alert(
          JSON.stringify((e as ApiError).details) || (e as ApiError).message,
        );
      } finally {
        setSubmitting(false);
        initialize(FORM_NAME, {
          articleIds: [],
          smarttags: [],
          sectionid: [],
          clearSections: false,
          clearSmarttags: false,
        });
        if (onAfterSave) {
          onAfterSave();
        }
      }
    });
  };

  return (
    <Sidebar
      visible={opened}
      onClose={onClose}
      header={__('Hromadná úprava článkov')}
    >
      <Form
        onSubmit={handleSubmit}
        openedSegment={openedSegment}
        sidebarOpened={opened}
        availableSegments={['sections', 'smarttags']}
        selectedArticleIds={selectedArticleIds}
        submitting={submitting}
      />
    </Sidebar>
  );
};

export default compose(
  withPopups,
  connect(undefined, mapDispatchToProps),
)(ArticlesUpdateSidebar) as any as React.FC<OwnProps>;
