import * as React from 'react';
import { IWidgets } from '../../library/App';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { __ } from '../../utilities/';
import { appSetupSelector, doctypesSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';
import ContentContext, {
  IContentContext,
} from '@sportnet/content/common/ContentContext';
import Segment from '@sportnet/ui/Segment';
import Tabber from '@sportnet/ui/Tabber';
import WidgetEditor from '@sportnet/content/editor';
import Widgets from './Widgets';

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
  doctypes: doctypesSelector(state),
});

interface OwnProps {
  value?: IWidgets;
  onChange: (content: IWidgets) => void;
  context?: IContentContext;
}

type Props = OwnProps &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

const tabs = [
  {
    url: '#editor-beta',
    label: __('Editor'),
  },
  {
    url: '#preview',
    label: __('Náhľad'),
  },
];

const C: React.FC<Props> = ({
  history,
  value,
  onChange,
  appSetup,
  doctypes,
  context = {},
}) => {
  const activeTab =
    tabs.find((t) => t.url === history.location.hash) || tabs[0];

  function goToPreview() {
    history.push(tabs[1].url);
  }

  function goToEditor() {
    history.push(tabs[0].url);
  }

  return (
    <ContentContext.Provider value={context}>
      <Segment raised noBottomGutter>
        <Tabber
          active={activeTab.label}
          onClickItem={(itemName) => {
            if (itemName === tabs[1].label) {
              goToPreview();
            } else if (itemName === tabs[0].label) {
              goToEditor();
            }
          }}
          items={tabs.map((tab) => tab.label)}
        >
          {activeTab === tabs[1] && (
            <ThemeProvider theme={defaultContentTheme}>
              <Widgets
                items={value || []}
                appSetup={appSetup}
                doctypes={doctypes}
                onClickGoToEditor={goToEditor}
              />
            </ThemeProvider>
          )}
          {activeTab === tabs[0] && value && (
            <ThemeProvider theme={defaultContentTheme}>
              <WidgetEditor
                appId={appSetup.appId}
                appSpace={appSetup.appSpace}
                contentDivider={appSetup.contentDivider}
                token={appSetup.token}
                initialValue={value as any}
                onChange={onChange}
              />
            </ThemeProvider>
          )}
        </Tabber>
      </Segment>
    </ContentContext.Provider>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(C) as any as React.FC<OwnProps>;
