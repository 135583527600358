/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  statusCode: number;

  name: string;

  description?: string;

  payload?: {};

  userinfo?: string;
};
export type feedId = string;
export type itemId = string;
export type feedItemId = string;
export type objectId = string;
export type Pager = {
  total?: number;

  limit?: number;

  offset?: number;

  next_offset?: number;
};
export type NotificationRequestStatus = {
  ok: boolean;

  requestId?: string;

  notificationDate?: string;

  created?: string;
};
export type NotificationRequestUser = {
  sportnetId: string;

  username?: string;

  name?: string;

  surname?: string;

  email?: string;

  phone?: string;
};
export type NotificationRequestHistoryItem = {
  notificationSentAt: string;

  title: string;

  description: string;

  date: string;

  type: 'auto' | 'manual';

  user: {
    sportnetId: string;

    username?: string;

    name?: string;

    surname?: string;

    email?: string;

    phone?: string;
  };

  status: {
    ok: boolean;

    requestId?: string;

    notificationDate?: string;

    created?: string;
  };
};
export type FeedItem_Create = {
  title: string;

  subtitle?: string;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  category?: string;

  description?: string;

  date: string;

  url: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;
};
export type FeedItem_Update = {
  title?: string;

  subtitle?: string;

  category?: string;

  description?: string;

  date?: string;

  url?: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };
};
export type FeedItem = {
  _id: string;

  itemId: string;

  appId: string;

  appSpace: string;

  feedId: string;

  createdDate: string;

  title: string;

  subtitle?: string;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  category?: string;

  description?: string;

  date: string;

  url: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;

  sorter?: number;
};
export type SmartTag = {
  key: string;

  label?: string;

  values: Array<{
    key: string;

    label?: string;
  }>;
};
export type Image = {
  media_url: string;

  public_url: string;

  dimensions?: {
    X: number;

    Y: number;

    W: number;

    H: number;
  };
};
export type Feed_Create = {
  title: string;

  description?: string;

  length?: number;

  sorter?: 'manual' | 'auto';

  fix?: number;
};
export type Feed_Update = {
  title?: string;

  description?: string;

  length?: number;

  sorter?: 'manual' | 'auto';

  fix?: number;
};
export type Feed = {
  _id: string;

  id: string;

  title: string;

  description?: string;

  appSpace: string;

  length?: number;

  sorter?: 'manual' | 'auto';

  fix?: number;

  createdDate: string;

  pushNotifications?: {
    mode: 'manual' | 'auto';

    apps: Array<string>;
  };
};
export type BannerContent = Array<{
  type: 'banners';

  mode:
    | 'full-rotation'
    | 'full-flat'
    | 'single-rotation'
    | 'single-random-rotation';

  title?: string;

  interval?: number;

  width?: number;

  items: Array<{
    picture: {
      media_url: string;

      public_url: string;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };

    url?: string;

    title?: string;
  }>;
}>;
export type Banner_Create = {
  title: string;

  dateFrom: string;

  dateTo?: string;

  content: Array<{
    type: 'banners';

    mode:
      | 'full-rotation'
      | 'full-flat'
      | 'single-rotation'
      | 'single-random-rotation';

    title?: string;

    interval?: number;

    width?: number;

    items: Array<{
      picture: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };

      url?: string;

      title?: string;
    }>;
  }>;

  positions: Array<number>;

  feeds: Array<string>;
};
export type Banner_Update = {
  title?: string;

  dateFrom?: string;

  dateTo?: string;

  content?: Array<{
    type: 'banners';

    mode:
      | 'full-rotation'
      | 'full-flat'
      | 'single-rotation'
      | 'single-random-rotation';

    title?: string;

    interval?: number;

    width?: number;

    items: Array<{
      picture: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };

      url?: string;

      title?: string;
    }>;
  }>;

  positions?: Array<number>;

  feeds?: Array<string>;
};
export type Banner = {
  _id: string;

  appSpace: string;

  createdDate?: string;

  title: string;

  dateFrom: string;

  dateTo?: string;

  content: Array<{
    type: 'banners';

    mode:
      | 'full-rotation'
      | 'full-flat'
      | 'single-rotation'
      | 'single-random-rotation';

    title?: string;

    interval?: number;

    width?: number;

    items: Array<{
      picture: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };

      url?: string;

      title?: string;
    }>;
  }>;

  positions: Array<number>;

  feeds: Array<string>;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 * RESTful API specification.

 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class Api {
  protected baseUrl: string = '';
  protected headers: { name: string; value: string }[] = [];
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });

    if (response.status === 204) return response;

    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      }
      return response;
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   *
   * @method
   * @name Api#getFeedsFromApp
   */
  getFeedsFromApp(
    appId: string,
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    feeds: Array<{
      _id: string;

      id: string;

      title: string;

      description?: string;

      appSpace: string;

      length?: number;

      sorter?: 'manual' | 'auto';

      fix?: number;

      createdDate: string;

      pushNotifications?: {
        mode: 'manual' | 'auto';

        apps: Array<string>;
      };
    }>;
  }> {
    let path = '/app/{appId}/{appSpace}/feeds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getItemsInFeedsFromApp
   */
  getItemsInFeedsFromApp(
    appId: string,
    appSpace: string,
    itemId: string,
    parameters: {} = {},
  ): Promise<{
    feeditems: Array<{
      _id: string;

      itemId: string;

      appId: string;

      appSpace: string;

      feedId: string;

      createdDate: string;

      title: string;

      subtitle?: string;

      image?: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };

      category?: string;

      description?: string;

      date: string;

      url: string;

      payload?: {};

      smarttags?: Array<{
        key: string;

        label?: string;

        values: Array<{
          key: string;

          label?: string;
        }>;
      }>;

      sorter?: number;
    }>;
  }> {
    let path = '/app/{appId}/{appSpace}/items/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#createFeedItemFromApp
   */
  createFeedItemFromApp(
    appId: string,
    appSpace: string,
    feedId: string,
    itemId: string,
    parameters: {} = {},
    body: {
      title: string;

      subtitle?: string;

      image?: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };

      category?: string;

      description?: string;

      date: string;

      url: string;

      payload?: {};

      smarttags?: Array<{
        key: string;

        label?: string;

        values: Array<{
          key: string;

          label?: string;
        }>;
      }>;
    },
  ): Promise<{
    _id: string;

    itemId: string;

    appId: string;

    appSpace: string;

    feedId: string;

    createdDate: string;

    title: string;

    subtitle?: string;

    image?: {
      media_url: string;

      public_url: string;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };

    category?: string;

    description?: string;

    date: string;

    url: string;

    payload?: {};

    smarttags?: Array<{
      key: string;

      label?: string;

      values: Array<{
        key: string;

        label?: string;
      }>;
    }>;

    sorter?: number;
  }> {
    let path = '/app/{appId}/{appSpace}/feeds/{feedId}/items/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{feedId}', feedId.toString());

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#updateFeedItemFromApp
   */
  updateFeedItemFromApp(
    appId: string,
    appSpace: string,
    feedId: string,
    itemId: string,
    parameters: {} = {},
    body: {
      title?: string;

      subtitle?: string;

      category?: string;

      description?: string;

      date?: string;

      url?: string;

      payload?: {};

      smarttags?: Array<{
        key: string;

        label?: string;

        values: Array<{
          key: string;

          label?: string;
        }>;
      }>;

      image?: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };
    },
  ): Promise<void> {
    let path = '/app/{appId}/{appSpace}/feeds/{feedId}/items/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{feedId}', feedId.toString());

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'PATCH',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#deleteFeedItemFromApp
   */
  deleteFeedItemFromApp(
    appId: string,
    appSpace: string,
    feedId: string,
    itemId: string,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/app/{appId}/{appSpace}/feeds/{feedId}/items/{itemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{feedId}', feedId.toString());

    path = path.replace('{itemId}', itemId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new Api();
export { Api };
