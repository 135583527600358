import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { IWidgets } from '../../../../library/App';
import { Link } from 'react-router-dom';
import { RootState } from '../../../../rootReducer';
import { RouteComponentProps } from 'react-router';
import { __ } from '../../../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  contentSelector,
  detailErrorSelector,
  detailIsFetchingSelector,
  detailSelector,
} from '../../selectors';
import {
  deleteSection,
  saveSection,
} from '../../../../containers/Section/actions';
import { initialize, isDirty, isSubmitting, isValid, submit } from 'redux-form';
import { loadDetail } from '../../actions';
import { saveSectionErrorHandler } from '../../../../containers/Section/errorHandler';
import { translateSportGroundAdditionalProperties } from '@sportnet/content/view/widgets/RaceMatchesList/utilities';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../../../components/ContentLoader';
import Form, { FORM_NAME, IFormData } from './form';
import HeaderBar from '@sportnet/ui/HeaderBar';
import NotFound from '@sportnet/ui/NotFound';
import React from 'react';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SubmitButton from '@sportnet/ui/Button/Submit';
import Tabs from '../../Tabs';
import WidgetsEditor from '../../../../containers/WidgetsEditor';
import withPopups, { WithPopupsProps } from '../../../../components/WithPopups';

const LINE =
  /(?:^|^)\s*(?:export\s+)?([\w.-]+)(?:\s*=\s*?|:\s+?)(\s*'(?:\\'|[^'])*'|\s*"(?:\\"|[^"])*"|\s*`(?:\\`|[^`])*`|[^#\r\n]+)?\s*(?:#.*)?(?:$|$)/gm;

// Parser src into an Object
function dotenvParse(src: string) {
  const obj: { [key: string]: string } = {};

  // Convert buffer to string
  let lines = src.toString();

  // Convert line breaks to same format
  lines = lines.replace(/\r\n?/gm, '\n');

  let match;
  while ((match = LINE.exec(lines)) != null) {
    const key = match[1];

    // Default undefined or null to empty string
    let value = match[2] || '';

    // Remove whitespace
    value = value.trim();

    // Check if double quoted
    const maybeQuote = value[0];

    // Remove surrounding quotes
    value = value.replace(/^(['"`])([\s\S]*)\1$/gm, '$2');

    // Expand newlines if double quoted
    if (maybeQuote === '"') {
      value = value.replace(/\\n/g, '\n');
      value = value.replace(/\\r/g, '\r');
    }

    // Add to object
    obj[key] = value;
  }

  return obj;
}

type RouteProps = RouteComponentProps<{ id: string }>;

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const sectionId = Number(props.match.params.id);
  return {
    isFetching: detailIsFetchingSelector(sectionId)(state),
    detail: detailSelector(sectionId)(state),
    error: detailErrorSelector(sectionId)(state),
    isSubmitting: isSubmitting(FORM_NAME)(state),
    isDirty: isDirty(FORM_NAME)(state),
    content: contentSelector(sectionId)(state),
    formIsValid: isValid(FORM_NAME)(state),
  };
};

const mapDispatchToProps = {
  initialize,
  submit,
  loadDetail: loadDetail.action,
  saveSection: saveSection.action,
  deleteSection: deleteSection.action,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  WithPopupsProps;

const C: React.FC<Props> = ({
  initialize,
  submit,
  match,
  loadDetail,
  isFetching,
  detail,
  error,
  confirm,
  saveSection,
  deleteSection,
  alert,
  isSubmitting,
  isDirty,
  history,
  content,
  formIsValid,
}) => {
  const [localContent, setLocalContent] = React.useState<IWidgets>();
  const [isLocalContentDirty, setIsLocalContentDirty] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);

  const sectionId = Number(match.params.id);

  React.useEffect(() => {
    setLocalContent(content);
  }, [content]);

  React.useEffect(() => {
    loadDetail({ sectionId });
  }, [loadDetail, sectionId]);

  React.useEffect(() => {
    if (detail) {
      initialize(
        FORM_NAME,
        {
          name: detail.name,
          heading: detail.heading,
          uniqid: detail.uniqid,
          perex: detail.perex,
          url: detail.url,
          redirecturl: detail.redirecturl,
          show: !!detail.show,
          icon: detail.icon,
          picture: detail.picture,
          publication_groups: detail.is_private
            ? detail.publication_groups || []
            : null,
          settings: Object.entries(detail.settings || {})
            .map(([skey, sval]) => {
              return `${skey}=${sval}`;
            })
            .join('\n'),
          meta: detail.meta || {},
        } as IFormData,
        {
          keepDirty: true,
        },
      );
    }
  }, [detail, initialize]);

  async function handleSubmit(values: IFormData) {
    try {
      setSubmitError(false);
      await saveSection({
        sectionId,
        values: {
          ...values,
          content: localContent || [],
          settings: dotenvParse(values.settings || ''),
        },
      });
      setIsLocalContentDirty(false);
    } catch (e) {
      setSubmitError(true);
      saveSectionErrorHandler(e, alert);
    }
  }

  function renderContent() {
    if (isFetching && typeof detail === 'undefined') {
      return <ContentLoader />;
    }
    if (error) {
      return (
        <NotFound
          title={__('Detail sekcie nie je možné načítať.')}
          icon="error"
        />
      );
    }
    return (
      <>
        <Segment raised>
          <Form onSubmit={handleSubmit} />
        </Segment>
        <WidgetsEditor
          value={localContent}
          onChange={(nextContent) => {
            setLocalContent(nextContent);
            setIsLocalContentDirty(true);
          }}
          context={{
            currentSectionId: detail?._id,
            helpers: {
              translateSportGroundAdditionalProperties,
            },
          }}
        />
      </>
    );
  }

  async function handleDelete() {
    if (!detail) {
      return;
    }
    if (await confirm(__('Naozaj chcete odstrániť túto sekciu?'))) {
      try {
        await deleteSection({ sectionId: detail._id! }); // @TODO opravit v csm/api
        history.push('/sections');
      } catch (e) {
        await alert(__('Sekciu nie je možné odstrániť'));
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to="/sections"
            title={__('Späť na zoznam')}
          />
          <HeaderBar.Header>
            {sectionId ? __('Detail sekcie') : __('Nová sekcia')}
          </HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Button danger basic disabled={!!error} onClick={handleDelete}>
              {__('Odstrániť')}
            </Button>
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              successText={__('Uložené!')}
              isSubmitting={isSubmitting}
              isError={submitError}
              disabled={
                (!isDirty && !isLocalContentDirty) || !!error || !formIsValid
              }
              onClick={() => {
                submit(FORM_NAME);
              }}
            >
              {__('Uložiť')}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext
        title={__('Detail sekcie')}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: '/sections',
          },
        ]}
      />
      <Tabs />

      <Segment>{renderContent()}</Segment>
    </ScrollLayout>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
