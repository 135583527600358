import * as React from 'react';
import { AppSpaceUser } from '../../CoreApi';
import { Article, Doctype } from '../../Api';
import { Field } from 'redux-form';
import { IAppSetup, User_Groups_List } from '../../library/App';
import { __, formatUrl, required } from '../../utilities/';
import { rem } from 'polished';
import AuthorsField from '../../components/AuthorsField';
import Feeds from '../Feeds';
import FormField from '@sportnet/ui/FormField';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Link from '@sportnet/ui/Link';
import Message from '@sportnet/ui/Message';
import Preview from '../Preview';
import PublicationGroupsField from '../../components/PublicationGroupsField';
import SectionsField from '../../components/SectionsField';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import styled from '../../theme/styled-components';

interface ISegProps {
  header: React.ReactElement;
  children?: React.ReactNode;
}

const Seg: React.FC<ISegProps> = ({ children, ...restProps }) => (
  <Segment {...restProps} noBottomGutter>
    {children}
  </Segment>
);

const UrlLink = styled(Link)`
  word-break: break-word;
`;

const BottomNegativeSegment = styled(Segment)`
  margin-bottom: ${rem(-20)};
`;

const DEFAULT_LAYOUT = {
  value: 'default',
  label: __('Default'),
};

export type IArticleSidebarSegmentType =
  | 'basic'
  | 'publication'
  | 'picture'
  | 'smarttags'
  | 'sections'
  | 'auth'
  | 'preview'
  | 'feeds'
  | 'articleLayouts'
  | 'authors'
  | '';

interface OwnProps {
  appSetup: IAppSetup;
  userGroups: User_Groups_List;
  usersWithRoles: AppSpaceUser[];
  openedSegment?: IArticleSidebarSegmentType;
  availableSegments: IArticleSidebarSegmentType[];
  sidebarOpened: boolean;
  doctypes: Doctype[];
  article?: Article;
  url: string | null | undefined;
  smarttagsCreatable: boolean;
}

type Props = OwnProps;

const ArticleSidebar: React.FC<Props> = ({
  appSetup,
  userGroups,
  openedSegment = 'basic',
  availableSegments,
  sidebarOpened,
  doctypes,
  article,
  url,
  usersWithRoles,
  smarttagsCreatable,
}) => {
  const [openedIdx, setOpenedIdx] =
    React.useState<IArticleSidebarSegmentType | null>(openedSegment);

  const doctypesById: { [key: string]: Doctype } = React.useMemo(
    () =>
      doctypes.reduce((acc: { [key: string]: Doctype }, doctype) => {
        acc[doctype.id] = doctype;
        return acc;
      }, {}),
    [doctypes],
  );

  const availableSegmentsById = React.useMemo(
    () =>
      availableSegments.reduce((acc: { [key: string]: boolean }, seg) => {
        acc[seg] = true;
        return acc;
      }, {}),
    [availableSegments],
  );

  React.useEffect(() => {
    if (sidebarOpened && !openedSegment && !openedIdx) {
      setOpenedIdx(availableSegments[0]);
    }
  }, [openedSegment, sidebarOpened, availableSegments, openedIdx]);

  React.useEffect(() => {
    if (openedSegment && sidebarOpened) {
      setOpenedIdx(openedSegment);
    }
  }, [openedSegment, sidebarOpened]);

  const handleSegmentToggle = (segment: IArticleSidebarSegmentType) => {
    return (collapsed: boolean) => {
      setOpenedIdx(collapsed ? null : segment);
    };
  };

  const articleLayoutsOptions = React.useMemo(() => {
    const layoutOptions = (appSetup.params.articleLayouts || []).map(
      (layout) => ({
        value: layout._id,
        label: layout.name,
      }),
    );
    return [DEFAULT_LAYOUT, ...layoutOptions];
  }, [appSetup.params.articleLayouts]);

  const getArticleUrl = (uri: string) => {
    const { articleUrlPattern } = appSetup.params;
    if (articleUrlPattern && uri) {
      const url: string = articleUrlPattern.replace('{{uri}}', uri);
      return (
        <Message primary title={__('URL článku')}>
          <UrlLink href={url} primary rel="noopener noreferrer" target="_blank">
            {url}
          </UrlLink>
        </Message>
      );
    }
    return (
      <Message danger title={__('Chyba URL')}>
        {__('Bez zadanej URL bude článok nedostupný.')}
      </Message>
    );
  };

  return (
    <>
      {article && (
        <BottomNegativeSegment>
          {article._id && (
            <FormField
              label={__('Identifikátor')}
              name="id"
              type="text"
              value={article._id}
              disabled
            />
          )}
          {article.externalIds && (
            <SmarttagTags
              items={article.externalIds.map(
                ({ service, externalId, error }) => {
                  return {
                    key: service || '',
                    values: [{ key: externalId || error || '' }],
                  };
                },
              )}
            />
          )}
          <Field
            component={FormFieldRedux}
            type="textarea"
            name="note"
            label={__('Interná poznámka')}
          />
        </BottomNegativeSegment>
      )}
      {'basic' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'basic'}
              toggleCollapse={handleSegmentToggle('basic')}
              size="xs"
            >
              {__('Základné informácie')}
            </SegmentHeader>
          }
        >
          <Field
            component={FormFieldRedux}
            type="text"
            name="name"
            required
            validate={[required]}
            label={__('Názov')}
          />
          <Field
            component={FormFieldRedux}
            type="theselect"
            name="doctype"
            label={__('Typ dokumentu')}
            options={Object.keys(doctypesById).map((id) => ({
              value: id,
              label: doctypesById[id].title,
            }))}
            format={(value: string = '') => {
              if (value in doctypesById) {
                return {
                  value,
                  label: doctypesById[value].title,
                };
              }
              return null;
            }}
            parse={(value: any) => {
              if (value) {
                return value.value;
              }
              return '';
            }}
          />
          <Field
            component={FormFieldRedux}
            type="textarea"
            name="perex"
            label={__('Perex')}
          />
        </Seg>
      )}
      {'publication' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'publication'}
              toggleCollapse={handleSegmentToggle('publication')}
              size="xs"
            >
              {__('Publikácia')}
            </SegmentHeader>
          }
        >
          <Field
            component={FormFieldRedux}
            type="text"
            name="url"
            label={__('URL')}
            placeholder={__('Bude vyplnené automaticky (príklad: "url")')}
            info={__(
              'Relatívna URL, na ktorej bude článok dostupný (príklad: "url-noveho-clanku"). Hodnota bude predvyplnená automaticky po prvom uložení.',
            )}
            required
            normalize={formatUrl}
            validate={[required]}
          />
          {article && article._id
            ? getArticleUrl(url || '')
            : url && getArticleUrl(url || '')}
          <Field
            component={FormFieldRedux}
            type="text"
            name="redirect"
            label={__('URL presmerovanie')}
            info={__(
              'Absolútna URL presmerovania (príklad: https://www.example.com/url-presmerovania)',
            )}
            placeholder="https://"
          />
          <Field
            component={FormFieldRedux}
            type="dateTime"
            name="valid_from"
            label={__('Publikovať od')}
          />
          {article && (
            <Field
              component={FormFieldRedux}
              type="dateTime"
              name="updated_at"
              label={__('Aktualizované')}
            />
          )}
          <Field
            component={FormFieldRedux}
            type="text"
            name="source"
            label={__('Zdroj')}
          />
          <Field
            component={FormFieldRedux}
            name="meta.title"
            counter={{ characters: true }}
            label={__('Meta title')}
          />
          <Field
            component={FormFieldRedux}
            type="textarea"
            name="meta.description"
            counter={{ characters: true }}
            label={__('Meta description')}
          />
          <Field
            component={FormFieldRedux}
            type="checkbox"
            name="locked"
            label={__('Zakázať úpravy')}
          />
        </Seg>
      )}
      {'authors' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'authors'}
              toggleCollapse={handleSegmentToggle('authors')}
              size="xs"
            >
              {__('Autori')}
            </SegmentHeader>
          }
        >
          <Field
            component={AuthorsField}
            name="authors"
            usersWithRoles={usersWithRoles}
          />
        </Seg>
      )}
      {'picture' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'picture'}
              toggleCollapse={handleSegmentToggle('picture')}
              size="xs"
            >
              {__('Titulný obrázok')}
            </SegmentHeader>
          }
        >
          <Field
            component={FormFieldRedux}
            type="image"
            name="picture"
            auth={appSetup}
          />
          <Field
            component={FormFieldRedux}
            type="text"
            label={__('Autor')}
            name="picture.author"
          />
          <Field
            component={FormFieldRedux}
            type="textarea"
            label={__('Popis')}
            name="picture.description"
          />
        </Seg>
      )}
      {'smarttags' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'smarttags'}
              toggleCollapse={handleSegmentToggle('smarttags')}
              size="xs"
            >
              {__('Smarttagy')}
            </SegmentHeader>
          }
        >
          <TagmanagerConnector
            appId={appSetup.appId}
            appspace={appSetup.appSpace}
            accessToken={appSetup.token}
          >
            {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
              <Field
                getSmarttagsValues={getSmarttagsValues}
                getSmarttagsKeys={getSmarttagsKeys}
                getSmarttagsByFts={getSmarttagsByFts}
                name="smarttags"
                type="smarttag"
                label={__('Smarttagy')}
                creatable={smarttagsCreatable}
                component={FormFieldRedux}
              />
            )}
          </TagmanagerConnector>
        </Seg>
      )}
      {'sections' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'sections'}
              toggleCollapse={handleSegmentToggle('sections')}
              size="xs"
            >
              {__('Zaradenie do sekcií')}
            </SegmentHeader>
          }
        >
          <Field component={SectionsField} name="sectionid" />
        </Seg>
      )}
      {'auth' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'auth'}
              toggleCollapse={handleSegmentToggle('auth')}
              size="xs"
            >
              {__('Autorizácia')}
            </SegmentHeader>
          }
        >
          <Field
            component={PublicationGroupsField}
            name="publication_groups"
            groups={userGroups}
          />
        </Seg>
      )}

      {'preview' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'preview'}
              toggleCollapse={handleSegmentToggle('preview')}
              size="xs"
            >
              {__('Náhľad')}
            </SegmentHeader>
          }
        >
          <Preview />
        </Seg>
      )}
      {'articleLayouts' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'articleLayouts'}
              toggleCollapse={handleSegmentToggle('articleLayouts')}
              size="xs"
            >
              {__('Šablóna')}
            </SegmentHeader>
          }
        >
          <Field
            component={FormFieldRedux}
            type="theselectsimple"
            name="layout"
            options={articleLayoutsOptions}
            validate={[required]}
            clearable={false}
          />
        </Seg>
      )}
      {'feeds' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'feeds'}
              toggleCollapse={handleSegmentToggle('feeds')}
              size="xs"
            >
              {__('Feeds')}
            </SegmentHeader>
          }
        >
          <Feeds articleId={article?._id} doctypesById={doctypesById} />
        </Seg>
      )}
    </>
  );
};

export default ArticleSidebar;
