import * as React from 'react';
import { __ } from '../../utilities/';
import { withRouter } from 'react-router-dom';
import Tabber from '@sportnet/ui/Tabber';

export default withRouter(function (props) {
  const { match, history, location } = props;

  const id = match.params.id;

  const tabs = {
    [`/sections/${id}`]: {
      label: __('Informácie'),
    },
    [`/sections/${id}/articles`]: {
      label: __('Články pod sekciou'),
    },
  };

  if (!(location.pathname in tabs)) {
    return null;
  }

  return (
    <Tabber
      active={tabs[location.pathname].label}
      onClickItem={(itemName) => {
        const nextUrl = Object.keys(tabs).find(
          (t) => tabs[t].label === itemName,
        );
        if (nextUrl) {
          history.push(nextUrl);
        }
      }}
      items={Object.keys(tabs).map((key) => tabs[key].label)}
    />
  );
});
