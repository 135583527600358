import { ALL_DOCTYPES } from '../../library/Doctypes';
import { ArticleId, SectionId } from '../../library/App';
import { Articles_Bulk_Update, Articles_Bulk_Update_Response } from '../../Api';
import { CommitError, commit } from '@sportnet/redux-list';
import { CustomThunkDispatch, ExtraArgumentType } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { SmarttagProps } from '@sportnet/ui/library/Smarttag';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { loadAll } from '../../containers/Article/actions';
import { smarttagsToUrl } from '@sportnet/tagmanager-connector';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';
import constants from './constants';

const create = actionCreatorFactory(config.ARTICLES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export type ApiArticlesQueryType = {
  offset?: number;
  q?: string;
  qopts?: string;
  ownerSportnetId?: string;
  authorId?: string;
  smarttag?: SmarttagProps[];
  sorter?: string;
  sectionid?: SectionId[];
  validFrom?: string;
  validTo?: string;
  hasDraft?: string;
  isPrivate?: string;
  doctype?: string[];
};

export function queryToApiArticlesParams(query: ApiArticlesQueryType) {
  let doctypesFilter: string[] | undefined;
  if ((query.doctype || []).includes(ALL_DOCTYPES.id)) {
    doctypesFilter = [];
  } else {
    doctypesFilter = query.doctype || [];
  }

  return {
    q: query.q,
    qopts: query.qopts,
    offset: query.offset,
    limit: constants.LIST_LIMIT,
    ownerSportnetId: query.ownerSportnetId,
    authorId: query.authorId,
    sectionId: query.sectionid ? query.sectionid : undefined,
    smarttags: smarttagsToUrl(query.smarttag) as any,
    validFrom: query.validFrom,
    validTo: query.validTo,
    hasDraft:
      query.hasDraft && query.hasDraft !== '' ? query.hasDraft === '1' : void 0,
    isPrivate:
      query.isPrivate && query.isPrivate !== ''
        ? query.isPrivate === '1'
        : void 0,
    sorter: query.sorter as
      | 'created'
      | '-created'
      | 'modified'
      | '-modified'
      | 'name'
      | '-name'
      | 'valid_from'
      | '-valid_from',
    doctype: doctypesFilter,
  };
}

export function load(query: ApiArticlesQueryType) {
  return (dispatch: CustomThunkDispatch) => {
    dispatch(
      commit.action({
        listName: constants.LIST_NAME,
        load: async () => {
          try {
            const queryParams = queryToApiArticlesParams(query);
            const params = {
              ...queryParams,
              limit: constants.LIST_LIMIT,
            };
            const res = await dispatch(loadAll.action(params));
            return {
              total: res.total || 0, // @TODO CSM/api ma total ako optional
              results: res.articles!.map((a) => a._id), // @TODO CSM/api ma articles ako optional
              nextOffset: res.nextOffset,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
}

/**
 * Bulk update of articles.
 *
 *
 */
export const updateArticles = createAsync<
  { payload: Articles_Bulk_Update; articleIds: ArticleId[] },
  Articles_Bulk_Update_Response
>(
  'UPDATE_ARTICLES',
  async ({ payload, articleIds }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());

    const ids = articleIds.map((id) => String(id)).join(',');

    const response = await Api.updateArticles(
      appId,
      appSpace,
      contentDivider,
      {
        ids,
      },
      payload,
    );

    return response;
  },
);
