import * as React from 'react';
import { IArticleSidebarSegmentType } from '../../containers/ArticleSidebar/fields';

interface ISidebarState {
  opened: boolean;
  openedSegment: IArticleSidebarSegmentType;
}

const DEFAULT_SIDEBAR_STATE: ISidebarState = {
  opened: false,
  openedSegment: 'publication',
};

type ISidebarAction =
  | { type: 'open'; segment: IArticleSidebarSegmentType }
  | { type: 'close' };

function sidebarReducer(
  state: ISidebarState,
  action: ISidebarAction,
): ISidebarState {
  switch (action.type) {
    case 'open':
      return { opened: true, openedSegment: action.segment };
    case 'close':
      return { ...state, opened: false };
    default:
      throw new Error();
  }
}

export default function useSidebar(defaultOpened = false) {
  const [sidebarState, dispatch] = React.useReducer(sidebarReducer, {
    ...DEFAULT_SIDEBAR_STATE,
    opened: defaultOpened,
  });

  return {
    isOpened: sidebarState.opened,
    openedSegment: sidebarState.openedSegment,
    open: (segment: IArticleSidebarSegmentType = '') => {
      dispatch({ type: 'open', segment });
    },
    close: React.useCallback(() => {
      dispatch({ type: 'close' });
    }, [dispatch]),
  };
}
