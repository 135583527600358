import { ExtraArgumentType } from '../../configureStore';
import { ISectionSave, SectionId } from '../../library/App';
import { RootState } from '../../rootReducer';
import { Section } from '../../Api';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { replaceEntities } from '../../pages/App/actions';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.ARTICLES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const saveSection = createAsync<
  { values: ISectionSave; sectionId?: SectionId },
  void
>('SAVE', async ({ values, sectionId }, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());
  let response: Section;

  let valuesToSave: any = values;

  if ('publication_groups' in values) {
    const is_private = values.publication_groups !== null;
    const publication_groups = values.publication_groups || [];
    valuesToSave = {
      ...values,
      is_private,
      publication_groups,
    };
  }

  if (sectionId) {
    response = await Api.editSection(
      appId,
      appSpace,
      contentDivider,
      sectionId,
      {},
      {
        ...valuesToSave,
        ...(valuesToSave.heading === undefined
          ? {}
          : { heading: valuesToSave.heading || null }),
      },
    );
  } else {
    response = await Api.postSection(
      appId,
      appSpace,
      contentDivider,
      {},
      {
        ...valuesToSave,
        content: valuesToSave.content || [],
        heading: valuesToSave.heading || null,
      },
    );
    response = await Api.editSection(
      appId,
      appSpace,
      contentDivider,
      response._id!,
      {},
      {
        sectionArticles: {
          articleFilter: [
            {
              type: 'section',
              value: response._id,
            },
          ],
        },
      } as any,
    );
  }

  dispatch(
    replaceEntities({
      sections: {
        [response._id!]: {
          ...response,
          name: response.name!,
        }, // @TODO csm/api _id optional
      },
    }),
  );
});

export const deleteSection = createAsync<{ sectionId: SectionId }, void>(
  'DELETE',
  async (params, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    await Api.deleteSection(
      appId,
      appSpace,
      contentDivider,
      params.sectionId,
      {},
    );
  },
);
