import { DoctypeId } from '../../library/App';
import { createSelector } from 'reselect';
import { entitiesSelector } from '../../pages/App/selectors';
import { hasCommitFailed, isCommiting } from '@sportnet/redux-list';
import constants from '../../containers/DoctypeList/constants';

export const detailIsFetchingSelector = (doctypeId: DoctypeId) =>
  isCommiting(constants.LIST_NAME);

export const detailSelector = (doctypeId: DoctypeId) =>
  createSelector(entitiesSelector, (entities) =>
    doctypeId in entities.doctypes ? entities.doctypes[doctypeId] : undefined,
  );

export const detailErrorSelector = (doctypeId: DoctypeId) =>
  hasCommitFailed(constants.LIST_NAME);
