import { ArticleId, IWidgets } from '../../library/App';
import { RootState } from '../../rootReducer';
import { createSelector } from 'reselect';
import { entitiesSelector } from '../../pages/App/selectors';

const detailDomain = (state: RootState) => state.articleDetail;

export const detailIsFetchingSelector = (articleId: ArticleId) =>
  createSelector(detailDomain, (domain) =>
    articleId in domain ? domain[articleId].isFetching : false,
  );

export const detailSelector = (articleId: ArticleId) =>
  createSelector(entitiesSelector, (entities) =>
    articleId in entities.articles ? entities.articles[articleId] : undefined,
  );

export const detailErrorSelector = (articleId: ArticleId) =>
  createSelector(detailDomain, (domain) =>
    articleId in domain ? domain[articleId].error : '',
  );

export const contentIsFetchingSelector = (articleId: ArticleId) =>
  createSelector(detailIsFetchingSelector(articleId), (domain) => domain);

export const contentSelector = (articleId: ArticleId) =>
  createSelector(entitiesSelector, (entities): IWidgets | undefined =>
    articleId in entities.articleContents
      ? entities.articleContents[articleId]
      : undefined,
  );
