import * as actions from './actions';
import {
  Error as ApiError,
  Translator_LanguageSettings_Item,
  Translator_TranslatedData_Item,
} from '../../Api';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

type DetailStateTranslation = Readonly<{
  isFetching: boolean;
  error?: string;
  result?: { [key: string]: Translator_TranslatedData_Item };
}>;

type DetailStateTranslationLanguages = Readonly<{
  isFetching: boolean;
  error?: string;
  result: {
    sourceLang: Translator_LanguageSettings_Item[];
    targetLang: Translator_LanguageSettings_Item[];
  } | null;
}>;

const DETAIL_INITIAL_STATE: DetailStateTranslation = {
  isFetching: false,
};

const DETAIL_INITIAL_STATE_TRANSLATION_LANGUAGES: DetailStateTranslationLanguages =
  {
    isFetching: false,
    result: null,
  };

export const detailReducer = reducerWithInitialState(DETAIL_INITIAL_STATE)
  .case(
    actions.getTranslation.async.started,
    (state): DetailStateTranslation => ({
      ...state,
      isFetching: true,
    }),
  )
  .case(
    actions.getTranslation.async.done,
    (state, { result }): DetailStateTranslation => ({
      ...state,
      isFetching: false,
      result,
    }),
  )
  .case(
    actions.getTranslation.async.failed,
    (state, { error }): DetailStateTranslation => ({
      ...state,
      isFetching: false,
      error: error.message,
    }),
  );

export const detailReducerTranslationLanguages = reducerWithInitialState(
  DETAIL_INITIAL_STATE_TRANSLATION_LANGUAGES,
)
  .case(
    actions.getTranslationLanguages.async.started,
    (state): DetailStateTranslationLanguages => ({
      ...state,
      isFetching: true,
    }),
  )
  .case(
    actions.getTranslationLanguages.async.done,
    (state, { result }): DetailStateTranslationLanguages => ({
      ...state,
      isFetching: false,
      result,
    }),
  )
  .case(
    actions.getTranslationLanguages.async.failed,
    (state, { error }): DetailStateTranslationLanguages => ({
      ...state,
      isFetching: false,
      error: (error as ApiError).name,
    }),
  );
