import * as React from 'react';
import { ContextBar, ContextBarItem } from '@sportnet/ui/ContextBar';
import { RootState } from '../../rootReducer';
import { Section } from '../../Api';
import { SectionId, Writeable } from '../../library/App';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { expandNodesInNewTreeWhichWereExpandedInOldTree } from '../../components/Tree/utilities';
import {
  loadTree,
  moveSection,
  setSectionTreeScrollTopPosition,
  setSectionTreeUIState,
} from './actions';
import { rem } from 'polished';
import {
  sectionTreeIsFetchingSelector,
  sectionTreeScrollTopPositionSelector,
  sectionTreeSelector,
  sectionTreeUIStateSelector,
} from './selectors';
import { toggleExpandedForAll } from '@sportnet/react-sortable-tree';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Icon from '@sportnet/ui/Icon';
import Link from '@sportnet/ui/Link';
import Loader from '@sportnet/ui/Loader';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import SectionSidebar from '../../containers/SectionSidebar';
import Segment from '@sportnet/ui/Segment';
import Tree, { MenuItem } from '../../components/Tree';
import styled from '../../theme/styled-components';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const FullHeightScrollLayout = styled(ScrollLayout)`
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    > div {
      flex-grow: 1;
    }
  }
  & > div:nth-child(3) > div {
    height: ${({ theme }) => rem(theme.contextBar.height)};
  }
`;

const mapStateToProps = (state: RootState) => ({
  sectionTree: sectionTreeSelector(state),
  sectionTreeIsFetching: sectionTreeIsFetchingSelector(state),
  tree: sectionTreeUIStateSelector(state),
  treeScrollTopPosition: sectionTreeScrollTopPositionSelector(state),
});

const mapDispatchToProps = {
  loadTree: loadTree.action,
  moveSection: moveSection.action,
  setTree: setSectionTreeUIState,
  setSectionTreeScrollTopPosition,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithPopupsProps;

const C: React.FC<Props> = ({
  loadTree,
  sectionTree,
  sectionTreeIsFetching,
  moveSection,
  alert,
  tree,
  setTree,
  treeScrollTopPosition,
  setSectionTreeScrollTopPosition,
}) => {
  const [quickEditSection, setQuickEditSection] = React.useState<
    (Section & { parentSectionId?: SectionId }) | null
  >(null);

  const [fetching, setFetching] = React.useState(0);

  React.useEffect(() => {
    loadTree();
  }, [loadTree]);

  React.useEffect(() => {
    if (sectionTree) {
      setTree(
        expandNodesInNewTreeWhichWereExpandedInOldTree(
          tree as Writeable<MenuItem[]>,
          sectionTree as any,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionTree]);

  const handleOnTreeScroll = React.useCallback(
    (o: { clientHeight: number; scrollHeight: number; scrollTop: number }) => {
      setSectionTreeScrollTopPosition(o.scrollTop);
    },
    [setSectionTreeScrollTopPosition],
  );

  return (
    <FullHeightScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Správa sekcií')}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            {fetching > 0 ? (
              <>
                {__('Ukladajú sa zmeny ...')}&nbsp; <Loader />
              </>
            ) : (
              <>
                {__('Všetko je uložené')}&nbsp; <Icon name="check" />
              </>
            )}
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__('Správa sekcií')} />

      <Segment noBottomGutter>
        <Link
          primary
          onClick={() =>
            setTree(
              toggleExpandedForAll({
                treeData: tree as Writeable<MenuItem[]>,
                expanded: true,
              }) as MenuItem[],
            )
          }
        >
          {__('Všetko otvoriť')}
        </Link>
        {' | '}
        <Link
          primary
          onClick={() =>
            setTree(
              toggleExpandedForAll({
                treeData: tree as Writeable<MenuItem[]>,
                expanded: false,
              }) as MenuItem[],
            )
          }
        >
          {__('Všetko zavrieť')}
        </Link>
        {' | '}
        <Link primary onClick={() => setQuickEditSection({})}>
          {__('Vytvoriť koreňovú sekciu')}
        </Link>
        {sectionTreeIsFetching && !tree.length && <Loader />}
      </Segment>

      <Tree
        initScrollTopPosition={treeScrollTopPosition}
        treeData={tree as Writeable<MenuItem[]>}
        onChange={setTree}
        onMoveNode={async (move) => {
          setFetching((a) => a + 1);
          try {
            await moveSection(move);
          } catch (e) {
            console.log(e);
            await alert(__('Nemožno zmeniť poradie'));
          }
          setFetching((a) => a - 1);
        }}
        onClickPencil={(section) => setQuickEditSection(section)}
        onClickPlus={(section) =>
          setQuickEditSection({
            parentSectionId: section._id,
          })
        }
        linkRenderer={(node) => `/sections/${node._id}`}
        onScroll={handleOnTreeScroll}
      />

      <SectionSidebar
        opened={quickEditSection !== null}
        onClose={() => {
          setQuickEditSection(null);
        }}
        section={quickEditSection !== null ? quickEditSection : undefined}
        onAfterSave={() => {
          setQuickEditSection(null);
          loadTree();
        }}
        onAfterDelete={() => {
          setQuickEditSection(null);
          loadTree();
        }}
      />
    </FullHeightScrollLayout>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
