import { ApiError } from '../../library/Api';
import { RootState } from '../../rootReducer';
import {
  SubmissionError,
  change as changeFormFiled,
  hasSubmitFailed,
  hasSubmitSucceeded,
  initialize,
  isDirty,
  isSubmitting,
  reset,
  submit,
} from 'redux-form';
import { __ } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getTranslation, getTranslationLanguages } from './actions';
import { mb } from '@sportnet/ui/Themes/utilities';
import {
  targetLanguageByIdSelector,
  translationLanguagesIsFetchingSelector,
  translationLanguagesSelector,
  translationSelector,
} from './selectors';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { useHistory } from 'react-router';
import Api, {
  Article,
  Article_Post,
  Translator_TextToTranslate_Item,
} from '../../Api';
import Button from '@sportnet/ui/Button';
import ContextBar, {
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import React from 'react';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SubmitButton from '@sportnet/ui/Button/Submit';
import TranslatorForm, { FORM_NAME, IFormData } from './TranslatorForm';
import styled from 'styled-components';

const HideOnMobile = styled.span`
  display: none;
  ${mb('xs')} {
    display: initial;
  }
`;

const mapStateToProps = (state: RootState) => {
  return {
    isDirty: isDirty(FORM_NAME)(state),
    defaultTargetLanguge: targetLanguageByIdSelector(state, 'SK'),
    appSetup: appSetupSelector(state),
    translation: translationSelector(state),
    submitting: isSubmitting(FORM_NAME)(state),
    isFetchingTranslationLanguages:
      translationLanguagesIsFetchingSelector(state),
    translationLanguages: translationLanguagesSelector(state),
    hasSubmitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
    hasSubmitFailed: hasSubmitFailed(FORM_NAME)(state),
  };
};

const mapDispatchToProps = {
  initialize,
  getTranslation: thunkToAction(getTranslation.action),
  getTranslationLanguages: thunkToAction(getTranslationLanguages.action),
  changeFormFiled,
  submit,
  reset,
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const Translator: React.FC<Props> = ({
  initialize,
  getTranslation,
  getTranslationLanguages,
  changeFormFiled,
  defaultTargetLanguge,
  appSetup,
  translation,
  submit,
  reset,
  submitting,
  isFetchingTranslationLanguages,
  translationLanguages,
  hasSubmitSucceeded,
  hasSubmitFailed,
}) => {
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      await getTranslationLanguages();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      initialize(FORM_NAME, {
        targetLang: defaultTargetLanguge,
        title: '',
        perex: '',
        body: '',
      } as IFormData);
    })();
  }, [initialize, defaultTargetLanguge]);

  const handleSubmit = async (formData: IFormData) => {
    const textToTranslateList: Translator_TextToTranslate_Item[] = [];

    const {
      title: titleSrc,
      perex: perexSrc,
      body: bodySrc,
      targetLang,
      sourceLang,
    } = formData;

    const title = titleSrc.trim();
    const perex = perexSrc.trim();
    const body = bodySrc.trim();

    if (!targetLang) {
      throw new SubmissionError<IFormData>({
        targetLang: __('Cieľový jazyk je povinné pole.'),
      });
    }

    if (!title && !perex && !body) {
      throw new SubmissionError<IFormData>({
        _error: __('Zadajte aspoň jedno z textových polí.'),
      });
    }

    if (title) {
      textToTranslateList.push({
        sourceLang: sourceLang?.value,
        targetLang: targetLang.value,
        name: 'title',
        text: title,
        contentType: 'string',
      });
    }

    if (perex) {
      textToTranslateList.push({
        sourceLang: sourceLang?.value,
        targetLang: targetLang.value,
        name: 'perex',
        text: perex,
        contentType: 'string',
      });
    }

    if (body) {
      textToTranslateList.push({
        sourceLang: sourceLang?.value,
        targetLang: targetLang.value,
        name: 'body',
        text: body,
        contentType: 'string',
      });
    }

    try {
      const traslation = await getTranslation({ textToTranslateList });
      changeFormFiled(
        FORM_NAME,
        'title_translated',
        traslation['title'] ? traslation['title'].text : '',
      );
      changeFormFiled(
        FORM_NAME,
        'perex_translated',
        traslation['perex'] ? traslation['perex'].text : '',
      );
      changeFormFiled(
        FORM_NAME,
        'body_translated',
        traslation['body'] ? traslation['body'].text : '',
      );
    } catch (e) {
      const apiError = e as ApiError;
      throw new SubmissionError<IFormData>({
        _error:
          (apiError?.details?.description || apiError?.details?.name) ??
          apiError.message ??
          'UNKNOWNN_ERROR',
      });
    }
  };

  const handleCreateArticleButtonClick = async () => {
    const title = translation?.title?.text ?? '';
    const perex = translation?.perex?.text;
    const body = translation?.body?.text;

    const data = {
      name: title,
      perex,
      widgets: [{ type: 'text', text: body }],
    };

    const takenArticle: Article = await Api.postArticle(
      appSetup.appId,
      appSetup.appSpace,
      appSetup.contentDivider,
      {},
      data as Article_Post,
    );

    history.push(`/articles/detail/${takenArticle._id}`);
  };

  const disableWholeForm =
    (isFetchingTranslationLanguages && !translationLanguages) || submitting;

  const handleResetClick = () => {
    reset(FORM_NAME);
  };

  return (
    <ScrollLayout
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Button
              danger
              type="reset"
              onClick={handleResetClick}
              disabled={disableWholeForm}
              icon="trash"
            >
              <HideOnMobile>{__('Reset')}</HideOnMobile>
            </Button>
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              isSubmitting={submitting}
              isError={hasSubmitFailed}
              successText={__('Preložiť')}
              onClick={() => {
                submit(FORM_NAME);
              }}
              disabled={disableWholeForm}
              icon="plus"
            >
              {__('Preložiť')}
            </SubmitButton>
          </ContextBarItem>
          <ContextBarItem>
            <Button
              primary
              disabled={disableWholeForm || !hasSubmitSucceeded}
              type="button"
              onClick={handleCreateArticleButtonClick}
              icon="edit-document"
            >
              <HideOnMobile>{__('Vytvoriť článok')}</HideOnMobile>
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment noBottomGutter raised paddingSize="xxxs">
          <TranslatorForm onSubmit={handleSubmit} />
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Translator,
);
