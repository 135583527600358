import { WithPopupsProps } from '../../components/WithPopups';
import { __ } from '../../utilities/';

export const saveDoctypeErrorHandler = async (
  e: any,
  alertFn: WithPopupsProps['alert'],
) => {
  const alert = alertFn;
  if (e && e.details) {
    if (e.details.code === 403) {
      await alert(__('Prístup bol odmietnutý'));
    } else {
      await alert(__('Typ dokumentu nie je možné uložiť'));
    }
  } else {
    await alert(__('Vyskytla sa neočakávaná chyba'));
  }
};
