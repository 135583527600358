import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import { connect } from 'react-redux';
import {
  hasCommitFailed,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { load } from './actions';
import { staticContentsSelector } from './selectors';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Link from '@sportnet/ui/Link';
import List from '../../components/StaticContentList';
import NotFound from '@sportnet/ui/NotFound';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import constants from './constants';

interface IOwnProps {}

const mapStateToProps = (state: RootState) => ({
  items: staticContentsSelector(state),
  isLoading: isCommiting(constants.LIST_NAME)(state),
  hasCommitFailed: hasCommitFailed(constants.LIST_NAME)(state),
});

const mapDispatchToProps = {
  load,
  initialize,
  setParams,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

const C: React.FC<Props> = ({
  items,
  load,
  isLoading,
  hasCommitFailed,
  initialize,
}) => {
  // Initialize redux-list
  React.useEffect(() => {
    initialize({
      listName: constants.LIST_NAME,
      initialParams: {
        offset: 0,
      },
    });
    setParams({
      listName: constants.LIST_NAME,
      parameters: {
        offset: 0,
      },
    });
  }, [initialize]);

  React.useEffect(() => {
    load();
  }, [load]);

  function render() {
    if (hasCommitFailed) {
      return (
        <NotFound
          title={__('Ups! Nastala chyba pri načítaní')}
          icon="error"
          actionLabel={__('Skúsiť znova')}
          onClickAction={() => load()}
        />
      );
    }

    if (isLoading && items.length === 0) {
      return <ContentLoader />;
    }

    if (items.length === 0) {
      return <NotFound title={__('Žiadne statické texty')} icon="search" />;
    }

    return <List items={items} />;
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Všetky statické texty')}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Link to="/static-content/detail">
              <Button primary>{__('Nový statický text')}</Button>
            </Link>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment noBottomGutter raised>
          {render()}
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(C) as any as React.FC<IOwnProps>;
