import * as React from 'react';
import { IArticle } from '../../library/App';
import { IArticleSidebarSegmentType } from './fields';
import { RootState } from '../../rootReducer';
import { __, prepareArticleToSave } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { deleteArticle, saveArticle } from '../Article/actions';
import { doctypesSelector } from '../../pages/App/selectors';
import { initialize } from 'redux-form';
import { saveArticleErrorHandler } from '../Article/errorHandler';
import Form, { FORM_NAME, IFormData } from './form';
import Sidebar from '@sportnet/ui/Sidebar';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const mapStateToProps = (state: RootState) => ({
  doctypes: doctypesSelector(state),
});

const mapDispatchToProps = {
  initialize,
  saveArticle: saveArticle.action,
  deleteArticle: deleteArticle.action,
};

interface OwnProps {
  onClose: () => void;
  onAfterSave?: () => void;
  onAfterDelete?: () => void;
  opened: boolean;
  article?: IArticle;
  openedSegment?: IArticleSidebarSegmentType;
  availableSegments?: IArticleSidebarSegmentType[];
  showControlButtons?: boolean;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithPopupsProps;

const C = React.memo<Props>(
  ({
    initialize,
    article,
    opened,
    onClose,
    onAfterSave,
    onAfterDelete,
    saveArticle,
    deleteArticle,
    doctypes,
    alert,
    confirm,
    openedSegment = 'basic',
    availableSegments = [
      'publication',
      'auth',
      'authors',
      'basic',
      'picture',
      'sections',
      'smarttags',
      'articleLayouts',
    ],
    showControlButtons = true,
  }) => {
    React.useEffect(() => {
      const valuesToInitialize: Partial<IFormData> = { layout: 'default' };
      if (article) {
        if (availableSegments.includes('basic')) {
          valuesToInitialize['name'] = article.name;
          valuesToInitialize['perex'] = article.perex;
          valuesToInitialize['doctype'] = article.doctype;
        }

        initialize(FORM_NAME, {
          ...valuesToInitialize,
          url: article.url,
          redirect: article.redirect,
          valid_from: article.valid_from,
          updated_at: article.updated_at || null,
          source: article.source,
          locked: article.locked,
          picture: article.picture,
          note: article.note,
          meta: article.meta || {},
          smarttags: article.smarttags,
          sectionid: article.sectionid
            ? Array.isArray(article.sectionid) || article.sectionid === null
              ? article.sectionid
              : [article.sectionid]
            : [],
          publication_groups: article.is_private
            ? article.publication_groups || []
            : null,
          layout: article.layout || 'default',
          authors: article.authors || [],
        } as IFormData);
      } else {
        if (doctypes.length > 0) {
          valuesToInitialize.doctype = doctypes[0].id;
        }
        initialize(FORM_NAME, valuesToInitialize);
      }
    }, [initialize, article, availableSegments, doctypes]);

    async function handleSubmit(values: IFormData) {
      if (!article) {
        return;
      }

      try {
        await saveArticle({
          articleId: article._id,
          values: prepareArticleToSave(values),
        });
        if (onAfterSave) {
          onAfterSave();
        }
      } catch (e) {
        await saveArticleErrorHandler(e, alert);
      }
    }

    async function handleDelete() {
      if (!article) {
        return;
      }
      if (await confirm(__(`Odstrániť článok "${article.name}"?`))) {
        try {
          await deleteArticle({ articleId: article._id! }); // @TODO opravit v csm/api
          if (onAfterDelete) {
            onAfterDelete();
          }
        } catch (e) {
          await alert(__('Článok nie je možné odstrániť'));
        }
      }
    }

    return (
      <Sidebar visible={opened} onClose={onClose} header={__('Úprava článku')}>
        <Form
          onDelete={showControlButtons ? handleDelete : undefined}
          onSubmit={handleSubmit}
          submitAvailable={showControlButtons}
          openedSegment={openedSegment}
          sidebarOpened={opened}
          availableSegments={availableSegments}
          article={article}
        />
      </Sidebar>
    );
  },
);

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C) as any as React.FC<OwnProps>;
