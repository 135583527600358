// import 'react-sortable-tree/style.css';
import * as React from 'react';
import { __ } from '../../utilities/';
import { accentInsensitiveSearch } from './utilities';
import { changeNodeAtPath } from '@sportnet/react-sortable-tree';
import { rem } from 'polished';
import Icon from '@sportnet/ui/Icon';
import Link from '@sportnet/ui/Link';
import SearchInput from './SearchInput';
import Segment from '@sportnet/ui/Segment';
import styled from '../../theme/styled-components';
import useSearch from './useSearch';

const isBrowser = typeof window !== 'undefined';
const SortableTree = isBrowser
  ? require('@sportnet/react-sortable-tree').default
  : undefined;

const Wrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  height: 100%;
  .rst_wrapper {
    height: 100vh;
  }
  .rst__tree {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }
  .rst__rowContents {
    align-items: initial;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.textColor};
    overflow: hidden;
    border-radius: ${({ theme }) => theme.largeBorderRadius};
  }
  .rst__rowTitle {
    font-weight: bold;
    display: block;
    height: 100%;
    width: 100%;
  }
  .rst__rowLabel {
    padding: 0;
    flex: 1;
  }
  .rst__moveHandle {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.textColor};
    border-right: 0;
    border-radius: ${({ theme }) => theme.largeBorderRadius};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    & ~ .rst__rowContents {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .rst__rowLandingPad::before,
  .rst__rowCancelPad::before {
    background-color: ${({ theme }) => theme.color.primary};
  }

  .rst__rowSearchMatch {
    outline: solid 3px ${({ theme }) => theme.color.success};
  }
  .rst__rowSearchFocus {
    outline: solid 3px ${({ theme }) => theme.color.primary};
  }
`;

const NodeButton = styled.button`
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 100%;
  display: block;
  font-size: ${rem(14)};
  width: 100%;
  text-align: left;
  padding: 0 0.5em;
  background: none;
  color: ${({ theme }) => theme.color.primary};
  text-align: left;

  :hover {
    background: ${({ theme }) => theme.color.primaryBg};
  }
  :disabled {
    color: ${({ theme }) => theme.color.fadedText};
    cursor: not-allowed;
    background: none;
  }
`;

const NodeLink = styled(Link)`
  text-decoration: none !important;
`;

const ActionButton = styled(NodeButton)`
  && {
    padding: 0 1em;
  }
`;

export interface MenuItem {
  _id: number;
  title: string;
  children?: MenuItem[];
}

export interface TreeNodeMoveAction {
  node: MenuItem;
  nextParentNode: MenuItem;
  treeData: MenuItem[];
  prevTreeIndex: number;
  nextTreeIndex: number;
  nextPath: number[];
  prevPath: number[];
}

interface OwnProps {
  treeData: MenuItem[];
  onChange?: (menuItems: MenuItem[]) => void;
  onClickPlus?: (node: MenuItem) => void;
  onClickPencil?: (node: MenuItem) => void;
  buttons?: Array<{
    icon: string;
    onClick: () => void;
  }>;
  activeNodeId?: string | null;
  canDrag?: boolean;
  onMoveNode?: (move: TreeNodeMoveAction) => void;
  sortable?: boolean;
  linkRenderer?: (node: MenuItem) => string;
  onClickNode?: (node: MenuItem) => void;
  isNodeDisabled?: (node: MenuItem) => boolean;
  onScroll?: (o: {
    clientHeight: number;
    scrollHeight: number;
    scrollTop: number;
  }) => void;
  initScrollTopPosition?: number;
}

type Props = OwnProps;

export default React.memo(function (props: Props) {
  const {
    treeData,
    onChange,
    onClickPencil,
    onClickPlus,
    onMoveNode,
    canDrag,
    sortable = true,
    linkRenderer,
    onClickNode,
    isNodeDisabled,
    onScroll,
    initScrollTopPosition,
  } = props;

  const search = useSearch();

  const refTree = React.useRef<HTMLDivElement | null>(null);

  function renderLink(node: MenuItem) {
    let disabled = false;
    if (isNodeDisabled) {
      disabled = isNodeDisabled(node);
    }

    if (disabled) {
      return <NodeButton disabled>{node.title}</NodeButton>;
    }

    if (linkRenderer) {
      return (
        <NodeLink to={linkRenderer(node)}>
          <NodeButton>{node.title}</NodeButton>
        </NodeLink>
      );
    }
    if (onClickNode) {
      return (
        <NodeButton onClick={() => onClickNode(node)}>{node.title}</NodeButton>
      );
    }
    return null;
  }

  const handleVirtualizedScroll = (o: {
    clientHeight: number;
    scrollHeight: number;
    scrollTop: number;
  }) => {
    if (onScroll && refTree.current) {
      onScroll(o);
    }
  };

  const handleVirtualizedOnRowsRendered = (o: {
    overscanStartIndex: number;
    overscanStopIndex: number;
    startIndex: number;
    stopIndex: number;
  }) => {
    if (!refTree.current) {
      refTree.current = document.querySelector('.rst__virtualScrollOverride');
      if (refTree.current) {
        if (initScrollTopPosition) {
          refTree.current.scrollTop = initScrollTopPosition;
        }
      }
    }
  };

  return (
    <Wrapper>
      {treeData.length > 0 && (
        <>
          <Segment noBottomGutter>
            <SearchInput
              value={search.searchString}
              onChange={search.handleSearchStringChange}
              placeholder={__('Zadajte názov sekcie')}
              onSelectMatch={search.handleSelectMatch}
              onSelectPrevMatch={search.handleSelectPrevMatch}
              onSelectNextMatch={search.handleSelectNextMatch}
              searchFocusIndex={search.searchFocusIndex}
              searchFoundCount={search.searchFoundCount}
            />
          </Segment>

          <div className="rst_wrapper">
            <SortableTree
              getNodeKey={(data: any) => data.node._id}
              canDrag={sortable ? canDrag : false}
              onMoveNode={onMoveNode}
              treeData={treeData}
              isVirtualized={false}
              onChange={onChange}
              reactVirtualizedListProps={{
                onScroll: handleVirtualizedScroll,
                onRowsRendered: handleVirtualizedOnRowsRendered,
              }}
              generateNodeProps={({
                node,
                path,
              }: {
                node: MenuItem;
                path: number[];
              }) => ({
                buttons: [
                  onClickPlus && (
                    <ActionButton
                      onClick={() => {
                        onClickPlus(node);
                        if (onChange) {
                          onChange(
                            changeNodeAtPath({
                              ignoreCollapsed: false,
                              path,
                              getNodeKey: (data) => data.node._id,
                              treeData,
                              newNode: ({ node }: { node: any }) => {
                                return { ...node, expanded: true };
                              },
                            }) as MenuItem[],
                          );
                        }
                      }}
                    >
                      <Icon
                        size={18}
                        name="plus"
                        data-testid="CMS_Section_Button_Plus"
                      />
                    </ActionButton>
                  ),
                  onClickPencil && (
                    <ActionButton
                      onClick={() => {
                        onClickPencil(node);
                      }}
                    >
                      <Icon
                        size={18}
                        name="pencil"
                        data-testid="CMS_Section_Button_Pencil"
                      />
                    </ActionButton>
                  ),
                ],
                title: renderLink(node),
              })}
              // search
              searchMethod={accentInsensitiveSearch}
              searchQuery={search.searchStringWithoutAccents}
              searchFocusOffset={search.searchFocusIndex}
              searchFinishCallback={search.handleSearchResult}
            />
          </div>
        </>
      )}
    </Wrapper>
  );
});
