import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import UrlmapList from '../../containers/UrlmapList';

interface IOwnProps {}

type Props = IOwnProps;
type RouteProps = RouteComponentProps<{ type?: string }>;

const mapStateToProps = (state: any, props: RouteProps) => {
  return { type: props.match.params.type };
};

const C: React.FC<Props> = () => {
  return (
    <>
      <UrlmapList />
    </>
  );
};

export default compose(withRouter, connect(mapStateToProps))(C);
