import * as React from 'react';
import { ArticleId, IWidgets, Writeable } from '../../library/App';
import { FORM_NAME, IFormData } from './BasicInfoForm';
import {
  IFormData as ISidebarFormData,
  FORM_NAME as SIDEBAR_FORM_NAME,
} from '../../containers/ArticleSidebar/form';
import { Prompt } from 'react-router';
import { RootState } from '../../rootReducer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { prepareArticleToSave } from '../../utilities/';
import { saveArticleDraft } from '../../containers/Article/actions';
import { thunkToAction } from 'typescript-fsa-redux-thunk';

const mapStateToProps = (state: RootState) => {
  return {
    formValues: getFormValues(FORM_NAME)(state) as IFormData | undefined,
    sidebarFormValues: getFormValues(SIDEBAR_FORM_NAME)(state) as
      | ISidebarFormData
      | undefined,
  };
};

const mapDispatchToProps = {
  saveArticleDraft: thunkToAction(saveArticleDraft.action),
};

interface IOwnProps {
  articleId: ArticleId;
  localContent: Writeable<IWidgets | undefined>;
  isFormDirty: boolean;
  handleIsSubmitting: (value: boolean) => void;
  handleIsConceptSaved: () => void;
}

const INTERVAL_VALUE = 120000; // 120s

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

const DraftManager: React.FC<IProps> = (props) => {
  const {
    articleId,
    formValues,
    localContent,
    sidebarFormValues,
    isFormDirty,
    saveArticleDraft,
    handleIsSubmitting,
    handleIsConceptSaved,
  } = props;

  const isActive = !!articleId && isFormDirty; // we are editing existing article and form is dirty

  const formatArticle = () => {
    const articleData: Partial<ISidebarFormData> &
      IFormData & { widgets: Writeable<IWidgets> } = {
      ...(sidebarFormValues || {}),
      ...((formValues || {}) as IFormData),
      widgets: localContent as Writeable<IWidgets>,
    };

    return prepareArticleToSave(articleData);
  };

  const dataRef = React.useRef<typeof formatArticle>(formatArticle);
  dataRef.current = formatArticle;

  React.useEffect(() => {
    if (!isActive) {
      return;
    }
    const saveDraft = async () => {
      handleIsSubmitting(true);
      try {
        await saveArticleDraft({ values: dataRef.current(), articleId });
      } catch (e) {
        //
      }
      handleIsSubmitting(false);
      handleIsConceptSaved();
    };
    const interval = setInterval(saveDraft, INTERVAL_VALUE);

    window.addEventListener('beforeunload', saveDraft);

    return () => {
      clearInterval(interval);
      window.removeEventListener('beforeunload', saveDraft);
    };
    // eslint-disable-next-line
  }, [isActive, articleId, saveArticleDraft]);

  return (
    <Prompt
      when={isActive}
      message={() => {
        (async () => {
          try {
            handleIsSubmitting(true);
            await saveArticleDraft({ values: dataRef.current(), articleId });
          } catch (e) {
            //
          }
          handleIsSubmitting(false);
          handleIsConceptSaved();
        })();
        return true;
      }}
    />
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DraftManager,
);
