import { IListArticle } from '../../library/Article';
import { ThemeInterface } from '../../theme/theme';
import { __ } from '../../utilities/';
import { format } from 'date-fns';
import { getProp } from '@sportnet/utilities';
import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import { withTheme } from 'styled-components';
import Icon from '@sportnet/ui/Icon';
import Link from '@sportnet/ui/Link';
import React from 'react';
import config from '../../config';
import styled, { css } from '../../theme/styled-components';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.color.fadedText};
  font-size: 90%;
  margin-top: 0.2em;
`;

const DesktopWrapper = styled.div`
  display: none;
  ${mb('s')} {
    display: block;
  }
`;

const MobileWrapper = styled.div`
  display: block;
  ${mb('s')} {
    display: none;
  }
`;

const ShowMoreWrapper = styled.div<{ isOpened: boolean }>`
  display: flex;
  align-items: center;
  padding: ${rem(4)};
  margin-left: ${rem(-4)};
  width: 100%;
  > .icon {
    transform: ${({ isOpened }) => (isOpened ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

const MobileMetaContent = styled.div<{ isOpened: boolean }>`
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  ${({ isOpened }) =>
    isOpened
      ? css`
          max-height: 500px;
        `
      : css`
          max-height: 0;
        `}
`;

interface OwnProps {
  article: IListArticle;
  onClickOwner?: (ownerSportnetId: string) => void;
  onClickAuthor?: (authorId: string) => void;
}

const ArticleMeta: React.FC<OwnProps & { theme: ThemeInterface }> = ({
  theme,
  article,
  onClickOwner,
  onClickAuthor,
}) => {
  const [isMobileMetaOpened, setIsMobileMetaOpened] = React.useState(false);

  const renderMeta = () => {
    return (
      <>
        {__('Vytvoril: ')}
        {onClickOwner ? (
          <Link
            href="#"
            faded
            onClick={(e) => {
              e.stopPropagation();
              const ownerSportnetId = getProp(
                article,
                ['owner', 'sportnetId'],
                '',
              );
              if (ownerSportnetId) {
                onClickOwner(ownerSportnetId);
              }
            }}
          >
            {getProp(article, ['owner', 'name'])!}
          </Link>
        ) : (
          getProp(article, ['owner', 'name'])
        )}
        {!!article.authors && !!article.authors.length && (
          <>
            {' | '}
            {__('Autor: ')}
            {article.authors
              .map(({ sportnetId, name }) =>
                onClickAuthor && sportnetId ? (
                  <Link
                    href="#"
                    faded
                    key={`author-${sportnetId}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickAuthor(sportnetId);
                    }}
                  >
                    {name}
                  </Link>
                ) : (
                  name
                ),
              )
              .map((e, i) =>
                i === 0
                  ? [e]
                  : [
                      <React.Fragment key={`element-${i}`}>, </React.Fragment>,
                      e,
                    ],
              )
              .reduce((a, b) => [...a, ...b])}
          </>
        )}
        {!!article.created && (
          <>
            {' | '}
            {__('Vytvorené')}:{' '}
            {format(new Date(article.created), config.DATETIME_FORMAT)}
          </>
        )}
      </>
    );
  };

  const handleShowMoreClick = (e: any) => {
    e.stopPropagation();
    setIsMobileMetaOpened(!isMobileMetaOpened);
  };

  return (
    <Wrapper>
      <DesktopWrapper>{renderMeta()}</DesktopWrapper>
      <MobileWrapper>
        <ShowMoreWrapper
          isOpened={isMobileMetaOpened}
          onClick={handleShowMoreClick}
        >
          {__('Viac')}
          <Icon name="triangle-down" color={theme.color.fadedText} />
        </ShowMoreWrapper>
        <MobileMetaContent isOpened={isMobileMetaOpened}>
          {renderMeta()}
        </MobileMetaContent>
      </MobileWrapper>
    </Wrapper>
  );
};

export default withTheme(ArticleMeta);
