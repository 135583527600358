import {
  Field,
  Form,
  InjectedFormProps,
  getFormSubmitErrors,
  reduxForm,
} from 'redux-form';
import { RootState } from '../../rootReducer';
import { Translator_TextToTranslate_Item } from '../../Api';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  translationLanguagesIsFetchingSelector,
  translationLanguagesSelector,
} from './selectors';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';
import Message from '@sportnet/ui/Message';
import React from 'react';
import Segment from '@sportnet/ui/Segment';

export const FORM_NAME = 'trasnlate-form';

export interface IFormData {
  title: string;
  perex: string;
  body: string;
  targetLang: {
    label: string;
    value: Required<Translator_TextToTranslate_Item>['targetLang'];
  } | null;
  sourceLang?: {
    label: string;
    value: Required<Translator_TextToTranslate_Item>['sourceLang'];
  } | null;
}

const mapStateToProps = (state: RootState) => {
  return {
    submitErrors: getFormSubmitErrors(FORM_NAME)(state),
    translationLanguages: translationLanguagesSelector(state),
    isFetchingTranslationLanguages:
      translationLanguagesIsFetchingSelector(state),
  };
};

interface OwnProps {}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  InjectedFormProps<IFormData>;

const TranslatorForm: React.FC<Props> = ({
  handleSubmit,
  submitting,
  translationLanguages,
  error,
  isFetchingTranslationLanguages,
  submitErrors,
}) => {
  const { sourceLang, targetLang } = translationLanguages ?? {
    sourceLang: [],
    targetLang: [],
  };

  const disableWholeForm =
    (isFetchingTranslationLanguages && !translationLanguages) || submitting;

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <Row>
          <Col xs={12}>
            <Segment paddingSize="xxxs">
              <Message danger title={__('Chyba validácie formulára!')}>
                {error}
              </Message>
            </Segment>
          </Col>
        </Row>
      )}
      <Row>
        <Col m={6}>
          <Segment paddingSize="xxxs">
            <Row>
              <Col m={6}>
                <Field
                  component={FormField}
                  type="theselect"
                  label={__('Z jazyka')}
                  name="sourceLang"
                  info={__(
                    'Pre automatickú detekciu ponechajte pole nevyplnené.',
                  )}
                  options={sourceLang}
                  disabled={disableWholeForm}
                  loading={
                    isFetchingTranslationLanguages && !translationLanguages
                  }
                />
              </Col>
            </Row>
            <Field
              label={__('Názov článku')}
              name="title"
              type="text"
              component={FormField}
              disabled={disableWholeForm}
            />
            <Field
              label={__('Perex')}
              name="perex"
              type="textarea"
              component={FormField}
              rows={4}
              disabled={disableWholeForm}
            />
            <Field
              label={__('Telo článku')}
              name="body"
              type="textarea"
              component={FormField}
              rows={15}
              disabled={disableWholeForm}
            />
          </Segment>
        </Col>
        <Col m={6}>
          <Segment paddingSize="xxxs">
            <Row>
              <Col m={6}>
                <Field
                  component={FormField}
                  type="theselect"
                  label={__('Do jazyka')}
                  name="targetLang"
                  required
                  options={targetLang}
                  disabled={disableWholeForm}
                  loading={
                    isFetchingTranslationLanguages && !translationLanguages
                  }
                  info={__('Cieľový jazyk, do ktorého bude text preložený.')}
                />
              </Col>
            </Row>
            <Field
              label={__('Názov článku')}
              name="title_translated"
              type="text"
              component={FormField}
              disabled
            />
            <Field
              label={__('Perex')}
              name="perex_translated"
              type="textarea"
              component={FormField}
              rows={4}
              disabled
            />
            <Field
              label={__('Telo článku')}
              name="body_translated"
              type="textarea"
              component={FormField}
              rows={15}
              disabled
            />
          </Segment>
        </Col>
      </Row>
    </Form>
  );
};

export default compose(
  reduxForm<IFormData, OwnProps>({ form: FORM_NAME }),
  connect(mapStateToProps),
)(TranslatorForm);
