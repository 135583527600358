import * as React from 'react';
import { Article, Article_Author, Smarttag } from '../../Api';
import { IPublicationGroupsValue } from '../../components/PublicationGroupsField';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { RootState } from '../../rootReducer';
import { SectionId } from '../../library/App';
import { __ } from '../../utilities/';
import {
  appSetupSelector,
  childAppRoleSelector,
  doctypesSelector,
  userGroupsSelector,
  usersWithRolesSelector,
} from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadUserGroups, loadUsersWithRoles } from '../../pages/App/actions';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import Fields, { IArticleSidebarSegmentType } from './fields';
import Segment from '@sportnet/ui/Segment';
import ShareArticleForm from './ShareArticleForm';

const mapDispatchToProps = {
  loadUserGroups: loadUserGroups.action,
  loadUsersWithRoles: loadUsersWithRoles.action,
};

export const FORM_NAME = 'article-sidebar-form';
export interface IFormData {
  name: string;
  perex: string | null;
  url: string;
  redirect: string;
  valid_from: string;
  updated_at?: string | null;
  source: string;
  locked: boolean;
  doctype: string;
  picture: {
    uri?: string;
    dimensions?: {
      X?: number;
      Y?: number;
      W?: number;
      H?: number;
    };
  };
  smarttags: Smarttag[];
  sectionid: SectionId[];
  publication_groups: IPublicationGroupsValue;
  layout: string;
  authors: Article_Author[];
}

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
  tagmanagerRole: childAppRoleSelector('tagmanager')(state),
  userGroups: userGroupsSelector(state),
  usersWithRoles: usersWithRolesSelector(state),
  doctypes: doctypesSelector(state),
  formValues: getFormValues(FORM_NAME)(state) as IFormData | undefined,
});

interface OwnProps {
  onDelete?: () => void;
  openedSegment?: IArticleSidebarSegmentType;
  availableSegments: IArticleSidebarSegmentType[];
  submitAvailable?: boolean;
  sidebarOpened: boolean;
  article?: Article;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<IFormData>;

const C: React.FC<Props> = ({
  appSetup,
  submitting,
  handleSubmit,
  onDelete,
  loadUserGroups,
  loadUsersWithRoles,
  userGroups,
  usersWithRoles,
  openedSegment,
  sidebarOpened,
  availableSegments,
  submitAvailable = true,
  doctypes,
  article,
  formValues,
  tagmanagerRole,
}) => {
  React.useEffect(() => {
    loadUserGroups();
  }, [loadUserGroups]);

  React.useEffect(() => {
    loadUsersWithRoles();
  }, [loadUsersWithRoles]);

  const [shareRequest, setShareRequest] = React.useState<boolean>(false);
  const onShare = () => {
    setShareRequest(true);
  };

  const content = (
    <>
      <Fields
        appSetup={appSetup}
        smarttagsCreatable={tagmanagerRole === 'admin'}
        userGroups={userGroups}
        usersWithRoles={usersWithRoles}
        openedSegment={openedSegment}
        availableSegments={availableSegments}
        sidebarOpened={sidebarOpened}
        doctypes={doctypes}
        article={article}
        url={formValues?.url}
      />
      <Segment>
        <Row>
          <Col xs={6}>
            {onDelete && (
              <Button danger block basic type="button" onClick={onDelete}>
                {__('Odstrániť')}
              </Button>
            )}
          </Col>
          <Col xs={6}>
            {submitAvailable && (
              <Button primary block type="submit" loading={submitting}>
                {__('Uložiť')}
              </Button>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <Button primary block type="button" basic onClick={onShare}>
              {__('Odoslať emailom')}
            </Button>
          </Col>
        </Row>
      </Segment>
      {shareRequest && (
        <ShareArticleForm
          onClose={() => setShareRequest(false)}
          article={article}
        ></ShareArticleForm>
      )}
    </>
  );

  if (submitAvailable) {
    return (
      <form onSubmit={submitAvailable ? handleSubmit : undefined}>
        {content}
      </form>
    );
  }

  return content;
};

export default compose(
  reduxForm<IFormData, OwnProps>({
    form: FORM_NAME,
  }),
  connect<{}, {}, OwnProps>(mapStateToProps, mapDispatchToProps),
)(C);
