import { RootState } from '../../rootReducer';
import { StaticContentCid } from '../../library/App';
import { createSelector } from 'reselect';
import { entitiesSelector } from '../../pages/App/selectors';

const detailDomain = (state: RootState) => state.staticContentDetail;

export const detailIsFetchingSelector = (staticContentId: StaticContentCid) =>
  createSelector(detailDomain, (domain) =>
    staticContentId in domain ? domain[staticContentId].isFetching : false,
  );

export const detailSelector = (staticContentId: StaticContentCid) =>
  createSelector(entitiesSelector, (entities) =>
    staticContentId in entities.staticContents
      ? entities.staticContents[staticContentId]
      : undefined,
  );

export const detailErrorSelector = (staticContentId: StaticContentCid) =>
  createSelector(detailDomain, (domain) =>
    staticContentId in domain ? domain[staticContentId].error : '',
  );
