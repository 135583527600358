import * as actions from './actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

type DetailState = Readonly<{
  [key: number]: Readonly<{
    isFetching: boolean;
    error: string;
  }>;
}>;

const DETAIL_INITIAL_STATE: DetailState = {};

export const detailReducer = reducerWithInitialState(DETAIL_INITIAL_STATE)
  .case(
    actions.loadDetail.async.started,
    (state, params): DetailState => ({
      ...state,
      [params.articleId]: {
        isFetching: true,
        error: '',
      },
    }),
  )
  .case(
    actions.loadDetail.async.done,
    (state, { result, params }): DetailState => ({
      ...state,
      [params.articleId]: {
        isFetching: false,
        error: '',
      },
    }),
  )
  .case(
    actions.loadDetail.async.failed,
    (state, { error, params }): DetailState => ({
      ...state,
      [params.articleId]: {
        isFetching: false,
        error: 'error',
      },
    }),
  );
