import * as React from 'react';
import { Article, Article_Put, SectionPublic } from '../Api';
import { IArticleSave, SectionId, Writeable } from '../library/App';
import { IOptions } from '@sportnet/ui/TheSelect/types';
import { defaultFilterFunction } from '@sportnet/ui/TheSelect/utils';

export const getIdFromProps = (
  props:
    | {
        id: string;
      }
    | {
        params: {
          id: string;
        };
      },
) => {
  if (!('id' in props)) {
    return Number(props.params.id);
  }
  return Number(props.id);
};

export const getArticleUrl = (article: Article) => {
  if (article.redirect) {
    return article.redirect;
  }
  if (article.url) {
    return `/${article.url}`;
  }
  return `/article/${article._id}`;
};

export const getSectionUrl = (section: SectionPublic) => {
  if (section.url) {
    return `/${section.url}`;
  } else {
    return `/section/${section._id}`;
  }
};

export const __ = (text: string) => text;

export function useWhyDidYouUpdate(name: string, props: any) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = React.useRef<any>();

  React.useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj: any = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

export function delay(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(1);
    }, time);
  });
}

export const paramsToObject = (params: string) => {
  const query = params.substr(1);
  const result: { [key: string]: string } = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const required = (value: string): string | null => {
  if (value) {
    return null;
  }
  return __('Pole je povinné');
};

interface IEntity {
  [key: string]: any;
}

interface IEntities {
  [key: string]: IEntity;
}

export const updateEntities = (
  oldEntities: IEntities,
  entitiesToUpdate: IEntities,
): IEntities => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return Object.keys(entities).reduce(
    (acc: typeof entities, entityName: keyof typeof entities) => {
      if (entityName in oldEntities) {
        acc[entityName] = Object.keys(entities[entityName]).reduce(
          (innerAcc: { [key: string]: any }, entityId: string) => {
            if (entityId in oldEntities[entityName]) {
              innerAcc[entityId] = {
                ...oldEntities[entityName][entityId],
                ...entities[entityName][entityId],
              };
            } else {
              innerAcc[entityId] = entities[entityName][entityId];
            }
            return innerAcc;
          },
          {},
        );
      } else {
        acc[entityName] = entities[entityName];
      }
      return acc;
    },
    {},
  );
};

export const debounce = <Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): ((...args: Params) => void) => {
  let timer: number;
  return (...args: Params) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export const filterSectionTreeOptions = (
  options: IOptions,
  inputValue: string,
) => {
  // filter + include parents
  const shownSectionsById = options.reduce(
    (acc: { [key: string]: boolean }, option) => {
      if (defaultFilterFunction(option, inputValue)) {
        acc[option.value || ''] = true;
        const { parent } = option;
        if (parent) {
          if (Array.isArray(parent)) {
            for (const parentId of parent) {
              acc[parentId] = true;
            }
          } else {
            acc[parent] = true;
          }
        }
      }
      return acc;
    },
    {},
  );
  return options.filter((option) => shownSectionsById[option.value || '']);
};

export const transformArticleSectionId = (
  sectionid: SectionId | null | SectionId[] | undefined,
): SectionId[] | null => {
  if (sectionid && Array.isArray(sectionid)) {
    return sectionid;
  }
  if (sectionid) {
    return [sectionid];
  }
  return null;
};

export const prepareArticleToSave = (
  articleData: IArticleSave,
): Article_Put => {
  const is_private =
    typeof articleData.publication_groups !== 'undefined' &&
    articleData.publication_groups !== null;

  return {
    doctype: articleData.doctype,
    locked: articleData.locked,
    name: articleData.name,
    perex: articleData.perex,
    picture: articleData.picture || undefined,
    redirect: articleData.redirect,
    smarttags: articleData.smarttags,
    source: articleData.source,
    url: articleData.url,
    valid_from: articleData.valid_from,
    updated_at: articleData.updated_at || null,
    widgets: articleData.widgets,
    publication_groups: articleData.publication_groups || [],
    sectionid: transformArticleSectionId(articleData.sectionid),
    is_private,
    layout: articleData.layout,
    authors: articleData.authors,
    meta: articleData.meta || {},
    note: articleData.note,
  };
};

const removeAccents = (text: string) => {
  return text
    .toLowerCase()
    .replace(/[áàãâä]/gi, 'a')
    .replace(/[čç]/gi, 'c')
    .replace(/[ď]/gi, 'd')
    .replace(/[éèê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[ľĺ]/gi, 'l')
    .replace(/[ñň]/gi, 'n')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[řŕ]/gi, 'r')
    .replace(/[š]/gi, 's')
    .replace(/[ť]/gi, 't')
    .replace(/[úùüûů]/gi, 'u')
    .replace(/[ý]/gi, 'y')
    .replace(/[ž]/gi, 'z');
};

const prepareUrl = (url: string) =>
  url.toLocaleLowerCase().replace(/[^a-z0-9-_+./]/g, '');

export const formatUrl = (url: string) => {
  return prepareUrl(removeAccents(url.trim()));
};
