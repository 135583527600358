import { appSetupSelector } from '../../pages/App/selectors';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Link from '@sportnet/ui/Link';
import React from 'react';

function formatCmsUrl(parentUrl: string, location: any) {
  const encoded = btoa(JSON.stringify(location));
  return `${parentUrl}#cms${encoded}`;
}

interface OwnProps
  extends Omit<React.ComponentProps<typeof Link>, 'target' | 'href'> {
  to: string;
}

type Props = OwnProps;

const EmbedLink: React.FC<Props> = (props) => {
  const { to, ...restProps } = props;
  const history = useHistory();
  const { parentUrl } = useSelector(appSetupSelector);

  const href = React.useMemo(() => {
    if (!to || !parentUrl) {
      return '';
    }
    return formatCmsUrl(parentUrl, { pathname: to });
  }, [parentUrl, to]);

  if (!href) {
    return <Link {...props} />;
  }

  return (
    <Link
      {...restProps}
      href={href}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        props.onClick?.(e);
        if (!e.ctrlKey || e.metaKey) {
          e.preventDefault();
          history.push(to);
        }
      }}
    />
  );
};

export default React.memo(EmbedLink);
