import * as React from 'react';
import { ALL_DOCTYPES } from '../../library/Doctypes';
import { IOption } from '@sportnet/ui/TheSelect/types';
import { IQuery } from './constants';
import { RootState } from '../../rootReducer';
import { SmarttagProps } from '@sportnet/ui/library/Smarttag';
import { __, filterSectionTreeOptions } from '../../utilities/';
import {
  appSetupSelector,
  doctypesSelector,
  usersWithRolesSelector,
} from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadTree } from '../../pages/Sections/actions';
import { loadUsersWithRoles } from '../../pages/App/actions';
import { rem } from 'polished';
import { sectionTreeOptionsSelector } from '../../pages/Sections/selectors';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { useTheSelectOptions } from '@sportnet/ui/TheSelect/utils';
import Button from '@sportnet/ui/Button';
import Cookies from 'js-cookie';
import Filter from '@sportnet/ui/Filter';
import Input from '@sportnet/ui/Input';
import Modal, { ModalActions } from '@sportnet/ui/Modal';
import SearchParams from '../../components/SearchParams';
import Segment from '@sportnet/ui/Segment';
import Tabber from '@sportnet/ui/Tabber';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import styled from '../../theme/styled-components';
import withPopups from '../../components/WithPopups';

const QOPTS_COOKIE_NAME = 'qopts';

const draftOptions = [
  {
    label: __('S konceptom'),
    value: '1',
  },
  {
    label: __('Bez konceptu'),
    value: '0',
  },
  {
    label: __('Nezáleží'),
    value: '',
  },
];

const privateOptions = [
  {
    label: __('Iba pre prihlásených'),
    value: '1',
  },
  {
    label: __('Iba pre neprihlásených'),
    value: '0',
  },
  {
    label: __('Nezáleží'),
    value: '',
  },
];

const defaultQopts = 'name:fulltext';

const SECTION_LEVEL_PADDING = 10;

const CustomLabel = styled.span<{ level: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${rem(12)};
  padding-left: ${({ level }) => rem(level * SECTION_LEVEL_PADDING)};
`;

const ChangedValueInfo = styled.span`
  width: ${rem(12)};
  height: ${rem(12)};
  border-radius: 100% !important;
  background-color: red;
  position: absolute;
  top: ${rem(4)};
  right: ${rem(4)};
`;

const renderLabel = (o: IOption) => {
  const { level } = o;
  return (
    <CustomLabel level={level}>
      {level ? `> ` : ''}
      {o.label}
    </CustomLabel>
  );
};

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
  usersWithRoles: usersWithRolesSelector(state),
  sectionTreeOptions: sectionTreeOptionsSelector(state),
  doctypes: doctypesSelector(state),
});

const mapDispatchToProps = {
  loadUsersWithRoles: loadUsersWithRoles.action,
  loadTree: thunkToAction(loadTree.action),
};

interface OwnProps {
  query: IQuery;
  setQuery: (value: Partial<IQuery>) => void;
}

type Props = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const C: React.FC<Props> = ({
  query,
  setQuery,
  appSetup,
  loadUsersWithRoles,
  loadTree,
  sectionTreeOptions,
  usersWithRoles,
  doctypes,
}) => {
  const doctypeFilter = query.doctype.length ? query.doctype[0] : '';
  const [isFulltextOptsOpen, setIsFulltextOptsOpen] = React.useState(false);
  const [qopts, setQopts] = React.useState(
    Cookies.get(QOPTS_COOKIE_NAME) ?? defaultQopts,
  );

  React.useEffect(() => {
    loadUsersWithRoles();
  }, [loadUsersWithRoles]);

  React.useEffect(() => {
    loadTree();
  }, [loadTree]);

  const usersWithRolesOptions = [
    { label: __('Nezáleží'), value: '' },
    ...usersWithRoles.map((user) => ({
      value: user.user_id,
      label: user.display_name || user.user_id,
    })),
  ];

  const selectedOwner = usersWithRolesOptions.find(
    (owner) => owner.value === query.ownerSportnetId,
  );

  const selectedAuthor = usersWithRolesOptions.find(
    (author) => author.value === query.authorId,
  );

  const [, hasDraft] = useTheSelectOptions(
    draftOptions,
    (x) => x,
    query.hasDraft,
    [draftOptions],
  );

  const [, isPrivate] = useTheSelectOptions(
    privateOptions,
    (x) => x,
    query.isPrivate,
    [privateOptions],
  );

  const [, sectionid] = useTheSelectOptions(
    sectionTreeOptions,
    (x) => x,
    query.sectionid,
    [sectionTreeOptions],
    {
      multiple: true,
    },
  );

  const [debouncedQ, setDebouncedQ] = React.useState(query.q);
  const debounceQtimer = React.useRef<number>();

  const filterValue = {
    ownerSportnetId: selectedOwner ? selectedOwner : null,
    authorId: selectedAuthor ? selectedAuthor : null,
    smarttag: query.smarttag,
    q: debouncedQ,
    validFrom: query.validFrom,
    validTo: query.validTo,
    hasDraft,
    isPrivate,
    sectionid,
  };

  const handleOnDoctypeFilterClick = React.useCallback(
    (tabId: string) => {
      if (tabId === ALL_DOCTYPES.title) {
        setQuery({ doctype: [ALL_DOCTYPES.id] });
      } else {
        const doctype = doctypes.find((dc) => dc.title === tabId);
        if (doctype) {
          setQuery({ doctype: [doctype.id] });
        } else {
          setQuery({ doctype: [] });
        }
      }
    },
    [doctypes, setQuery],
  );

  const tabItems = React.useMemo(() => {
    const articleDoctypes = doctypes.map((doctype) => {
      return doctype.title;
    });

    articleDoctypes.push(ALL_DOCTYPES.title);
    return articleDoctypes;
  }, [doctypes]);

  const activeDoctype = React.useMemo(() => {
    if (doctypeFilter) {
      if (doctypeFilter === ALL_DOCTYPES.id) {
        return ALL_DOCTYPES.title;
      }
      const doctype = doctypes.find((dc) => dc.id === doctypeFilter);
      return doctype ? doctype.title : '';
    }
    return doctypes.length ? doctypes[0].title : '';
  }, [doctypeFilter, doctypes]);

  return (
    <>
      <Tabber
        active={activeDoctype}
        items={tabItems}
        onClickItem={handleOnDoctypeFilterClick}
      />

      <Modal
        centered
        size="s"
        key={'fulltext-modal'}
        isOpen={isFulltextOptsOpen}
        handleClose={() => {
          setIsFulltextOptsOpen(false);
          setQopts(Cookies.get(QOPTS_COOKIE_NAME) ?? defaultQopts);
        }}
      >
        <Segment>
          <h2 style={{ margin: `${rem(8)} ${rem(0)} ${rem(24)} ${rem(0)}` }}>
            {__('Parametre vyhľadávania')}
          </h2>
          <SearchParams
            onChange={setQopts}
            value={qopts}
            defaultOptions={defaultQopts}
          />
        </Segment>

        <ModalActions>
          <Button
            type="button"
            onClick={() => {
              setIsFulltextOptsOpen(false);
              setQopts(Cookies.get(QOPTS_COOKIE_NAME) ?? defaultQopts);
            }}
          >
            {__('Zavrieť')}
          </Button>
          <div>
            <Button
              type="button"
              basic
              primary
              onClick={() => {
                setQopts(defaultQopts);
              }}
            >
              {__('Pôvodné nastavenia')}
            </Button>
            &nbsp;
            <Button
              primary
              type="button"
              onClick={async () => {
                setIsFulltextOptsOpen(false);
                setQuery({ qopts });
                Cookies.set(QOPTS_COOKIE_NAME, qopts);
              }}
            >
              {__('Potvrdiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>

      <TagmanagerConnector
        appId={appSetup.appId}
        appspace={appSetup.appSpace}
        accessToken={appSetup.token}
      >
        {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
          <Filter
            searchInputButton={
              <Input.Button
                primary
                size="l"
                onClick={(e: any) => setIsFulltextOptsOpen(true)}
                style={{ position: 'relative' }}
              >
                {JSON.stringify(defaultQopts.split(',').sort()) !==
                  JSON.stringify(qopts.split(',').sort()) && (
                  <ChangedValueInfo />
                )}
                {__('Parametre')}
              </Input.Button>
            }
            value={filterValue}
            onChange={(nextFilterValue) => {
              const newQuery = {
                offset: 0,
                ownerSportnetId: nextFilterValue.ownerSportnetId
                  ? ((nextFilterValue.ownerSportnetId as IOption)
                      .value as string)
                  : '',
                authorId: nextFilterValue.authorId
                  ? ((nextFilterValue.authorId as IOption).value as string)
                  : '',
                smarttag: nextFilterValue.smarttag as SmarttagProps[],
                q: nextFilterValue.q ? (nextFilterValue.q as string) : '',
                qopts: nextFilterValue.qopts
                  ? (nextFilterValue.qopts as string)
                  : qopts,
                validFrom: nextFilterValue.validFrom
                  ? new Date(nextFilterValue.validFrom as string).toJSON()
                  : '',
                validTo: nextFilterValue.validTo
                  ? new Date(nextFilterValue.validTo as string).toJSON()
                  : '',
                hasDraft: nextFilterValue.hasDraft
                  ? ((nextFilterValue.hasDraft as IOption).value as string)
                  : '',
                isPrivate: nextFilterValue.isPrivate
                  ? ((nextFilterValue.isPrivate as IOption).value as string)
                  : '',
                sectionid: nextFilterValue.sectionid
                  ? (nextFilterValue.sectionid as IOption[]).map(
                      (o) => o.value as number,
                    )
                  : [],
              };
              // ak sa zmeni q => nastavime state a pockame na debounce timer
              if (
                nextFilterValue.q === undefined ||
                filterValue.q !== nextFilterValue.q
              ) {
                setDebouncedQ((nextFilterValue.q as string) || '');
                if (debounceQtimer.current) {
                  window.clearTimeout(debounceQtimer.current);
                }
                debounceQtimer.current = window.setTimeout(() => {
                  setQuery(newQuery);
                }, 500);
              } else {
                // zavolame funkciu na zmenu ihned
                setQuery(newQuery);
              }
            }}
            filters={[
              {
                type: 'query' as const,
                name: 'q',
                placeholder: __('Vyhľadávanie v článkoch'),
              },
              {
                type: 'select' as const,
                options: usersWithRolesOptions,
                disabled: false,
                label: __('Vytvoril'),
                name: 'ownerSportnetId',
              },
              {
                type: 'select' as const,
                options: usersWithRolesOptions,
                disabled: false,
                label: __('Autor'),
                name: 'authorId',
              },
              {
                type: 'smarttag' as const,
                label: 'Smart:tag',
                name: 'smarttag',
                getSmarttagsKeys,
                getSmarttagsValues,
                getSmarttagsByFts,
              },
              {
                type: 'select' as const,
                options: sectionTreeOptions,
                label: __('Sekcia'),
                name: 'sectionid',
                multiple: true,
                filterOptions: filterSectionTreeOptions,
                renderLabel: renderLabel,
              },
              {
                type: 'date' as const,
                label: __('Publikované od'),
                name: 'validFrom',
              },
              {
                type: 'date' as const,
                label: __('Publikované do'),
                name: 'validTo',
              },
              {
                type: 'select' as const,
                options: draftOptions,
                label: __('Koncept'),
                name: 'hasDraft',
                multiple: false,
              },
              {
                type: 'select' as const,
                options: privateOptions,
                label: __('Iba pre prihlásených'),
                name: 'isPrivate',
                multiple: false,
              },
            ]}
          />
        )}
      </TagmanagerConnector>
    </>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
