import * as React from 'react';
import { ListingType } from '../../Api';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ListingList from '../../containers/ListingList';

interface IOwnProps {
  type: ListingType;
}

type Props = IOwnProps;
type RouteProps = RouteComponentProps<{ type?: string }>;

const mapStateToProps = (state: any, props: RouteProps) => {
  return { type: props.match.params.type };
};

const C: React.FC<Props> = ({ type = 'system' }) => {
  return (
    <>
      <ListingList type={type} />
    </>
  );
};

export default compose(withRouter, connect(mapStateToProps))(C);
