import { RootState } from '../../rootReducer';
import { createSelector } from 'reselect';

export const appSetupSelector = (state: RootState) => state.setup;

export const currentAppSpaceInfoSelector = createSelector(
  appSetupSelector,
  (substate) => {
    const appSpace = substate.appSpaces.find(
      (i) => i.app_space === substate.appSpace,
    );
    return appSpace;
  },
);

export const currentAppSpaceChildAppsRolesSelector = createSelector(
  currentAppSpaceInfoSelector,
  (substate) => substate?.child_apps_role,
);

export const childAppRoleSelector = (appId: string) =>
  createSelector(currentAppSpaceChildAppsRolesSelector, (substate) => {
    const app = substate?.find((i) => i.app_id === appId);
    return app ? app.role : undefined;
  });

export const entitiesSelector = (state: RootState) => {
  return state.entities;
};

const doctypesDomain = (state: RootState) => {
  return state.doctypes;
};

export const doctypesSelector = createSelector(
  doctypesDomain,
  (domain) => domain.doctypes,
);

const userGroupsDomain = (state: RootState) => {
  return state.userGroups;
};

export const userGroupsSelector = createSelector(
  userGroupsDomain,
  (domain) => domain.groups,
);

export const usersWithRolesDomain = (state: RootState) => {
  return state.usersWithRoles;
};

export const usersWithRolesSelector = createSelector(
  usersWithRolesDomain,
  (domain) => domain.users,
);
