import * as React from 'react';
import { IAppSetup, IWidgets } from '../../library/App';
import { IContent } from '@sportnet/content/utilities/library';
import { __ } from '../../utilities/';
import Api, { Doctypes } from '../../Api';
import Content from '@sportnet/content/view';
import ContentLoader from '../../components/ContentLoader';
import NotFound from '@sportnet/ui/NotFound';

interface OwnProps {
  items: IWidgets;
  doctypes: Doctypes;
  appSetup: IAppSetup;
  onClickGoToEditor: () => void;
}

type Props = OwnProps;

const C: React.FC<Props> = ({
  items,
  doctypes,
  appSetup,
  onClickGoToEditor,
}) => {
  const [expandedWidgets, setExpandedWidgets] = React.useState<IContent>([]);
  const [isExpanding, setIsExpanding] = React.useState(false);

  React.useEffect(() => {
    if (items.length) {
      const { appId, appSpace, contentDivider } = appSetup;
      const expand = async () => {
        setIsExpanding(true);
        try {
          const response = await Api.expandWidgets(
            appId,
            appSpace,
            contentDivider,
            {},
            {
              content: items,
            },
          );
          setExpandedWidgets((response.content || []) as IContent);
          setIsExpanding(false);
        } catch (e) {
          setIsExpanding(false);
        }
      };
      expand();
    }
  }, [appSetup, items]);

  if (isExpanding) {
    return <ContentLoader />;
  }

  if (!expandedWidgets.length) {
    return (
      <NotFound
        title={__('Náhľad nemožno zobraziť, pretože neexistuje obsah')}
        icon="error"
        actionLabel={__('Prejsť na úpravu obsahu')}
        onClickAction={onClickGoToEditor}
      />
    );
  }

  return (
    <Content
      items={expandedWidgets}
      renderLink={(link: any) => (
        <a href="/" target="_blank" {...link.linkProps}>
          {link.linkProps.children}
        </a>
      )}
    />
  );
};

export default C;
