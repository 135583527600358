import * as React from 'react';
import { Article } from '../../Api';
import { IAppSetup, ISectionNode, SectionId } from '../../library/App';
import { IListArticle } from '../../library/Article';
import { MenuItem } from '../Tree';
import { RouteComponentProps, withRouter } from 'react-router';
import { ThemeInterface } from '../../theme/theme';
import { __ } from '../../utilities/';
import { format, isAfter, isBefore } from 'date-fns';
import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import { withTheme } from 'styled-components';
import ArticleMeta from './ArticleMeta';
import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import EmbedLink from '../EmbedLink';
import Icon, { IIconName } from '@sportnet/ui/Icon';
import Link from '@sportnet/ui/Link';
import Text from '@sportnet/ui/Text';
import Tooltip from '@sportnet/ui/Tooltip';
import config from '../../config';
import styled from '../../theme/styled-components';

const ArticleTitleWrapper = styled.div`
  margin-bottom: ${rem(4)};
`;

const ArticleSymbolsWrapper = styled.div`
  margin-top: ${rem(4)};
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  > * {
    flex: 0 0 auto;
  }
`;

const WrappedStyledName = ({ isLocked, ...props }: any) => {
  return <EmbedLink {...props} />;
};
const StyledName = styled(WrappedStyledName)`
  margin-bottom: 0.3em;
  ${({ isLocked, theme }) => (isLocked ? `color:${theme.color.danger}` : '')}
`;

const GoToArticle = styled.a`
  padding: 0 ${rem(4)};
`;

const Draft = styled.span`
  padding: ${rem(2)};
  background-color: #fdeaea;
  border-radius: ${({ theme }) => theme.largeBorderRadius};
  color: ${({ theme }) => theme.color.danger};
  border: 1px solid ${({ theme }) => theme.color.danger};
  font-size: ${rem(11)};
  display: inline-flex;
  margin: 0 ${rem(4)} ${rem(4)} 0;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  ${mb('m')} {
    word-break: unset;
    white-space: unset;
  }
`;

const Note = styled(Draft)`
  background-color: #dae3f7;
  color: ${({ theme }) => theme.color.primary};
  border: 1px solid ${({ theme }) => theme.color.primary};
`;

const Semaphores = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0 ${rem(2)};
  }
`;
const Semaphore = styled.span<{ color: string }>`
  height: ${rem(12)};
  width: ${rem(12)};
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: block;
  margin: 0 auto;
`;

const RightAlign = styled.span`
  text-align: right;
  display: block;
  flex-grow: 1;
  width: 100%;
`;

// TODO: exportnut v @sportnet/ui a nasledne importnut odtial
export interface Selected {
  // TODO: in @sportnet/ui each key in this object should be transformed to string
  // because article `_id` is number.
  [key: string]: boolean;
}

type SectionItem =
  | (ISectionNode &
      MenuItem & {
        parent?: SectionId;
      })
  | null;

interface OwnProps {
  items: IListArticle[];
  onClickItem: (item: IListArticle) => void;
  onClickPublish: (item: IListArticle) => void;
  sorter?: string;
  onSort?: (sorter: string) => void;
  onClickOwner?: (ownerSportnetId: string) => void;
  onClickAuthor?: (authorId: string) => void;
  actionButton?: {
    label?: string;
    type?: 'primary' | 'danger' | 'warning' | 'success';
    icon?: string;
    onClick?: (article: IListArticle) => void;
  };
  onEditName?: (item: IListArticle) => void;
  listingNameKey?: string;
  onReorder?: (articles: IListArticle[]) => void;
  markers?: Array<number>;
  selected?: Selected;
  onSelect?: (selected: Selected) => void;
  appSetup?: IAppSetup;
  sectionTree?: (ISectionNode & MenuItem)[];
}

type Props = OwnProps & { theme: ThemeInterface } & RouteComponentProps;

const C: React.FC<Props> = ({
  items,
  onClickItem,
  onClickPublish,
  sorter = '',
  onSort,
  onClickOwner,
  onClickAuthor,
  actionButton,
  onReorder,
  onEditName,
  listingNameKey,
  theme,
  markers,
  location: { search },
  selected,
  onSelect,
  appSetup,
  sectionTree,
}) => {
  const { articleUrlPattern } = appSetup?.params || {};

  const findSectionInSectionTree = (
    sectionId: number,
    sectionTreePart: typeof sectionTree = sectionTree,
  ): SectionItem => {
    const item = (sectionTreePart || []).find((i) => i._id === sectionId);
    if (!item) {
      const children = sectionTreePart?.reduce(
        (acc, i) => [...acc, ...(i.children || [])],
        [],
      );
      if ((children || []).length === 0) {
        return null;
      }
      return findSectionInSectionTree(sectionId, children);
    }
    return item;
  };

  const getSectionPath = (section: SectionItem) => {
    return [section!._id, ...((section!.parent || []) as number[])]
      .reverse()
      .map((parent) => {
        const parentSection = findSectionInSectionTree(parent);
        if (parentSection) {
          return parentSection.title;
        }
        return null;
      })
      .filter((i) => i);
  };

  const getTableHeader = () => {
    const order = sorter[0] === '-' ? 'desc' : 'asc';
    const sortedColumnId = order === 'desc' ? sorter.slice(1) : sorter;
    const header: any = [
      {
        header: '',
        width: 16,
      },
      {
        id: 'stats_view_total',
        header: (
          <RightAlign>
            <Tooltip content={__('Čítanosť / Počet zobrazení')}>
              {__('PV')}
            </Tooltip>
          </RightAlign>
        ) as any,
      },
      {
        id: 'name',
        header: __('Názov'),
      },
      {
        _id: 'sectionid',
        header: __('Sekcia'),
      },
      {
        id: 'valid_from',
        header: __('Publikované od'),
        width: 150,
        sortable: true,
        sort: (sortedColumnId === 'valid_from' ? order : undefined) as
          | 'desc'
          | 'asc'
          | undefined,
      },
    ];
    if (actionButton) {
      header.push({
        header: '',
        width: 60,
      });
    }
    return header;
  };

  const renderHighlightedTexts = (
    name: string = '',
    highlights?: Article['highlights'],
  ) => {
    if (!!!highlights?.length) {
      return name;
    }

    return highlights?.map(({ texts = [] }) => {
      return texts.map((t) => {
        if (t.type === 'hit') {
          return <span style={{ backgroundColor: 'yellow' }}>{t.value}</span>;
        }
        return t.value;
      });
    });
  };

  const now = new Date();

  return (
    <BasicTable
      selected={selected}
      onSelect={onSelect ? onSelect : undefined}
      onSort={
        onSort
          ? (col, newDirection) => {
              if (typeof newDirection === 'undefined') {
                onSort('');
              } else {
                onSort(`${newDirection === 'asc' ? '' : '-'}${col.id}`);
              }
            }
          : undefined
      }
      columns={getTableHeader()}
      rows={items}
      onClickRow={(item) => {
        onClickItem(item);
      }}
      renderRow={(item: IListArticle) => {
        const url: string = (articleUrlPattern || '').replace(
          '{{uri}}',
          item.url || '',
        );
        // oranzova: nie je publikovany, je rozpisany
        let semaphoreColor = theme.color.warning;
        // zelena: clanok je publikovany (ak ma aj koncept, ponechajme kludne ten badge za nadpisom este)
        if (item.valid_from && isBefore(new Date(item.valid_from), now)) {
          semaphoreColor = theme.color.success;
        }
        // cervena: existuje vobec stav ze nie je koncept a nie je publikovany? Ak ano, toto je ten stav
        else if (item.draft) {
          semaphoreColor = theme.color.danger;
        }

        const isLocked =
          item.locks &&
          item.locks.length > 0 &&
          item.locks[0]?.lockedTo &&
          isAfter(new Date(item.locks[0].lockedTo), now);

        return [
          <Semaphores>
            <Semaphore color={semaphoreColor} />
            {isLocked && (
              <Tooltip
                content={
                  <div>
                    <strong>{`Uzamkol ${
                      item.locks![0]?.displayName
                    } do`}</strong>
                    <br />
                    <i>{new Date(item.locks![0].lockedTo!).toLocaleString()}</i>
                  </div>
                }
              >
                <Icon color={theme.color.danger} name="lock" size={16} />
              </Tooltip>
            )}
            {!!item.is_private && (
              <Tooltip
                content={
                  <div>{__('Článok je viditeľný len pre prihlásených')}</div>
                }
              >
                <Icon name="visibility-off" size={16} />
              </Tooltip>
            )}
          </Semaphores>,
          <RightAlign key="stats_view_total">
            {(item.stats_view_total || 0).toLocaleString()}
          </RightAlign>,
          <>
            {onEditName && (
              <Button
                style={{ verticalAlign: 'middle' }}
                icon="pencil"
                onClick={() => {
                  onEditName(item);
                }}
              />
            )}
            <ArticleTitleWrapper>
              <StyledName
                to={`/articles/detail/${item._id}${search}`}
                isLocked={isLocked}
                onClick={(e: any) => e.stopPropagation()}
              >
                <strong>
                  {onEditName && listingNameKey
                    ? (item as any)[listingNameKey] ||
                      renderHighlightedTexts(item.name, item.highlights)
                    : renderHighlightedTexts(item.name, item.highlights)}
                </strong>
              </StyledName>
              <ArticleSymbolsWrapper>
                {!!url && (
                  <Tooltip content={__('Zobraziť na webe')}>
                    <GoToArticle
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      rel="noopener noreferrer"
                      href={url}
                      target="_blank"
                    >
                      <Icon name="external-link" />
                    </GoToArticle>
                  </Tooltip>
                )}
                {item && item.draft && <Draft>{__('Koncept')}</Draft>}
                {item && item.note && (
                  <span onClick={(e) => e.stopPropagation()}>
                    <Tooltip content={item.note}>
                      <Note>{__('Interná poznámka')}</Note>
                    </Tooltip>
                  </span>
                )}
              </ArticleSymbolsWrapper>
            </ArticleTitleWrapper>
            <ArticleMeta
              article={item}
              onClickOwner={onClickOwner}
              onClickAuthor={onClickAuthor}
            />
          </>,
          <div>
            {!!item.sectionid &&
              (item.sectionid as number[]).length > 0 &&
              (item.sectionid as number[]).map((sectionid, idx, a) => {
                const section = findSectionInSectionTree(sectionid);
                let content = <div key={`section-path-${sectionid}-${idx}`} />;
                if (section && idx === 0) {
                  content = (
                    <Tooltip
                      key={`section-${section._id}`}
                      content={<div>{getSectionPath(section).join(' / ')}</div>}
                    >
                      <div>
                        {section.title}
                        {a.length > 1 && <>,&nbsp;</>}
                      </div>
                    </Tooltip>
                  );
                } else if (section) {
                  content = (
                    <Tooltip
                      key={`section-${section._id}-${idx}`}
                      content={<div>{getSectionPath(section).join(' / ')}</div>}
                    >
                      <div>
                        {section.title}
                        {idx < a.length - 1 && <>,&nbsp;</>}
                      </div>
                    </Tooltip>
                  );
                }
                return content;
              })}
          </div>,
          item.valid_from ? (
            format(new Date(item.valid_from), config.DATETIME_FORMAT)
          ) : (
            <>
              <Text faded>{__('Nenastavené')}</Text>
              <br />
              <Link
                primary
                onClick={(e) => {
                  e.stopPropagation();
                  onClickPublish(item);
                }}
              >
                {__('Publikovať')}
              </Link>
            </>
          ),
          actionButton ? (
            <Button
              primary={actionButton?.type === 'primary'}
              danger={actionButton?.type === 'danger'}
              warning={actionButton?.type === 'warning'}
              success={actionButton?.type === 'success'}
              icon={(actionButton?.icon as IIconName) || null}
              onClick={() => {
                if (actionButton.onClick) {
                  actionButton.onClick(item);
                }
              }}
            >
              {actionButton.label}
            </Button>
          ) : null,
        ];
      }}
      rowKey="_id"
      onReorder={onReorder}
      rowStyle={(item, idx) => {
        if (markers && markers.includes(idx + 1)) {
          return { borderBottom: '3px solid black' };
        }
        return { borderBottom: '1px solid #eee' };
      }}
    />
  );
};

export default withRouter(withTheme(C));
