import {
  Error as ApiError,
  Translator_LanguageSettings_Item,
  Translator_TextToTranslate_Item,
  Translator_TranslatedData_Item,
} from '../../Api';
import { ExtraArgumentType } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.TRANSLATOR_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const getTranslation = createAsync<
  { textToTranslateList: Translator_TextToTranslate_Item[] },
  { [key: string]: Translator_TranslatedData_Item },
  { message: string; details: ApiError }
>('GET_TRANSLATION', async (params, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());

  const { textToTranslateList } = params;

  const response = await Api.getTranslation(
    appId,
    appSpace,
    contentDivider,
    {},
    {
      textToTranslateList,
    },
  );

  const { data } = response.translated;
  const translation = data.reduce((acc, item) => {
    acc[item.name] = item;
    return acc;
  }, {} as { [key: string]: Translator_TranslatedData_Item });

  return translation;
});

export const getTranslationLanguages = createAsync<
  {},
  {
    sourceLang: Translator_LanguageSettings_Item[];
    targetLang: Translator_LanguageSettings_Item[];
  }
>(
  'GET_TRANSLATION_LANGUAGES',
  async (_params, _dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    const response = await Api.getTranslationLanguageSettings(
      appId,
      appSpace,
      contentDivider,
      {},
    );
    return response.languages;
  },
);
