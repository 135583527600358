import { IQueryResult, NumberParam } from '@sportnet/query-hoc/useQuery';

const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
  },
};

const constants = {
  LIST_NAME: 'section_articles_list',
  LIST_LIMIT: 50,
  QUERY_HOC_CONFIG,
};

export default constants;

export type IQuery = IQueryResult<typeof QUERY_HOC_CONFIG>['query'];
