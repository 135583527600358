import * as React from 'react';
import { Article } from '../../../../Api';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { IArticleSidebarSegmentType } from '../../../../containers/ArticleSidebar/fields';
import { RootState } from '../../../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { __ } from '../../../../utilities/';
import { appSetupSelector, doctypesSelector } from '../../../App/selectors';
import { articlesSelector } from './selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';
import {
  detailErrorSelector,
  detailIsFetchingSelector,
  detailSelector,
} from '../../selectors';
import {
  getListTotal,
  hasCommitFailed,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { getProp } from '@sportnet/utilities';
import { load } from './actions';
import { loadDetail } from '../../actions';
import { loadTree } from '../../../Sections/actions';
import { saveSection } from '../../../../containers/Section/actions';
import { sectionTreeFullSelector } from '../../../Sections/selectors';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import ArticleSidebar from '../../../../containers/ArticleSidebar';
import ArticlesListWidget from '@sportnet/content/editor/widgets/ArticlesList';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../../../components/ContentLoader';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Link from '@sportnet/ui/Link';
import List from '../../../../components/ArticleList';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import SubmitButton from '@sportnet/ui/Button/Submit';
import Tabs from '../../Tabs';
import WidgetEditorContext from '@sportnet/content/editor/appContext';
import constants from './constants';
import useQuery from '@sportnet/query-hoc/useQuery';

type RouteProps = RouteComponentProps<{ id: string }>;

interface IOwnProps {}

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const sectionId = Number(props.match.params.id);

  return {
    isFetching: detailIsFetchingSelector(sectionId)(state),
    detail: detailSelector(sectionId)(state),
    error: detailErrorSelector(sectionId)(state),
    appSetup: appSetupSelector(state),
    doctypes: doctypesSelector(state),
    sectionTree: sectionTreeFullSelector(state),
    items: articlesSelector(state),
    total: getListTotal(constants.LIST_NAME)(state),
    isLoading: isCommiting(constants.LIST_NAME)(state),
    hasCommitFailed: hasCommitFailed(constants.LIST_NAME)(state),
  };
};

const mapDispatchToProps = {
  initialize,
  load,
  setParams,
  loadDetail: loadDetail.action,
  saveSection: saveSection.action,
  loadTree: loadTree.action,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  IOwnProps;

const C: React.FC<Props> = ({
  match,
  detail,
  isFetching,
  error,
  appSetup,
  doctypes,
  sectionTree,
  loadTree,
  initialize,
  history,
  location: { search, pathname },
  items,
  load,
  setParams,
  total,
  isLoading,
  hasCommitFailed,
  loadDetail,
  saveSection,
}) => {
  const sectionId = Number(match.params.id);
  const prevSectionId = React.useRef<number>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);
  const [sectionArticles, setSectionArticles] = React.useState<{
    display?: string;
    articleFilter?: any[];
    header?: string;
  }>({});
  const [quickEditArticle, setQuickEditArticle] = React.useState<
    (Article & { defaultOpenedSegment?: IArticleSidebarSegmentType }) | null
  >(null);
  const { query, setQuery } = useQuery(
    search,
    (serializedQuery) => history.push(`${pathname}${serializedQuery}`),
    constants.QUERY_HOC_CONFIG,
  );

  React.useEffect(() => {
    if (detail && prevSectionId.current !== sectionId) {
      prevSectionId.current = sectionId;
      const sectionArticles = getProp(detail, ['sectionArticles'], {});
      setSectionArticles(sectionArticles);
    }
  }, [detail, sectionId]);

  React.useEffect(() => {
    loadTree();
    loadDetail({ sectionId });
  }, [loadTree, loadDetail, sectionId]);

  // Initialize redux-list
  React.useEffect(() => {
    initialize({
      listName: constants.LIST_NAME,
      initialParams: {
        offset: 0,
        filter: [],
      },
    });
  }, [initialize]);

  // Reload items when query changes
  React.useEffect(() => {
    setParams({
      listName: constants.LIST_NAME,
      parameters: {
        ...query,
        filter: JSON.stringify(sectionArticles.articleFilter || []),
      },
    });
    load();
  }, [load, setParams, query, sectionArticles.articleFilter]);

  function render() {
    if (hasCommitFailed) {
      return (
        <NotFound
          title={__('Ups! Nastala chyba pri načítaní')}
          icon="error"
          actionLabel={__('Skúsiť znova')}
          onClickAction={() => load()}
        />
      );
    }

    if (isFetching && typeof detail === 'undefined') {
      return <ContentLoader />;
    }

    if (error) {
      return (
        <NotFound
          title={__('Detail sekcie nie je možné načítať.')}
          icon="error"
        />
      );
    }

    return (
      <List
        items={items}
        onClickItem={(item) => {
          setQuickEditArticle(item);
        }}
        onClickPublish={(item) => {
          setQuickEditArticle({
            ...item,
            defaultOpenedSegment: 'publication',
          });
        }}
      />
    );
  }

  async function submit() {
    if (detail) {
      try {
        setIsSubmitting(true);
        setSubmitError(false);
        await saveSection({
          sectionId,
          values: {
            sectionArticles,
          } as any,
        });
      } catch (e) {
        setSubmitError(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <>
          <AppContext
            title={__('Detail sekcie')}
            breadcrumbs={[
              {
                name: __('Späť na zoznam'),
                url: '/sections',
              },
            ]}
          />
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to="/sections"
              title={__('Späť na zoznam')}
            />
            <HeaderBar.Header>
              {sectionId ? __('Detail sekcie') : __('Nová sekcia')}
            </HeaderBar.Header>
          </HeaderBar>
          <Tabs />
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={query.offset}
              limit={constants.LIST_LIMIT}
              total={total || 0}
              onChangeOffset={(e) => {
                setQuery({
                  offset: e,
                });
              }}
              loading={!!isLoading}
            />
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <Link to={`/articles/detail?section_id=${sectionId}`}>
              <Button primary basic>
                {__('Nový článok v tejto sekcii')}
              </Button>
            </Link>
          </ContextBarItem>
          <ContextBarItem>
            <SubmitButton
              onClick={submit}
              successText={__('Uložené!')}
              isSubmitting={isSubmitting}
              isError={submitError}
              disabled={!!error}
            >
              {__('Uložiť filter')}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment
          raised
          header={<SegmentHeader>{__('Zobrazenie článkov')}</SegmentHeader>}
        >
          <ThemeProvider theme={defaultContentTheme}>
            <WidgetEditorContext.Provider
              value={{
                auth: appSetup,
                availableWidgets: [],
                doctypes,
                sectionTree: sectionTree as any,
                calendars: [],
                variables: {},
                theme: {},
                staticContents: [],
                articlelistDisplayOptions: [
                  {
                    value: 'list',
                    label: 'Zoznam',
                  },
                  {
                    value: 'list_without_pictures',
                    label: 'Zoznam bez obrázkov',
                  },
                  {
                    value: 'tiles',
                    label: 'Dlaždice',
                  },
                ],
              }}
            >
              <ArticlesListWidget
                data={{ display: 'tiles' as any, ...sectionArticles }}
                onChange={setSectionArticles}
                headerPlaceholder={__('Ďalšie články v sekcii')}
                articleFilterPreview={false}
              />
            </WidgetEditorContext.Provider>
          </ThemeProvider>
        </Segment>
        <Segment noBottomGutter raised>
          {render()}
        </Segment>
      </Segment>

      <ArticleSidebar
        opened={quickEditArticle !== null}
        onClose={() => {
          setQuickEditArticle(null);
        }}
        openedSegment={
          quickEditArticle !== null
            ? quickEditArticle.defaultOpenedSegment
            : undefined
        }
        article={quickEditArticle !== null ? quickEditArticle : undefined}
        onAfterSave={() => {
          setQuickEditArticle(null);
          load();
        }}
        onAfterDelete={() => {
          setQuickEditArticle(null);
          load();
        }}
      />
    </ScrollLayout>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(C) as any as React.FC<IOwnProps>;
