import { Smarttag } from '../Api';
import { Smarttagvalue } from '../library/Smarttag';

const findSmarttag = (
  smarttag: Smarttagvalue,
  arrayOfSmarttags: Smarttag[],
) => {
  return arrayOfSmarttags.find((arrayItem) => arrayItem.key === smarttag.key);
};

const findValue = (value: Smarttagvalue, smarttagValues: Smarttagvalue[]) => {
  return smarttagValues.find(
    (smarttagValue) => smarttagValue.key === value.key,
  );
};

/**
 * Na vstupe je mnozina smart:tagov, na vystupe mnozina unikatnych smart:tagov
 * @param smarttags
 * @returns
 */
const getUniqueSmarttags = (smarttags: Smarttag[]): Smarttag[] => {
  return smarttags.reduce((acc: Smarttag[], smarttag) => {
    const existingSmarttag = findSmarttag(smarttag, acc);
    if (existingSmarttag) {
      // pohaldame vo values existujuceho smarttagu, ci existuje value. Ak nie, doplnime ju (mutujeme povodny objekt smarttagu)
      const missingValues = smarttag.values.reduce(
        (accMissingValues: Smarttagvalue[], smarttagValue) => {
          const existingValue = findValue(
            smarttagValue,
            existingSmarttag.values,
          );
          if (!existingValue) {
            accMissingValues.push(smarttagValue);
          }
          return accMissingValues;
        },
        [],
      );
      if (Array.isArray(existingSmarttag.values)) {
        existingSmarttag.values.push(...missingValues);
      } else {
        existingSmarttag.values = [...missingValues];
      }
    } else {
      acc.push({
        ...smarttag,
        values: Array.isArray(smarttag.values)
          ? [...smarttag.values.map((v) => ({ ...v }))] // TODO: robit kopiu samotnych values pravdepodobne netreba, kedze ich nemutujeme
          : [],
      });
    }
    return acc;
  }, []);
};

export default getUniqueSmarttags;
