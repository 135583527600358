import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { IArticleSidebarSegmentType } from '../ArticleSidebar/fields';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import { appSetupSelector, doctypesSelector } from '../../pages/App/selectors';
import { articlesSelector } from './selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getListNextOffset,
  getListTotal,
  hasCommitFailed,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { load, queryToApiArticlesParams } from './actions';
import { sectionTreeSelector } from '../../pages/Sections/selectors';
import Api, { Article } from '../../Api';
import ArticleFilter from './Filter';
import ArticleSidebar from '../ArticleSidebar';
import ArticlesUpdateSidebar from '../ArticlesUpdateSidebar';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import Link from '@sportnet/ui/Link';
import List, { Selected } from '../../components/ArticleList';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import constants from './constants';
import useQuery from '@sportnet/query-hoc/useQuery';

interface IOwnProps {}

const mapStateToProps = (state: RootState) => ({
  items: articlesSelector(state),
  total: getListTotal(constants.LIST_NAME)(state),
  nextOffset: getListNextOffset(constants.LIST_NAME)(state),
  isLoading: isCommiting(constants.LIST_NAME)(state),
  hasCommitFailed: hasCommitFailed(constants.LIST_NAME)(state),
  appSetup: appSetupSelector(state),
  doctypes: doctypesSelector(state),
  sectionTree: sectionTreeSelector(state),
});

const mapDispatchToProps = {
  initialize,
  load,
  setParams,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps &
  IOwnProps;

const C: React.FC<Props> = ({
  initialize,
  history,
  location: { search, pathname },
  items,
  load,
  setParams,
  nextOffset,
  isLoading,
  hasCommitFailed,
  appSetup,
  doctypes,
  sectionTree,
}) => {
  const [quickEditArticle, setQuickEditArticle] = React.useState<
    (Article & { defaultOpenedSegment?: IArticleSidebarSegmentType }) | null
  >(null);
  const [isExporting, setIsExporintg] = React.useState(false);
  const { query, setQuery } = useQuery(
    search,
    (serializedQuery) => history.push(`${pathname}${serializedQuery}`),
    constants.QUERY_HOC_CONFIG,
  );
  const [selected, setSelected] = React.useState<Selected>({});

  const [articlesUpdateSidebarOpened, setArticlesUpdateSidebarOpened] =
    React.useState<boolean>(false);

  const defaultDoctypeId = doctypes.length ? doctypes[0].id : '';

  // Initialize redux-list
  React.useEffect(() => {
    if (!defaultDoctypeId) {
      return;
    }
    initialize({
      listName: constants.LIST_NAME,
      initialParams: {
        offset: 0,
        doctypes: [defaultDoctypeId],
      },
    });
  }, [initialize, defaultDoctypeId]);

  // Reload items when query changes
  React.useEffect(() => {
    if (!defaultDoctypeId) {
      return;
    }
    const queryParams = {
      ...query,
      doctype: query.doctype.length ? query.doctype : [defaultDoctypeId],
    };
    setParams({
      listName: constants.LIST_NAME,
      parameters: queryParams,
    });
    load(queryParams);
  }, [load, setParams, query, defaultDoctypeId, setSelected]);

  if (!defaultDoctypeId) {
    return null;
  }

  function render() {
    if (hasCommitFailed) {
      return (
        <NotFound
          title={__('Ups! Nastala chyba pri načítaní')}
          icon="error"
          actionLabel={__('Skúsiť znova')}
          onClickAction={() => load(query)}
        />
      );
    }

    if (isLoading && items.length === 0) {
      return <ContentLoader />;
    }

    if (items.length === 0) {
      return <NotFound title={__('Žiadne články')} icon="search" />;
    }

    return (
      <List
        appSetup={appSetup}
        sectionTree={sectionTree}
        items={items}
        onClickItem={(item) => {
          setQuickEditArticle(item);
        }}
        onClickPublish={(item) => {
          setQuickEditArticle({
            ...item,
            defaultOpenedSegment: 'publication',
          });
        }}
        onClickOwner={(ownerSportnetId) => {
          setQuery({
            ...query,
            ownerSportnetId,
          });
        }}
        onClickAuthor={(authorId) => {
          setQuery({
            ...query,
            authorId,
          });
        }}
        sorter={query.sorter}
        onSort={(sorter) => {
          setQuery({
            ...query,
            sorter,
          });
        }}
        selected={articlesUpdateSidebarOpened ? selected : undefined}
        onSelect={articlesUpdateSidebarOpened ? setSelected : undefined}
      />
    );
  }

  const exportArticles = async () => {
    setIsExporintg(true);
    const { appId, appSpace, contentDivider } = appSetup;
    const { articleUrlPattern } = appSetup.params;
    const parameters = {
      ...queryToApiArticlesParams({
        ...query,
        doctype: query.doctype.length ? query.doctype : [defaultDoctypeId],
      }),
      limit: 10000,
      offset: 0,
      output: 'xlsx',
      articleUrlPattern,
    };
    try {
      const response: any = await Api.getArticles(
        appId,
        appSpace,
        contentDivider,
        parameters as any,
      );
      window.location = response.url;
    } catch (e) {
      console.log(e);
    } finally {
      setIsExporintg(false);
    }
  };

  return (
    <ScrollLayout
      topFixed={
        <>
          <ArticleFilter query={query} setQuery={setQuery} />
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={query.offset}
              limit={constants.LIST_LIMIT}
              nextOffset={nextOffset}
              onChangeOffset={(e) => {
                setQuery({
                  offset: e,
                });
              }}
              loading={!!isLoading}
            />
          </ContextBarItem>
          <ContextBarItem>
            <Button
              onClick={exportArticles}
              loading={isLoading || isExporting}
              disabled={isLoading || isExporting}
            >
              {__(`Exportovať články`)}
            </Button>
            <Button
              onClick={() => {
                setArticlesUpdateSidebarOpened(true);
              }}
            >
              {__(`Hromadná úprava`)}
            </Button>
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <Link to={`/articles/detail${search}`}>
              <Button primary>{__('Nový článok')}</Button>
            </Link>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment noBottomGutter raised>
          {render()}
        </Segment>
      </Segment>

      <ArticleSidebar
        opened={quickEditArticle !== null}
        onClose={() => {
          setQuickEditArticle(null);
        }}
        openedSegment={
          quickEditArticle !== null
            ? quickEditArticle.defaultOpenedSegment
            : undefined
        }
        article={quickEditArticle !== null ? quickEditArticle : undefined}
        onAfterSave={() => {
          setQuickEditArticle(null);
          load(query);
        }}
        onAfterDelete={() => {
          setQuickEditArticle(null);
          load(query);
        }}
      />

      <ArticlesUpdateSidebar
        opened={articlesUpdateSidebarOpened}
        onClose={() => {
          setSelected({});
          setArticlesUpdateSidebarOpened(false);
        }}
        selectedArticleIds={Object.entries(selected).reduce(
          (acc: number[], [key, val]) => {
            val && acc.push(Number(key));
            return acc;
          },
          [],
        )}
        onAfterSave={() => {
          load(query);
          setSelected({});
        }}
      />
    </ScrollLayout>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(C) as any as React.FC<IOwnProps>;
