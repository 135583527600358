import * as React from 'react';
import { IOption } from '@sportnet/ui/TheSelect/types';
import { IQuery } from './constants';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadTree } from '../../pages/Sections/actions';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { useTheSelectOptions } from '@sportnet/ui/TheSelect/utils';
import Filter from '@sportnet/ui/Filter';
import Tabber from '@sportnet/ui/Tabber';
import withPopups from '../../components/WithPopups';

const externalSourceOptions = [
  { label: __('Nezáleží'), value: '' },
  { label: 'TASR', value: 'TASR' },
  { label: 'SITA', value: 'SITA' },
];

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
});

const mapDispatchToProps = {
  loadTree: thunkToAction(loadTree.action),
};

interface OwnProps {
  query: IQuery;
  setQuery: (value: Partial<IQuery>) => void;
}

type Props = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const C: React.FC<Props> = ({ query, setQuery, appSetup, loadTree }) => {
  React.useEffect(() => {
    loadTree();
  }, [loadTree]);

  const [, externalSource] = useTheSelectOptions(
    externalSourceOptions,
    (x) => x,
    query.externalSource,
    [externalSourceOptions],
  );

  const [debouncedQ, setDebouncedQ] = React.useState(query.q);
  const debounceQtimer = React.useRef<number>();

  const filterValue = {
    q: debouncedQ,
    publishedFrom: query.publishedFrom,
    publishedTo: query.publishedTo,
    externalSource,
  };

  const states = [
    { id: 'new', title: 'Nové' },
    {
      id: 'taken',
      title: 'Prebraté',
    },
    {
      id: 'deleted',
      title: 'Zahodené',
    },
  ];

  const handleOnTypeFilterClick = React.useCallback(
    (tabId: string) => {
      const state = states.find((state) => state.title === tabId);
      if (state) {
        setQuery({ state: state.id });
      }
    },
    [states, setQuery],
  );

  const tabItems = React.useMemo(() => {
    return states.map((state) => {
      return state.title;
    });
  }, [states]);

  const activeTab = React.useMemo(() => {
    const state = states.find((tab) => tab.id === query.state);
    return state ? state.title : '';
  }, [states, query.state]);

  return (
    <>
      <Tabber
        active={activeTab}
        items={tabItems}
        onClickItem={handleOnTypeFilterClick}
      ></Tabber>

      <Filter
        value={filterValue}
        onChange={(nextFilterValue) => {
          const newQuery = {
            offset: 0,
            q: nextFilterValue.q ? (nextFilterValue.q as string) : '',
            publishedFrom: nextFilterValue.publishedFrom
              ? new Date(nextFilterValue.publishedFrom as string).toJSON()
              : '',
            publishedTo: nextFilterValue.publishedTo
              ? new Date(nextFilterValue.publishedTo as string).toJSON()
              : '',
            externalSource: nextFilterValue.externalSource
              ? ((nextFilterValue.externalSource as IOption).value as string)
              : '',
          };
          if (
            nextFilterValue.q === undefined ||
            filterValue.q !== nextFilterValue.q
          ) {
            setDebouncedQ((nextFilterValue.q as string) || '');
            if (debounceQtimer.current) {
              window.clearTimeout(debounceQtimer.current);
            }
            debounceQtimer.current = window.setTimeout(() => {
              setQuery(newQuery);
            }, 300);
          } else {
            // zavolame funkciu na zmenu ihned
            setQuery(newQuery);
          }
        }}
        filters={[
          {
            type: 'query' as const,
            name: 'q',
            placeholder: __('Vyhľadávanie v externých článkoch'),
          },
          {
            type: 'select' as const,
            options: externalSourceOptions,
            label: __('Zdroj'),
            name: 'externalSource',
            multiple: false,
          },
          {
            type: 'date' as const,
            label: __('Dátum od'),
            name: 'publishedFrom',
          },
          {
            type: 'date' as const,
            label: __('Dátum do'),
            name: 'publishedTo',
          },
        ]}
      />
    </>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
