import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { IListingCreate, IListingEdit } from '../../library/App';
import { Link } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import { appSetupSelector, doctypesSelector } from '../../pages/App/selectors';
import { articlesSelector } from '../Section/pages/Articles/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  createListing,
  deleteListing,
  editListing,
  loadDetail,
} from '../../containers/Listing/actions';
import {
  detailErrorSelector,
  detailIsFetchingSelector,
  detailSelector,
} from '../../containers/Listing/selectors';
import {
  getListNextOffset,
  getListTotal,
  initialize as initializeList,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import {
  initialize as initializeForm,
  isDirty,
  isSubmitting,
  submit,
} from 'redux-form';
import { load } from '../Section/pages/Articles/actions';
import { loadTree } from '../Sections/actions';
import { saveListingErrorHandler } from '../../containers/Listing/errorHandler';
import { sectionTreeFullSelector } from '../Sections/selectors';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import Api, { Article } from '../../Api';
import ArticleFilter from '@sportnet/content/editor/components/ArticleFilter';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import Form, { FORM_NAME, IFormData } from './form';
import FormField from '@sportnet/ui/FormField';
import HeaderBar from '@sportnet/ui/HeaderBar';
import List from '../../components/ArticleList';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import SubmitButton from '@sportnet/ui/Button/Submit';
import WidgetEditorContext from '@sportnet/content/editor/appContext';
import constants from '../Section/pages/Articles/constants';
import useQuery from '@sportnet/query-hoc/useQuery';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

type RouteProps = RouteComponentProps<{ id?: string }>;
type ListingArticle = Article & { listingName?: string };

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const listingId = props.match.params.id;
  return {
    isFetching: listingId ? detailIsFetchingSelector(listingId)(state) : false,
    detail: listingId ? detailSelector(listingId)(state) : undefined,
    error: listingId ? detailErrorSelector(listingId)(state) : undefined,
    isSubmitting: isSubmitting(FORM_NAME)(state),
    isDirty: isDirty(FORM_NAME)(state),
    appSetup: appSetupSelector(state),
    total: getListTotal(constants.LIST_NAME)(state),
    isLoading: isCommiting(constants.LIST_NAME)(state),
    items: articlesSelector(state),
    sectionTree: sectionTreeFullSelector(state),
    doctypes: doctypesSelector(state),
    nextOffset: getListNextOffset(constants.LIST_NAME)(state),
  };
};

const mapDispatchToProps = {
  initializeForm,
  load,
  initializeList,
  submit,
  setParams,
  loadDetail: thunkToAction(loadDetail.action),
  createListing: thunkToAction(createListing.action),
  editListing: thunkToAction(editListing.action),
  deleteListing: thunkToAction(deleteListing.action),
  loadTree: loadTree.action,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  WithPopupsProps;

const C: React.FC<Props> = ({
  initializeForm,
  initializeList,
  submit,
  match,
  loadDetail,
  isFetching,
  confirm,
  detail,
  error,
  history,
  location: { search, pathname },
  createListing,
  editListing,
  deleteListing,
  alert,
  isSubmitting,
  isDirty,
  appSetup,
  setParams,
  load,
  total,
  isLoading,
  items,
  sectionTree,
  doctypes,
  loadTree,
  nextOffset,
}) => {
  const [submitError, setSubmitError] = React.useState(false);
  const [fixedArticles, setFixedArticles] = React.useState<ListingArticle[]>(
    [],
  );
  const [articleFilterChanged, setArticleFilterChanged] =
    React.useState<boolean>(false);
  const [fixedArticlesChanged, setFixedArticlesChanged] =
    React.useState<boolean>(false);
  const [articleFilter, setArticleFilter] = React.useState<any[]>([]);
  const [editingArticle, setEditingArticle] =
    React.useState<null | ListingArticle>(null);

  const { query, setQuery } = useQuery(
    search,
    (serializedQuery) => history.push(`${pathname}${serializedQuery}`),
    constants.QUERY_HOC_CONFIG,
  );

  const listingId = match.params.id || '';
  const { appId, appSpace, contentDivider } = appSetup;

  React.useEffect(() => {
    (async () => {
      if (listingId) {
        try {
          await loadDetail({ listingId });
        } catch (e) {
          // alert('Neexistuje');
        }
      }
    })();
  }, [loadDetail, listingId]);

  React.useEffect(() => {
    if (detail) {
      setArticleFilter(detail.articleFilter || []);
    }
  }, [detail]);

  // Initialize fixed articles
  React.useEffect(() => {
    (async () => {
      if (detail) {
        initializeForm(FORM_NAME, {
          name: detail.name,
          type: detail.type,
          listingId: detail.listingId,
          markers: detail.markers?.join(', ') || '',
        } as IFormData);

        // fixed articles
        const { articles } = await Api.getListingFixedArticles(
          appId,
          appSpace,
          contentDivider,
          listingId,
        );
        setFixedArticles(articles);
      }
    })();
  }, [detail, initializeForm, appId, appSpace, contentDivider, listingId]);

  // Initialize section tree
  React.useEffect(() => {
    (async () => {
      await loadTree();
    })();
  }, [loadTree]);

  // Initialize redux-list
  React.useEffect(() => {
    initializeList({
      listName: constants.LIST_NAME,
      initialParams: {
        offset: 0,
        filter: [],
      },
    });
  }, [initializeList]);

  const excludeIds = React.useMemo(() => {
    return fixedArticles.map(({ _id }) => _id);
  }, [fixedArticles]);

  const excludeIdsKey = React.useMemo(() => {
    return excludeIds.sort().join('-');
  }, [excludeIds]);

  // Reload items when query changes
  React.useEffect(() => {
    setParams({
      listName: constants.LIST_NAME,
      parameters: {
        ...query,
        filter: JSON.stringify(articleFilter || []),
        excludeIds,
      },
    });
    load();
  }, [load, setParams, query, articleFilter, excludeIdsKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const listingDataChanged = () =>
    isDirty || fixedArticlesChanged || articleFilterChanged;

  async function handleSubmit(formValues: IFormData) {
    try {
      setSubmitError(false);
      if (!listingId) {
        const values: IListingCreate = {
          name: formValues.name,
          type: formValues.type,
          markers: (formValues.markers || '')
            .split(/[ ,.]+/)
            ?.filter((x) => !isNaN(x as any) && +x > 0)
            .map((x) => +x),
          articles: fixedArticles.map((item) => {
            const a: { _id: number; name?: string } = { _id: item._id! };
            if (item.listingName) {
              a.name = item.listingName!;
            }
            return a;
          }),
          articleFilter,
          listingId: formValues.listingId,
        };
        await createListing({ values });
        await loadDetail({ listingId });
        history.push(`/listing/${values.listingId}`);
      } else {
        // combine form values with fixed articles and articleFilter
        const values: IListingEdit = {
          name: formValues.name,
          type: formValues.type,
          markers: (formValues.markers || '')
            .split(/[ ,.]+/)
            ?.filter((x) => !isNaN(x as any) && +x > 0)
            .map((x) => +x),
          articles: fixedArticles.map((item) => {
            const a: { _id: number; name?: string } = { _id: item._id! };
            if (item.listingName) {
              a.name = item.listingName!;
            }
            return a;
          }),
          articleFilter,
        };

        await editListing({ values, listingId });
        await loadDetail({ listingId });
      }
    } catch (e) {
      setSubmitError(true);
      saveListingErrorHandler(e, alert);
    }
  }

  /**
   * Deleting fixed article from fixed articles table.
   */
  const handleDeleteFixedArticleClick = (article: Article) => {
    // remove row from fixed articles table
    setFixedArticles((prev) => {
      const copy = [...prev];
      const idx = copy.findIndex((item) => item._id === article._id);
      if (idx > -1) {
        copy.splice(idx, 1);
      }
      return copy;
    });
    setFixedArticlesChanged(true);
  };

  const addFixedArticle = (article: Article) => {
    setFixedArticles((prev) => [...prev, article]);
    setFixedArticlesChanged(true);
  };

  /**
   * Handles reordering items in fixed articles table.
   * @param articles List of fixed articles.
   */
  const handleFixedArticlesReorder = (articles: Article[]) => {
    setFixedArticles(articles);
    setFixedArticlesChanged(true);
  };

  const handleChangeFilter = (newFilter: any[]) => {
    setArticleFilter(newFilter);
    setArticleFilterChanged(true);
  };

  const renderContent = () => {
    if (isFetching && typeof detail === 'undefined') {
      return <ContentLoader />;
    }
    if (error) {
      return (
        <NotFound
          title={__('Detail listingu nie je možné načítať.')}
          icon="error"
        />
      );
    }
    return (
      <>
        <Segment raised>
          <Form onSubmit={handleSubmit} creating={!!!listingId} />
        </Segment>
        <Segment
          header={<SegmentHeader>{__('Fixné články')}</SegmentHeader>}
          raised
        >
          <List
            items={fixedArticles || []}
            onClickItem={(item) => {}}
            onClickPublish={(item) => {}}
            actionButton={{
              type: 'danger',
              icon: 'trash',
              onClick: handleDeleteFixedArticleClick,
            }}
            onReorder={handleFixedArticlesReorder}
            onEditName={(item) => {
              setEditingArticle({ ...item } as ListingArticle);
            }}
            listingNameKey="listingName"
            markers={detail?.markers || []}
          />
        </Segment>
        <Modal
          handleClose={() => setEditingArticle(null)}
          isOpen={!!editingArticle}
        >
          {editingArticle && (
            <ModalContent>
              <FormField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEditingArticle((prev) => ({
                    ...prev,
                    listingName: e.target.value,
                  }));
                }}
                value={
                  (editingArticle as any).listingName || editingArticle.name
                }
              />
            </ModalContent>
          )}
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button type="button" onClick={() => setEditingArticle(null)}>
                {__('Zavrieť')}
              </Button>
              <Button
                primary
                type="button"
                onClick={() => {
                  if (editingArticle) {
                    setFixedArticles((fixedArticles) => {
                      const index = fixedArticles.findIndex(
                        ({ _id }) => _id === editingArticle._id,
                      );
                      if (index >= 0) {
                        fixedArticles[index] = editingArticle;
                      }
                      return [...fixedArticles];
                    });
                    setFixedArticlesChanged(true);
                    setEditingArticle(null);
                  }
                }}
              >
                {__('Upraviť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Segment
          header={<SegmentHeader>{__('Filter článkov')}</SegmentHeader>}
          raised
        >
          <WidgetEditorContext.Provider
            value={{
              auth: appSetup,
              availableWidgets: [],
              doctypes,
              sectionTree: sectionTree as any,
              calendars: [],
              variables: {},
              theme: {},
              staticContents: [],
              articlelistDisplayOptions: [
                {
                  value: 'list',
                  label: 'Zoznam',
                },
                {
                  value: 'list_without_pictures',
                  label: 'Zoznam bez obrázkov',
                },
                {
                  value: 'tiles',
                  label: 'Dlaždice',
                },
              ],
            }}
          >
            <ArticleFilter
              filter={articleFilter}
              filterName="articleFilter"
              widgetType="articlelist"
              preview={false}
              onChangeFilter={handleChangeFilter}
            />
          </WidgetEditorContext.Provider>
        </Segment>
        <Segment raised>
          <List
            items={items || []}
            onClickItem={(item) => {}}
            onClickPublish={(item) => {}}
            actionButton={{
              type: 'success',
              icon: 'plus',
              onClick: addFixedArticle,
            }}
          />
        </Segment>
      </>
    );
  };

  async function handleDelete() {
    if (!detail) {
      return;
    }
    if (await confirm(__('Naozaj chcete odstrániť tento listing?'))) {
      try {
        await deleteListing({ listingId: detail.listingId });
        history.push('/listings');
      } catch (e) {
        await alert(__('Listing nie je možné odstrániť'));
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to={`/listings/${detail?.type}`}
            title={__('Späť na zoznam')}
          />
          <HeaderBar.Header>
            {listingId ? __('Detail listingu') : __('Nový listing')}
          </HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={query.offset}
              limit={constants.LIST_LIMIT}
              nextOffset={nextOffset}
              total={total || 0}
              onChangeOffset={(e) => {
                setQuery({
                  offset: e,
                });
              }}
              loading={!!isLoading}
            />
          </ContextBarItem>
          {listingId && (
            <ContextBarItem>
              <Button danger basic disabled={!!error} onClick={handleDelete}>
                {__('Odstrániť')}
              </Button>
            </ContextBarItem>
          )}
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              successText={__('Uložené!')}
              isSubmitting={isSubmitting}
              isError={submitError}
              disabled={!listingDataChanged() || !!error}
              onClick={() => {
                submit(FORM_NAME);
              }}
            >
              {__('Uložiť')}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment>{renderContent()}</Segment>
    </ScrollLayout>
  );
};

export default compose(
  withRouter,
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
