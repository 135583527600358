import * as React from 'react';
import { ListingType } from '../../Api';
import { ThemeInterface } from '../../theme/theme';
import { change, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { editListing } from '../../containers/Listing/actions';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import Icon from '@sportnet/ui/Icon';
import Input from '@sportnet/ui/Input';
import styled, { withTheme } from 'styled-components';

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  * {
    margin: 0;
    padding-bottom: 0;
  }
`;

const NameInput = styled(Input)``;

interface IOwnProps {
  creating: boolean;
  priority: number;
  type: ListingType;
  listingId: string;
  markers: Array<number>;
  reload: () => void;
}

type Props = IOwnProps & { theme: ThemeInterface };

const mapDispatchToProps = {
  change,
  editListing: thunkToAction(editListing.action),
};

export const FORM_NAME = 'listing-list-form';
export interface IFormData {
  priority: number;
}

const ListingListForm: React.FC<Props & typeof mapDispatchToProps> = ({
  priority,
  type,
  listingId,
  markers,
  editListing,
  reload,
  theme,
}) => {
  const [newPriority, setNewPriority] = React.useState<number | undefined>(
    priority,
  );
  const [isPriorityChanged, setIsPriorityChanged] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    setIsPriorityChanged(newPriority !== priority);
  }, [newPriority, priority]);

  const handleSubmit = async (e: any, clear = false) => {
    e.preventDefault();

    if (clear) {
      setNewPriority(undefined);
    }

    setIsFetching(true);
    await editListing({
      values: { priority: clear || !newPriority ? null : newPriority },
      listingId,
    })
      .then(() => {
        setIsPriorityChanged(false);
        reload();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <NameInput
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();
        }}
        value={newPriority || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNewPriority(
            e.target.value?.length > 0 ? parseInt(e.target.value) : undefined,
          );
        }}
        loading={isFetching}
      >
        <input />
        {isPriorityChanged && (
          <Input.Button
            loading={isFetching}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
            }}
          >
            <Icon color={theme.color.primary} name="check" />
          </Input.Button>
        )}
        {!isPriorityChanged && !!newPriority && (
          <Input.Button
            loading={isFetching}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              handleSubmit(e, true);
            }}
          >
            <Icon color={theme.color.primary} name="close" />
          </Input.Button>
        )}
      </NameInput>
    </Wrapper>
  );
};

export default withTheme(
  compose(
    reduxForm({
      form: FORM_NAME,
    }),
    connect(null, mapDispatchToProps),
  )(ListingListForm) as any,
);
