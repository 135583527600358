import { Doctype } from '../../Api';
import { DoctypeId, IDoctypeSave } from '../../library/App';
import { ExtraArgumentType } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { updateEntities } from '../../pages/App/actions';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.DOCTYPE_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const save = createAsync<{ values: IDoctypeSave; isNew: boolean }, void>(
  'SAVE',
  async ({ values, isNew }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    let response: Doctype;

    if (isNew) {
      response = await Api.postDoctype(
        appId,
        appSpace,
        contentDivider,
        {},
        values,
      );
    } else {
      response = await Api.editDoctype(
        appId,
        appSpace,
        contentDivider,
        values.id,
        {},
        values,
      );
    }

    dispatch(
      updateEntities({
        doctypes: {
          [response.id]: response,
        },
      }),
    );
  },
);

export const deleteDoctype = createAsync<{ doctypeId: DoctypeId }, void>(
  'DELETE',
  async ({ doctypeId }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    await Api.deleteDoctype(appId, appSpace, contentDivider, doctypeId, {});
  },
);
