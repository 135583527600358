import { WithPopupsProps } from '../../components/WithPopups';
import { __ } from '../../utilities/';

export const saveStaticContentErrorHandler = async (
  e: any,
  alertFn: WithPopupsProps['alert'],
) => {
  const alert = alertFn;
  if (e && e.details) {
    if (e.details.name === 'CYCLIC_ENTITY_REFERENCE') {
      await alert(
        __(
          'Obsah statického textu obsahuje widget "Obsah inej položky", ktorý referuje na samého seba',
        ),
      );
    } else if (e.details.code === 403) {
      await alert(__('Prístup bol odmietnutý'));
    } else if (e.details.code === 409) {
      await alert(__('Statický text so zadaným identifikátorom už existuje'));
    } else {
      await alert(__('Statický text nie je možné uložiť'));
    }
  } else {
    await alert(__('Vyskytla sa neočakávaná chyba'));
  }
};
