import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { changeState, load } from './actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { externalArticlesSelector } from './selectors';
import { format } from 'date-fns';
import {
  getListNextOffset,
  hasCommitFailed,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { objectToParams } from '@sportnet/utilities';
import { rem } from 'polished';
import Api, {
  Article,
  Article_Post,
  External_Article,
  External_Article_Put,
} from '../../Api';
import ArticleFilter from './Filter';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import Header from '@sportnet/ui/Header';
import List from '../../components/ExternalArticleList';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import config from '../../config';
import constants from './constants';
import styled from '../../theme/styled-components';
import useQuery from '@sportnet/query-hoc/useQuery';

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: ${rem(12)};
  justify-content: space-between;
`;

interface IOwnProps {}

const mapStateToProps = (state: RootState) => ({
  items: externalArticlesSelector(state),
  isLoading: isCommiting(constants.LIST_NAME)(state),
  hasCommitFailed: hasCommitFailed(constants.LIST_NAME)(state),
  appSetup: appSetupSelector(state),
  nextOffset: getListNextOffset(constants.LIST_NAME)(state),
});

const mapDispatchToProps = {
  initialize,
  load,
  setParams,
  changeState,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps &
  IOwnProps;

const C: React.FC<Props> = ({
  initialize,
  history,
  location: { search, pathname },
  items,
  load,
  changeState,
  setParams,
  nextOffset,
  isLoading,
  hasCommitFailed,
  appSetup,
}) => {
  const [externalArticlesChanged, setExternalArticlesChanged] =
    React.useState<boolean>(false);
  const [detailArticle, setDetailArticle] =
    React.useState<External_Article | null>(null);
  const { query, setQuery } = useQuery(
    search,
    (serializedQuery) => history.push(`${pathname}${serializedQuery}`),
    constants.QUERY_HOC_CONFIG,
  );

  const getSearchParams = () => {
    const queryString = objectToParams(query);
    return queryString ? '?' + queryString : '';
  };

  const handleDeleteExternalArticleClick = (article: External_Article) => {
    const state: External_Article_Put = {
      state: 'deleted',
    };
    changeState({ state, articleId: article._id! });
    setExternalArticlesChanged(true);
  };

  const [takingExternalArticleId, setTakingExternalArticleId] = React.useState<
    string | null
  >(null);

  const handleTakeExternalArticleClick = async (article: External_Article) => {
    const state: External_Article_Put = {
      state: 'taken',
    };
    changeState({ state, articleId: article._id! });
    const data: Article_Post = {
      name: article.name || '',
      perex: article.perex,
      widgets: [{ type: 'text', text: article.html }],
      authors: [{ name: article.externalSource?.toUpperCase() || '' }],
    };

    setTakingExternalArticleId(article._id!);

    let takenArticle: Article = await Api.postArticle(
      appSetup.appId,
      appSetup.appSpace,
      appSetup.contentDivider,
      {},
      data as Article_Post,
    );

    setTakingExternalArticleId(null);

    history.push(
      `/articles/detail/${
        takenArticle._id
      }${getSearchParams()}&from=external-articles`,
    );
  };

  // Initialize redux-list
  React.useEffect(() => {
    initialize({
      listName: constants.LIST_NAME,
      initialParams: {
        offset: 0,
        state: 'new',
      },
    });
  }, [initialize]);

  const [lastUpdate, setLastUpdate] = React.useState(new Date());
  const reloadTimer = React.useRef<number>();
  React.useEffect(() => {
    const queryParams = {
      ...query,
    };
    if (reloadTimer.current) {
      clearTimeout(reloadTimer.current);
    }
    setParams({
      listName: constants.LIST_NAME,
      parameters: queryParams,
    });
    load(queryParams);
    reloadTimer.current = window.setTimeout(() => {
      setLastUpdate(new Date());
    }, 10000);
    return () => {
      // is fired on component unmount
      if (reloadTimer.current) {
        window.clearTimeout(reloadTimer.current);
      }
    };
  }, [lastUpdate, load, setParams, query]);

  // Reload items when articles changed
  React.useEffect(() => {
    if (externalArticlesChanged) {
      setExternalArticlesChanged(false);
      load(query);
    }
  }, [externalArticlesChanged, load, query]);

  function render() {
    if (hasCommitFailed) {
      return (
        <NotFound
          title={__('Ups! Nastala chyba pri načítaní')}
          icon="error"
          actionLabel={__('Skúsiť znova')}
          onClickAction={() => load(query)}
        />
      );
    }

    if (isLoading && items.length === 0) {
      return <ContentLoader />;
    }

    if (items.length === 0) {
      return <NotFound title={__('Žiadne externé články')} icon="search" />;
    }

    return (
      <List
        items={items}
        takingExternalArticleId={takingExternalArticleId}
        onClickItem={(item) => {
          setDetailArticle(item);
        }}
        sorter={query.sorter}
        onSort={(sorter) => {
          setQuery({
            ...query,
            sorter,
          });
        }}
        deleteButton={
          query.state === 'new'
            ? {
                type: 'danger',
                icon: 'trash',
                onClick: handleDeleteExternalArticleClick,
              }
            : undefined
        }
        takeButton={
          query.state === 'taken'
            ? undefined
            : {
                type: 'primary',
                icon: 'add-circle-outline',
                label: __('Prebrať'),
                onClick: handleTakeExternalArticleClick,
              }
        }
      />
    );
  }

  const handleModalClose = React.useCallback(() => {
    setDetailArticle(null);
  }, []);

  return (
    <ScrollLayout
      topFixed={
        <>
          <ArticleFilter query={query} setQuery={setQuery} />
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={query.offset}
              nextOffset={nextOffset}
              limit={constants.LIST_LIMIT}
              onChangeOffset={(e) => {
                setQuery({
                  offset: e,
                });
              }}
              loading={!!isLoading}
            />
          </ContextBarItem>
          <ContextBarSpacer />
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment noBottomGutter raised>
          {render()}
        </Segment>
      </Segment>

      {detailArticle && (
        <Modal isOpen={detailArticle !== null} handleClose={handleModalClose}>
          <ModalContent>
            <Segment noBottomGutter>
              <Header>{detailArticle?.name}</Header>
              <p>
                <strong>{__('Perex: ')}</strong>
                {detailArticle?.perex}
              </p>
              <p>
                <strong>{__('Zdroj: ')}</strong>
                {(detailArticle?.externalSource || '').toUpperCase()}
              </p>
              <p>
                <strong>{__('Dátum: ')}</strong>
                {detailArticle?.published
                  ? format(
                      new Date(detailArticle?.published),
                      config.DATETIME_FORMAT,
                    )
                  : ''}
              </p>
            </Segment>
            <Segment>
              <div
                dangerouslySetInnerHTML={{ __html: detailArticle?.html || '' }}
              />
            </Segment>
          </ModalContent>
          <ModalActions>
            <Button
              primary
              basic
              onClick={() => {
                setDetailArticle(null);
              }}
            >
              Zavrieť
            </Button>
            <ButtonWrapper>
              {detailArticle?.state === 'taken' ? null : (
                <Button
                  primary
                  icon="add-circle-outline"
                  onClick={() => {
                    if (detailArticle) {
                      handleTakeExternalArticleClick(detailArticle);
                    }
                  }}
                  disabled={takingExternalArticleId === detailArticle!._id}
                  loading={takingExternalArticleId === detailArticle!._id}
                >
                  {__('Prebrať')}
                </Button>
              )}
              {detailArticle?.state === 'deleted' ? null : (
                <Button
                  danger
                  icon="trash"
                  onClick={() => {
                    if (detailArticle) {
                      handleDeleteExternalArticleClick(detailArticle);
                      setDetailArticle(null);
                    }
                  }}
                  disabled={takingExternalArticleId === detailArticle!._id}
                >
                  {__('Do koša')}
                </Button>
              )}
            </ButtonWrapper>
          </ModalActions>
        </Modal>
      )}
    </ScrollLayout>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(C) as any as React.FC<IOwnProps>;
