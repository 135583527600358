import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { URLMapUrl } from '../../Api';
import { __ } from '../../utilities/';
import BasicTable from '@sportnet/ui/BasicTable';
import Icon from '@sportnet/ui/Icon';
import styled from 'styled-components';

const Permanent = styled.div`
  display: inline-flex;
  font-size: 80%;
  align-items: center;
  gap: 5px;
  background: #eee;
  padding: 3px 5px;
  border-radius: 5px;
`;

interface OwnProps {
  items: URLMapUrl[];
  onClickRow: (item: URLMapUrl) => void;
}

type Props = OwnProps & RouteComponentProps;

const C: React.FC<Props> = ({ items, onClickRow }) => {
  return (
    <BasicTable
      columns={[
        {
          header: __('Zdrojová URL'),
        },
        {
          header: __('Cieľová URL'),
        },
        {
          header: __('Permanentné'),
        },
      ]}
      rows={items}
      onClickRow={onClickRow}
      renderRow={(item: URLMapUrl) => [
        item.url,
        item.location,
        item.permanent ? (
          <Permanent>
            <Icon name="check" />
            <span>HTTP 301</span>
          </Permanent>
        ) : (
          <Permanent>
            <Icon name="close" />
            <span>HTTP 302</span>
          </Permanent>
        ),
      ]}
      rowKey="url"
    />
  );
};

export default withRouter(C);
