/* tslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  statusCode: number;

  name: string;

  description: string | null;

  payload?: object | null;

  userinfo?: string;
};
export type Folder_Tree = {
  folders?: Array<Folder_Get>;
};
export type Folder = {
  name?: string;
};
export type Folder_Get = {
  name?: string;

  _id?: string;

  contentspace?: string;

  children?: Array<Folder_Get>;

  parents?: Array<string>;
};
export type Folder_Put = {
  name?: string;
};
export type Folder_Post = {
  name: string;

  parent?: string | null;
};
export type Files_List = {
  files?: Array<File_Get>;
};
export type File_Abstract = {
  author?: string;
};
export type File = {
  name?: string;

  author?: string;

  description?: string;

  source?: string;
};
export type File_Get = {
  name?: string;

  author?: string;

  description?: string;

  source?: string;

  _id?: string;

  contentspace?: string;

  modified_date?: string;

  filepath?: string;

  parents?: Array<string>;
};
export type File_Put = {
  author?: string;

  description?: string;

  name?: string;

  source?: string;

  smarttags?: Array<{
    key?: string;

    values?: Array<{
      key?: string;

      title?: string;
    }>;
  }>;
};
export type Files_List_Put = {
  author?: string;

  name?: string;

  description?: string;

  source?: string;

  id?: Array<string>;

  smarttags?: Array<{
    key?: string;

    values?: Array<{
      key?: string;

      title?: string;
    }>;
  }>;
};
export type Files_Ids = Array<string>;
export type Smarttags = Array<Smarttag>;
export type Smarttag = {
  key?: string;

  values?: Array<{
    key?: string;

    title?: string | null;
  }>;
};
export type File_List_Move = {
  ids?: Array<string>;

  destination_id?: string | null;
};

class ApiError {
  public message: string;
  public details: Error;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 * RESTful API specification.

 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class Api {
  protected baseUrl: string = 'https://localhost';
  protected token: string;

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      return response.json();
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Endpoint pre stiahnutie súboru a ulozenie do cieľového adresára zadefinovaného ako parameter destination (nepovinné)
   * @method
   * @name Api#createFileFromUrl
   */
  createFileFromUrl(
    appId: string,
    appSpace: string,
    parameters: {} = {},
    body: {
      url: string;

      destination?: string;
    },
  ): Promise<File_Get> {
    let path = '/app/{app_id}/space/{app_space}/filefromurl';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre získanie zoznamu súborov pre všetky adresáre
   * @method
   * @name Api#getFiles
   */
  getFiles(
    appId: string,
    appSpace: string,
    parameters: {
      parentId?: string;
      name?: string;
      type?: string;
      smarttag?: Array<string>;
      query?: string;
      sorter?: string;
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<Files_List> {
    let path = '/app/{app_id}/space/{app_space}/files';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['parentId'] !== 'undefined') {
      queryParameters['parent_id'] = parameters['parentId'];
    }

    queryParameters['parent_id'] = this.transformParameter(
      queryParameters['parent_id'],
    );

    if (typeof parameters['name'] !== 'undefined') {
      queryParameters['name'] = parameters['name'];
    }

    queryParameters['name'] = this.transformParameter(queryParameters['name']);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['smarttag'] !== 'undefined') {
      queryParameters['smarttag'] = parameters['smarttag'];
    }

    queryParameters['smarttag'] = this.transformParameter(
      queryParameters['smarttag'],
      'joinUsingPipes',
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre upload súboru do cieľového adresára zadefinovaného ako parameter destination (nepovinné)
   * @method
   * @name Api#uploadFile
   */
  uploadFile(
    appId: string,
    appSpace: string,
    parameters: {},
  ): Promise<File_Get> {
    let path = '/app/{app_id}/space/{app_space}/files';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre úpravu informácií o viacerých súboroch podľa ID súborov
   * @method
   * @name Api#editFilesByIds
   */
  editFilesByIds(
    appId: string,
    appSpace: string,
    parameters: {},
    body: Files_List_Put,
  ): Promise<Files_List> {
    let path = '/app/{app_id}/space/{app_space}/files';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre zmazanie viacerých súborov podľa ID
   * @method
   * @name Api#deleteFilesByIds
   */
  deleteFilesByIds(
    appId: string,
    appSpace: string,
    parameters: {},
    body: Files_Ids,
  ): Promise<{
    message?: string;
  }> {
    let path = '/app/{app_id}/space/{app_space}/files';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre hromadný presun súborov do jedného priečinku
   * @method
   * @name Api#moveFiles
   */
  moveFiles(
    appId: string,
    appSpace: string,
    parameters: {},
    body: File_List_Move,
  ): Promise<Files_List> {
    let path = '/app/{app_id}/space/{app_space}/files/move';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre detail súboru na základe jeho ID
   * @method
   * @name Api#getFileById
   */
  getFileById(
    appId: string,
    appSpace: string,
    fileId: string,
    parameters: {} = {},
  ): Promise<File_Get> {
    let path = '/app/{app_id}/space/{app_space}/file/{file_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    path = path.replace('{file_id}', fileId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre úpravu informácií o súbore podľa ID súboru
   * @method
   * @name Api#editFileById
   */
  editFileById(
    appId: string,
    appSpace: string,
    fileId: string,
    parameters: {},
    body: File_Put,
  ): Promise<File_Get> {
    let path = '/app/{app_id}/space/{app_space}/file/{file_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    path = path.replace('{file_id}', fileId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre zmazanie súboru podľa ID
   * @method
   * @name Api#deleteFileById
   */
  deleteFileById(
    appId: string,
    appSpace: string,
    fileId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path = '/app/{app_id}/space/{app_space}/file/{file_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    path = path.replace('{file_id}', fileId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Endpoint pre presun súboru do priečinku
   * @method
   * @name Api#moveFile
   */
  moveFile(
    appId: string,
    appSpace: string,
    fileId: string,
    destinationId: string,
    parameters: {} = {},
  ): Promise<File_Get> {
    let path =
      '/app/{app_id}/space/{app_space}/file/{file_id}/move/{destination_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{app_id}', appId.toString());

    path = path.replace('{app_space}', appSpace.toString());

    path = path.replace('{file_id}', fileId.toString());

    path = path.replace('{destination_id}', destinationId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new Api();
