// import Icon from '@sportnet/ui/Icon';
import * as React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import { appSetupSelector, doctypesSelector } from './selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadAppInfo, loadAppSpaces, loadDoctypes } from './actions';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import Api from '../../Api';
import AppProviderContext from '../../containers/AppProvider/appProviderContext';
import Article from '../Article';
import Articles from '../Articles';
import Doctype from '../Doctype';
import Doctypes from '../Doctypes';
import ExternalArticles from '../ExternalArticles';
import Listing from '../Listing';
import Listings from '../Listings';
import Section from '../Section';
import Sections from '../Sections';
import StaticContent from '../StaticContent';
import StaticContents from '../StaticContents';
import TheLayout from '@sportnet/ui/TheLayout';
import Translator from '../Translator';
import Urlmap from '../Urlmap';

const navigationItems = [
  {
    label: __('Články'),
    url: '/articles',
    subitems: [
      {
        label: __('Typy dokumentov'),
        url: '/articles/doctypes',
      },
    ],
  },
  {
    label: __('Správa sekcií'),
    url: '/sections',
  },
  {
    label: __('Listingy'),
    url: '/listings',
  },
  {
    label: __('Presmerovania'),
    url: '/urlmap',
  },
  {
    label: __('Statické texty'),
    url: '/static-content',
  },
  {
    label: __('Prekladač jazykov'),
    url: '/translator',
  },
];

const mapStateToProps = (state: RootState) => ({
  doctypes: doctypesSelector(state),
  appSetup: appSetupSelector(state),
});

const mapDispatchToProps = {
  loadDoctypes: loadDoctypes.action,
  loadApp: thunkToAction(loadAppInfo.action),
  loadAppspaces: thunkToAction(loadAppSpaces.action),
};

interface OwnProps {
  top?: React.ReactNode;
  bottom?: React.ReactNode;
  children?: React.ReactNode;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps;

const C: React.FC<Props> = ({
  children,
  loadApp,
  loadAppspaces,
  loadDoctypes,
  doctypes,
  appSetup,
  history,
  ...restProps
}) => {
  const [doctypesLoaded, setDoctypesLoaded] = React.useState(false);
  const value = React.useContext(AppProviderContext);

  React.useEffect(() => {
    (async () => {
      await loadDoctypes();
      await loadApp();
      await loadAppspaces();
      setDoctypesLoaded(true);
    })();
  }, [loadDoctypes, loadApp, loadAppspaces]);

  // Loadovanie externych sprav
  const [externalArticlesCount, setExternalArticlesCount] = React.useState(0);
  React.useEffect(() => {
    let timer: number;
    const getCountNewExternalArticles = async () => {
      Api.getCountNewExternalArticles(
        appSetup.appId,
        appSetup.appSpace,
        appSetup.contentDivider,
      )
        .then((c) => {
          setExternalArticlesCount(c.externalArticlesCount || 0);
        })
        .finally(() => {
          timer = window.setTimeout(getCountNewExternalArticles, 10000);
        });
    };
    getCountNewExternalArticles();
    return () => {
      // is fired on component unmount
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [appSetup.appId, appSetup.appSpace, appSetup.contentDivider]);

  if (!doctypesLoaded) {
    return null;
  }

  const experimentalStatusBarActions: any[] = [];
  const finalNavigationItems = [
    ...navigationItems,
    {
      label: __(`Externé správy (${externalArticlesCount})`),
      url: '/external-articles',
    },
  ];

  const activeAppSpace = (appSetup.appSpaces || []).find(
    (i) => i.app_space === appSetup.appSpace,
  );

  return (
    <TheLayout
      apps={[appSetup.app as any]}
      appSpaces={[activeAppSpace as any]}
      app={appSetup.app as any}
      appSpace={activeAppSpace as any}
      onClose={value.onCloseApp}
      navigation={finalNavigationItems}
      renderNavigationLink={({ item, linkProps }) => (
        <NavLink exact to={item.url || ''} {...linkProps}>
          {linkProps.children}
        </NavLink>
      )}
      experimentalStatusBarActions={experimentalStatusBarActions}
      {...restProps}
    >
      <Switch>
        <Route exact path="/" component={Articles} />
        <Route path="/articles/doctypes/detail/:id?" component={Doctype} />
        <Route path="/articles/doctypes" component={Doctypes} />
        <Route path="/articles/detail/:id?" component={Article} />
        <Route path="/articles" component={Articles} />
        <Route path="/sections/:id" component={Section} />
        <Route path="/sections" component={Sections} />
        <Route path="/listing/:id?" component={Listing} />
        <Route exact path="/listings">
          <Redirect to="/listings/system" />
        </Route>
        <Route path="/listings/:type?" component={Listings} />
        <Route path="/urlmap" component={Urlmap} />
        <Route path="/static-content/detail/:id?" component={StaticContent} />
        <Route path="/static-content" component={StaticContents} />
        <Route path="/external-articles" component={ExternalArticles} />
        <Route path="/translator" component={Translator} />
      </Switch>
    </TheLayout>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(C as any) as any as React.FC<OwnProps>;
