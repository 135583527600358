import * as React from 'react';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { connect } from 'react-redux';
import Api, { Article } from '../../Api';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
});

interface OwnProps {
  onClose: () => void;
  article?: Article;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const C: React.FC<Props> = ({ appSetup, article, onClose }) => {
  const [shareSubject, setShareSubject] = React.useState<string>('');
  const [shareEmails, setShareEmails] = React.useState<string>('');
  const [isSharing, setIsSharing] = React.useState(false);
  const [shareResult, setShareResult] = React.useState('');
  const [shareError, setShareError] = React.useState('');
  const handleSend = () => {
    const shareOptions = {
      subject: shareSubject || '',
      emails: shareEmails
        .trim()
        .split(',')
        .map((e) => e.trim())
        .filter(Boolean),
      articleUrlPattern: appSetup.params.articleUrlPattern as string,
    };
    if (!shareOptions.emails.length) {
      setShareError(__('Zadajte emaily oddelené čiarkou'));
    }
    setIsSharing(true);
    setShareResult('');
    setShareError('');
    Api.shareArticle(
      appSetup.appId,
      appSetup.appSpace,
      appSetup.contentDivider,
      article?._id!,
      {},
      shareOptions,
    )
      .then((r) => {
        setShareResult(__('Článok bol odoslaný na zadaný email'));
      })
      .catch((e) => {
        setShareError(
          __(
            `Chyba pri odosielaní emailu ${
              e.details ? e.details.name : e.message
            }`,
          ),
        );
      })
      .finally(() => {
        setIsSharing(false);
      });
  };

  return (
    <Modal handleClose={onClose} isOpen={true}>
      <ModalContent>
        <FormField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setShareSubject(e.target.value);
          }}
          value={shareSubject}
          label="Subjekt"
          disabled={isSharing}
          placeholder={__('(nepovinné)')}
        />
        <FormField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setShareEmails(e.target.value);
          }}
          value={shareEmails}
          label="Emaily"
          disabled={isSharing}
          placeholder={__('Zadajte emaily oddelené čiarkou')}
        />
        {shareError && <Message danger>{shareError}</Message>}
        {shareResult && <Message success>{shareResult}</Message>}
      </ModalContent>
      <ModalActions>
        <Button type="button" onClick={onClose} disabled={isSharing}>
          {__('Zavrieť')}
        </Button>
        <Button
          primary
          type="button"
          onClick={handleSend}
          disabled={isSharing}
          loading={isSharing}
        >
          {__('Odoslať')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default connect(mapStateToProps)(C);
