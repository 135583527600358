import { IContentContext } from '@sportnet/content/common/ContentContext';
import { IWidgets } from '../../library/App';
import React from 'react';
import WidgetsEditor from '../../containers/WidgetsEditor/';

interface OwnProps {
  input: {
    value?: IWidgets;
    onChange: (content: IWidgets) => void;
  };
  context?: IContentContext;
}

/**
 * WidgetsEditor ako Redux form field - pouzivame napriklad pre definovanie defaultnych
 * widgetov pre doctype (TODO: bolo by vhodne pouzit ho pre vsetky formulare).
 */
const WidgetsEditorField: React.FC<OwnProps> = ({ input, context }) => {
  return (
    <WidgetsEditor
      value={input.value ?? []}
      onChange={input.onChange}
      context={context}
    />
  );
};

export default WidgetsEditorField;
