import { createGlobalStyle } from './styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: #fff;
  }
  html {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
  }
  * {
    box-sizing: border-box;
    min-width: 0;
  }
  #root {
    height: 100%;
    width: 100%;
  }
`;
