import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { URLMapUrl } from '../../Api';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import Segment from '@sportnet/ui/Segment';
import styled from 'styled-components';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface IOwnProps {
  urlmapUrl: URLMapUrl;
  onSubmit: (urlmapUrl: URLMapUrl) => void;
  onDelete: (urlmapUrl: URLMapUrl) => void;
  currentApiOperation: 'submit' | 'delete' | null;
}

type Props = IOwnProps & RouteComponentProps & WithPopupsProps;

const C: React.FC<Props> = ({
  urlmapUrl,
  confirm,
  onDelete,
  onSubmit,
  currentApiOperation = null,
}) => {
  const [url, setUrl] = React.useState(urlmapUrl.url);
  const [location, setLocation] = React.useState(urlmapUrl.location);
  const [permanent, setPermanent] = React.useState(urlmapUrl.permanent);
  const isNew = React.useMemo(() => !!!urlmapUrl.url, [urlmapUrl]);
  const [showErrors, setShowErrors] = React.useState(false);

  // set initial data
  React.useEffect(() => {
    setShowErrors(false);
    setUrl(urlmapUrl.url);
    setLocation(urlmapUrl.location);
    setPermanent(urlmapUrl.permanent);
  }, [urlmapUrl]);

  const validateAndSubmit = () => {
    setShowErrors(true);
    if (url && location) {
      onSubmit({ url, location, permanent, urltype: 'redirect' });
    }
  };
  const confirmDelete = async () => {
    if (await confirm('Zmazať presmerovanie?')) {
      onDelete(urlmapUrl);
    }
  };
  return (
    <Segment>
      <FormField
        label="URL"
        name="url"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setUrl(e.target.value.replace(/^\//, ''));
        }}
        value={url}
        required
        disabled={!isNew || currentApiOperation}
        error={showErrors && !url ? __('Povinné pole') : undefined}
        info={__('Zadajte relatívnu URL')}
      />
      <FormField
        label="Cieľová URL"
        name="location"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLocation(e.target.value)
        }
        required
        value={location}
        disabled={currentApiOperation}
        error={showErrors && !location ? __('Povinné pole') : undefined}
        info={__(
          'Zadajte absolútnu URL - začína lomítkom (/) alebo protokolom (https://)',
        )}
      />
      <FormField
        label="Permanentné presmerovanie"
        name="location"
        type="checkbox"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPermanent(e.target.checked)
        }
        disabled={currentApiOperation}
        checked={permanent}
      />
      <Buttons>
        {!isNew ? (
          <Button
            onClick={confirmDelete}
            danger
            disabled={!!currentApiOperation}
            loading={currentApiOperation === 'delete'}
          >
            {__('Odstrániť')}
          </Button>
        ) : (
          <div></div>
        )}
        <Button
          onClick={validateAndSubmit}
          primary
          type="submit"
          disabled={!!currentApiOperation}
          loading={currentApiOperation === 'submit'}
        >
          {__('Uložiť')}
        </Button>
      </Buttons>
    </Segment>
  );
};

export default compose(withRouter, withPopups)(C) as any as React.FC<IOwnProps>;
