import { ISectionNode, ISectionTree, SectionId } from '../../library/App';
import { MenuItem } from '../../components/Tree';
import { RootState } from '../../rootReducer';
import { Section } from '../../Api';
import { createSelector } from 'reselect';
import { entitiesSelector } from '../App/selectors';

// Section Tree
const sectionTreeDomain = (state: RootState) => state.sectionTree;

const sectionTreeTreeSelector = createSelector(
  sectionTreeDomain,
  (treeDomain) => treeDomain.tree,
);

export const sectionTreeIsFetchingSelector = createSelector(
  sectionTreeDomain,
  (treeDomain) => treeDomain.isFetching,
);

export const sectionTreeFullSelector = createSelector(
  sectionTreeTreeSelector,
  entitiesSelector,
  (tree, entities): Array<Section> => {
    if (!tree) {
      return [];
    }
    const traverseTree = (sections: ISectionTree): Array<Section> => {
      return sections.map((section) => {
        if (section.sections && section.sections.length > 0) {
          return {
            ...entities.sections[section._id],
            sections: traverseTree(section.sections),
          };
        }
        return entities.sections[section._id];
      });
    };

    return traverseTree(tree);
  },
);

export const sectionTreeSelector = createSelector(
  sectionTreeTreeSelector,
  entitiesSelector,
  (tree, entities): Array<ISectionNode & MenuItem> | undefined => {
    if (!tree) {
      return tree;
    }
    function getEntity(id: SectionId) {
      const e = entities.sections[id];
      return {
        ...e,
        _id: e._id!, // @TODO csm/api _id optional
        title: e.name || '',
      };
    }

    const traverseTree = (
      sections: ISectionTree,
    ): Array<ISectionNode & MenuItem> => {
      return sections.map((section) => {
        if (section.sections && section.sections.length > 0) {
          return {
            ...getEntity(section._id),
            children: traverseTree(section.sections),
          };
        }
        return getEntity(section._id);
      });
    };

    return traverseTree(tree);
  },
);

interface ISectionTreeOption {
  value: string | number;
  label: string;
  level: number;
  parent: SectionId[];
}

interface ISectionTreeNode {
  _id: SectionId;
  name?: string;
  sections?: ReadonlyArray<ISectionTreeNode>;
  parent?: SectionId[] | SectionId | null;
}

const normalizeSectionParent = (
  parent: SectionId[] | SectionId | null | undefined,
): SectionId[] => {
  if (parent) {
    if (Array.isArray(parent)) {
      return parent;
    } else {
      return [parent];
    }
  }
  return [];
};

export const sectionTreeOptionsSelector = createSelector(
  sectionTreeFullSelector,
  (tree): ISectionTreeOption[] => {
    const result: ISectionTreeOption[] = [];

    const traverseTree = (
      sections: ReadonlyArray<ISectionTreeNode>,
      level = 0,
    ) => {
      sections.forEach((section) => {
        result.push({
          value: section._id,
          label: section.name!,
          level,
          parent: normalizeSectionParent(section.parent),
        });
        if (section.sections && section.sections.length > 0) {
          traverseTree(section.sections, level + 1);
        }
      });
    };

    traverseTree(tree as ReadonlyArray<ISectionTreeNode>);
    return result;
  },
);

export const sectionTreeUIStateSelector = createSelector(
  sectionTreeDomain,
  (treeDomain) => treeDomain.uiState,
);

export const sectionTreeScrollTopPositionSelector = (state: RootState) =>
  state.sectionTreeScrollTopPosition;
