import { CommitError, commit, getListParameters } from '@sportnet/redux-list';
import { CustomThunkDispatch } from '../../../../configureStore';
import { RootState } from '../../../../rootReducer';
import { loadAll } from '../../../../containers/Article/actions';
import { prepareArticleFilter } from '@sportnet/content/editor/components/ArticleFilter/utils';
import constants from './constants';

export function load() {
  return (dispatch: CustomThunkDispatch, getState: () => RootState) => {
    const params = getListParameters(constants.LIST_NAME)(getState());

    let filter = [];
    try {
      filter = JSON.parse(params.filter);
    } catch (e) {
      filter = [];
    }

    const requestParams = {
      offset: params.offset,
      limit: constants.LIST_LIMIT,
      ...(prepareArticleFilter(filter) as any),
      excludeIds: params.excludeIds,
    };

    dispatch(
      commit.action({
        listName: constants.LIST_NAME,
        load: async () => {
          try {
            const res = await dispatch(loadAll.action(requestParams));
            return {
              total: res.total || 0, // @TODO CSM/api ma total ako optional
              results: res.articles!.map((a) => a._id), // @TODO CSM/api ma articles ako optional
              nextOffset: res.nextOffset,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
}
