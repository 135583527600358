import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { IWidgets, Writeable } from '../../library/App';
import { Link } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { RouteComponentProps } from 'react-router';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  deleteStaticContent,
  loadDetail,
  saveStaticContent,
} from '../../containers/StaticContent/actions';
import {
  detailErrorSelector,
  detailIsFetchingSelector,
  detailSelector,
} from '../../containers/StaticContent/selectors';
import { initialize, isDirty, isSubmitting, submit } from 'redux-form';
import { saveStaticContentErrorHandler } from '../../containers/StaticContent/errorHandler';
import { translateSportGroundAdditionalProperties } from '@sportnet/content/view/widgets/RaceMatchesList/utilities';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import Form, { FORM_NAME, IFormData } from './form';
import HeaderBar from '@sportnet/ui/HeaderBar';
import NotFound from '@sportnet/ui/NotFound';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SubmitButton from '@sportnet/ui/Button/Submit';
import WidgetsEditor from '../../containers/WidgetsEditor';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

type RouteProps = RouteComponentProps<{ id?: string }>;

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const staticContentCid = props.match.params.id;
  return {
    isFetching: staticContentCid
      ? detailIsFetchingSelector(staticContentCid)(state)
      : false,
    detail: staticContentCid
      ? detailSelector(staticContentCid)(state)
      : undefined,
    error: staticContentCid
      ? detailErrorSelector(staticContentCid)(state)
      : undefined,
    isSubmitting: isSubmitting(FORM_NAME)(state),
    isDirty: isDirty(FORM_NAME)(state),
  };
};

const mapDispatchToProps = {
  initialize,
  submit,
  loadDetail: loadDetail.action,
  saveStaticContent: saveStaticContent.action,
  deleteStaticContent: deleteStaticContent.action,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  WithPopupsProps;

const C: React.FC<Props> = ({
  initialize,
  submit,
  match,
  loadDetail,
  isFetching,
  confirm,
  detail,
  error,
  history,
  saveStaticContent,
  deleteStaticContent,
  alert,
  isSubmitting,
  isDirty,
}) => {
  const [localContent, setLocalContent] = React.useState<IWidgets>([]);
  const [isLocalContentDirty, setIsLocalContentDirty] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);

  React.useEffect(() => {
    if (detail) {
      setLocalContent(detail.content || []);
    }
  }, [detail]);

  const staticContentCid = match.params.id;

  React.useEffect(() => {
    if (staticContentCid) {
      loadDetail({ staticContentCid });
    }
  }, [loadDetail, staticContentCid]);

  React.useEffect(() => {
    if (detail) {
      initialize(FORM_NAME, {
        name: detail.name,
        cid: detail.cid,
      } as IFormData);
    }
  }, [detail, initialize]);

  async function handleSubmit(values: IFormData) {
    try {
      setSubmitError(false);
      await saveStaticContent({
        values: { ...values, content: localContent as Writeable<IWidgets> },
      });
      setIsLocalContentDirty(false);
    } catch (e) {
      setSubmitError(true);
      saveStaticContentErrorHandler(e, alert);
    }
  }

  function renderContent() {
    if (isFetching && typeof detail === 'undefined') {
      return <ContentLoader />;
    }
    if (detail && typeof detail.content === 'undefined') {
      return <ContentLoader />;
    }
    if (error) {
      return (
        <NotFound
          title={__('Detail statického textu nie je možné načítať.')}
          icon="error"
        />
      );
    }
    return (
      <>
        <Segment raised>
          <Form onSubmit={handleSubmit} />
        </Segment>
        <WidgetsEditor
          value={localContent}
          onChange={(nextContent) => {
            setIsLocalContentDirty(true);
            setLocalContent(nextContent);
          }}
          context={{
            currentStaticContentCid: detail?.cid,
            helpers: {
              translateSportGroundAdditionalProperties,
            },
          }}
        />
      </>
    );
  }

  async function handleDelete() {
    if (!detail) {
      return;
    }
    if (await confirm(__('Naozaj chcete odstrániť tento statický text?'))) {
      try {
        await deleteStaticContent({ staticContentCid: detail.cid! }); // @TODO opravit v csm/api
        history.push('/static-content');
      } catch (e) {
        await alert(__('Statický text nie je možné odstrániť'));
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to="/static-content"
            title={__('Späť na zoznam')}
          />
          <HeaderBar.Header>
            {staticContentCid
              ? __('Detail statického textu')
              : __('Nový statický text')}
          </HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          {staticContentCid && (
            <ContextBarItem>
              <Button danger basic disabled={!!error} onClick={handleDelete}>
                {__('Odstrániť')}
              </Button>
            </ContextBarItem>
          )}
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              successText={__('Uložené!')}
              isSubmitting={isSubmitting}
              isError={submitError}
              disabled={(!isDirty && !isLocalContentDirty) || !!error}
              onClick={() => {
                submit(FORM_NAME);
              }}
            >
              {__('Uložiť')}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext
        title={__('Detail statického textu')}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: '/static-content',
          },
        ]}
      />

      <Segment>{renderContent()}</Segment>
    </ScrollLayout>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
