import * as React from 'react';
import { IAppSetup } from '../../library/App';
import { __ } from '../../utilities/';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import config from '../../config';
import styled from '../../theme/styled-components';

const Form = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

interface OwnProps {
  appSetup: IAppSetup;
  onSubmit: (auth: Omit<IAppSetup, 'parentUrl' | 'params'>) => void;
}

type Props = OwnProps;

const C: React.FC<Props> = ({
  appSetup = {
    appId: '',
    appSpace: '',
    token: '',
    contentDivider: config.DEFAULT_CONTENT_DIVIDER,
  },
  onSubmit,
}) => {
  const [appId, setAppId] = React.useState(appSetup.appId);
  const [appSpace, setAppSpace] = React.useState(appSetup.appSpace);
  const [token, setToken] = React.useState(appSetup.token);
  const [contentDivider, setContentDivider] = React.useState(
    appSetup.contentDivider,
  );

  function handleSubmit() {
    if (appId && appSpace && token && contentDivider) {
      onSubmit({
        appId,
        appSpace,
        token,
        contentDivider,
        app: {},
        appSpaces: [],
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormField
        label="App id"
        name="appId"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setAppId(e.target.value)
        }
        value={appId}
        error={appId ? undefined : __('Povinné pole')}
      />
      <FormField
        label="Appspace"
        name="appSpace"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setAppSpace(e.target.value)
        }
        value={appSpace}
        error={appSpace ? undefined : __('Povinné pole')}
      />
      <FormField
        label="Rozdeľovač obsahu"
        name="contentDivider"
        placeholder={config.DEFAULT_CONTENT_DIVIDER}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setContentDivider(e.target.value)
        }
        value={contentDivider}
        error={contentDivider ? undefined : __('Povinné pole')}
      />
      <FormField
        label="Access token"
        name="token"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setToken(e.target.value)
        }
        value={token}
        error={token ? undefined : __('Povinné pole')}
      />
      <Button onClick={handleSubmit} primary type="submit">
        {__('Potvrdiť')}
      </Button>
    </Form>
  );
};

export default C;
