import * as React from 'react';
import { __ } from '../../utilities/';
import { v1 as uuid } from 'uuid';
import Button from '@sportnet/ui/Button';
import Modal, { AlertModal, ModalActions } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';

export type WithPopupsProps = {
  alert: (text: string) => Promise<void>;
  confirm: (text: string, onConfirm?: () => void) => Promise<boolean>;
};

enum PopupTypes {
  ALERT = 'ALERT',
  CONFIRM = 'CONFIRM',
}

const makeHoc = <WrappedProps extends WithPopupsProps>(
  Component: React.ComponentType<WrappedProps & WithPopupsProps>,
): React.ComponentType<WrappedProps & WithPopupsProps & any> => {
  interface Popup {
    type: PopupTypes;
  }

  interface AlertPopup extends Popup {
    type: PopupTypes.ALERT;
    payload: {
      text: string;
    };
  }

  interface ConfirmPopup extends Popup {
    type: PopupTypes.CONFIRM;
    payload: {
      text: string;
      onConfirm: () => void;
    };
  }

  interface State {
    popups: {
      [key: string]: Popup;
    };
    isSubmitting: boolean;
  }

  return class MakeHoc extends React.Component<WrappedProps, State> {
    promises: {
      [key: string]: {
        resolve: (value?: {} | PromiseLike<{}>) => void;
      };
    } = {};

    constructor(props: WrappedProps) {
      super(props);
      this.state = {
        popups: {},
        isSubmitting: false,
      };
    }

    renderPopup = (popupUid: string) => {
      const popup = this.state.popups[popupUid];
      const { type } = popup;
      if (type === PopupTypes.ALERT) {
        const alertPopup = popup as AlertPopup;
        return (
          <AlertModal
            key={popupUid}
            isOpen
            handleClose={() => {
              this.promises[popupUid].resolve();
              this.close(popupUid);
            }}
          >
            {alertPopup.payload.text}
          </AlertModal>
        );
      } else if (type === PopupTypes.CONFIRM) {
        const confirmPopup = popup as ConfirmPopup;
        return (
          <Modal
            centered
            size="xs"
            key={popupUid}
            isOpen
            handleClose={() => {
              this.promises[popupUid].resolve(false);
              this.close(popupUid);
            }}
          >
            <Segment>{confirmPopup.payload.text}</Segment>
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button
                  type="button"
                  onClick={() => {
                    this.promises[popupUid].resolve(false);
                    this.close(popupUid);
                  }}
                >
                  {__('Zavrieť')}
                </Button>
                <Button
                  loading={this.state.isSubmitting}
                  primary
                  type="button"
                  onClick={async () => {
                    if (confirmPopup.payload.onConfirm) {
                      this.setState({
                        isSubmitting: true,
                      });
                      try {
                        await confirmPopup.payload.onConfirm();
                        this.promises[popupUid].resolve(true);
                      } catch (e) {
                        this.promises[popupUid].resolve(false);
                        throw e;
                      }
                      this.close(popupUid);
                      this.setState({
                        isSubmitting: false,
                      });
                    } else {
                      this.promises[popupUid].resolve(true);
                      this.close(popupUid);
                    }
                  }}
                >
                  {__('Potvrdiť')}
                </Button>
              </div>
            </ModalActions>
          </Modal>
        );
      }
      return null;
    };

    close(uid: string) {
      delete this.promises[uid];
      const popups = { ...this.state.popups };
      delete popups[uid];
      this.setState({
        popups,
      });
    }

    alert = (text: string) => {
      return new Promise((resolve) => {
        const uid = uuid();
        this.promises[uid] = { resolve };
        const popups = { ...this.state.popups };
        popups[uid] = {
          type: PopupTypes.ALERT,
          payload: {
            text,
          },
        } as AlertPopup;

        this.setState({
          popups,
        });
      });
    };

    confirm = (text: string, onConfirm: () => void) => {
      return new Promise((resolve) => {
        const uid = uuid();
        this.promises[uid] = { resolve };
        const popups = { ...this.state.popups };
        popups[uid] = {
          type: PopupTypes.CONFIRM,
          payload: {
            text,
            onConfirm,
          },
        } as ConfirmPopup;

        this.setState({
          popups,
        });
      });
    };

    render() {
      return (
        <>
          <Component
            {...this.props}
            alert={this.alert}
            confirm={this.confirm}
          />
          <>{Object.keys(this.state.popups).map(this.renderPopup)}</>
        </>
      );
    }
  };
};

export default makeHoc;
