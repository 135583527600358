import { ListingId } from '../../library/App';
import { RootState } from '../../rootReducer';
import { createSelector } from 'reselect';
import { entitiesSelector } from '../../pages/App/selectors';

const detailDomain = (state: RootState) => state.listingDetail;

export const detailIsFetchingSelector = (listingId: ListingId) =>
  createSelector(detailDomain, (domain) =>
    listingId in domain ? domain[listingId].isFetching : false,
  );

export const detailSelector = (listingId: ListingId) =>
  createSelector(entitiesSelector, (entities) =>
    listingId in entities.listings ? entities.listings[listingId] : undefined,
  );

export const detailErrorSelector = (listingId: ListingId) =>
  createSelector(detailDomain, (domain) =>
    listingId in domain ? domain[listingId].error : '',
  );
