import {
  IQueryResult,
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';
import { ListingType } from '../../Api';

const LIST_LIMIT = 50;
const DEFAULT_TYPE: ListingType = 'system';

export const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
    limit: NumberParam(LIST_LIMIT),
    type: StringParam(DEFAULT_TYPE),
    q: StringParam(''),
  },
};

const constants = {
  LIST_NAME: 'LISTINGS_LIST',
  LIST_LIMIT,
  QUERY_HOC_CONFIG,
};

export default constants;

export type IQuery = IQueryResult<typeof QUERY_HOC_CONFIG>['query'];
