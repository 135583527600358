import { ExtraArgumentType } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { SectionId } from '../../library/App';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { replaceEntities } from '../App/actions';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.SECTIONS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadDetail = createAsync<{ sectionId: SectionId }, void>(
  'LOAD_DETAIL',
  async (params, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    const response = await Api.getSection(
      appId,
      appSpace,
      contentDivider,
      params.sectionId,
    );
    const { content = [], ...section } = response;
    dispatch(
      replaceEntities({
        sections: {
          [response._id!]: section, // @TODO CSM/api optional _id
        },
        sectionContents: {
          [response._id!]: content, // @TODO CSM/api optional _id
        },
      }),
    );
  },
);
