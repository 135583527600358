import { NumberParam } from '@sportnet/query-hoc/useQuery';

const LIST_LIMIT = 100;

export const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
    limit: NumberParam(LIST_LIMIT),
  },
};

const constants = {
  LIST_NAME: 'URLMAP_LIST',
  LIST_LIMIT,
  QUERY_HOC_CONFIG,
};

export default constants;
