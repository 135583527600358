import { Articles_Bulk_Update_Response } from '../../Api';

export const getSelectedCountArticlesLabel = (count: number) => {
  switch (count) {
    case 1: {
      return `1 článok`;
    }
    case 2:
    case 3:
    case 4: {
      return `${count} články`;
    }
    default:
      return `${count} článkov`;
  }
};

export const getResultMessage = (results: Articles_Bulk_Update_Response) => {
  const { matchedCount: count } = results;
  switch (count) {
    case 1: {
      return `Bol upravený 1 článok.`;
    }
    case 2:
    case 3:
    case 4: {
      return `Boli upravené ${count} články.`;
    }
    default:
      return `Bolo upravených ${count} článkov.`;
  }
};
