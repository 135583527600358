import * as React from 'react';
import { __ } from '../../utilities/';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import ExternalArticleList from '../../containers/ExternalArticleList';

interface IOwnProps {}

type Props = IOwnProps;

const C: React.FC<Props> = () => {
  return (
    <>
      <AppContext title={__('Všetky externé články')} />
      <ExternalArticleList />
    </>
  );
};

export default C;
