import * as actions from './actions';
import { ISectionNode } from '../../library/App';
import { MenuItem } from '../../components/Tree';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

type SectionTreeState = Readonly<{
  isFetching: boolean;
  tree?: ReadonlyArray<ISectionNode>;
  uiState: Readonly<MenuItem[]>;
}>;

type SectionTreeScrollTopPosition = Readonly<number>;

const SECTiON_TREE_SCROLL_TOP_POSITION_INITIAL_STATE: SectionTreeScrollTopPosition = 0;

export const sectionTreeScrollTopPositionReducer = reducerWithInitialState(
  SECTiON_TREE_SCROLL_TOP_POSITION_INITIAL_STATE,
).case(
  actions.setSectionTreeScrollTopPosition,
  (_, scrollTopPosition): SectionTreeScrollTopPosition => {
    return scrollTopPosition;
  },
);

const SECTION_TREE_INITIAL_STATE: SectionTreeState = {
  isFetching: false,
  tree: undefined,
  uiState: [],
};

export const sectionTreeReducer = reducerWithInitialState(
  SECTION_TREE_INITIAL_STATE,
)
  .case(
    actions.loadTree.async.started,
    (state): SectionTreeState => ({
      ...state,
      isFetching: true,
    }),
  )
  .case(
    actions.loadTree.async.done,
    (state, { result }): SectionTreeState => ({
      ...state,
      tree: result,
      isFetching: false,
    }),
  )
  .case(
    actions.loadTree.async.failed,
    (state): SectionTreeState => ({
      ...state,
      isFetching: false,
    }),
  )
  .case(actions.setSectionTreeUIState, (state, tree) => ({
    ...state,
    uiState: [...tree],
  }));
