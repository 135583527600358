import * as React from 'react';
import {
  Field,
  InjectedFormProps,
  change,
  getFormValues,
  isValid,
  reduxForm,
} from 'redux-form';
import { IPublicationGroupsValue } from '../../components/PublicationGroupsField';
import { RootState } from '../../rootReducer';
import { __, formatUrl, required } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormFieldStandalone from '@sportnet/ui/FormField';
import Link from '@sportnet/ui/Link';
import Message from '@sportnet/ui/Message';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import slugify from 'slugify';
import styled from '../../theme/styled-components';

const UrlLink = styled(Link)`
  word-break: break-word;
`;

export const FORM_NAME = 'section-sidebar-form';
export interface IFormData {
  name: string;
  uniqid?: string;
  url?: string;
  redirecturl?: string | null;
  show?: boolean;
  publication_groups: IPublicationGroupsValue;
}

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
  formValues: getFormValues(FORM_NAME)(state) as IFormData | undefined,
  formIsValid: isValid(FORM_NAME)(state),
});

const mapDispatchToProps = {
  change,
};

interface OwnProps {
  onDelete?: () => void;
  isNew: boolean;
  sectionId?: number;
}

type Props = OwnProps &
  InjectedFormProps<IFormData> &
  ReturnType<typeof mapStateToProps>;

const C: React.FC<Props & typeof mapDispatchToProps> = ({
  submitting,
  handleSubmit,
  onDelete,
  appSetup,
  formValues,
  isNew,
  sectionId,
  change,
  formIsValid,
}) => {
  const getSectionUrl = (uri: string) => {
    const { sectionUrlPattern } = appSetup.params;
    if (sectionUrlPattern && uri) {
      const url: string = sectionUrlPattern.replace('{{uri}}', uri);
      return (
        <Message primary title={__('URL sekcie')}>
          <UrlLink href={url} primary target="_blank" rel="noopener noreferrer">
            {url}
          </UrlLink>
        </Message>
      );
    }
    return (
      <Message danger title={__('Chyba URL')}>
        {__('Bez zadanej URL bude sekcia nedostupná.')}
      </Message>
    );
  };
  return (
    <form onSubmit={handleSubmit}>
      <Segment
        header={
          <SegmentHeader size="xs">{__('Základné informácie')}</SegmentHeader>
        }
      >
        {!isNew && (
          <FormFieldStandalone
            label={__('ID sekcie')}
            value={sectionId}
            disabled
          />
        )}
        <Field
          component={FormField}
          type="text"
          name="name"
          label={__('Názov')}
          required
          validate={[required]}
          onChange={(e: any) => {
            isNew &&
              change(
                FORM_NAME,
                'url',
                slugify(e.target.value || '', { remove: /[+]/ }).toLowerCase(),
              );
          }}
        />
        <Field
          component={FormField}
          type="text"
          name="uniqid"
          label={__('Identifikátor')}
          info={__('Zadávajte len v prípade, že nepoužívate štandardné pages.')}
        />
        <Field
          component={FormField}
          type="text"
          name="url"
          label={__('URL')}
          placeholder={__('Príklad: "url"')}
          info={__(
            'Relatívna URL, na ktorej bude sekcia dostupná (príklad: "url-sekcie").',
          )}
          required
          validate={[required]}
          normalize={formatUrl}
        />
        {getSectionUrl(formValues?.url || '')}
        <Field
          component={FormField}
          type="text"
          name="redirecturl"
          label={__('URL presmerovanie')}
          info={__(
            'Absolútna URL presmerovania (príklad: https://www.example.com/url-presmerovania)',
          )}
          placeholder="https://"
        />
        <Field
          component={FormField}
          type="checkbox"
          name="show"
          label={__('Zobrazovať')}
        />
      </Segment>
      <Segment>
        <Row>
          <Col xs={6}>
            {onDelete && (
              <Button danger block basic type="button" onClick={onDelete}>
                {__('Odstrániť')}
              </Button>
            )}
          </Col>
          <Col xs={6}>
            <Button
              primary
              block
              type="submit"
              loading={submitting}
              disabled={!formIsValid}
            >
              {__('Uložiť')}
            </Button>
          </Col>
        </Row>
      </Segment>
    </form>
  );
};

export default compose(
  reduxForm<IFormData, OwnProps>({
    form: FORM_NAME,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(C);
