import { ExtraArgumentType } from '../../configureStore';
import { IListingCreate, ListingId } from '../../library/App';
import { Listing_Patch } from '../../Api';
import { RootState } from '../../rootReducer';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { updateEntities } from '../../pages/App/actions';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.LISTINGS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadDetail = createAsync<{ listingId: ListingId }, void>(
  'LOAD_DETAIL',
  async (params, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());

    const response = await Api.getListingDetail(
      appId,
      appSpace,
      contentDivider,
      params.listingId,
    );
    dispatch(
      updateEntities({
        listings: { [response.listingId]: response }, // @TODO CSM/api optional cid
      }),
    );
  },
);

export const createListing = createAsync<{ values: IListingCreate }, void>(
  'CREATE',
  async ({ values }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());

    const response = await Api.createListing(
      appId,
      appSpace,
      contentDivider,
      {},
      values,
    );
    dispatch(
      updateEntities({
        listings: {
          [response.listingId!]: response, // @TODO "!" opravit v csm.api - _id nemoze byt optional
        },
      }),
    );
    return response;
  },
);

export const editListing = createAsync<
  { values: Listing_Patch; listingId: ListingId },
  void
>('EDIT', async ({ values, listingId }, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());
  const response = await Api.editListing(
    appId,
    appSpace,
    contentDivider,
    listingId,
    {},
    values,
  );

  dispatch(
    updateEntities({
      listings: {
        [response._id]: response,
      },
    }),
  );
  return response;
});

export const deleteListing = createAsync<{ listingId: ListingId }, void>(
  'DELETE',
  async ({ listingId }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    await Api.deleteListing(appId, appSpace, contentDivider, listingId, {});
  },
);
