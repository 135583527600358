import * as React from 'react';
import { RootState } from '../../rootReducer';
import { SectionId } from '../../library/App';
import { WrappedFieldProps } from 'redux-form';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { entitiesSelector } from '../../pages/App/selectors';
import { loadTree } from '../../pages/Sections/actions';
import { rem } from 'polished';
import {
  sectionTreeIsFetchingSelector,
  sectionTreeSelector,
} from '../../pages/Sections/selectors';
import Button from '@sportnet/ui/Button';
import FormGroup from '@sportnet/ui/FormGroup';
import Header from '@sportnet/ui/Header';
import Label from '@sportnet/ui/Label/Label';
import Link from '@sportnet/ui/Link';
import Modal, { ModalActions } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import Tree, { MenuItem } from '../../components/Tree';
import Values from './Values';
import styled from '../../theme/styled-components';
import withPopups from '../../components/WithPopups';

const StyledModal = styled(Modal)`
  bottom: ${({ theme }) => rem(theme.grid.gutterWidth)};
` as any;

const TreeWrapper = styled('div')`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const AddSectionWrapper = styled('div')`
  text-align: center;
  margin-top: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
`;

const mapStateToProps = (state: RootState) => ({
  sectionTree: sectionTreeSelector(state),
  sectionEntities: entitiesSelector(state).sections,
  sectionTreeIsFetching: sectionTreeIsFetchingSelector(state),
});

const mapDispatchToProps = {
  loadTree: loadTree.action,
};

interface OwnProps extends WrappedFieldProps {
  label?: string;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

/**
 * `null` - checkbox unchecked, content is public
 *
 * **empty** `array` - checkbox checked, content is only available to logged in users
 *
 * **non empty** `array` - checkbox checked, content is only available for users assigned to selected groups
 */
export type IValue = SectionId[];

const C: React.FC<Props> = ({
  input,
  input: { onChange },
  label,
  loadTree,
  sectionTree,
  sectionEntities,
  sectionTreeIsFetching,
}) => {
  const [modalOpened, setModalOpened] = React.useState(false);
  const [internalTree, setInternalTree] = React.useState<MenuItem[]>([]);
  const value = (input.value || []) as IValue;
  const sortedValue = value.slice().sort();
  const defaultSectionId = value[0]; // it's always first one

  React.useEffect(() => {
    loadTree();
  }, [loadTree]);

  React.useEffect(() => {
    if (sectionTree) {
      setInternalTree(sectionTree);
    }
  }, [sectionTree]);

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Values
        value={sortedValue}
        onChange={onChange}
        sectionEntities={sectionEntities}
        selected={defaultSectionId}
        onChangeSelection={(nextSectionId) => {
          const idx = value.indexOf(nextSectionId);
          onChange([
            nextSectionId,
            ...value.slice(0, idx),
            ...value.slice(idx + 1),
          ]);
        }}
      />

      <AddSectionWrapper>
        <Link primary onClick={openModal}>
          {__('Zaradiť do sekcie ...')}
        </Link>
      </AddSectionWrapper>

      <StyledModal isOpen={modalOpened} handleClose={closeModal} size="full">
        <Segment noBottomGutter>
          <Header>{__('Vyberte novú sekciu')}</Header>
        </Segment>

        <TreeWrapper>
          {!!sectionTree && !sectionTreeIsFetching && (
            <Tree
              sortable={false}
              treeData={internalTree}
              onChange={setInternalTree}
              onClickNode={(node) => {
                onChange([...value, node._id]);
                closeModal();
              }}
              isNodeDisabled={(node) => value.includes(node._id)}
            />
          )}
        </TreeWrapper>

        <ModalActions>
          <div />
          <Button onClick={closeModal}>{__('Zavrieť')}</Button>
        </ModalActions>
      </StyledModal>
    </FormGroup>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
