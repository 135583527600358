import { CommitError, commit, getListParameters } from '@sportnet/redux-list';
import { CustomThunkDispatch } from '../../configureStore';
import { ListingType } from '../../Api';
import { RootState } from '../../rootReducer';
import { loadListings } from '../../pages/App/actions';
import constants from './constants';

export function load() {
  return (dispatch: CustomThunkDispatch, getState: () => RootState) => {
    dispatch(
      commit.action({
        listName: constants.LIST_NAME,
        load: async () => {
          const params = getListParameters(constants.LIST_NAME)(getState()) as {
            limit?: number;
            offset?: number;
            type: ListingType;
          };
          try {
            const res = await dispatch(loadListings.action(params));
            return {
              total: res.total,
              results: res.listings.map((a) => a.listingId),
              nextOffset: res.nextOffset,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
}
