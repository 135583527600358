import { __ } from '../../utilities/';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import React from 'react';
import Translator from '../../containers/Translator';

const TranslatorPage: React.FC = () => {
  return (
    <>
      <AppContext title={__('Prekladač jazykov')} />
      <Translator />
    </>
  );
};

export default TranslatorPage;
