import { rem } from 'polished';
import React from 'react';
import styled, { css } from '../../theme/styled-components';

const Wrapper = styled.div<{ shouldWrap?: boolean }>`
  display: flex;
  ${({ shouldWrap }) =>
    shouldWrap &&
    css`
      flex-wrap: wrap;
    `}
  margin: ${({ theme }) => rem(-theme.grid.gutterWidth / 2)};
  & > * {
    margin: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  }
`;

interface OwnProps {
  className?: string;
  shouldWrap?: boolean;
  children?: React.ReactNode;
}

type Props = OwnProps;

const HorizontalLayout: React.FC<Props> = ({
  className,
  children,
  shouldWrap,
}) => (
  <Wrapper className={className} shouldWrap={shouldWrap}>
    {children}
  </Wrapper>
);

export default HorizontalLayout;
