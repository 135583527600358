import { MenuItem } from '.';
import { map, walk } from '@sportnet/react-sortable-tree';
import { removeAccents } from '@sportnet/utilities';

export function expandNodesInNewTreeWhichWereExpandedInOldTree(
  oldTree: MenuItem[],
  newTree: MenuItem[],
) {
  const expanded: { [key: string]: boolean } = {};
  // walk through old tree, gather expanded nodes
  walk({
    treeData: oldTree,
    callback: (n: any) => {
      if (n.node.expanded) {
        expanded[n.node._id] = true;
      }
    },
    ignoreCollapsed: false,
    getNodeKey: (data) => data.node._id,
  });

  // expand nodes in new tree
  const expandedTree = map({
    treeData: newTree,
    callback: (n: any) => {
      const baseObject = {
        ...n.node,
        children: n.node.children || [],
      };
      if (n.node._id in expanded) {
        return { ...baseObject, expanded: true };
      }
      return { ...baseObject, expanded: false };
    },
    ignoreCollapsed: false,
    getNodeKey: (node) => node.node._id,
  });

  return expandedTree as MenuItem[];
}

export const accentInsensitiveSearch = ({
  node,
  searchQuery,
}: {
  node: MenuItem;
  searchQuery: string;
}) => {
  if (searchQuery) {
    return removeAccents(node.title).includes(searchQuery);
  }
  return false;
};
