import * as React from 'react';
import { IPicture } from '@sportnet/ui/MediaManagerImage';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { deleteArticle } from '../Article/actions';
import { initialize } from 'redux-form';
import Button from '@sportnet/ui/Button';
import Checkbox from '@sportnet/ui/Checkbox';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import MediamanagerApi from '../../MediamanagerApi';
import Modal, { ModalActions } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import styled from '../../theme/styled-components';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const mapDispatchToProps = {
  initialize,
  deleteArticle: deleteArticle.action,
};

const PreviewImg = styled('img')`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
`;

interface IFetchedMetadata {
  title?: string;
  description?: string;
  url?: string;
  picture?: IPicture;
}

interface IState {
  url: string;
  urlIsFetching: boolean;
  urlFetchingError: boolean;
  fetchedMetadata: {
    title?: string;
    description?: string;
    images?: string[];
  };
  submitMetadata: {
    title: boolean;
    description: boolean;
    image: boolean;
  };
  mediamanagerIsSubmitting: boolean;
  mediamanagerSubmitFailed: boolean;
}

const INITIAL_STATE: IState = {
  url: '',
  urlIsFetching: false,
  urlFetchingError: false,
  fetchedMetadata: {},
  submitMetadata: {
    title: true,
    description: true,
    image: true,
  },
  mediamanagerIsSubmitting: false,
  mediamanagerSubmitFailed: false,
};

type IAction =
  | {
      type: 'RESET';
    }
  | {
      type: 'URL_CHANGE';
      url: string;
    }
  | {
      type: 'FETCH_URL_STARTED';
    }
  | {
      type: 'FETCH_URL_DONE';
      metadata: IState['fetchedMetadata'];
    }
  | {
      type: 'FETCH_URL_FAILED';
    }
  | {
      type: 'METADATA_TOGGLE';
      field: keyof IState['submitMetadata'];
    }
  | {
      type: 'MEDIAMANAGER_UPLOAD_STARTED';
      pictureUrl?: string;
    }
  | {
      type: 'MEDIAMANAGER_UPLOAD_DONE';
    }
  | {
      type: 'MEDIAMANAGER_UPLOAD_FAILED';
    };

function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case 'RESET':
      return INITIAL_STATE;
    case 'URL_CHANGE':
      return { ...state, url: action.url };
    case 'FETCH_URL_STARTED':
      return {
        ...state,
        urlIsFetching: true,
        urlFetchingError: false,
      };
    case 'FETCH_URL_DONE':
      return {
        ...state,
        urlIsFetching: false,
        urlFetchingError: false,
        fetchedMetadata: action.metadata,
      };
    case 'FETCH_URL_FAILED':
      return { ...state, urlIsFetching: false, urlFetchingError: true };
    case 'METADATA_TOGGLE':
      return {
        ...state,
        submitMetadata: {
          ...state.submitMetadata,
          [action.field]: !state.submitMetadata[action.field],
        },
      };
    case 'MEDIAMANAGER_UPLOAD_STARTED':
      return {
        ...state,
        mediamanagerIsSubmitting: true,
        mediamanagerSubmitFailed: false,
      };
    case 'MEDIAMANAGER_UPLOAD_DONE':
      return {
        ...state,
        mediamanagerIsSubmitting: false,
        mediamanagerSubmitFailed: false,
      };
    case 'MEDIAMANAGER_UPLOAD_FAILED':
      return {
        ...state,
        mediamanagerIsSubmitting: false,
        mediamanagerSubmitFailed: true,
      };
  }
  return state;
}

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
});

interface OwnProps {
  opened: boolean;
  onSubmit: (data: IFetchedMetadata) => void;
  onClose: () => void;
}

type Props = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  WithPopupsProps;

const C: React.FC<Props> = ({ opened, onSubmit, onClose, alert, appSetup }) => {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  React.useEffect(() => {
    if (opened) {
      dispatch({ type: 'RESET' });
    }
  }, [opened]);

  async function upload(url: string) {
    const { appId, appSpace } = appSetup;
    try {
      dispatch({ type: 'MEDIAMANAGER_UPLOAD_STARTED' });
      const file = await MediamanagerApi.createFileFromUrl(
        appId,
        appSpace,
        {},
        {
          url,
        },
      );
      dispatch({ type: 'MEDIAMANAGER_UPLOAD_DONE' });
      return file.filepath;
    } catch (e) {
      dispatch({ type: 'MEDIAMANAGER_UPLOAD_FAILED' });
    }
  }

  async function submit() {
    const submitData: IFetchedMetadata = {
      url: state.url,
    };

    if (
      state.submitMetadata.image &&
      state.fetchedMetadata.images &&
      state.fetchedMetadata.images.length > 0
    ) {
      submitData['picture'] = {
        uri: await upload(state.fetchedMetadata.images[0]),
      };
    }

    if (state.submitMetadata.title && state.fetchedMetadata.title) {
      submitData['title'] = state.fetchedMetadata.title;
    }

    if (state.submitMetadata.description && state.fetchedMetadata.description) {
      submitData['description'] = state.fetchedMetadata.description;
    }

    onSubmit(submitData);
  }

  async function fetchUrl(url: string) {
    dispatch({
      type: 'FETCH_URL_STARTED',
    });
    const response = await fetch(
      `https://microservices.bart.sk/sharer/request?url=${url}`,
    );
    if (response.ok) {
      const json = await response.json();
      if (json.title || json.description || json.images.length > 0) {
        const metadata: IState['fetchedMetadata'] = {};
        if (json.title) {
          metadata.title = json.title;
        }
        if (json.description) {
          metadata.description = json.description;
        }
        if (json.images.length > 0) {
          metadata.images = json.images.map((img: any) => img.url);
        }
        dispatch({
          type: 'FETCH_URL_DONE',
          metadata,
        });
      } else {
        await alert(
          __('Zo zadanej url sa nám nepodarilo vytiahnúť žiadne informácie'),
        );
        dispatch({
          type: 'FETCH_URL_FAILED',
        });
      }
    } else {
      dispatch({
        type: 'FETCH_URL_FAILED',
      });
    }
  }

  const metadataAvailable = Object.keys(state.fetchedMetadata).length > 0;
  const isThereSomethingToSubmit =
    state.submitMetadata.description ||
    state.submitMetadata.image ||
    state.submitMetadata.title;

  return (
    <Modal size="xs" isOpen={opened} handleClose={onClose}>
      <Segment
        header={
          <SegmentHeader toggleCollapse={() => {}}>
            {__('1. Vložte URL adresu')}
          </SegmentHeader>
        }
      >
        <Input
          error={state.urlFetchingError}
          placeholder="https://"
          value={state.url}
          onChange={(e: React.KeyboardEvent<HTMLInputElement>) => {
            dispatch({
              type: 'URL_CHANGE',
              url: e.currentTarget.value,
            });
          }}
        >
          <input />
          <Input.Button
            primary
            disabled={!state.url.trim()}
            icon="check"
            loading={state.urlIsFetching}
            onClick={() => {
              fetchUrl(state.url);
            }}
          />
        </Input>
      </Segment>
      <Segment
        header={
          <SegmentHeader disabled={!metadataAvailable}>
            {__('2. Vyberte si dáta')}
          </SegmentHeader>
        }
      >
        {state.fetchedMetadata.title && (
          <>
            <FormGroup>
              <Label>
                <Checkbox
                  checked={state.submitMetadata.title}
                  onChange={(e) =>
                    dispatch({ type: 'METADATA_TOGGLE', field: 'title' })
                  }
                />
                {__('Prevziať názov')}
              </Label>
            </FormGroup>
            {state.submitMetadata.title && (
              <Segment secondary>{state.fetchedMetadata.title}</Segment>
            )}
          </>
        )}
        {state.fetchedMetadata.description && (
          <>
            <FormGroup>
              <Label>
                <Checkbox
                  checked={state.submitMetadata.description}
                  onChange={(e) =>
                    dispatch({
                      type: 'METADATA_TOGGLE',
                      field: 'description',
                    })
                  }
                />
                {__('Prevziať perex')}
              </Label>
            </FormGroup>

            {state.submitMetadata.description && (
              <Segment secondary>{state.fetchedMetadata.description}</Segment>
            )}
          </>
        )}
        {state.fetchedMetadata.images &&
          state.fetchedMetadata.images.length > 0 && (
            <>
              <FormGroup>
                <Label>
                  <Checkbox
                    checked={state.submitMetadata.image}
                    onChange={(e) =>
                      dispatch({ type: 'METADATA_TOGGLE', field: 'image' })
                    }
                  />
                  {__('Prevziať titulný obrázok')}
                </Label>
              </FormGroup>
              {state.submitMetadata.image && (
                <Segment secondary>
                  <PreviewImg src={state.fetchedMetadata.images[0]} />
                </Segment>
              )}
            </>
          )}
      </Segment>
      <ModalActions>
        <div>&nbsp;</div>
        <div>
          <Button type="button" onClick={onClose}>
            {__('Zavrieť')}
          </Button>
          <Button
            primary
            disabled={!isThereSomethingToSubmit}
            loading={state.mediamanagerIsSubmitting}
            type="button"
            onClick={async () => {
              await submit();
            }}
          >
            {__('Potvrdiť')}
          </Button>
        </div>
      </ModalActions>
    </Modal>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C) as any as React.FC<OwnProps>;
