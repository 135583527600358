import { ExtraArgumentType } from '../../configureStore';
import { INormalizedSectionTree, ISectionEntity } from '../../library/App';
import { MenuItem, TreeNodeMoveAction } from '../../components/Tree';
import { RootState } from '../../rootReducer';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { isEqual } from '@sportnet/utilities';
import { replaceEntities } from '../App/actions';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.SECTIONS_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setSectionTreeUIState = create<MenuItem[]>(
  'SET_SECTION_TREE_UI_STATE',
);

export const setSectionTreeScrollTopPosition = create<number>(
  'SET_SECTION_TREE_SCROLL_TOP_POSITION',
);

export const loadTree = createAsync<void, INormalizedSectionTree>(
  'LOAD_TREE',
  async (params, dispatch, getState, { Api }): Promise<any> => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    const response = await Api.getAdminSectionsTree(
      appId,
      appSpace,
      contentDivider,
    );

    const entities: { [key: string]: ISectionEntity } = {};

    const normalizeTree = (
      sections: (typeof response)['tree'],
    ): INormalizedSectionTree => {
      return sections!.map((section: any) => {
        entities[section._id] = section;

        if (section.sections && section.sections.length > 0) {
          const normalizedSections = normalizeTree(section.sections);
          entities[section._id].sections = normalizedSections.map(
            (s: any) => s._id,
          );
          return {
            _id: section._id,
            sections: normalizedSections,
          };
        }

        return {
          _id: section._id,
        };
      });
    };
    const result = normalizeTree(response.tree); // @TODO csm/api optional

    dispatch(
      replaceEntities({
        sections: entities,
      }),
    );

    return result;
  },
);

export const moveSection = createAsync<TreeNodeMoveAction, boolean>(
  'MOVE_SECTION',
  async (move, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());

    const promises = [];

    if (
      isEqual(move.prevPath, move.nextPath) &&
      move.prevTreeIndex === move.nextTreeIndex
    ) {
      // no move performed - noop`
      return false;
    }

    // subtree of parent of moved item
    // in other words: moved item and its siblings
    const subtree =
      move.nextParentNode !== undefined
        ? move.nextParentNode.children || []
        : move.treeData;

    promises.push(
      Api.changeOrderOfSections(
        appId,
        appSpace,
        contentDivider,
        {},
        { order: subtree.map((item, idx) => [item._id, idx]) },
      ),
    );

    // node was moved
    promises.push(
      Api.editSection(
        appId,
        appSpace,
        contentDivider,
        move.node._id,
        {},
        { newParent: move.nextParentNode ? move.nextParentNode._id : null },
      ),
    );

    await Promise.all(promises as any);
    return true;
  },
);
