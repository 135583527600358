import * as React from 'react';
import { IQuery } from './constants';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import {
  appSetupSelector,
  doctypesSelector,
  usersWithRolesSelector,
} from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadTree } from '../../pages/Sections/actions';
import { loadUsersWithRoles } from '../../pages/App/actions';
import { sectionTreeOptionsSelector } from '../../pages/Sections/selectors';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import Filter from '@sportnet/ui/Filter';
import withPopups from '../../components/WithPopups';

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
  usersWithRoles: usersWithRolesSelector(state),
  sectionTreeOptions: sectionTreeOptionsSelector(state),
  doctypes: doctypesSelector(state),
});

const mapDispatchToProps = {
  loadUsersWithRoles: loadUsersWithRoles.action,
  loadTree: thunkToAction(loadTree.action),
};

interface OwnProps {
  query: IQuery;
  setQuery: (value: Partial<IQuery>) => void;
}

type Props = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const C: React.FC<Props> = ({ query, setQuery }) => {
  const [debouncedQ, setDebouncedQ] = React.useState(query.q);
  const debounceQtimer = React.useRef<number>();

  const filterValue = {
    q: debouncedQ,
  };

  return (
    <>
      <Filter
        value={filterValue}
        onChange={(nextFilterValue) => {
          const newQuery = {
            offset: 0,

            q: nextFilterValue.q ? (nextFilterValue.q as string) : '',
          };
          // ak sa zmeni q => nastavime state a pockame na debounce timer
          if (
            nextFilterValue.q === undefined ||
            filterValue.q !== nextFilterValue.q
          ) {
            setDebouncedQ((nextFilterValue.q as string) || '');
            if (debounceQtimer.current) {
              window.clearTimeout(debounceQtimer.current);
            }
            debounceQtimer.current = window.setTimeout(() => {
              setQuery(newQuery);
            }, 300);
          } else {
            // zavolame funkciu na zmenu ihned
            setQuery(newQuery);
          }
        }}
        filters={[
          {
            type: 'query' as const,
            name: 'q',
            placeholder: __('Vyhľadávanie v listingoch'),
          },
        ]}
      />
    </>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
