import * as React from 'react';
import { Doctype } from '../../Api';
import { FORM_NAME, IFormData } from '../../pages/Article/BasicInfoForm';
import {
  IFormData as ISidebarFormData,
  FORM_NAME as SIDEBAR_FORM_NAME,
} from '../../containers/ArticleSidebar/form';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import { appSetupSelector, entitiesSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { sectionTreeIsFetchingSelector } from '../../pages/Sections/selectors';
import FeedsComponent from '@sportnet/ui/Feeds';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const mapStateToProps = (state: RootState) => {
  return {
    formValues: getFormValues(FORM_NAME)(state) as IFormData | undefined,
    sidebarFormValues: getFormValues(SIDEBAR_FORM_NAME)(state) as
      | ISidebarFormData
      | undefined,
    appSetup: appSetupSelector(state),
    sectionEntities: entitiesSelector(state).sections,
    sectionTreeIsFetching: sectionTreeIsFetchingSelector(state),
  };
};

interface IOwnProps {
  articleId: number;
  doctypesById: { [key: string]: Doctype };
}

type IProps = IOwnProps & ReturnType<typeof mapStateToProps> & WithPopupsProps;

const Feeds: React.FC<IProps> = (props) => {
  const {
    formValues,
    sidebarFormValues,
    appSetup,
    articleId,
    doctypesById,
    sectionTreeIsFetching,
    sectionEntities,
  } = props;

  const [mainSectionId] = sidebarFormValues?.sectionid || [];

  const getFeedUrl = () => {
    let uri = '';

    if (sidebarFormValues?.redirect) {
      if (sidebarFormValues.redirect.startsWith('http')) {
        return sidebarFormValues.redirect;
      } else {
        uri = sidebarFormValues.redirect;
      }
    } else if (sidebarFormValues?.url) {
      uri = sidebarFormValues.url;
    }

    const { articleUrlPattern } = appSetup.params;
    if (uri && articleUrlPattern) {
      return articleUrlPattern.replace('{{uri}}', uri);
    }

    return '';
  };

  const getDoctypeTitle = () => {
    if (formValues?.doctype) {
      return doctypesById[formValues?.doctype]?.title || '';
    }
    return '';
  };

  return (
    <FeedsComponent
      accessToken={appSetup.token}
      appId={appSetup.appId}
      appSpace={appSetup.appSpace}
      itemId={`${articleId}`}
      fieldNameMap={{
        title: __('Názov článku'),
        date: __('Dátum publikácie'),
        url: __('URL alebo URL presmerovanie'),
      }}
      value={{
        title: formValues?.name || '',
        subtitle: getDoctypeTitle(), // @TODO sem by mala prist v buducnosti sekcia
        image: sidebarFormValues?.picture as {
          media_url: string;
          public_url: string;
          dimensions?: { X: number; Y: number; W: number; H: number };
        },
        category: mainSectionId
          ? (!!!sectionTreeIsFetching &&
              sectionEntities[mainSectionId]?.name) ||
            ''
          : '',
        description: formValues?.perex || '',
        date: sidebarFormValues?.valid_from || '',
        url: getFeedUrl(),
        smarttags: sidebarFormValues?.smarttags as any, // @BUG Smarttags maju `key` a `value` definovane ako string | number
      }}
    />
  );
};

export default compose(withPopups, connect(mapStateToProps))(Feeds);
