import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { Link, RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { deleteDoctype, save } from '../../containers/Doctype/actions';
import {
  detailErrorSelector,
  detailIsFetchingSelector,
  detailSelector,
} from '../../containers/Doctype/selectors';
import { initialize, isDirty, isSubmitting, submit } from 'redux-form';
import { initialize as initializeList, setParams } from '@sportnet/redux-list';
import { load } from '../../containers/DoctypeList/actions';
import { loadDoctypes } from '../App/actions';
import { saveDoctypeErrorHandler } from '../../containers/Doctype/errorHandler';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import Form, { FORM_NAME, IFormData } from './form';
import HeaderBar from '@sportnet/ui/HeaderBar';
import NotFound from '@sportnet/ui/NotFound';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SubmitButton from '@sportnet/ui/Button/Submit';
import constants from '../../containers/DoctypeList/constants';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

type RouteProps = RouteComponentProps<{ id?: string }>;

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const doctypeId = props.match.params.id;
  return {
    isFetching: doctypeId ? detailIsFetchingSelector(doctypeId)(state) : false,
    detail: doctypeId ? detailSelector(doctypeId)(state) : undefined,
    error: doctypeId ? detailErrorSelector(doctypeId)(state) : undefined,
    isSubmitting: isSubmitting(FORM_NAME)(state),
    isDirty: isDirty(FORM_NAME)(state),
  };
};

const mapDispatchToProps = {
  initialize,
  initializeList: initializeList,
  submit,
  load,
  save: save.action,
  deleteDoctype: deleteDoctype.action,
  loadDoctypes: loadDoctypes.action,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  WithPopupsProps;

const C: React.FC<Props> = ({
  initialize,
  submit,
  match,
  load,
  isFetching,
  confirm,
  detail,
  error,
  history,
  deleteDoctype,
  initializeList,
  save,
  alert,
  isSubmitting,
  isDirty,
  loadDoctypes,
}) => {
  const [submitError, setSubmitError] = React.useState(false);

  // Initialize redux-list
  React.useEffect(() => {
    initializeList({
      listName: constants.LIST_NAME,
      initialParams: {
        offset: 0,
      },
    });
    setParams({
      listName: constants.LIST_NAME,
      parameters: {
        offset: 0,
      },
    });
  }, [initializeList]);

  const doctypeId = match.params.id;

  React.useEffect(() => {
    load();
  }, [load]);

  React.useEffect(() => {
    if (detail) {
      initialize(FORM_NAME, {
        id: detail.id,
        title: detail.title,
        defaultPicture: detail.defaultPicture,
        overrideAuthorBySmarttagKey: detail.overrideAuthorBySmarttagKey,
        overrideAuthorBySmarttagKeyCheckbox:
          !!detail.overrideAuthorBySmarttagKey,
        showCreatedBy: detail.showCreatedBy,
        top: detail.top,
        bottom: detail.bottom,
        social: detail.social,
        relatedArticles: detail.relatedArticles,
        defaultWidgets: detail.defaultWidgets || [],
      } as IFormData);
    } else {
      initialize(FORM_NAME, {
        defaultWidgets: [],
      });
    }
  }, [detail, initialize]);

  async function handleSubmit(values: IFormData) {
    try {
      setSubmitError(false);
      await save({
        values: {
          id: values.id,
          title: values.title,
          defaultPicture: values.defaultPicture,
          showCreatedBy: values.overrideAuthorBySmarttagKeyCheckbox
            ? values.showCreatedBy
            : false,
          overrideAuthorBySmarttagKey:
            values.overrideAuthorBySmarttagKeyCheckbox
              ? values.overrideAuthorBySmarttagKey || ''
              : '',
          top: values.top,
          bottom: values.bottom,
          social: values.social || {},
          relatedArticles: {
            ...values.relatedArticles,
            limit: values.relatedArticles?.limit ?? void 0,
          },
          defaultWidgets: values.defaultWidgets || [],
        },
        isNew: !doctypeId,
      });
      loadDoctypes();
      history.push('/articles/doctypes');
    } catch (e) {
      setSubmitError(true);
      saveDoctypeErrorHandler(e, alert);
    }
  }

  function renderContent() {
    if (isFetching && typeof detail === 'undefined') {
      return <ContentLoader />;
    }
    if (error) {
      return (
        <NotFound
          title={__('Typ dokumentu nie je možné načítať.')}
          icon="error"
        />
      );
    }
    return (
      <Segment>
        <Form onSubmit={handleSubmit} noChanges={!!doctypeId} />
      </Segment>
    );
  }

  async function handleDelete() {
    if (!detail) {
      return;
    }
    if (await confirm(__('Naozaj chcete odstrániť tento typ dokumentu?'))) {
      try {
        await deleteDoctype({ doctypeId: detail.id });
        loadDoctypes();
        history.push('/articles/doctypes');
      } catch (e) {
        await alert(__('Typ dokumentu nie je možné odstrániť'));
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to="/articles/doctypes"
            title={__('Späť na zoznam')}
          />
          <HeaderBar.Header>
            {doctypeId ? __('Detail typu dokumentu') : __('Nový typ dokumentu')}
          </HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          {doctypeId && (
            <ContextBarItem>
              <Button danger basic disabled={!!error} onClick={handleDelete}>
                {__('Odstrániť')}
              </Button>
            </ContextBarItem>
          )}
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              successText={__('Uložené!')}
              isSubmitting={isSubmitting}
              isError={submitError}
              disabled={!isDirty || !!error}
              onClick={() => {
                submit(FORM_NAME);
              }}
            >
              {__('Uložiť')}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext
        title={__('Detail typu dokumentu')}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: '/articles/doctypes',
          },
        ]}
      />
      {renderContent()}
    </ScrollLayout>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
