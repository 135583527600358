import * as React from 'react';
import { Listing } from '../../Api';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import { format } from 'date-fns';
import BasicTable from '@sportnet/ui/BasicTable';
import Form from './form';
import config from '../../config';
import rem from 'polished/lib/helpers/rem';
import styled from 'styled-components';

const PriorityWrapper = styled.div`
  max-width: ${rem(128)};
  display: flex;
  justify-content: center;
`;

interface OwnProps {
  items: Listing[];
  reload: () => void;
}

type Props = OwnProps & RouteComponentProps;

const C: React.FC<Props> = ({ items, history, reload }) => {
  return (
    <BasicTable
      columns={[
        {
          header: __('Názov'),
        },
        {
          header: __('Identifikátor'),
        },
        {
          header: __('Typ'),
        },
        {
          header: __('Dátum vytvorenia'),
        },
        {
          header: __('Priorita'),
        },
      ]}
      rows={items}
      onClickRow={(item) => {
        history.push(`/listing/${item.listingId}`);
      }}
      renderRow={(item: Listing) => [
        item.name,
        item.listingId,
        item.type,
        format(new Date(item.created!), config.DATETIME_FORMAT),
        <PriorityWrapper>
          <Form
            priority={item.priority}
            key={item._id}
            markers={item.markers}
            type={item.type}
            listingId={item.listingId}
            reload={reload}
          />
        </PriorityWrapper>,
      ]}
      rowKey="listingId"
    />
  );
};

export default withRouter(C);
