import {
  Article,
  Article_Post,
  Article_Put,
  Articles_List,
  External_Article,
  External_Articles_List,
} from '../../Api';
import { ArticleId } from '../../library/App';
import { ExtraArgumentType } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { replaceEntities } from '../../pages/App/actions';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.ARTICLES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const formatSectionId = (
  sectionId: number[] | number | undefined | string,
): string | undefined => {
  if (sectionId) {
    if (Array.isArray(sectionId)) {
      if (sectionId.length > 0) {
        return sectionId.join(',');
      }
    } else {
      return String(sectionId);
    }
  }
  return void 0;
};

export const loadAll = createAsync<
  {
    limit?: number;
    offset?: number;
    ownerSportnetId?: string;
    authorId?: string;
    smarttags?: string[];
    sectionId?: number | number[] | string;
    validFrom?: string;
    validTo?: string;
    hasDraft?: boolean;
    q?: string;
    sorter?:
      | 'created'
      | '-created'
      | 'modified'
      | '-modified'
      | 'name'
      | '-name'
      | 'valid_from'
      | '-valid_from';
    exludeIds?: string[];
  },
  Articles_List
>('LOAD_ALL', async (params, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());

  const parameters = {
    ...params,
    sectionId: formatSectionId(params.sectionId),
  };

  const response = await Api.getArticles(
    appId,
    appSpace,
    contentDivider,
    parameters,
  );

  const entities: { [key: string]: Article } = {};

  response.articles!.forEach((article) => {
    // @TODO "!" opravit v csm.api - articles nemozu byt optional
    entities[article._id!] = article; // @TODO "!" opravit v csm.api - _id nemoze byt optional
  });

  dispatch(
    replaceEntities({
      articles: entities,
    }),
  );

  return response;
});

export const loadDetail = createAsync<{ articleId: ArticleId }, void>(
  'LOAD_DETAIL',
  async (params, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    const response = await Api.getArticle(
      appId,
      appSpace,
      contentDivider,
      params.articleId,
    );
    const { widgets, ...rest } = response;
    dispatch(
      replaceEntities({
        articles: { [response._id!]: rest }, // @TODO CSM/api optional _id
        articleContents: {
          [response._id!]: (widgets as { type: string }[]) || [],
        }, // @TODO CSM/api optional _id
      }),
    );
  },
);

export const saveArticle = createAsync<
  { values: Article_Post | Article_Put; articleId?: ArticleId },
  Article
>('SAVE', async ({ values, articleId }, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());
  let response: Article;

  if (articleId) {
    response = await Api.editArticle(
      appId,
      appSpace,
      contentDivider,
      articleId,
      {},
      values as Article_Put,
    );
  } else {
    response = await Api.postArticle(
      appId,
      appSpace,
      contentDivider,
      {},
      values as Article_Post,
    );
  }

  dispatch(
    replaceEntities({
      articles: {
        [response._id!]: response, // @TODO "!" opravit v csm.api - _id nemoze byt optional
      },
    }),
  );

  return response;
});

export const saveArticleDraft = createAsync<
  { values: Article_Post | Article_Put; articleId: ArticleId },
  void
>('SAVE_DRAFT', async ({ values, articleId }, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());

  const response = await Api.editArticleDraft(
    appId,
    appSpace,
    contentDivider,
    articleId,
    {},
    values,
  );

  dispatch(
    replaceEntities({
      articles: {
        [response._id!]: response, // @TODO "!" opravit v csm.api - _id nemoze byt optional
      },
    }),
  );

  return response;
});

export const deleteArticle = createAsync<{ articleId: ArticleId }, void>(
  'DELETE',
  async ({ articleId }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    await Api.deleteArticle(appId, appSpace, contentDivider, articleId, {});
  },
);

export const deleteArticleDraft = createAsync<{ articleId: ArticleId }, void>(
  'DELETE_DRAFT',
  async ({ articleId }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    await Api.deleteArticleDraft(
      appId,
      appSpace,
      contentDivider,
      articleId,
      {},
    );
  },
);

export const loadExternalArticle = createAsync<
  {
    limit?: number;
    offset?: number;
    state?: 'new' | 'deleted' | 'taken';
    publishedFrom?: string;
    publishedTo?: string;
    externalSource?: string;
    q?: string;
    sorter?: 'name' | '-name' | 'published' | '-published';
  },
  External_Articles_List
>('LOAD_EXTERNAL_ARTICLE', async (params, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());

  const response = await Api.getExternalArticles(
    appId,
    appSpace,
    contentDivider,
    params,
  );

  const entities: { [key: string]: External_Article } = {};

  response.externalArticles!.forEach((article) => {
    entities[article._id!] = article;
  });

  dispatch(
    replaceEntities({
      externalArticles: entities,
    }),
  );

  return response;
});
