import { ExtraArgumentType } from '../../configureStore';
import { IStaticContentSave, StaticContentCid } from '../../library/App';
import { RootState } from '../../rootReducer';
import { Static_Content } from '../../Api';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { getProp } from '@sportnet/utilities';
import { updateEntities } from '../../pages/App/actions';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.STATIC_CONTENT_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadAll = createAsync<void, Static_Content[]>(
  'LOAD_ALL',
  async (params, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    const response = await Api.getStaticContents(
      appId,
      appSpace,
      contentDivider,
    );

    const entities: { [key: string]: Static_Content } = {};

    response.static_content!.forEach((item) => {
      // @TODO "!" opravit v csm.api - articles nemozu byt optional
      entities[item.cid!] = item; // @TODO "!" opravit v csm.api - cid nemoze byt optional
    });

    dispatch(
      updateEntities({
        staticContents: entities,
      }),
    );

    return response.static_content as Static_Content[];
  },
);

export const loadDetail = createAsync<
  { staticContentCid: StaticContentCid },
  void
>('LOAD_DETAIL', async (params, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());
  const response = await Api.getStaticContent(
    appId,
    appSpace,
    contentDivider,
    params.staticContentCid,
  );

  response.content = getProp(response, ['content'], [])!;

  dispatch(
    updateEntities({
      staticContents: { [response.cid!]: response }, // @TODO CSM/api optional cid
    }),
  );
});

export const saveStaticContent = createAsync<
  { values: IStaticContentSave },
  void
>('SAVE', async ({ values }, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());
  const response = await Api.postStaticContent(
    appId,
    appSpace,
    contentDivider,
    {},
    values,
  );

  dispatch(
    updateEntities({
      staticContents: {
        [response.cid!]: response, // @TODO "!" opravit v csm.api - _id nemoze byt optional
      },
    }),
  );
});

export const deleteStaticContent = createAsync<
  { staticContentCid: StaticContentCid },
  void
>('DELETE', async ({ staticContentCid }, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());
  await Api.deleteStaticContent(
    appId,
    appSpace,
    contentDivider,
    staticContentCid,
    {},
  );
});
