import * as React from 'react';
import { Field, change } from 'redux-form';
import { IAppSetup } from '../../library/App';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import SectionsField from '../../components/SectionsField';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import TagmanagerConnector from '@sportnet/tagmanager-connector';

export const FORM_NAME = 'articles-update-sidebar-form';

const mapDispatchToProps = {
  changeField: change,
};

interface ISegProps {
  header: React.ReactElement;
  children?: React.ReactNode;
}

const Seg: React.FC<ISegProps> = ({ children, ...restProps }) => (
  <Segment {...restProps} noBottomGutter>
    {children}
  </Segment>
);

export type IArticlesUpdateSidebarSegmentType = 'smarttags' | 'sections' | '';

interface OwnProps {
  appSetup: IAppSetup;
  openedSegment?: IArticlesUpdateSidebarSegmentType;
  availableSegments: IArticlesUpdateSidebarSegmentType[];
  sidebarOpened: boolean;
  smarttagsCreatable: boolean;
}

type Props = OwnProps & typeof mapDispatchToProps;

const C: React.FC<Props> = ({
  appSetup,
  openedSegment = null,
  availableSegments,
  sidebarOpened,
  changeField,
  smarttagsCreatable,
}) => {
  const [openedIdx, setOpenedIdx] =
    React.useState<IArticlesUpdateSidebarSegmentType | null>(openedSegment);

  const availableSegmentsById = React.useMemo(
    () =>
      availableSegments.reduce((acc: { [key: string]: boolean }, seg) => {
        acc[seg] = true;
        return acc;
      }, {}),
    [availableSegments],
  );

  React.useEffect(() => {
    if (openedSegment && sidebarOpened) {
      setOpenedIdx(openedSegment);
    }
  }, [openedSegment, sidebarOpened]);

  const handleSegmentToggle = (segment: IArticlesUpdateSidebarSegmentType) => {
    return (collapsed: boolean) => {
      setOpenedIdx(collapsed ? null : segment);
    };
  };

  return (
    <>
      {'smarttags' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'smarttags'}
              toggleCollapse={handleSegmentToggle('smarttags')}
              size="xs"
            >
              {__('Smarttagy')}
            </SegmentHeader>
          }
        >
          <TagmanagerConnector
            appId={appSetup.appId}
            appspace={appSetup.appSpace}
            accessToken={appSetup.token}
          >
            {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
              <>
                <Field
                  getSmarttagsValues={getSmarttagsValues}
                  getSmarttagsKeys={getSmarttagsKeys}
                  getSmarttagsByFts={getSmarttagsByFts}
                  name="smarttags"
                  type="smarttag"
                  label={__('Smarttagy')}
                  component={FormFieldRedux}
                  creatable={smarttagsCreatable}
                  onChange={(e: any) => {
                    changeField(FORM_NAME, 'smarttags', e);
                    changeField(FORM_NAME, 'clearSmarttags', false);
                  }}
                />
              </>
            )}
          </TagmanagerConnector>

          <Field
            component={FormFieldRedux}
            label={__('Vymazať všetky smart:tagy')}
            name="clearSmarttags"
            type="checkbox"
            info={__('Zvoleným článkom sa odstránia všetky smart:tagy')}
            onChange={(e: any) => {
              if (e.target.checked) {
                changeField(FORM_NAME, 'smarttags', []);
              }
              changeField(FORM_NAME, 'clearSmarttags', e.target.checked);
            }}
          />
        </Seg>
      )}
      {'sections' in availableSegmentsById && (
        <Seg
          header={
            <SegmentHeader
              collapsible
              isCollapsed={openedIdx !== 'sections'}
              toggleCollapse={handleSegmentToggle('sections')}
              size="xs"
            >
              {__('Zaradenie do sekcií')}
            </SegmentHeader>
          }
        >
          <Field
            component={SectionsField}
            name="sectionid"
            onChange={(e: any) => {
              changeField(FORM_NAME, 'sectionid', e);
              changeField(FORM_NAME, 'clearSections', false);
            }}
          />
          <Field
            component={FormFieldRedux}
            label={__('Vymazať zaradenie do sekcií')}
            name="clearSections"
            type="checkbox"
            info={__('Zvoleným článkom sa odstráni priradenie do sekcií.')}
            onChange={(e: any) => {
              if (e.target.checked) {
                changeField(FORM_NAME, 'sectionid', []);
              }
              changeField(FORM_NAME, 'clearSections', e.target.checked);
            }}
          />
        </Seg>
      )}
    </>
  );
};

export default compose(connect(undefined, mapDispatchToProps))(C);
