import * as React from 'react';
import { Field, change, getFormValues, reduxForm } from 'redux-form';
import { IOption } from '@sportnet/ui/TheSelect/types';
import { IPicture } from '@sportnet/ui/MediaManagerImage';
import { IWidgets } from '../../library/App';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import { appSetupSelector } from '../App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getProp, slugify } from '@sportnet/utilities';
import { getSmarttagsKeys } from '@sportnet/tagmanager-connector';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormFieldBasic from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import InputRedux from '@sportnet/ui/Input/redux-form';
import Label from '@sportnet/ui/Label/Label';
import Message from '@sportnet/ui/Message';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import WidgetsEditorField from '../../containers/WidgetsEditorField';

export const FORM_NAME = 'doctype-form';

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
  formValues: getFormValues(FORM_NAME)(state) as IFormData,
});

const mapDispatchToProps = {
  change,
};

interface IOwnProps {
  noChanges?: boolean;
}

type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const socialNetworks = [
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'linkedin',
    label: 'LinkedIn',
  },
  {
    value: 'twitter',
    label: 'Twitter',
  },
  {
    value: 'tumblr',
    label: 'Tumblr',
  },
  {
    value: 'pinterest',
    label: 'Pinterest',
  },
  {
    value: 'mail',
    label: 'e-mail',
  },
];

const socialWarning = (
  <Message warning>{__('Pozor! Nezvolili ste žiadne sociálne siete')}</Message>
);

export interface IFormData {
  id: string;
  title: string;
  defaultPicture?: IPicture;
  overrideAuthorBySmarttagKeyCheckbox?: boolean;
  overrideAuthorBySmarttagKey?: string;
  showCreatedBy?: boolean;
  top?: {
    showAuthor?: boolean;
    showId?: boolean;
    showDate?: boolean;
    showSmarttags?: boolean;
    showSocial?: boolean;
  };
  bottom?: {
    showAuthor?: boolean;
    showSmarttags?: boolean;
    showSocial?: boolean;
  };
  social?: {
    [key: string]: boolean;
  };
  relatedArticles?: {
    show?: boolean;
    limit?: number | null;
  };
  defaultWidgets?: IWidgets[];
}

const C: React.FC<Props> = ({
  change,
  noChanges = false,
  appSetup,
  formValues = {} as IFormData,
}) => {
  const [smarttagOptions, setSmarttagOptions] = React.useState<IOption[]>([]);
  const [changedId, setChangedId] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const keys = await getSmarttagsKeys({
        appId: appSetup.appId,
        appspace: appSetup.appSpace,
        accessToken: appSetup.token,
      });
      setSmarttagOptions(
        keys.map((smarttag) => ({
          value: smarttag.key,
          label: smarttag.key,
        })),
      );
    })();
  }, [appSetup]);

  const [allSocialChecked, someSocialChecked] = React.useMemo(() => {
    const formSocial = formValues.social || {};
    let allChecked = true;
    let someChecked = false;
    for (let network of socialNetworks) {
      if (!formSocial[network.value]) {
        allChecked = false;
      } else {
        someChecked = true;
      }
    }
    return [allChecked, someChecked];
  }, [formValues.social]);

  return (
    <>
      <Row>
        <Col xs={12} s={6}>
          <Segment
            raised
            header={<SegmentHeader>{__('Základné informácie')}</SegmentHeader>}
          >
            <Field
              component={FormField}
              type="text"
              name="title"
              label={__('Názov')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!changedId && !noChanges) {
                  change(FORM_NAME, 'id', slugify(e.target.value));
                }
              }}
            />
            <Field
              component={FormField}
              type="text"
              name="id"
              disabled={noChanges}
              label={__('Identifikátor')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setChangedId(true);
              }}
            />
            <Field
              component={FormField}
              type="image"
              name="defaultPicture"
              label={__('Predvolený titulný obrázok')}
              auth={appSetup}
            />
          </Segment>
        </Col>
        <Col xs={12} s={6}>
          <Segment
            raised
            header={<SegmentHeader>{__('Sociálne siete')}</SegmentHeader>}
          >
            <FormFieldBasic
              type="checkbox"
              label={__('Povoliť všetky')}
              checked={allSocialChecked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  change(
                    FORM_NAME,
                    'social',
                    socialNetworks.reduce((acc: any, sn) => {
                      acc[sn.value] = true;
                      return acc;
                    }, {}),
                  );
                } else {
                  change(FORM_NAME, 'social', '');
                }
              }}
            />
            {socialNetworks.map((item) => (
              <Field
                key={`socialNetwork-${item.value}`}
                component={FormField}
                type="checkbox"
                label={`Povoliť zdieľanie cez ${item.label}`}
                name={`social.${item.value}`}
              />
            ))}
          </Segment>
          <Segment
            raised
            header={<SegmentHeader>{__('Autor obsahu')}</SegmentHeader>}
          >
            <Message primary>
              {__(
                'Autorom obsahu je človek, ktorý obsah vytvorí v administrácii. Toto chovanie môžete zmeniť nižšie.',
              )}
            </Message>
            <Field
              component={FormField}
              type="checkbox"
              label={__('Zobrazovať autora podľa smarttagov')}
              name="overrideAuthorBySmarttagKeyCheckbox"
            />
            {formValues.overrideAuthorBySmarttagKeyCheckbox && (
              <Segment secondary noBottomGutter>
                <Field
                  component={FormField}
                  type="theselect"
                  name="overrideAuthorBySmarttagKey"
                  options={smarttagOptions}
                  label={__('Kľúč smarttagu')}
                  format={(value: string = '') => {
                    if (value) {
                      return {
                        value,
                        label: value,
                      };
                    }
                    return null;
                  }}
                  parse={(value: any) => {
                    if (value) {
                      return value.value;
                    }
                    return '';
                  }}
                />
                <Field
                  component={FormField}
                  type="checkbox"
                  name="showCreatedBy"
                  label={__('Zobraziť aj originálneho autora')}
                />
              </Segment>
            )}
          </Segment>
        </Col>
        <Col xs={12} s={6}>
          <Segment
            raised
            header={
              <SegmentHeader>
                {__('Zobrazenie v hlavičke obsahu')}
              </SegmentHeader>
            }
          >
            <Field
              component={FormField}
              type="checkbox"
              name="top.showAuthor"
              label={__('Zobraziť autora')}
            />
            <Field
              component={FormField}
              type="checkbox"
              name="top.showId"
              label={__('Zobraziť ID článku')}
            />
            <Field
              component={FormField}
              type="checkbox"
              name="top.showDate"
              label={__('Zobraziť dátum publikácie')}
            />
            <Field
              component={FormField}
              type="checkbox"
              name="top.showSmarttags"
              label={__('Zobraziť smarttagy')}
            />
            <Field
              component={FormField}
              type="checkbox"
              name="top.showSocial"
              label={__('Zobraziť sociálne siete')}
            />
            {getProp(formValues, ['top', 'showSocial']) &&
              !someSocialChecked &&
              socialWarning}
          </Segment>
        </Col>
        <Col xs={12} s={6}>
          <Segment
            raised
            header={
              <SegmentHeader>{__('Zobrazenie pod obsahom')}</SegmentHeader>
            }
          >
            <Field
              component={FormField}
              type="checkbox"
              name="bottom.showAuthor"
              label={__('Zobraziť autora')}
            />
            <Field
              component={FormField}
              type="checkbox"
              name="bottom.showSmarttags"
              label={__('Zobraziť smarttagy')}
            />
            <Field
              component={FormField}
              type="checkbox"
              name="bottom.showSocial"
              label={__('Zobraziť sociálne siete')}
            />
            {getProp(formValues, ['bottom', 'showSocial']) &&
              !someSocialChecked &&
              socialWarning}
          </Segment>
        </Col>
        <Col xs={12} s={6}>
          <Segment
            raised
            header={<SegmentHeader>{__('Podobné články')}</SegmentHeader>}
          >
            <Field
              component={FormField}
              type="checkbox"
              label={__('Zobrazovať podobné články')}
              name="relatedArticles.show"
            />
            {formValues?.relatedArticles?.show && (
              <FormGroup>
                <Label>{__('Maximálny počet')}</Label>
                <Field
                  name="relatedArticles.limit"
                  component={InputRedux}
                  type="number"
                  parse={(value: string) => {
                    return value && !Number.isNaN(Number(value))
                      ? Number(value)
                      : null;
                  }}
                />
              </FormGroup>
            )}
          </Segment>
        </Col>
        <Col xs={12} s={6}>
          <Segment
            raised
            header={
              <SegmentHeader>
                {__('Prednastavené widgety článku')}
              </SegmentHeader>
            }
          >
            <Field name="defaultWidgets" component={WidgetsEditorField} />
          </Segment>
        </Col>
      </Row>
    </>
  );
};

export default compose(
  reduxForm<IFormData, IOwnProps>({
    form: FORM_NAME,
    initialValues: {
      top: {
        showAuthor: false,
        showId: false,
        showDate: true,
        showSmarttags: false,
      },
      bottom: {
        showAuthor: true,
        showSmarttags: true,
      },
      relatedArticles: {
        show: true,
        limit: 12,
      },
    },
  }),
  connect<{}, {}, IOwnProps>(mapStateToProps, mapDispatchToProps),
)(C);
