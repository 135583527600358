import * as React from 'react';
import { ISectionSave, SectionId } from '../../library/App';
import { Section } from '../../Api';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { deleteSection, saveSection } from '../Section/actions';
import { initialize } from 'redux-form';
import { saveSectionErrorHandler } from '../Section/errorHandler';
import Form, { FORM_NAME, IFormData } from './form';
import Sidebar from '@sportnet/ui/Sidebar';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const mapDispatchToProps = {
  initialize,
  saveSection: saveSection.action,
  deleteSection: deleteSection.action,
};

interface OwnProps {
  onClose: () => void;
  onAfterSave?: () => void;
  onAfterDelete?: () => void;
  opened: boolean;
  section?: Section & { parentSectionId?: SectionId };
}

type Props = OwnProps & typeof mapDispatchToProps & WithPopupsProps;

const C = React.memo<Props>(
  ({
    initialize,
    section,
    opened,
    onClose,
    onAfterSave,
    onAfterDelete,
    saveSection,
    deleteSection,
    alert,
    confirm,
  }) => {
    React.useEffect(() => {
      if (section) {
        initialize(FORM_NAME, {
          name: section.name || '',
          uniqid: section.uniqid,
          url: section.url || '',
          redirecturl: section.redirecturl,
          show: !!section.show,
          publication_groups: section.is_private
            ? section.publication_groups
            : null,
        } as IFormData);
      }
    }, [initialize, section]);

    async function handleSubmit(values: IFormData) {
      if (!section) {
        return;
      }
      try {
        const valuesToSave: ISectionSave = values;
        if (section.parentSectionId) {
          valuesToSave.parent = section.parentSectionId;
        }
        await saveSection({
          sectionId: section._id,
          values: valuesToSave,
        });
        if (onAfterSave) {
          onAfterSave();
        }
      } catch (e) {
        await saveSectionErrorHandler(e, alert);
      }
    }

    async function handleDelete() {
      if (!section) {
        return;
      }
      if (await confirm(__(`Odstrániť sekciu "${section.name}"?`))) {
        try {
          await deleteSection({ sectionId: section._id! }); // @TODO opravit v csm/api
          if (onAfterDelete) {
            onAfterDelete();
          }
        } catch (e) {
          await alert(__('Sekciu nie je možné odstrániť'));
        }
      }
    }

    const isNew = !section || !section._id;

    return (
      <Sidebar
        visible={opened}
        onClose={onClose}
        header={isNew ? __('Nová sekcia') : __('Úprava sekcie')}
      >
        <Form
          onDelete={isNew ? undefined : handleDelete}
          onSubmit={handleSubmit}
          isNew={isNew}
          sectionId={section ? section._id : undefined}
        />
      </Sidebar>
    );
  },
);

export default compose(
  withPopups,
  connect(null, mapDispatchToProps),
)(C) as any as React.FC<OwnProps>;
