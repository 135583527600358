import {
  IQueryResult,
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';

const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
    q: StringParam(''),
    state: StringParam('new'),
    externalSource: StringParam(''),
    sorter: StringParam(''),
    publishedFrom: StringParam(''),
    publishedTo: StringParam(''),
  },
};

const constants = {
  LIST_NAME: 'external_articles_list',
  LIST_LIMIT: 50,
  QUERY_HOC_CONFIG,
};

export default constants;

export type IQuery = IQueryResult<typeof QUERY_HOC_CONFIG>['query'];
