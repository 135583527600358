import { __ } from '../../utilities/';
import Checkbox from '@sportnet/ui/Checkbox';
import Label from '@sportnet/ui/Label/Label';
import Message from '@sportnet/ui/Message';
import React from 'react';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';

const items = [
  {
    _id: 'name:fulltext',
    label: 'Vyhľadaj v nadpise fulltextom (prednastavené)',
    info: 'Hľadá v nadpise zadané slová a zároveň ich variácie (futbal/futbalista, Hamšík/Hamšíka). Do značnej miery funguje bez diakritiky.',
  },
  {
    _id: 'name:phrase',
    label: 'Vyhľadaj v nadpise frázou',
    info: 'Hľadá v nadpise frázu. Je potrebné zadať presný výraz s diakritikou. Slová musia byť v nadpise v zadanom poradí.',
  },
  {
    _id: 'meta.title:fulltext',
    label: 'Vyhľadaj v meta.title fulltextom',
    info: 'Hľadá v meta.title zadané slová a zároveň ich variácie (futbal/futbalista, Hamšík/Hamšíka). Do značnej miery funguje bez diakritiky.',
  },
  {
    _id: 'perex:fulltext',
    label: 'Vyhľadaj v perexe fulltextom',
    info: 'Hľadá v perexe zadané slová a zároveň ich variácie (futbal/futbalista, Hamšík/Hamšíka). Do značnej miery funguje bez diakritiky.',
  },
  {
    _id: 'smarttags.values.key:fulltext',
    label: 'Vyhľadaj v smart:tagoch fulltextom',
    info: 'Hľadá v smart:tagoch zadané slová a zároveň ich variácie (futbal/futbalista, Hamšík/Hamšíka). Do značnej miery funguje bez diakritiky.',
  },
  {
    _id: 'name:autocomplete',
    label: 'Vyhľadaj automatickým doplnením nadpisu',
    info: 'Samostatná možnosť vyhľadávanie v nadpisoch článkov, ktorá sa snaží doplniť nadpis podľa zadaného výrazu (suggestion). Podporuje aj zadanie časti slova (napr. Versta -> Verstappen). Nie je možné kombinovať s ostatnými možnosťami',
    single: true,
  },
];

type Props = {
  onChange?: (value: string) => void;
  value?: string;
  defaultOptions?: string;
};

const SearchParams: React.FC<Props> = ({
  onChange,
  value,
  defaultOptions = items[1]._id,
}) => {
  const qopts: string = React.useMemo(() => value ?? defaultOptions, [value]);

  const toggleSelection = (_id: string) => {
    const qoptsArr = qopts === '' ? [] : qopts.split(',');
    const idx = qoptsArr.indexOf(_id);

    if (idx !== undefined && idx >= 0) {
      qoptsArr.splice(idx, 1);
    } else if (items.find((i) => i._id === qopts && !!i.single)) {
      qoptsArr.splice(0, 1, _id);
    } else if (!!items.find((i) => i._id === _id)?.single) {
      qoptsArr.splice(0, qoptsArr.length, _id);
    } else {
      qoptsArr.push(_id);
    }
    !!onChange && onChange(qoptsArr.join(','));
  };

  return (
    <>
      {items.map((i) => (
        <Segment
          key={`search-param-${i._id}`}
          raised
          header={
            <SegmentHeader>
              <Label>
                <Checkbox
                  checked={qopts.includes(i._id)}
                  onChange={() => toggleSelection(i._id)}
                />
                <span style={{ cursor: 'pointer' }}>{__(i.label)}</span>
              </Label>
            </SegmentHeader>
          }
        >
          <Message primary>{i.info}</Message>
        </Segment>
      ))}
    </>
  );
};

export default SearchParams;
