import * as React from 'react';
import { IAppSetup } from '../../library/App';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setup } from '../../pages/App/actions';
import Api from '../../Api';
import AppProviderContext from './appProviderContext';
import Cookies from 'js-cookie';
import CoreApi from '../../CoreApi';
import FeedsApi from '../../FeedsApi';
import Form from './form';
import Loader from '@sportnet/ui/Loader';
import MediamanagerApi from '../../MediamanagerApi';
import NotFound from '@sportnet/ui/NotFound';
import config from '../../config';

const devParams: IAppSetup['params'] = {
  previewUrlPattern:
    'https://test.pages.sportnet.online/previews/{{previewId}}',
  articleUrlPattern: 'https://test.pages.sportnet.online/{{uri}}',
  sectionUrlPattern: 'https://test.pages.sportnet.online/{{uri}}',
  articleLayouts: [],
};

const APP_SETUP_COOKIES_KEY = 'setup';

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
});

const debug = false;
const isStandalone = window.location === window.parent.location;

const mapDispatchToProps = {
  setup,
};

type OwnProps = {
  children?: React.ReactNode;
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps;

const C: React.FC<Props> = ({ setup, appSetup, children, history }) => {
  const [showForm, setShowForm] = React.useState(false);
  const [initFailure, setInitFailure] = React.useState(false);

  function formatMessage(type: string, payload = {}) {
    return {
      type,
      payload,
    };
  }

  const postMessage = React.useCallback((type: string, payload?: string) => {
    if (debug) {
      console.log('[cms] >>>', type);
    }
    window.parent.postMessage(formatMessage(type, payload), '*');
  }, []);

  const close = React.useCallback(() => {
    if (isStandalone) {
      Cookies.remove(APP_SETUP_COOKIES_KEY);
      window.location.reload();
    } else {
      postMessage('CMS_CLOSE');
    }
  }, [postMessage]);

  const handleLocationChange = React.useCallback(
    (location: any) => {
      const encoded = btoa(JSON.stringify(location));
      postMessage('CMS_LOCATION_CHANGES', encoded);
    },
    [postMessage],
  );

  React.useEffect(() => {
    history.listen(handleLocationChange);
  }, [history, handleLocationChange]);

  const handleMessage = React.useCallback(
    (e: MessageEvent) => {
      const { type, payload } = e.data;
      if (debug) {
        console.log('[cms] <<<', e.data);
      }
      if (type === 'CMS_SET_LOCATION') {
        try {
          const nextLocation = JSON.parse(atob(payload));
          history.replace(nextLocation);
        } catch (e) {
          console.error(e);
          // vyskusali sme, nevyslo
        }
      } else if (type === 'CMS_SET_APP') {
        let parsedPayload: any = {};
        try {
          parsedPayload = JSON.parse(payload);
          if (
            parsedPayload.appId &&
            parsedPayload.appSpace &&
            parsedPayload.token
          ) {
            Api.setToken(parsedPayload.token);
            CoreApi.setToken(parsedPayload.token);
            MediamanagerApi.setToken(parsedPayload.token);
            FeedsApi.setToken(parsedPayload.token);
            CoreApi.getPublicApp('cms').then((appInfo) => {
              setup({
                appId: parsedPayload.appId,
                appSpace: parsedPayload.appSpace,
                appSpaces: [],
                token: parsedPayload.token,
                contentDivider:
                  parsedPayload.contentDivider ||
                  config.DEFAULT_CONTENT_DIVIDER,
                parentUrl: parsedPayload.parentUrl || '',
                app: {
                  name: appInfo.name,
                  logo: appInfo.logo || '',
                },
                params: parsedPayload.params || {},
              });
            });
          } else {
            throw new Error();
          }
        } catch (e) {
          setInitFailure(true);
          throw Error(`Invalid ${type} action payload "${payload}".`);
        }
      }
    },
    [setup, history],
  );

  React.useEffect(() => {
    if (isStandalone) {
      let params: any = {};
      try {
        params = JSON.parse(Cookies.get(APP_SETUP_COOKIES_KEY) || '');
        if (
          params.appId &&
          params.appSpace &&
          params.token &&
          params.contentDivider
        ) {
          Api.setToken(params.token);
          CoreApi.setToken(params.token);
          MediamanagerApi.setToken(params.token);
          FeedsApi.setToken(params.token);
          CoreApi.getPublicApp('cms').then((appInfo) => {
            setup({
              appId: params.appId,
              appSpace: params.appSpace,
              appSpaces: [],
              token: params.token,
              contentDivider: params.contentDivider,
              parentUrl: '',
              app: {
                name: appInfo.name,
                logo: appInfo.logo || '',
              },
              params: process.env.NODE_ENV === 'development' ? devParams : {},
            });
          });
        } else {
          throw new Error();
        }
      } catch (e) {
        setShowForm(true);
      }
    } else {
      window.addEventListener('message', handleMessage, false);
      postMessage('CMS_READY');
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  }, [setup, handleMessage, postMessage]);

  const context = React.useMemo(
    () => ({
      onCloseApp: close,
    }),
    [close],
  );

  if (initFailure) {
    return (
      <NotFound
        title={__('Ajaj! Niečo sa pokazilo')}
        icon="error"
        actionLabel={__('Zavrieť')}
        onClickAction={close}
      />
    );
  }

  if (showForm) {
    return (
      <Form
        appSetup={appSetup}
        onSubmit={(values) => {
          Cookies.set(APP_SETUP_COOKIES_KEY, JSON.stringify(values));
          window.location.reload();
        }}
      />
    );
  }

  if (
    !appSetup.token ||
    !appSetup.appId ||
    !appSetup.appSpace ||
    !appSetup.contentDivider
  ) {
    return <Loader />;
  }

  return (
    <AppProviderContext.Provider value={context}>
      {children}
    </AppProviderContext.Provider>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
