const config = {
  BASE_NS: 'app',
  ARTICLES_NS: 'articles',
  SECTIONS_NS: 'sections',
  LISTINGS_NS: 'listings',
  TRANSLATOR_NS: 'translator',
  EXTERNAL_ARTICLES_NS: 'external_articles',
  STATIC_CONTENT_NS: 'staticContent',
  DOCTYPE_NS: 'doctype',
  DATETIME_FORMAT: 'd.M.yyyy HH:mm',
  DATE_FORMAT: 'd.M.yyyy',
  DEFAULT_CONTENT_DIVIDER: 'default',
  LIMIT_EXPORT_ARTICLES: 5000,
  ARTICLE_LOCK_UPDATE_INTERVAL: 10000,
};

export default config;
