import * as React from 'react';
import { IValue } from '.';
import { __ } from '../../utilities/';
import { entitiesSelector } from '../../pages/App/selectors';
import Link from '@sportnet/ui/Link';
import Message from '@sportnet/ui/Message';
import Text from '@sportnet/ui/Text';
import styled from '../../theme/styled-components';

interface OwnProps {
  value: IValue;
  onChange: (value: IValue) => void;
  sectionEntities: ReturnType<typeof entitiesSelector>['sections'];
  selected: IValue[0];
  onChangeSelection: (nextValue: IValue[0]) => void;
}

const Value = styled('div')`
  display: flex;
  font-size: 0.8em;
`;

const Label = styled('label')`
  flex: 1;
`;

const RemoveLink = styled(Link)`
  margin-left: auto;
`;

type Props = OwnProps;

const C: React.FC<Props> = ({
  value,
  onChange,
  sectionEntities,
  selected,
  onChangeSelection,
}) => {
  if (value.length === 0) {
    return (
      <Message warning>{__('Článok nie je zaradený v žiadnej sekcii')}</Message>
    );
  }

  return (
    <>
      {value.map((v) => {
        const section = v in sectionEntities ? sectionEntities[v] : null;
        return (
          <Value key={v}>
            <Label>
              <input
                type="radio"
                checked={selected === v}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChangeSelection(v);
                  }
                }}
              />
              &nbsp;
              {section ? (
                [
                  ...(section.parent || [])
                    .slice()
                    .reverse()
                    .map((parentId) => (sectionEntities[parentId] || {}).name),
                  section.name,
                ].join(' / ')
              ) : (
                <Text faded>
                  {__('neexistujúca sekcia')} ({v})
                </Text>
              )}
            </Label>
            <RemoveLink
              onClick={() => {
                const idx = value.indexOf(v); // @TODO csm/api _id je optional
                onChange([...value.slice(0, idx), ...value.slice(idx + 1)]);
              }}
              danger
            >
              {__('Odstrániť')}
            </RemoveLink>
          </Value>
        );
      })}
    </>
  );
};

export default C;
