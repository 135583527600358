import * as React from 'react';
import { External_Article } from '../../Api';
import { IIconName } from '@sportnet/ui/Icon';
import { RouteComponentProps, withRouter } from 'react-router';
import { ThemeInterface } from '../../theme/theme';
import { __ } from '../../utilities/';
import { format } from 'date-fns';
import { mb } from '@sportnet/ui/Themes/utilities';
import { withTheme } from 'styled-components';
import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import config from '../../config';
import styled from '../../theme/styled-components';

type IListExternalArticle = External_Article;

const Name = styled.p`
  margin-bottom: 0.3em;
  display: block;
  ${mb('m')} {
    display: inline;
  }
`;

const RightAlign = styled.span`
  text-align: right;
  display: block;
  flex-grow: 1;
  width: 100%;
`;

interface OwnProps {
  items: IListExternalArticle[];
  onClickItem: (item: IListExternalArticle) => void;
  sorter?: string;
  onSort?: (sorter: string) => void;
  deleteButton?: {
    label?: string;
    type?: 'primary' | 'danger' | 'warning' | 'success';
    icon?: string;
    onClick?: (article: IListExternalArticle) => void;
  };
  takeButton?: {
    label?: string;
    type?: 'primary' | 'danger' | 'warning' | 'success';
    icon?: string;
    onClick?: (article: IListExternalArticle) => void;
  };
  onReorder?: (articles: IListExternalArticle[]) => void;
  markers?: Array<number>;
  takingExternalArticleId?: string | null;
}

type Props = OwnProps & { theme: ThemeInterface } & RouteComponentProps;

const C: React.FC<Props> = ({
  items,
  onClickItem,
  sorter = '',
  onSort,
  deleteButton,
  takeButton,
  onReorder,
  markers,
  takingExternalArticleId,
}) => {
  const getTableHeader = () => {
    const order = sorter[0] === '-' ? 'desc' : 'asc';
    const sortedColumnId = order === 'desc' ? sorter.slice(1) : sorter;
    const header: any = [
      {
        id: 'name',
        header: __('Názov'),
        sortable: true,
        sort: (sortedColumnId === 'name' ? order : undefined) as
          | 'desc'
          | 'asc'
          | undefined,
      },
      {
        id: 'state',
        header: (<RightAlign>{__('Stav')}</RightAlign>) as any,
      },
      {
        id: 'externalSource',
        header: __('Zdroj'),
        width: 150,
      },
      {
        id: 'published',
        header: __('Dátum'),
        width: 150,
        sortable: true,
        sort: (sortedColumnId === 'published' ? order : undefined) as
          | 'desc'
          | 'asc'
          | undefined,
      },
    ];
    if (takeButton) {
      header.push({
        header: '',
        width: 60,
      });
    }
    if (deleteButton) {
      header.push({
        header: '',
        width: 60,
      });
    }
    return header;
  };
  return (
    <BasicTable
      onSort={
        onSort
          ? (col, newDirection) => {
              if (typeof newDirection === 'undefined') {
                onSort('');
              } else {
                onSort(`${newDirection === 'asc' ? '' : '-'}${col.id}`);
              }
            }
          : undefined
      }
      columns={getTableHeader()}
      rows={items}
      onClickRow={(item) => {
        onClickItem(item);
      }}
      renderRow={(item: IListExternalArticle) => {
        return [
          <Name>
            <strong>{item.name}</strong>
          </Name>,
          item.state ? (
            <RightAlign>
              {item.state === 'taken'
                ? 'Prebratý'
                : item.state! === 'deleted'
                ? 'Zmazaný'
                : 'Nový'}
            </RightAlign>
          ) : (
            ''
          ),
          item.externalSource ? item.externalSource.toUpperCase() : '',
          item.published
            ? format(new Date(item.published), config.DATETIME_FORMAT)
            : '',
          takeButton ? (
            <Button
              primary={takeButton?.type === 'primary'}
              danger={takeButton?.type === 'danger'}
              warning={takeButton?.type === 'warning'}
              success={takeButton?.type === 'success'}
              icon={(takeButton?.icon as IIconName) || null}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (takeButton.onClick) {
                  e.stopPropagation();
                  takeButton.onClick(item);
                }
              }}
              disabled={takingExternalArticleId === item._id}
              loading={takingExternalArticleId === item._id}
            >
              {takeButton.label}
            </Button>
          ) : null,
          deleteButton ? (
            <Button
              primary={deleteButton?.type === 'primary'}
              danger={deleteButton?.type === 'danger'}
              warning={deleteButton?.type === 'warning'}
              success={deleteButton?.type === 'success'}
              icon={(deleteButton?.icon as IIconName) || null}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (deleteButton.onClick) {
                  e.stopPropagation();
                  deleteButton.onClick(item);
                }
              }}
              disabled={takingExternalArticleId === item._id}
            >
              {deleteButton.label}
            </Button>
          ) : null,
        ];
      }}
      rowKey="_id"
      onReorder={onReorder}
      rowStyle={(item, idx) => {
        if (markers && markers.includes(idx + 1)) {
          return { borderBottom: '3px solid black' };
        }
        return { borderBottom: '1px solid #eee' };
      }}
    />
  );
};

export default withRouter(withTheme(C));
