import * as React from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { RootState } from '../../../../rootReducer';
import { __, formatUrl, required } from '../../../../utilities/';
import { appSetupSelector, userGroupsSelector } from '../../../App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadUserGroups } from '../../../App/actions';
import { rem } from 'polished';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';
import Link from '@sportnet/ui/Link';
import Message from '@sportnet/ui/Message';
import PublicationGroupsField, {
  IPublicationGroupsValue,
} from '../../../../components/PublicationGroupsField';
import styled from '../../../../theme/styled-components';

const mapDispatchToProps = {
  loadUserGroups: loadUserGroups.action,
};

const UrlLink = styled(Link)`
  word-break: break-word;
`;

// FIX: because counter under the input field has position `absolute`
const FieldWithCounterWrapper = styled.div`
  padding-bottom: ${rem(20)};
`;

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export const FORM_NAME = 'section-detail-form';
export interface IFormData {
  name: string;
  heading: string;
  perex: string;
  uniqid: string;
  url: string;
  redirecturl: string;
  show: boolean;
  picture: {
    uri?: string;
    dimensions?: {
      X?: number;
      Y?: number;
      W?: number;
      H?: number;
    };
  };
  publication_groups: IPublicationGroupsValue;
  settings?: string;
}

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
  userGroups: userGroupsSelector(state),
  formValues: getFormValues(FORM_NAME)(state) as IFormData | undefined,
});

const C: React.FC<Props> = ({
  appSetup,
  userGroups,
  loadUserGroups,
  formValues,
}) => {
  React.useEffect(() => {
    loadUserGroups();
  }, [loadUserGroups]);

  const getSectionUrl = (uri: string) => {
    const { sectionUrlPattern } = appSetup.params;
    if (sectionUrlPattern && uri) {
      const url: string = sectionUrlPattern.replace('{{uri}}', uri);
      return (
        <Message primary title={__('URL sekcie')}>
          <UrlLink href={url} primary target="_blank" rel="noopener noreferrer">
            {url}
          </UrlLink>
        </Message>
      );
    }
    return (
      <Message danger title={__('Chyba URL')}>
        {__('Bez zadanej URL bude sekcia nedostupná.')}
      </Message>
    );
  };

  return (
    <>
      <Row>
        <Col xs={12} s={12} m={12} l={6}>
          <Field
            component={FormField}
            type="text"
            name="name"
            label={__('Názov')}
            required
            validate={[required]}
          />
          <Field
            component={FormField}
            type="text"
            name="heading"
            label={__('Alternatívny názov')}
            info={__('Ak je zadaný, zobrazuje sa ako nadpis stránky sekcie')}
          />
          <Field
            component={FormField}
            type="text"
            name="url"
            placeholder={__('Príklad: "url"')}
            label={__('URL')}
            info={__(
              'Relatívna URL, na ktorej bude sekcia dostupná (príklad: "url-sekcie").',
            )}
            required
            normalize={formatUrl}
            validate={[required]}
          />
          {getSectionUrl(formValues?.url || '')}
          <Field
            component={FormField}
            type="text"
            name="redirecturl"
            label={__('URL presmerovanie')}
            info={__(
              'Absolútna URL presmerovania (príklad: https://www.example.com/url-presmerovania)',
            )}
            placeholder="https://"
          />
          <Field
            component={FormField}
            type="textarea"
            name="perex"
            style={{ height: '80px' }}
            label={__('Perex')}
          />
          <FieldWithCounterWrapper>
            <Field
              component={FormField}
              name="meta.title"
              counter={{ characters: true }}
              label={__('Meta title')}
            />
          </FieldWithCounterWrapper>
          <FieldWithCounterWrapper>
            <Field
              component={FormField}
              type="textarea"
              name="meta.description"
              counter={{ characters: true }}
              label={__('Meta description')}
              style={{ height: '80px' }}
            />
          </FieldWithCounterWrapper>
          <Field
            component={FormField}
            type="checkbox"
            name="show"
            label={__('Zobrazovať na stránke')}
          />
        </Col>
        <Col xs={12} s={12} m={12} l={6}>
          <Field
            component={FormField}
            type="text"
            name="uniqid"
            label={__('Identifikátor')}
            info={__(
              'Zadávajte len v prípade, že nepoužívate štandardné pages.',
            )}
          />
          <Field
            component={PublicationGroupsField}
            name="publication_groups"
            label={__('Autorizácia')}
            groups={userGroups}
          />
          <Field
            component={FormField}
            type="image"
            name="icon"
            label={__('Ikona')}
            auth={{
              appId: appSetup.appId,
              appSpace: appSetup.appSpace,
              token: appSetup.token,
            }}
          />
          <Field
            component={FormField}
            type="image"
            name="picture"
            label={__('Titulný obrázok')}
            auth={{
              appId: appSetup.appId,
              appSpace: appSetup.appSpace,
              token: appSetup.token,
            }}
          />
          <Field
            component={FormField}
            type="textarea"
            name="settings"
            label={__('Vlastné nastavenia')}
            style={{ height: '100px' }}
            placeholder={'KEY=VALUE'}
            info={
              <div style={{ maxWidth: '200px' }}>
                {__(
                  'Nastavenia potrebné pre vlastné riešenie pages. Zadávajte v tvare KLUC=HODNOTA',
                )}
              </div>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(C);
