import * as React from 'react';
import { AppSpaceUser } from '../../CoreApi';
import { Article_Author } from '../../Api';
import { IOption } from '@sportnet/ui/TheSelect/types';
import { WrappedFieldProps } from 'redux-form';
import { __ } from '../../utilities/';
import { rem } from 'polished';
import Button from '@sportnet/ui/Button';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Link from '@sportnet/ui/Link';
import Select from '@sportnet/ui/TheSelect';
import Text from '@sportnet/ui/Text';
import styled from 'styled-components';

interface OwnProps extends WrappedFieldProps {
  usersWithRoles: AppSpaceUser[];
}

const DeleteButton = styled(Button)`
  padding: ${rem(0)};
  min-height: 0;
  margin: 0 0 auto auto;
  border: none;
  svg {
    width: ${rem(18)};
    height: ${rem(18)};
  }
`;

const AddOtherPersonButton = styled(Link)`
  padding: ${rem(4)} 0;
  outline: none;
  text-decoration: underline;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ErrorText = styled.div`
  font-size: ${rem(11)};
  margin-top: ${rem(3)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

type Props = OwnProps;

const AuthorsField: React.FC<Props> = ({ input, usersWithRoles }) => {
  const values = input.value || [];

  const [formErrors, setFormErrors] = React.useState<string[]>(
    new Array(values.length),
  );

  const options = React.useMemo(() => {
    return (usersWithRoles || []).map((user) => ({
      label: user.display_name || user.user_id,
      value: user.user_id,
    }));
  }, [usersWithRoles]);

  const handleChangeSelect = (nextValue: IOption) => {
    if (nextValue) {
      // ak sa zvoleny uzivatel nenachadza medzi autormi, pridame ho
      const authorIdx = values.findIndex(
        (author: Article_Author) => nextValue.value === author.sportnetId,
      );
      if (authorIdx < 0) {
        input.onChange([
          ...values,
          { sportnetId: nextValue.value, name: nextValue.label },
        ]);
      }
    }
  };

  const handleChangeInputValue = (idx: number, value: string) => {
    if (!(value || '').trim()) {
      setFormErrors((prev) => {
        prev[idx] = __('Vyplňte meno autora!');
        return prev;
      });
    } else {
      setFormErrors((prev) => {
        prev[idx] = '';
        return prev;
      });
    }
    const tmp = [...values];
    tmp[idx] = { ...tmp[idx], name: value };
    input.onChange(tmp);
  };

  const handleDeleteAuthorClick = (idx: number) => {
    const tmp = [...values];
    setFormErrors((prev) => {
      prev[idx] = '';
      return prev;
    });
    tmp.splice(idx, 1);
    input.onChange(tmp);
  };

  const handleAddOtherPersonClick = () => {
    const tmp = [...values, { name: '' }];
    input.onChange(tmp);
  };

  return (
    <>
      <FormGroup>
        <Label
          info={__(
            'Vyberte autora zo zoznamu používateľov systému, alebo kliknite na "Pridať iného autora".',
          )}
        >
          {__('Zvoľte používateľa')}
        </Label>
        <Select
          value={null}
          onChange={handleChangeSelect}
          options={options}
          clearable={false}
        />
        <AddOtherPersonButton onClick={handleAddOtherPersonClick}>
          {__('Pridať iného autora')}
        </AddOtherPersonButton>
      </FormGroup>
      {values.map((value: Article_Author, idx: number) => (
        <FormGroup key={idx}>
          <LabelWrapper>
            <Label>
              {value.sportnetId
                ? `${__('Používateľ: ')} ${value.sportnetId}`
                : __('Iný autor')}
            </Label>
            <DeleteButton
              icon="close"
              danger
              basic
              onClick={() => handleDeleteAuthorClick(idx)}
            />
          </LabelWrapper>
          <Input
            name="name"
            value={value.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeInputValue(idx, e.target.value);
            }}
          />
          {formErrors[idx] && (
            <ErrorText>
              <Text danger>{formErrors[idx]}</Text>
            </ErrorText>
          )}
        </FormGroup>
      ))}
    </>
  );
};

export default AuthorsField;
