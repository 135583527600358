import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Articles from './pages/Articles';
import Information from './pages/Information';

const sectionRoutes = () => {
  return (
    <Switch>
      <Route path="/sections/:id/articles" component={Articles} />
      <Route path="/sections/:id" component={Information} />
    </Switch>
  );
};

export default sectionRoutes;
