import { CommitError, commit } from '@sportnet/redux-list';
import { CustomThunkDispatch } from '../../configureStore';
import { ExternalArticleId } from '../../library/App';
import { External_Article_Put } from '../../Api';
import { ExtraArgumentType } from '../../configureStore';
import { RootState } from '../../rootReducer';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { loadExternalArticle } from '../../containers/Article/actions';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';
import constants from './constants';

const create = actionCreatorFactory(config.EXTERNAL_ARTICLES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export type ApiArticlesQueryType = {
  offset?: number;
  q?: string;
  externalSource?: string;
  sorter?: string;
  state?: any;
  publishedFrom?: string;
  publishedTo?: string;
};

export function queryToApiArticlesParams(query: ApiArticlesQueryType) {
  return {
    q: query.q,
    offset: query.offset,
    limit: constants.LIST_LIMIT,
    externalSource: query.externalSource,
    publishedFrom: query.publishedFrom,
    publishedTo: query.publishedTo,
    state: query.state,
    sorter: query.sorter as 'name' | '-name' | 'published' | '-published',
  };
}

export function load(query: ApiArticlesQueryType) {
  return (dispatch: CustomThunkDispatch) => {
    return dispatch(
      commit.action({
        listName: constants.LIST_NAME,
        load: async () => {
          try {
            const res = await dispatch(
              loadExternalArticle.action({
                ...queryToApiArticlesParams(query),
                limit: constants.LIST_LIMIT,
              }),
            );
            return {
              total: res.total || 0,
              results: res.externalArticles!.map((a) => a._id),
              nextOffset: res.nextOffset,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
}

export const changeState = createAsync<
  { state: External_Article_Put; articleId: ExternalArticleId },
  void
>(
  'CHANGE_EXTERNAL_ARTICLE',
  async ({ state, articleId }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());
    const response = await Api.editExternalArticleState(
      appId,
      appSpace,
      contentDivider,
      articleId,
      {},
      state,
    );
    return response;
  },
);
