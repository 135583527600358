import { __, debounce } from '../../utilities/';
import { rem } from 'polished';
import HorizontalLayout from '../HorizontalLayout';
import Input from '@sportnet/ui/Input';
import Message from '@sportnet/ui/Message';
import Paginator from '@sportnet/ui/Paginator';
import React from 'react';
import styled from '../../theme/styled-components';

const SInput = styled(Input)`
  height: ${rem(40)};
`;

const StyledHorizontalLayout: typeof HorizontalLayout = styled(
  HorizontalLayout,
)`
  margin-top: ${({ theme }) => rem(-theme.grid.gutterWidth)};
  /* fix input background color */
  & > div:first-child {
    background-color: ${({ theme }) => theme.input.backgroundColor};
    border-radius: ${({ theme }) => rem(theme.input.borderRadius)};
  }
  /* fix Message margins */
  & > div:last-child > div {
    margin-bottom: 0;
  }
`;

interface IOwnProps {
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
  onSelectPrevMatch: () => void;
  onSelectNextMatch: () => void;
  onSelectMatch: (index: number) => void;
  searchFocusIndex: number;
  searchFoundCount: number;
}

type IProps = IOwnProps;

const SearchInput: React.FC<IProps> = ({
  placeholder,
  value,
  onChange,
  onSelectPrevMatch,
  onSelectNextMatch,
  onSelectMatch,
  searchFocusIndex,
  searchFoundCount,
}) => {
  const [localValue, setLocalValue] = React.useState(value);

  const propagateChange = React.useMemo(() => {
    return debounce(onChange, 200);
  }, [onChange]);

  return (
    <StyledHorizontalLayout shouldWrap>
      <SInput
        type="text"
        placeholder={placeholder}
        value={localValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const val = e.target.value;
          setLocalValue(val);
          propagateChange(val);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter' && event.shiftKey) {
            onSelectPrevMatch();
          } else if (event.key === 'Enter') {
            onSelectNextMatch();
          }
        }}
      >
        <Input.Icon name="search" />
        <input />
      </SInput>

      {value && (
        <Paginator
          offset={searchFocusIndex}
          limit={1}
          total={searchFoundCount}
          onChangeOffset={onSelectMatch}
        />
      )}

      {value && searchFoundCount > 1 && (
        <Message primary>
          {__(
            'Stlačte Enter pre ďalší výsledok, alebo Shift+Enter pre predchádzajúci',
          )}
        </Message>
      )}
    </StyledHorizontalLayout>
  );
};

export default React.memo(SearchInput);
