import { createSelector } from 'reselect';
import { entitiesSelector } from '../../pages/App/selectors';
import { getListResults } from '@sportnet/redux-list';
import at from '@sportnet/utilities/at';
import constants from './constants';

export const staticContentsSelector = createSelector(
  entitiesSelector,
  getListResults(constants.LIST_NAME),
  (entities, ids) => (ids ? at(entities.staticContents, ids) : []),
);
