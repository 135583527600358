import { RootState } from '../../rootReducer';
import { createSelector } from 'reselect';

const translationDomain = (state: RootState) => state.translation;
const translationLanguagesDomain = (state: RootState) =>
  state.translationLanguages;

export const translationSelector = createSelector(
  translationDomain,
  (domain) => domain.result ?? null,
);

export const translationLanguagesIsFetchingSelector = createSelector(
  translationLanguagesDomain,
  (domain) => domain.isFetching,
);

export const translationLanguagesSelector = createSelector(
  translationLanguagesDomain,
  (domain) => {
    return domain.result
      ? {
          sourceLang: domain.result.sourceLang.map((lang) => ({
            value: lang.lang,
            label: lang.name,
          })),
          targetLang: domain.result.targetLang.map((lang) => ({
            value: lang.lang,
            label: lang.name,
          })),
        }
      : null;
  },
);

export const targetLanguageByIdSelector = createSelector(
  [translationLanguagesSelector, (_langs, langId) => langId],
  (langs, langId) => {
    return langs
      ? langs.targetLang.find((lang) => lang.value === langId) ?? null
      : null;
  },
);
