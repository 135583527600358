import * as React from 'react';
import { Field, change, reduxForm } from 'redux-form';
import { ListingType } from '../../Api';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { listingTypes } from '../../containers/ListingList';
import Col, { Row } from '@sportnet/ui/Grid';
import FormField from '@sportnet/ui/FormField/redux-form';
import slugify from 'slugify';

interface IOwnProps {
  creating: boolean;
}

type Props = IOwnProps;

const mapDispatchToProps = {
  change,
};

export const FORM_NAME = 'listing-form';
export interface IFormData {
  name: string;
  listingId: string;
  type: ListingType;
  markers: string;
}

const ListingForm: React.FC<Props & typeof mapDispatchToProps> = ({
  change,
  creating,
}) => {
  return (
    <>
      <Row>
        <Col xs={12} s={6} m={4}>
          <Field
            component={FormField}
            type="text"
            name="name"
            label={__('Názov')}
            onChange={(e: any) => {
              creating &&
                change(
                  FORM_NAME,
                  'listingId',
                  slugify(e.target.value || '').toLowerCase(),
                );
            }}
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            component={FormField}
            type="text"
            name="listingId"
            label={__('Identifikátor')}
            disabled={!creating}
          />
        </Col>
        <Col xs={12} s={6} m={3}>
          <Field
            component={FormField}
            type="theselectsimple"
            name="type"
            label={__('Typ')}
            options={listingTypes.map(({ title, type }) => ({
              label: title,
              value: type,
            }))}
          />
        </Col>
        <Col xs={12} s={6} m={2}>
          <Field
            component={FormField}
            type="text"
            name="markers"
            label={__('Markery')}
            placeholder="napr. 1,4,7,10"
            info={__(
              'Umiestnenia markerov (deliacích čiar v listingu fixných článkov).',
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
  }),
  connect(null, mapDispatchToProps),
)(ListingForm) as any;
