import * as React from 'react';
import { Doctype } from '../../Api';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import BasicTable from '@sportnet/ui/BasicTable';

interface OwnProps {
  items: Doctype[];
}

type Props = OwnProps & RouteComponentProps;

const C: React.FC<Props> = ({ items, history }) => {
  return (
    <BasicTable
      columns={[
        {
          header: __('Identifikátor'),
          width: 150,
        },
        {
          header: __('Názov'),
        },
      ]}
      rows={items}
      onClickRow={(item) => {
        history.push(`/articles/doctypes/detail/${item.id}`);
      }}
      renderRow={(item: Doctype) => [item.id, item.title]}
      rowKey="id"
    />
  );
};

export default withRouter(C);
