import { detailReducer as articleDetailReducer } from './containers/Article/reducer';
import { combineReducers } from 'redux';
import {
  detailReducerTranslationLanguages,
  detailReducer as translationReducer,
} from './containers/Translator/reducer';
import {
  doctypesReducer,
  entitiesReducer,
  listingsReducer,
  setupReducer,
  userGroupsReducer,
  usersWithRolesReducer,
} from './pages/App/reducer';
import { reducer as fromReducer } from 'redux-form';
import { reducer as listReducer } from '@sportnet/redux-list';
import { detailReducer as listingDetailReducer } from './containers/Listing/reducer';
import { detailReducer as sectionDetailReducer } from './pages/Section/reducer';
import {
  sectionTreeReducer,
  sectionTreeScrollTopPositionReducer,
} from './pages/Sections/reducer';
import { detailReducer as staticContentDetailReducer } from './containers/StaticContent/reducer';

const rootReducer = combineReducers({
  setup: setupReducer,
  doctypes: doctypesReducer,
  userGroups: userGroupsReducer,
  usersWithRoles: usersWithRolesReducer,
  entities: entitiesReducer,
  articleDetail: articleDetailReducer,
  sectionDetail: sectionDetailReducer,
  staticContentDetail: staticContentDetailReducer,
  listingDetail: listingDetailReducer,
  form: fromReducer,
  list: listReducer,
  sectionTree: sectionTreeReducer,
  sectionTreeScrollTopPosition: sectionTreeScrollTopPositionReducer,
  translation: translationReducer,
  translationLanguages: detailReducerTranslationLanguages,
});

export default rootReducer;

export type RootState = {
  setup: ReturnType<typeof setupReducer>;
  doctypes: ReturnType<typeof doctypesReducer>;
  listings: ReturnType<typeof listingsReducer>;
  urlmap: ReturnType<typeof doctypesReducer>;
  userGroups: ReturnType<typeof userGroupsReducer>;
  usersWithRoles: ReturnType<typeof usersWithRolesReducer>;
  entities: ReturnType<typeof entitiesReducer>;
  articleDetail: ReturnType<typeof articleDetailReducer>;
  sectionDetail: ReturnType<typeof sectionDetailReducer>;
  staticContentDetail: ReturnType<typeof staticContentDetailReducer>;
  listingDetail: ReturnType<typeof listingDetailReducer>;
  list: ReturnType<typeof listReducer>;
  sectionTree: ReturnType<typeof sectionTreeReducer>;
  sectionTreeScrollTopPosition: ReturnType<
    typeof sectionTreeScrollTopPositionReducer
  >;
  translation: ReturnType<typeof translationReducer>;
  translationLanguages: ReturnType<typeof detailReducerTranslationLanguages>;
};
