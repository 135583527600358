import * as React from 'react';
import { __ } from '../../utilities/';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import ArticleList from '../../containers/ArticleList';

interface IOwnProps {}

type Props = IOwnProps;

const C: React.FC<Props> = () => {
  return (
    <>
      <AppContext title={__('Všetky články')} />
      <ArticleList />
    </>
  );
};

export default C;
