import * as actions from './actions';
import { AnyAction } from 'redux';
import { AppSpaceUser } from '../../CoreApi';
import {
  Article,
  Doctype,
  External_Article,
  Listing,
  Listings_List,
  Section,
  Static_Content,
  Translator_TranslatedData_Item,
  URLMapUrl,
  URLMap_List,
} from '../../Api';
import { IAppSetup, IWidgets, User_Groups_List } from '../../library/App';
import { getProp } from '@sportnet/utilities';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import config from '../../config';

type EntitiesState = Readonly<{
  articles: Readonly<{
    [key: string]: Readonly<Article>;
  }>;
  listings: Readonly<{
    [key: string]: Readonly<Listing>;
  }>;
  urlmap: Readonly<{
    [key: string]: Readonly<URLMapUrl>;
  }>;
  articleContents: Readonly<{
    [key: string]: IWidgets;
  }>;
  sections: Readonly<{
    [key: string]: Readonly<Section>;
  }>;
  sectionContents: Readonly<{
    [key: string]: IWidgets;
  }>;
  staticContents: Readonly<{
    [key: string]: Static_Content;
  }>;
  doctypes: Readonly<{
    [key: string]: Doctype;
  }>;
  externalArticles: Readonly<{
    [key: string]: Readonly<External_Article>;
  }>;
  translation: Readonly<{
    [key: string]: Readonly<Translator_TranslatedData_Item>;
  }>;
}>;

export const entitiesReducer = (
  state: EntitiesState = {
    articles: {},
    articleContents: {},
    sections: {},
    sectionContents: {},
    staticContents: {},
    doctypes: {},
    listings: {},
    urlmap: {},
    externalArticles: {},
    translation: {},
  },
  action: AnyAction,
) => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return {
      ...state,
      ...Object.keys(action.payload.result.entities).reduce((acc, entity) => {
        const e = getProp(
          state,
          [entity],
          {},
        ) as EntitiesState[keyof EntitiesState];
        return {
          ...acc,
          [entity]: {
            ...e,
            ...action.payload.result.entities[entity],
          },
        };
      }, {}),
    };
  }
  return state;
};

const APP_SETUP_INITIAL_STATE: IAppSetup = {
  appSpace: '',
  appSpaces: [],
  appId: '',
  token: '',
  contentDivider: config.DEFAULT_CONTENT_DIVIDER,
  parentUrl: '',
  params: {},
  app: {},
};

export const setupReducer = reducerWithInitialState<IAppSetup>(
  APP_SETUP_INITIAL_STATE,
)
  .case(actions.setup, (state, payload): IAppSetup => payload)
  .case(
    actions.loadAppInfo.async.done,
    (state, payload): IAppSetup => ({ ...state, app: payload.result }),
  )
  .case(
    actions.loadAppSpaces.async.done,
    (state, payload): IAppSetup => ({ ...state, appSpaces: payload.result }),
  );

type DoctypesState = Readonly<{
  isFetching: boolean;
  doctypes: Doctype[];
}>;

const DOCTYPES_INITIAL_STATE: DoctypesState = {
  isFetching: false,
  doctypes: [],
};

export const doctypesReducer = reducerWithInitialState<DoctypesState>(
  DOCTYPES_INITIAL_STATE,
)
  .case(
    actions.loadDoctypes.async.started,
    (state): DoctypesState => ({
      ...state,
      isFetching: true,
      doctypes: [],
    }),
  )
  .case(
    actions.loadDoctypes.async.done,
    (state, { result }): DoctypesState => ({
      ...state,
      isFetching: false,
      doctypes: result,
    }),
  )
  .case(
    actions.loadDoctypes.async.failed,
    (state): DoctypesState => ({
      ...state,
      isFetching: false,
    }),
  );

type ListingsState = Readonly<{
  isFetching: boolean;
  listings: Listings_List['listings'];
}>;

const LISTINGS_INITIAL_STATE: ListingsState = {
  isFetching: false,
  listings: [],
};

export const listingsReducer = reducerWithInitialState<ListingsState>(
  LISTINGS_INITIAL_STATE,
)
  .case(
    actions.loadListings.async.started,
    (state): ListingsState => ({
      ...state,
      isFetching: true,
      listings: [],
    }),
  )
  .case(
    actions.loadListings.async.done,
    (state, { result }): ListingsState => ({
      ...state,
      isFetching: false,
      listings: result.listings,
    }),
  )
  .case(
    actions.loadListings.async.failed,
    (state): ListingsState => ({
      ...state,
      isFetching: false,
    }),
  );

type UrlmapState = Readonly<{
  isFetching: boolean;
  urlmap: URLMap_List['urlmap'];
}>;

const URLMAP_INITIAL_STATE: UrlmapState = {
  isFetching: false,
  urlmap: [],
};

export const urlmapReducer = reducerWithInitialState<UrlmapState>(
  URLMAP_INITIAL_STATE,
)
  .case(
    actions.loadUrlmap.async.started,
    (state): UrlmapState => ({
      ...state,
      isFetching: true,
      urlmap: [],
    }),
  )
  .case(
    actions.loadUrlmap.async.done,
    (state, { result }): UrlmapState => ({
      ...state,
      isFetching: false,
      urlmap: result.urlmap,
    }),
  )
  .case(
    actions.loadUrlmap.async.failed,
    (state): UrlmapState => ({
      ...state,
      isFetching: false,
    }),
  );

type UserGroupsState = Readonly<{
  isFetching: boolean;
  groups: User_Groups_List;
}>;

const USER_GROUPS_INITIAL_STATE: UserGroupsState = {
  isFetching: false,
  groups: [],
};

export const userGroupsReducer = reducerWithInitialState<UserGroupsState>(
  USER_GROUPS_INITIAL_STATE,
)
  .case(
    actions.loadUserGroups.async.started,
    (state): UserGroupsState => ({
      ...state,
      isFetching: true,
      groups: [],
    }),
  )
  .case(
    actions.loadUserGroups.async.done,
    (state, { result }): UserGroupsState => ({
      ...state,
      isFetching: false,
      groups: result as User_Groups_List,
    }),
  )
  .case(
    actions.loadUserGroups.async.failed,
    (state): UserGroupsState => ({
      ...state,
      isFetching: false,
    }),
  );

type UsersWithRolesState = Readonly<{
  isFetching: boolean;
  users: Array<AppSpaceUser>;
}>;

const USERS_WITH_ROLES_INITIAL_STATE: UsersWithRolesState = {
  isFetching: false,
  users: [],
};

export const usersWithRolesReducer =
  reducerWithInitialState<UsersWithRolesState>(USERS_WITH_ROLES_INITIAL_STATE)
    .case(
      actions.loadUsersWithRoles.async.started,
      (state): UsersWithRolesState => ({
        ...state,
        isFetching: true,
        users: [],
      }),
    )
    .case(
      actions.loadUsersWithRoles.async.done,
      (state, { result }): UsersWithRolesState => ({
        ...state,
        isFetching: false,
        users: result,
      }),
    )
    .case(
      actions.loadUsersWithRoles.async.failed,
      (state): UsersWithRolesState => ({
        ...state,
        isFetching: false,
      }),
    );
