import { RootState } from '../../rootReducer';
import { SectionId } from '../../library/App';
import { createSelector } from 'reselect';
import { entitiesSelector } from '../App/selectors';

const detailDomain = (state: RootState) => state.sectionDetail;

export const detailIsFetchingSelector = (sectionId: SectionId) =>
  createSelector(detailDomain, (domain) =>
    sectionId in domain ? domain[sectionId].isFetching : false,
  );

export const detailSelector = (sectionId: SectionId) =>
  createSelector(entitiesSelector, (entities) =>
    sectionId in entities.sections ? entities.sections[sectionId] : undefined,
  );

export const detailErrorSelector = (sectionId: SectionId) =>
  createSelector(detailDomain, (domain) =>
    sectionId in domain ? domain[sectionId].error : '',
  );

export const contentSelector = (sectionId: SectionId) =>
  createSelector(
    entitiesSelector,
    (entities) => entities.sectionContents[sectionId],
  );
