import * as React from 'react';
import { Article, Doctype } from '../../Api';
import {
  Field,
  WrappedFieldProps,
  getFormValues,
  isDirty,
  reduxForm,
} from 'redux-form';
import { IArticleSidebarSegmentType } from '../../containers/ArticleSidebar/fields';
import {
  IFormData as ISidebarFormData,
  FORM_NAME as SIDEBAR_FORM_NAME,
} from '../../containers/ArticleSidebar/form';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { doctypesSelector } from '../App/selectors';
import { rem } from 'polished';
import ArticleHints from '../../components/ArticleHints';
import ContentHeader from '../../components/ContentHeader';
import FormField from '@sportnet/ui/FormField/redux-form';
import Link from '@sportnet/ui/Link';
import Loader from '@sportnet/ui/Loader';
import Message from '@sportnet/ui/Message';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import sk from 'date-fns/locale/sk';
import styled from '../../theme/styled-components';

export const FORM_NAME = 'article-detail-form';

export interface IFormData {
  name: string;
  perex: string;
  doctype: string;
}

const MessageWrapper = styled('div')`
  margin-bottom: ${({ theme }) => rem(theme.grid.gutterWidth)};
  button {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const InlineLoader = styled(Loader)`
  display: inline-flex;
  margin-left: ${rem(5)};
  width: ${rem(13)};
  height: ${rem(13)};
`;

const HeaderInput = styled('input')<{ error: boolean }>`
  flex: 1 0 0;
  font-size: 1.5rem;
  margin-bottom: ${rem(24)};
  border: 0;
  border-bottom: 1px solid
    ${({ theme, error }) => (error ? theme.color.danger : theme.separatorColor)};
`;

const Counter = styled.div`
  position: absolute;
  bottom: ${rem(4)};
  font-size: ${rem(11)};
`;

const mapStateToProps = (state: RootState) => {
  return {
    isDirty: isDirty(FORM_NAME)(state),
    isSidebarFormDirty: isDirty(SIDEBAR_FORM_NAME)(state),
    sidebarFormValues: getFormValues(SIDEBAR_FORM_NAME)(state) as
      | ISidebarFormData
      | undefined,
    doctypes: doctypesSelector(state),
  };
};

interface IOwnProps {
  article?: Article;
  sidebarIsOpened: boolean;
  openSidebar: (segment?: IArticleSidebarSegmentType) => void;
  closeSidebar: () => void;
  onDeleteDraft: () => void;
  locks?: {
    sportnetId: string;
    displayName: string;
    lockedTo: string | null;
  }[];
}

type IProps = ReturnType<typeof mapStateToProps> & IOwnProps;

const HeaderField: React.FC<
  WrappedFieldProps & {
    placeholder: string;
  }
> = ({ input, meta: { touched, error }, placeholder }) => {
  const [internalCounter, setInternalCounter] = React.useState({
    characters: 0,
    words: 0,
  });
  const setCharactersAndWordsCount = (value: string) => {
    if (typeof value === 'string') {
      const text: string[] = value.trim().split(/\s+/);
      setInternalCounter({
        characters: text.join(' ').length,
        words: text.filter((i) => i).length,
      });
    }
  };

  React.useEffect(() => {
    const initValue = input.value;
    if (initValue) {
      setCharactersAndWordsCount(initValue);
    }
  }, [input.value]);

  const formatWordsCounter = () => {
    let counterItems = [];
    counterItems.push(`${internalCounter.characters} znakov`);
    return counterItems.join(', ');
  };

  return (
    <>
      <HeaderInput
        {...input}
        placeholder={placeholder}
        error={touched && error}
      />
      <Counter>{formatWordsCounter()}</Counter>
    </>
  );
};

const C: React.FC<IProps> = (props) => {
  const {
    article,
    sidebarFormValues,
    sidebarIsOpened,
    closeSidebar,
    openSidebar,
    doctypes,
    onDeleteDraft,
    locks,
  } = props;

  const doctypesById: { [key: string]: Doctype } = React.useMemo(
    () =>
      doctypes.reduce((acc: { [key: string]: Doctype }, doctype) => {
        acc[doctype.id] = doctype;
        return acc;
      }, {}),
    [doctypes],
  );

  const [isLoading, setIsLoading] = React.useState(false);

  const sidebarFormValuesRelevantForHints: { [key: string]: any } = {};
  if (sidebarFormValues) {
    sidebarFormValuesRelevantForHints.valid_from = sidebarFormValues.valid_from;
    sidebarFormValuesRelevantForHints.smarttags = sidebarFormValues.smarttags;
    sidebarFormValuesRelevantForHints.url = sidebarFormValues.url;
    sidebarFormValuesRelevantForHints.picture = sidebarFormValues.picture;
  }

  return (
    <>
      <ContentHeader
        onClickAction={
          sidebarIsOpened ? closeSidebar : () => openSidebar('publication')
        }
        actionActive={sidebarIsOpened}
      >
        <Field
          component={HeaderField}
          counter={{ characters: true }}
          name="name"
          placeholder={__('Sem napíšte názov článku')}
        />
      </ContentHeader>
      {locks && locks.length > 0 && (
        <MessageWrapper>
          <Message danger>
            <span
              dangerouslySetInnerHTML={{
                __html: __(
                  `Článok uzamkol _${locks[0]?.displayName}_ do
                  *${new Date(locks[0].lockedTo!).toLocaleString()}*.`,
                )
                  .replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
                  .replace(/_([^_]+)_/g, '<em>$1</em>'),
              }}
            />
            &nbsp;
            {isLoading && <InlineLoader size="xs" />}
          </Message>
        </MessageWrapper>
      )}
      {article && article.draft && (
        <MessageWrapper>
          <Message danger>
            <span
              dangerouslySetInnerHTML={{
                __html: __(
                  `Koncept naposledy uložil *${
                    article.draft.createdBy?.displayName
                  }* _${formatDistanceToNow(new Date(article.draft!.created!), {
                    locale: sk,
                    addSuffix: true,
                  })}_.`,
                )
                  .replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
                  .replace(/_([^_]+)_/g, '<em>$1</em>'),
              }}
            />
            &nbsp;
            <Link
              onClick={async () => {
                if (isLoading) {
                  return;
                }
                setIsLoading(true);
                try {
                  await onDeleteDraft();
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              <strong>{__('Vymazať koncept')}</strong>
            </Link>
            {isLoading && <InlineLoader size="xs" />}
          </Message>
        </MessageWrapper>
      )}
      <ArticleHints
        article={{
          ...article,
          ...sidebarFormValuesRelevantForHints,
        }}
        onClickPublicationAction={() => openSidebar('publication')}
        onClickPictureAction={() => openSidebar('picture')}
        onClickSmarttagAction={() => openSidebar('smarttags')}
        onClickUrlAction={() => openSidebar('publication')}
      />
      <Field
        component={FormField}
        type="theselect"
        name="doctype"
        label={__('Typ dokumentu')}
        options={Object.keys(doctypesById).map((id) => ({
          value: id,
          label: doctypesById[id].title,
        }))}
        format={(value: string = '') => {
          if (value in doctypesById) {
            return {
              value,
              label: doctypesById[value].title,
            };
          }
          return null;
        }}
        parse={(value: any) => {
          if (value) {
            return value.value;
          }
          return '';
        }}
      />
      <Field
        name="perex"
        label={__('Perex')}
        type="textarea"
        component={FormField}
        counter={{ characters: true, words: true }}
      />
    </>
  );
};

export default compose(
  reduxForm<IFormData, IOwnProps>({
    form: FORM_NAME,
  }),
  connect(mapStateToProps),
)(C);
