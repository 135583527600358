import { CommitError, commit } from '@sportnet/redux-list';
import { CustomThunkDispatch } from '../../configureStore';
import { loadAll } from '../../containers/StaticContent/actions';
import constants from './constants';

export function load() {
  return (dispatch: CustomThunkDispatch) => {
    dispatch(
      commit.action({
        listName: constants.LIST_NAME,
        load: async () => {
          try {
            const res = await dispatch(loadAll.action());
            return {
              total: 0,
              results: res.map((a) => a.cid),
              nextOffset: null,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
}
