import * as React from 'react';
import { __ } from '../../utilities/';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import DoctypeList from '../../containers/DoctypeList';

interface IOwnProps {}

type Props = IOwnProps;

const C: React.FC<Props> = () => {
  return (
    <>
      <AppContext title={__('Typy dokumentov pre články')} />
      <DoctypeList />
    </>
  );
};

export default C;
