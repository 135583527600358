// import { setTimeout } from 'timers/promises';
import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { IArticleSidebarSegmentType } from '../../containers/ArticleSidebar/fields';
import { IContent } from '@sportnet/content/utilities/library';
import {
  IFormData as ISidebarFormData,
  FORM_NAME as SIDEBAR_FORM_NAME,
} from '../../containers/ArticleSidebar/form';
import { IWidgets, Writeable } from '../../library/App';
import { Link } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { RouteComponentProps } from 'react-router';
import { __, prepareArticleToSave } from '../../utilities/';
import { appSetupSelector } from '../App/selectors';
import {
  change,
  getFormValues,
  initialize,
  isDirty,
  isValid,
} from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  contentSelector,
  detailErrorSelector,
  detailIsFetchingSelector,
  detailSelector,
} from '../../containers/Article/selectors';
import {
  deleteArticle,
  deleteArticleDraft,
  loadDetail,
  saveArticle,
  saveArticleDraft,
} from '../../containers/Article/actions';
import { doctypesSelector } from '../App/selectors';
import { getProp, objectToParams, paramsToObject } from '@sportnet/utilities';
import { saveArticleErrorHandler } from '../../containers/Article/errorHandler';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { translateSportGroundAdditionalProperties } from '@sportnet/content/view/widgets/RaceMatchesList/utilities';
import Api from '../../Api';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import ArticleLocalContentContext from './ArticleLocalContentContext';
import ArticleSidebar from '../../containers/ArticleSidebar';
import BasicInfoForm, { FORM_NAME, IFormData } from './BasicInfoForm';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import DraftManager from './DraftManager';
import FeedsApi from '../../FeedsApi';
import FetchMetadataModal from '../../containers/FetchMetadataModal';
import HeaderBar from '@sportnet/ui/HeaderBar';
import NotFound from '@sportnet/ui/NotFound';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SubmitButton from '@sportnet/ui/Button/Submit';
import WidgetsEditor from '../../containers/WidgetsEditor';
import config from '../../config';
import getAllUniqueSmartTagsFromAllPhotogalleries from '../../utilities/getAllUniqueSmartTagsFromAllPhotogalleries';
import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
import useSidebar from './useSidebar';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        outerHeight: window.outerHeight,
        outerWidth: window.outerWidth,
      });
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

function useIsResponsiveLayout(breakpoint = 768) {
  const resizeHook = useWindowSize();

  const mobileLayout = React.useMemo(() => {
    return resizeHook.innerWidth ? resizeHook.innerWidth <= breakpoint : false;
  }, [resizeHook.innerWidth, breakpoint]);

  return mobileLayout;
}

type RouteProps = RouteComponentProps<{ id: string }>;

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const articleId = Number(
    getProp((props.match.params.id || '').split('?'), [0]),
  );
  return {
    detail: detailSelector(articleId)(state),
    doctypes: doctypesSelector(state),
    content: contentSelector(articleId)(state),
    error: detailErrorSelector(articleId)(state),
    isDirty: isDirty(FORM_NAME)(state),
    isSidebarFormDirty: isDirty(SIDEBAR_FORM_NAME)(state),
    formValues: getFormValues(FORM_NAME)(state) as IFormData | undefined,
    sidebarFormValues: getFormValues(SIDEBAR_FORM_NAME)(state) as
      | ISidebarFormData
      | undefined,
    appSettings: appSetupSelector(state),
    isFetching: detailIsFetchingSelector(articleId)(state),
    isSidebarFormValid: isValid(SIDEBAR_FORM_NAME)(state),
  };
};

const mapDispatchToProps = {
  initialize,
  loadDetail: loadDetail.action,
  saveArticle: thunkToAction(saveArticle.action),
  deleteArticle: deleteArticle.action,
  deleteArticleDraft: deleteArticleDraft.action,
  change,
  saveArticleDraft: thunkToAction(saveArticleDraft.action),
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  WithPopupsProps;

const C: React.FC<Props> = (props) => {
  const {
    initialize,
    isFetching,
    confirm,
    match,
    loadDetail,
    detail,
    error,
    saveArticle,
    alert,
    content,
    isDirty,
    history,
    isSidebarFormDirty,
    sidebarFormValues,
    formValues,
    deleteArticle,
    deleteArticleDraft,
    change,
    saveArticleDraft,
    location,
    appSettings,
    doctypes,
    location: { search },
    isSidebarFormValid,
  } = props;

  const { query } = useQuery(
    search,
    (serializedQuery) => {
      history.push({
        search: serializedQuery,
      });
    },
    {
      parameters: {
        doctype: StringParam((doctypes || []).length ? doctypes[0].id : ''),
      },
    },
  );

  const articleId = Number(getProp((match.params.id || '').split('?'), [0]));

  // is submiting
  const [isSubmittingPublish, setIsSubmittingPublish] = React.useState(false);

  const [isSubmittingPublishNewConcept, setIsSubmittingPublishNewConcept] =
    React.useState(false);

  const [
    isSubmittingPublishExistingConcept,
    setIsSubmittingPublishExistingConcept,
  ] = React.useState(false);

  const [
    isSubmittingPublishUpdateExisting,
    setIsSubmittingPublishUpdateExisting,
  ] = React.useState(false);

  const [
    isSubmittingRemovePublishedExsiting,
    setIsSubmittingRemovePublishedExsiting,
  ] = React.useState(false);

  // submit errors
  const [submitErrorPublish, setSubmitErrorPublish] = React.useState(false);

  const [submitErrorPublishNewConcept, setSubmitErrorPublishNewConcept] =
    React.useState(false);

  const [
    submitErrorPublishExistingConcept,
    setSubmitErrorPublishExistingConcept,
  ] = React.useState(false);

  const [
    submitErrorPublishUpdateExisting,
    setSubmitErrorPublishUpdateExisting,
  ] = React.useState(false);

  const [
    submitErrorRemovePublishedExsiting,
    setSubmitErrorRemovePublishedExsiting,
  ] = React.useState(false);

  const [isDuplicateFetching, setIsDuplicateFetching] = React.useState(false);

  // other states
  const mobileLayout = useIsResponsiveLayout();
  const { close, isOpened, open, openedSegment } = useSidebar(
    mobileLayout ? !articleId : true,
  );
  const [localContent, setLocalContent] = React.useState<IWidgets | undefined>(
    articleId ? undefined : [],
  );

  const [isConceptSaved, setIsConceptSaved] = React.useState(false);
  const [isArticlePublished, setIsArticlePublished] = React.useState(false);

  const [isLocalContentDirty, setIsLocalContentDirty] = React.useState(false);
  const [fetchMetadataModalOpened, setFetchMetadataModalOpened] =
    React.useState(false);

  const [locks, setLocks] = React.useState([] as any);
  const [editEnabled, setEditEnabled] = React.useState(false);

  const { doctype: doctypeId } = formValues ?? { doctype: '' };
  const doctype = React.useMemo(() => {
    return doctypes.find((d) => d.id === doctypeId);
  }, [doctypeId, doctypes]);

  const timer = React.useRef<number | null>();

  const lockArticle = React.useCallback(async () => {
    try {
      const { locks, editEnabled } = await Api.lockArticle(
        appSettings.appId,
        appSettings.appSpace,
        appSettings.contentDivider,
        articleId,
      );
      setLocks([...locks]);
      setEditEnabled(editEnabled || false);
    } catch (e) {
      console.error(e);
    } finally {
      timer.current = window.setTimeout(
        lockArticle,
        config.ARTICLE_LOCK_UPDATE_INTERVAL,
      );
    }
  }, [
    appSettings.appId,
    appSettings.appSpace,
    appSettings.contentDivider,
    articleId,
  ]);

  React.useEffect(() => {
    if (!articleId) {
      return;
    }
    lockArticle();
    return () => {
      // is fired on component unmount
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [articleId, lockArticle]);

  React.useEffect(() => {
    if (doctypeId === 'photogallery') {
      change(
        SIDEBAR_FORM_NAME,
        'smarttags',
        getAllUniqueSmartTagsFromAllPhotogalleries(
          (localContent ?? []) as IContent,
        ),
      );
    }
  }, [doctypeId, localContent]);

  React.useEffect(() => {
    return () => {
      Api.deleteArticleLock(
        appSettings.appId,
        appSettings.appSpace,
        appSettings.contentDivider,
        articleId,
      ).catch((e) => console.log(e));
    };
  }, [
    appSettings.appId,
    appSettings.appSpace,
    appSettings.contentDivider,
    articleId,
  ]);

  React.useEffect(() => {
    const onbeforeunloadFn = () => {
      Api.deleteArticleLock(
        appSettings.appId,
        appSettings.appSpace,
        appSettings.contentDivider,
        articleId,
      ).catch((e) => console.log(e));
    };
    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [
    appSettings.appId,
    appSettings.appSpace,
    appSettings.contentDivider,
    articleId,
  ]);

  // Pri zovelni doctypeu sa do obsahu vlozia defaultne widgety (ak ich ma doctype nastavene).
  // Defautne widgety sa do obsahu doplnia len vtedy, ak je to novy clanok (bez ID) a ak este clanok nema vlozene
  // ziadne widgety
  React.useEffect(() => {
    if (articleId || !doctype) {
      return;
    }
    const { defaultWidgets } = doctype;
    if (defaultWidgets && defaultWidgets.length > 0) {
      setLocalContent((prev) => {
        if (!prev) {
          return [...defaultWidgets];
        }

        if (prev.length > 0) {
          alert(
            'Prednastavené widgety pre doctype budú vložené do obsahu na koniec!',
          );
        }

        const newState = [...prev];
        newState.push(...defaultWidgets);
        return newState;
      });
    }
  }, [doctype, articleId, setLocalContent, alert]);

  // ak je v url ?section_id=id, a ide o novy clanok, nastavi sa toto id ako parent sekcia
  const [sectionIdSet, setSectionIdSet] = React.useState(false);
  const { section_id, ...searchParams } = paramsToObject(
    location.search.slice(1),
  );
  React.useEffect(() => {
    if (section_id && !sectionIdSet && !articleId) {
      change(SIDEBAR_FORM_NAME, 'sectionid', [Number(section_id)]);
      setSectionIdSet(true);
    }
    // eslint-disable-next-line
  }, [section_id, articleId, change]);

  React.useEffect(() => {
    if (articleId) {
      loadDetail({ articleId });
    }
  }, [loadDetail, articleId]);

  React.useEffect(() => {
    if (detail && articleId) {
      if (detail.draft) {
        setIsConceptSaved(true);
      }
      if (!detail.draft) {
        setIsArticlePublished(true);
      }
    }
  }, [detail, articleId]);

  React.useEffect(() => {
    if (detail) {
      initialize(FORM_NAME, {
        name: detail.name,
        doctype: detail.doctype,
        perex: detail.perex,
      } as IFormData);
    }
  }, [detail, initialize]);

  React.useEffect(() => {
    if (content) {
      setLocalContent(content);
    }
  }, [content]);

  const getSearchParams = () => {
    const query = objectToParams(searchParams);
    return query ? '?' + query : '';
  };

  const availableArticleSegments = React.useMemo(() => {
    const segments: IArticleSidebarSegmentType[] = [
      'publication',
      'auth',
      'picture',
      'sections',
      'smarttags',
      'articleLayouts',
      'authors',
    ];

    if (appSettings.params.previewUrlPattern) {
      segments.push('preview');
    }
    if (articleId && appSettings.params.articleUrlPattern) {
      segments.push('feeds');
    }
    return segments;
  }, [
    appSettings.params.previewUrlPattern,
    appSettings.params.articleUrlPattern,
    articleId,
  ]);

  const isFormDirty =
    (isDirty || isSidebarFormDirty || isLocalContentDirty) && !error;

  // new article or existing article - publish with existing valid_from from form or set valid_from to actual Date if not set in form
  const handleSubmitPublish = async () => {
    const editedSidebarFormValues: any = { ...(sidebarFormValues || {}) };

    if (!editedSidebarFormValues.valid_from) {
      editedSidebarFormValues.valid_from = new Date();
    }

    const articleData: Partial<ISidebarFormData> &
      IFormData & { widgets: Writeable<IWidgets> } = {
      ...editedSidebarFormValues,
      ...formValues,
      widgets: localContent as Writeable<IWidgets>,
    };

    try {
      setSubmitErrorPublish(false);
      setIsSubmittingPublish(true);
      const savedArticle = await saveArticle({
        articleId,
        values: prepareArticleToSave(articleData),
      });
      if (!articleId) {
        history.push(
          `/articles/detail/${savedArticle._id}${getSearchParams()}`,
        );
      }

      setIsSubmittingPublish(false);
      setIsLocalContentDirty(false);
    } catch (e) {
      setSubmitErrorPublish(true);
      setIsSubmittingPublish(false);
      await saveArticleErrorHandler(e, alert);
    }

    setIsArticlePublished(true);

    if (isConceptSaved) {
      setIsConceptSaved(false);
    }
  };

  // new article - save as concept with valid_from as null
  const handleSubmitPublishNewConcept = async () => {
    const editedSidebarFormValues: any = { ...(sidebarFormValues || {}) };
    editedSidebarFormValues.valid_from = null;

    const articleData: Partial<ISidebarFormData> &
      IFormData & { widgets: Writeable<IWidgets> } = {
      ...editedSidebarFormValues,
      ...formValues,
      widgets: localContent as Writeable<IWidgets>,
    };

    try {
      setSubmitErrorPublishNewConcept(false);
      setIsSubmittingPublishNewConcept(true);

      const savedArticle = await saveArticle({
        articleId,
        values: prepareArticleToSave(articleData),
      });

      if (!articleId) {
        history.push(
          `/articles/detail/${savedArticle._id}${getSearchParams()}`,
        );
      }

      setIsSubmittingPublishNewConcept(false);
      setIsLocalContentDirty(false);
    } catch (e) {
      setSubmitErrorPublishNewConcept(true);
      setIsSubmittingPublishNewConcept(false);
      await saveArticleErrorHandler(e, alert);
    }

    setIsConceptSaved(true);

    if (isArticlePublished) {
      setIsArticlePublished(false);
    }
  };

  // existing article - save as concept with valid from as null
  const handleSubmitPublishExistingConcept = async () => {
    const editedSidebarFormValues: any = { ...(sidebarFormValues || {}) };

    const articleData: Partial<ISidebarFormData> &
      IFormData & { widgets: Writeable<IWidgets> } = {
      ...editedSidebarFormValues,
      ...formValues,
      widgets: localContent as Writeable<IWidgets>,
    };

    try {
      setSubmitErrorPublishExistingConcept(false);
      setIsSubmittingPublishExistingConcept(true);

      await saveArticleDraft({
        values: prepareArticleToSave(articleData),
        articleId,
      });

      setIsSubmittingPublishExistingConcept(false);
      setIsLocalContentDirty(false);
    } catch (e) {
      setSubmitErrorPublishExistingConcept(true);
      setIsSubmittingPublishExistingConcept(false);
      await saveArticleErrorHandler(e, alert);
    }

    setIsConceptSaved(true);

    if (isArticlePublished) {
      setIsArticlePublished(false);
    }
  };

  // existing article - valid_from + updated_at
  const handleSubmitPublishUpdateExsiting = async () => {
    const editedSidebarFormValues: any = { ...(sidebarFormValues || {}) };

    if (!editedSidebarFormValues.valid_from) {
      editedSidebarFormValues.valid_from = new Date();
    }

    editedSidebarFormValues.updated_at = new Date();

    const articleData: Partial<ISidebarFormData> &
      IFormData & { widgets: Writeable<IWidgets> } = {
      ...editedSidebarFormValues,
      ...formValues,
      widgets: localContent as Writeable<IWidgets>,
    };

    try {
      setSubmitErrorPublishUpdateExisting(false);
      setIsSubmittingPublishUpdateExisting(true);
      const savedArticle = await saveArticle({
        articleId,
        values: prepareArticleToSave(articleData),
      });
      if (!articleId) {
        history.push(
          `/articles/detail/${savedArticle._id}${getSearchParams()}`,
        );
      }

      setIsSubmittingPublishUpdateExisting(false);
      setIsLocalContentDirty(false);
    } catch (e) {
      setSubmitErrorPublishUpdateExisting(true);
      setIsSubmittingPublishUpdateExisting(false);
      await saveArticleErrorHandler(e, alert);
    }

    setIsArticlePublished(true);

    if (isConceptSaved) {
      setIsConceptSaved(false);
    }
  };

  // existing article - remove date of publication
  const handleSubmitRemovePublishedExsiting = async () => {
    const editedSidebarFormValues: any = { ...(sidebarFormValues || {}) };

    if (editedSidebarFormValues.valid_from) {
      editedSidebarFormValues.valid_from = null;
    }

    const articleData: Partial<ISidebarFormData> &
      IFormData & { widgets: Writeable<IWidgets> } = {
      ...editedSidebarFormValues,
      ...formValues,
      widgets: localContent as Writeable<IWidgets>,
    };

    try {
      setSubmitErrorRemovePublishedExsiting(false);
      setIsSubmittingRemovePublishedExsiting(true);
      const savedArticle = await saveArticle({
        articleId,
        values: prepareArticleToSave(articleData),
      });

      if (!articleId) {
        history.push(
          `/articles/detail/${savedArticle._id}${getSearchParams()}`,
        );
      }

      setIsSubmittingRemovePublishedExsiting(false);
      setIsLocalContentDirty(false);
    } catch (e) {
      setSubmitErrorRemovePublishedExsiting(true);
      setIsSubmittingRemovePublishedExsiting(false);
      await saveArticleErrorHandler(e, alert);
    }

    setIsArticlePublished(true);
    setIsConceptSaved(true);
  };

  async function handleDelete() {
    if (!detail) {
      return;
    }
    if (await confirm(__('Naozaj chcete odstrániť tento článok?'))) {
      try {
        const { feeditems } = await FeedsApi.getItemsInFeedsFromApp(
          appSettings.appId,
          appSettings.appSpace,
          articleId.toString(),
        );

        if ((feeditems || []).length > 0) {
          const requests = feeditems.map((feedItem: any) => {
            return FeedsApi.deleteFeedItemFromApp(
              feedItem.appId,
              feedItem.appSpace,
              feedItem.feedId,
              feedItem.itemId,
            );
          });

          try {
            await Promise.all(requests);
            await deleteArticle({ articleId: detail._id! });
            history.push(`/articles${getSearchParams()}`);
          } catch (e) {
            console.error(e);
          }
        } else {
          await deleteArticle({ articleId: detail._id! });
          history.push(`/articles${getSearchParams()}`);
        }
      } catch (e) {
        await alert(__('Článok nie je možné odstrániť'));
      }
    }
  }

  async function handleDeleteDraft() {
    await deleteArticleDraft({ articleId });
    await loadDetail({ articleId });
  }

  async function duplicateArticle() {
    try {
      setIsDuplicateFetching(true);

      const article = await Api.postDuplicatedArticle(
        appSettings.appId,
        appSettings.appSpace,
        appSettings.contentDivider,
        articleId,
      );

      history.push(`/articles/detail/${article._id}${getSearchParams()}`);
    } catch (e) {
      await alert(__('Článok nie je možné duplikovať'));
    } finally {
      setIsDuplicateFetching(false);
    }
  }

  function renderContent() {
    if (error) {
      return (
        <NotFound
          title={__('Detail článku nie je možné načítať.')}
          icon="error"
          actionLabel={__('Späť na všetky články')}
          onClickAction={() => {
            history.push(`/articles${getSearchParams()}`);
          }}
        />
      );
    }
    if (
      isFetching ||
      (articleId &&
        (typeof detail === 'undefined' || typeof content === 'undefined'))
    ) {
      return <ContentLoader />;
    }

    return (
      <>
        <Segment raised>
          <BasicInfoForm
            article={detail}
            sidebarIsOpened={isOpened}
            openSidebar={open}
            closeSidebar={close}
            onDeleteDraft={handleDeleteDraft}
            locks={locks}
            initialValues={{
              doctype: query.doctype,
            }}
          />
        </Segment>
        <WidgetsEditor
          key={`widgets-editor-${articleId ? articleId : doctypeId}`}
          value={localContent}
          onChange={(nextValue) => {
            setLocalContent(nextValue);
            setIsLocalContentDirty(true);
          }}
          context={{
            currentArticleId: detail?._id,
            helpers: {
              translateSportGroundAdditionalProperties,
            },
          }}
        />
      </>
    );
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to={
              searchParams.from
                ? `/${searchParams.from}/${getSearchParams()}`
                : `/${getSearchParams()}`
            }
            title={__('Späť na zoznam')}
          />
          <HeaderBar.Header>
            {articleId ? __('Detail článku') : __('Nový článok')}
          </HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Button
              danger
              basic
              disabled={!!error || !editEnabled}
              onClick={handleDelete}
            >
              {__('Odstrániť')}
            </Button>
          </ContextBarItem>
          <ContextBarItem>
            <Button
              onClick={() => {
                setFetchMetadataModalOpened(true);
              }}
            >
              {__('Vyplniť z URL')}
            </Button>
          </ContextBarItem>
          <ContextBarItem>
            <Button
              onClick={() => {
                duplicateArticle();
              }}
              loading={isDuplicateFetching}
              disabled={isDuplicateFetching}
            >
              {__('Duplikovať')}
            </Button>
          </ContextBarItem>
          <ContextBarSpacer />

          {articleId ? (
            <>
              <ContextBarItem>
                <SubmitButton
                  successText={__('Koncept uložený...')}
                  isSubmitting={isSubmittingPublishExistingConcept}
                  isError={submitErrorPublishExistingConcept}
                  onClick={() => {
                    handleSubmitPublishExistingConcept();
                  }}
                  icon="write"
                  disabled={!editEnabled || !isSidebarFormValid}
                >
                  {__('Uložiť koncept')}
                </SubmitButton>
              </ContextBarItem>
              <ContextBarItem>
                <SubmitButton
                  successText={__('Publikované...')}
                  isSubmitting={isSubmittingPublish}
                  isError={submitErrorPublish}
                  onClick={() => {
                    handleSubmitPublish();
                  }}
                  icon="check"
                  disabled={!editEnabled || !isSidebarFormValid}
                >
                  {__('Publikovať')}
                </SubmitButton>
              </ContextBarItem>
              <ContextBarItem>
                <SubmitButton
                  successText={__('Aktualizácia publikovaná...')}
                  isSubmitting={isSubmittingPublishUpdateExisting}
                  isError={submitErrorPublishUpdateExisting}
                  onClick={() => {
                    handleSubmitPublishUpdateExsiting();
                  }}
                  icon="check-circle"
                  disabled={!editEnabled || !isSidebarFormValid}
                >
                  {__('Publikovať s aktualizáciou')}
                </SubmitButton>
              </ContextBarItem>
              {detail && detail.valid_from && (
                <ContextBarItem>
                  <SubmitButton
                    successText={__('Publikácia zrušená...')}
                    isSubmitting={isSubmittingRemovePublishedExsiting}
                    isError={submitErrorRemovePublishedExsiting}
                    disabled={!!error || !editEnabled}
                    onClick={() => {
                      handleSubmitRemovePublishedExsiting();
                    }}
                    icon="trash"
                  >
                    {__('Zrušiť publikáciu')}
                  </SubmitButton>
                </ContextBarItem>
              )}
            </>
          ) : (
            <>
              <ContextBarItem>
                <SubmitButton
                  successText={__('Koncept uložený...')}
                  isSubmitting={isSubmittingPublishNewConcept}
                  isError={submitErrorPublishNewConcept}
                  onClick={() => {
                    handleSubmitPublishNewConcept();
                  }}
                  icon="write"
                >
                  {__('Uložiť koncept')}
                </SubmitButton>
              </ContextBarItem>
              <ContextBarItem>
                <SubmitButton
                  successText={__('Publikované...')}
                  isSubmitting={isSubmittingPublish}
                  isError={submitErrorPublish}
                  onClick={() => {
                    handleSubmitPublish();
                  }}
                  disabled={!editEnabled}
                >
                  {__('Publikovať')}
                </SubmitButton>
              </ContextBarItem>
            </>
          )}
        </ContextBar>
      }
    >
      <AppContext
        title={__('Detail článku')}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: '/',
          },
        ]}
      />

      <Segment>{renderContent()}</Segment>

      <ArticleLocalContentContext.Provider value={localContent || []}>
        <ArticleSidebar
          opened={isOpened}
          onClose={close}
          article={detail}
          openedSegment={openedSegment}
          availableSegments={availableArticleSegments}
          showControlButtons={false}
        />
      </ArticleLocalContentContext.Provider>

      <DraftManager
        articleId={articleId}
        localContent={localContent}
        isFormDirty={isFormDirty}
        handleIsConceptSaved={() => setIsConceptSaved(true)}
        handleIsSubmitting={(value: boolean) =>
          setIsSubmittingPublishExistingConcept(value)
        }
      />

      {fetchMetadataModalOpened && (
        <FetchMetadataModal
          opened={fetchMetadataModalOpened}
          onClose={() => {
            setFetchMetadataModalOpened(false);
          }}
          onSubmit={(submitData) => {
            if (submitData['title']) {
              change(FORM_NAME, 'name', submitData['title']);
            }
            if (submitData['description']) {
              change(FORM_NAME, 'perex', submitData['description']);
            }
            if (submitData['picture']) {
              change(SIDEBAR_FORM_NAME, 'picture', submitData['picture']);
            }
            setFetchMetadataModalOpened(false);
          }}
        />
      )}
    </ScrollLayout>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(C);
