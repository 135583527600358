import * as React from 'react';
import { FORM_NAME, IFormData } from '../../pages/Article/BasicInfoForm';
import {
  IFormData as ISidebarFormData,
  FORM_NAME as SIDEBAR_FORM_NAME,
} from '../../containers/ArticleSidebar/form';
import { IWidgets, Writeable } from '../../library/App';
import { RootState } from '../../rootReducer';
import { __, prepareArticleToSave } from '../../utilities/';
import { appSetupSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { savePreview } from './actions';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import ArticleLocalContentContext from '../../pages/Article/ArticleLocalContentContext';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import addSeconds from 'date-fns/addSeconds';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const validityOptions = [
  { value: 600, label: __('10 minút') },
  { value: 3600, label: __('1 hodina') },
  { value: 43200, label: __('12 hodín') },
  { value: 86400, label: __('24 hodín') },
  { value: 604800, label: __('1 týždeň') },
];

const mapStateToProps = (state: RootState) => {
  return {
    formValues: getFormValues(FORM_NAME)(state) as IFormData | undefined,
    sidebarFormValues: getFormValues(SIDEBAR_FORM_NAME)(state) as
      | ISidebarFormData
      | undefined,
    appSetup: appSetupSelector(state),
  };
};

const mapDispatchToProps = {
  savePreview: thunkToAction(savePreview.action),
};

interface IOwnProps {}

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithPopupsProps;

const Preview: React.FC<IProps> = (props) => {
  const { formValues, sidebarFormValues, savePreview, appSetup, alert } = props;

  const localContent = React.useContext(ArticleLocalContentContext);
  const [validity, setValidity] = React.useState(600);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSavePreview = async () => {
    setIsLoading(true);
    try {
      const articleData: Partial<ISidebarFormData> &
        IFormData & { widgets: Writeable<IWidgets> } = {
        ...(sidebarFormValues || {}),
        ...((formValues || {}) as IFormData),
        widgets: localContent as Writeable<IWidgets>,
      };

      const response = await savePreview({
        values: {
          type: 'article',
          valid_to: addSeconds(new Date(), validity).toJSON(),
          data: prepareArticleToSave(articleData),
        },
      });

      const { previewUrlPattern } = appSetup.params;

      if (previewUrlPattern) {
        window.open(previewUrlPattern.replace('{{previewId}}', response._id));
      }
    } catch (e) {
      alert(__('Vytvorenie náhľadu sa nepodarilo'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormField
        label={__('Platnosť náhľadu')}
        type="theselectsimple"
        value={validity}
        onChange={setValidity}
        options={validityOptions}
        clearable={false}
      />
      <Button
        basic
        block
        type="button"
        onClick={handleSavePreview}
        loading={isLoading}
      >
        {__('Vytvoriť náhľad')}
      </Button>
    </>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(Preview);
