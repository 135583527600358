import * as React from 'react';
import { Article } from '../../Api';
import { compareAsc, format } from 'date-fns';
import { getProp } from '@sportnet/utilities';
import { rem } from 'polished';
import Link from '@sportnet/ui/Link';
import Message from '@sportnet/ui/Message';
import config from '../../config';
import styled from '../../theme/styled-components';

interface OwnProps {
  article: Article;
  onClickPublicationAction?: () => void;
  onClickPictureAction?: () => void;
  onClickSmarttagAction?: () => void;
  onClickUrlAction?: () => void;
}

const Wrapper = styled('div')`
  margin-bottom: ${({ theme }) => rem(theme.grid.gutterWidth)};
  button {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const C: React.FC<OwnProps> = ({
  article,
  onClickPictureAction,
  onClickPublicationAction,
  onClickSmarttagAction,
  onClickUrlAction,
}) => {
  const messages = [];

  if (!article.valid_from) {
    messages.push(
      <Message warning key="no_publication">
        Článok <strong>nemá</strong> nastavenú publikáciu.{' '}
        <Link primary onClick={onClickPublicationAction}>
          Nastaviť publikáciu
        </Link>
      </Message>,
    );
  } else {
    const now = new Date();
    const validFrom = new Date(article.valid_from);

    if (compareAsc(validFrom, now) === 1) {
      messages.push(
        <Message warning key="later_valid_from">
          Článok <strong>sa nezobrazuje</strong>. Publikácia je nastavená od{' '}
          <strong>{format(validFrom, config.DATETIME_FORMAT)}</strong>.{' '}
          <Link onClick={onClickPublicationAction}>Zmeniť publikáciu</Link>
        </Message>,
      );
    }
  }

  if (!getProp(article, ['picture'])) {
    messages.push(
      <Message warning key="no_picture">
        Článok <strong>nemá</strong> nastavený titulný obrázok.{' '}
        <Link onClick={onClickPictureAction}>Nastaviť titulný obrázok</Link>
      </Message>,
    );
  }

  if (!article.url) {
    messages.push(
      <Message warning key="no_url">
        Článok <strong>nemá</strong> nastavenú peknú url.{' '}
        <Link onClick={onClickUrlAction}>Nastaviť url</Link>
      </Message>,
    );
  }

  if (!article.smarttags || article.smarttags.length === 0) {
    messages.push(
      <Message warning key="no_smarttags">
        Článok <strong>nemá</strong> nastavené smarttagy.{' '}
        <Link onClick={onClickSmarttagAction}>Nastaviť smarttagy</Link>
      </Message>,
    );
  }

  return <Wrapper>{messages}</Wrapper>;
};

export default C;
