import { ExtraArgumentType } from '../../configureStore';
import { IPreviewSave } from '../../library/App';
import { Preview } from '../../Api';
import { RootState } from '../../rootReducer';
import { appSetupSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.ARTICLES_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const savePreview = createAsync<{ values: IPreviewSave }, Preview>(
  'SAVE',
  async ({ values }, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());

    const response = await Api.postPreview(
      appId,
      appSpace,
      contentDivider,
      {},
      values,
    );

    return response;
  },
);
