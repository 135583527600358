import * as React from 'react';
import { IOption } from '@sportnet/ui/TheSelect/types';
import { IPublicationGroups, User_Groups_List } from '../../library/App';
import { WrappedFieldProps } from 'redux-form';
import { __ } from '../../utilities/';
import Checkbox from '@sportnet/ui/Checkbox';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import Message from '@sportnet/ui/Message';
import Select from '@sportnet/ui/TheSelect';

interface OwnProps extends WrappedFieldProps {
  label?: string;
  groups: User_Groups_List;
}

type Props = OwnProps;

/**
 * `null` - checkbox unchecked, content is public
 *
 * **empty** `array` - checkbox checked, content is only available to logged in users
 *
 * **non empty** `array` - checkbox checked, content is only available for users assigned to selected groups
 */
export type IPublicationGroupsValue = IPublicationGroups | null;

const C: React.FC<Props> = ({ input, groups, label }) => {
  const value = (input.value || null) as IPublicationGroupsValue;

  function handleChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      input.onChange([]);
    } else {
      input.onChange(null);
    }
  }

  function handleChangeSelect(nextValue: IOption[]) {
    input.onChange(nextValue.map((v) => v.value));
  }

  const options = React.useMemo(() => {
    return groups.map((g) => ({ label: g.name, value: g._id }));
  }, [groups]);

  const selectedOptions = value
    ? options.filter((o) => value.includes(o.value))
    : [];

  const isPrivate = value !== null;
  return (
    <>
      <FormGroup>{label && <Label>{label}</Label>}</FormGroup>
      <FormGroup>
        <Label>
          <Checkbox checked={isPrivate} onChange={handleChangeCheckbox} />
          {__('Viditeľné len pre prihlásených')}
        </Label>
        {isPrivate && selectedOptions.length === 0 && (
          <Message warning>
            {__(
              'Ak nevyberiete skupinu, obsah bude dostupný všetkým prihláseným používateľom',
            )}
          </Message>
        )}
      </FormGroup>
      <FormGroup>
        <Label>{__('Skupiny s prístupom')}</Label>
        <Select
          disabled={!isPrivate}
          value={selectedOptions}
          onChange={handleChangeSelect}
          multiple
          options={options}
        />
      </FormGroup>
    </>
  );
};

export default C;
