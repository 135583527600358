import { IContent } from '@sportnet/content/utilities/library';
import { Smarttag } from '../Api';
import getUniqueSmarttags from './getUniqueSmarttags';

/**
 * Prechadza widgety clnkov a zo vsetlych fotogalerii vytiahne vsetky smart:tagy a na konci
 * vrati unikatnu mnozinu vsetkych smart:tagov;
 * @param widgets
 * @returns
 */
const getAllUniqueSmartTagsFromAllPhotogalleries = (
  widgets: IContent,
): Smarttag[] => {
  const allSmartTgsFromAllPhotogalleries = widgets.reduce(
    (acc: Smarttag[], widget) => {
      if (widget.type === 'photogallery') {
        const smartTags = (widget.pictures ?? []).reduce(
          (accPictureSmarttags: Smarttag[], picture) => {
            if (!!picture.smarttags && picture.smarttags.length > 0) {
              accPictureSmarttags.push(...(picture.smarttags as Smarttag[]));
            }
            return accPictureSmarttags;
          },
          [],
        );
        acc.push(...smartTags);
      }
      return acc;
    },
    [],
  );

  return getUniqueSmarttags(allSmartTgsFromAllPhotogalleries);
};

export default getAllUniqueSmartTagsFromAllPhotogalleries;
