import * as React from 'react';
import { Article, Smarttag } from '../../Api';
import { ArticleId, SectionId } from '../../library/App';
import {
  InjectedFormProps,
  change,
  getFormValues,
  reduxForm,
} from 'redux-form';
import { RootState } from '../../rootReducer';
import { __ } from '../../utilities/';
import {
  appSetupSelector,
  childAppRoleSelector,
} from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loadUserGroups, loadUsersWithRoles } from '../../pages/App/actions';
import { rem } from 'polished';
import { slugify } from '@sportnet/utilities';
import { smarttagsToUrl } from '@sportnet/tagmanager-connector';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import Fields, { FORM_NAME, IArticlesUpdateSidebarSegmentType } from './fields';
import Message from '@sportnet/ui/Message';
import Segment from '@sportnet/ui/Segment';
import styled from 'styled-components';

const mapDispatchToProps = {
  loadUserGroups: loadUserGroups.action,
  loadUsersWithRoles: loadUsersWithRoles.action,
  changeField: change,
};

const WarningsWrapper = styled.div`
  padding: ${rem(12)};
`;

export interface IFormData {
  articleIds: ArticleId[];
  smarttags: Smarttag[];
  sectionid: SectionId[];
  clearSmarttags: boolean;
  clearSections: boolean;
}

type FieldWarning<T> = {
  [Property in keyof T]?: string;
};

const mapStateToProps = (state: RootState) => ({
  appSetup: appSetupSelector(state),
  formValues: getFormValues(FORM_NAME)(state) as IFormData | undefined,
  tagmanagerRole: childAppRoleSelector('tagmanager')(state),
});

interface OwnProps {
  openedSegment?: IArticlesUpdateSidebarSegmentType;
  availableSegments: IArticlesUpdateSidebarSegmentType[];
  sidebarOpened: boolean;
  article?: Article;
  selectedArticleIds: ArticleId[];
  submitting: boolean;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<IFormData>;

const C: React.FC<Props> = ({
  appSetup,
  submitting,
  handleSubmit,
  loadUserGroups,
  loadUsersWithRoles,
  openedSegment,
  sidebarOpened,
  availableSegments,
  formValues,
  selectedArticleIds,
  changeField,
  tagmanagerRole,
}) => {
  const [userWarnings, setUserWarnings] = React.useState<
    FieldWarning<IFormData>
  >({});

  React.useEffect(() => {
    loadUserGroups();
  }, [loadUserGroups]);

  React.useEffect(() => {
    loadUsersWithRoles();
  }, [loadUsersWithRoles]);

  React.useEffect(() => {
    changeField(FORM_NAME, 'articleIds', selectedArticleIds);
  }, [selectedArticleIds, changeField]);

  const {
    clearSmarttags = false,
    clearSections = false,
    smarttags = [],
    sectionid = [],
  } = formValues ?? {};
  const smarttagsString = smarttagsToUrl(smarttags);
  const sectionsString = sectionid.join(',');

  React.useEffect(() => {
    if (clearSmarttags) {
      setUserWarnings((prev) => {
        return {
          ...prev,

          clearSmarttags: __(
            'Článkom sa odstránia všetky nastavené smart:tagy',
          ),
        };
      });
    } else {
      setUserWarnings((prev) => {
        return {
          ...prev,
          clearSmarttags: '',
        };
      });
    }
    if (clearSections) {
      setUserWarnings((prev) => {
        return {
          ...prev,

          clearSections: __('Článkom sa odstránia všetky nastavené sekcie!'),
        };
      });
    } else {
      setUserWarnings((prev) => {
        return {
          ...prev,
          clearSections: '',
        };
      });
    }
  }, [clearSmarttags, clearSections, changeField]);

  React.useEffect(() => {
    if (smarttagsString) {
      setUserWarnings((prev) => {
        return {
          ...prev,
          smarttags: __(
            'Zvolené smart:tagy nahradia všetky nastavené smart:tagy na článkoch!',
          ),
        };
      });
    } else {
      setUserWarnings((prev) => {
        return {
          ...prev,
          smarttags: '',
        };
      });
    }
  }, [smarttagsString]);

  React.useEffect(() => {
    if (sectionsString) {
      setUserWarnings((prev) => {
        return {
          ...prev,
          sectionid: __(
            'Zvolené sekcie nahradia všetky nastavené sekcie na článkoch!',
          ),
        };
      });
    } else {
      setUserWarnings((prev) => {
        return {
          ...prev,
          sectionid: '',
        };
      });
    }
  }, [sectionsString]);

  const disableSaveButton = () => {
    if (formValues) {
      if (
        formValues &&
        (formValues.sectionid.length > 0 ||
          formValues.smarttags.length > 0 ||
          formValues.clearSections ||
          formValues.clearSmarttags) &&
        selectedArticleIds.length > 0
      ) {
        return false;
      }
    }

    return true;
  };

  const getUserWarnings = () => {
    const warnings = (Object.keys(userWarnings) as (keyof IFormData)[]).reduce(
      (acc: string[], key) => {
        if (key in userWarnings && userWarnings[key]) {
          acc.push(userWarnings[key] as string);
        }
        return acc;
      },
      [],
    );
    return (
      <WarningsWrapper>
        {selectedArticleIds.length === 0 && (
          <Message warning>
            <strong>{__('Nie sú označené žiadne články na úpravu!')}</strong>
          </Message>
        )}

        {warnings.map((w) => (
          <Message key={slugify(w)} warning>
            <strong>{w}</strong>
          </Message>
        ))}
      </WarningsWrapper>
    );
  };

  const content = (
    <>
      {getUserWarnings()}
      <Fields
        smarttagsCreatable={tagmanagerRole === 'admin'}
        appSetup={appSetup}
        openedSegment={openedSegment}
        availableSegments={availableSegments}
        sidebarOpened={sidebarOpened}
      />

      <Segment>
        <Row>
          <Col xs={6}>
            <Button
              primary
              block
              type="submit"
              loading={submitting}
              disabled={disableSaveButton()}
            >
              {__('Uložiť')}
            </Button>
          </Col>
        </Row>
        <br />
      </Segment>
    </>
  );

  return <form onSubmit={handleSubmit}>{content}</form>;
};

export default compose(
  reduxForm<IFormData, OwnProps>({
    form: FORM_NAME,
  }),
  connect<{}, {}, OwnProps>(mapStateToProps, mapDispatchToProps),
)(C);
