import { AppPublic } from './../../CoreApi';
import { AppSpaceUser, User_Groups_List } from '../../CoreApi';
import { CustomThunkDispatch, ExtraArgumentType } from '../../configureStore';
import {
  Doctype,
  Listing,
  ListingType,
  Listings_List,
  URLMapUrl,
  URLMap_List,
} from '../../Api';
import { IAppSetup, Writeable } from '../../library/App';
import { RootState } from '../../rootReducer';
import { appSetupSelector, entitiesSelector } from '../../pages/App/selectors';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { updateEntities as updateEntitiesUtility } from '../../utilities/';
import actionCreatorFactory from 'typescript-fsa';
import config from '../../config';

const create = actionCreatorFactory(config.BASE_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadDoctypes = createAsync<void, Doctype[]>(
  'LOAD_DOCTYPES',
  async (params, dispatch, getState, { Api }) => {
    const { appId, appSpace, contentDivider } = appSetupSelector(getState());

    const response = await Api.getDoctypes(appId, appSpace, contentDivider);

    const entities: { [key: string]: Doctype } = {};
    response.forEach((item) => {
      entities[item.id] = item;
    });

    dispatch(
      updateEntities({
        doctypes: entities,
      }),
    );

    return response;
  },
);

export const loadListings = createAsync<
  {
    limit?: number;
    offset?: number;
    type: ListingType;
  },
  Listings_List
>('LOAD_LISTINGS', async (params, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());
  const response = await Api.getListings(
    appId,
    appSpace,
    contentDivider,
    params,
  );

  const entities: { [key: string]: Listing } = {};
  response.listings.forEach((item) => {
    entities[item.listingId!] = { priority: undefined, ...item };
  });

  dispatch(
    updateEntities({
      listings: entities,
    }),
  );

  return response;
});

export const loadUrlmap = createAsync<
  {
    limit?: number;
    offset?: number;
  },
  URLMap_List
>('LOAD_URLMAP', async (params, dispatch, getState, { Api }) => {
  const { appId, appSpace, contentDivider } = appSetupSelector(getState());
  const response = await Api.getUrlmap(appId, appSpace, contentDivider, params);

  const entities: { [key: string]: URLMapUrl } = {};
  response.urlmap.forEach((item) => {
    entities[item.url!] = item;
  });

  dispatch(
    updateEntities({
      urlmap: entities,
    }),
  );

  return response;
});

export const loadUserGroups = createAsync<void, User_Groups_List>(
  'LOAD_USER_GROUPS',
  async (params, dispatch, getState, { CoreApi }) => {
    const appSpace = appSetupSelector(getState()).appSpace;
    const r = await CoreApi.getPublicAppSpaceGroups(appSpace);
    return r.groups!; // @TODO core/api groups je optional
  },
);

export const setup = create<IAppSetup>('SETUP');

export const updateEntities = (
  entitiesToUpdate: Partial<RootState['entities']>,
) => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return (dispatch: CustomThunkDispatch, getState: () => RootState) => {
    const oldEntities = entitiesSelector(getState());
    const nextEntities = updateEntitiesUtility(oldEntities, entities);
    return dispatch({
      type: 'UPDATE_ENTITIES',
      payload: {
        result: {
          entities: nextEntities,
        },
      },
    });
  };
};

export const replaceEntities = (entities: Partial<RootState['entities']>) => {
  return {
    type: 'REPLACE_ENTITIES',
    payload: {
      result: {
        entities,
      },
    },
  };
};

export const loadUsersWithRoles = createAsync<void, Array<AppSpaceUser>>(
  'LOAD_USERS_WITH_ROLES',
  async (params, dispatch, getState, { CoreApi }) => {
    const { appId, appSpace } = appSetupSelector(getState());
    const response = await CoreApi.appgrantGetRoles(appId, appSpace);
    return response.users || [];
  },
);

export const loadAppInfo = createAsync<void, AppPublic>(
  'LOAD_APP_INFO',
  async (params, dispatch, getState, { CoreApi }) => {
    const setup = appSetupSelector(getState());
    const app: AppPublic = await CoreApi.getPublicApp(setup.appId);
    return app;
  },
);

export const loadAppSpaces = createAsync<void, AppSpaceUser[]>(
  'LOAD_APPSPACES',
  async (params, dispatch, getState, { CoreApi }) => {
    const setup = appSetupSelector(getState());
    const result: {
      appspaces?: AppSpaceUser[];
    } = await CoreApi.meAppSpacesForApp(setup.appId);
    const appSpaceInfo = await CoreApi.meAppInfoForAppSpace(
      setup.appId,
      setup.appSpace,
    );
    return ((result.appspaces || []) as any).map((i: any) => {
      if (i.app_space === setup.appSpace) {
        return appSpaceInfo;
      }
      return i;
    });
  },
);
