import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { ListingType } from '../../Api';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { __ } from '../../utilities/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getListNextOffset,
  hasCommitFailed,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { listingsSelector } from './selectors';
import { load } from './actions';
import Button from '@sportnet/ui/Button';
import ContentLoader from '../../components/ContentLoader';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Link from '@sportnet/ui/Link';
import List from '../../components/ListingList';
import ListingsFilter from './Filter';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import Tabber from '@sportnet/ui/Tabber';
import constants from './constants';
import useQuery from '@sportnet/query-hoc/useQuery';

interface IOwnProps {
  type: ListingType;
}

const mapStateToProps = (state: RootState) => ({
  items: listingsSelector(state),
  nextOffset: getListNextOffset(constants.LIST_NAME)(state),
  isLoading: isCommiting(constants.LIST_NAME)(state),
  hasCommitFailed: hasCommitFailed(constants.LIST_NAME)(state),
});

const mapDispatchToProps = {
  load,
  initialize,
  setParams,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps &
  RouteComponentProps;

export const listingTypes: { title: string; type: ListingType }[] = [
  { title: __('Systémové listingy'), type: 'system' },
  { title: __('Článkové listingy'), type: 'article-tabs' },
];

const C: React.FC<Props> = ({
  items,
  load,
  isLoading,
  location: { search, pathname },
  hasCommitFailed,
  initialize,
  type,
  history,
  match,
  nextOffset,
}) => {
  const { type: actualType } = match.params as { type: string };

  const { query, setQuery } = useQuery(
    search,
    (serializedQuery) => {
      history.push({
        search: serializedQuery,
      });
    },
    constants.QUERY_HOC_CONFIG,
  );

  // Initialize redux-list
  React.useEffect(() => {
    if (!actualType) {
      return;
    }
    const parameters = {
      ...query,
      type: actualType,
    };
    initialize({
      listName: constants.LIST_NAME,
      initialParams: parameters,
    });
    setParams({
      listName: constants.LIST_NAME,
      parameters,
    });
    load();
  }, [initialize, query, actualType, load]);

  function render() {
    if (hasCommitFailed) {
      return (
        <NotFound
          title={__('Ups! Nastala chyba pri načítaní')}
          icon="error"
          actionLabel={__('Skúsiť znova')}
          onClickAction={() => load()}
        />
      );
    }

    if (isLoading && items.length === 0) {
      return <ContentLoader />;
    }

    if (items.length === 0) {
      return <NotFound title={__('Žiadne listingy')} icon="search" />;
    }

    return <List items={items} reload={load} />;
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Zoznam listingov')}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={query.offset}
              nextOffset={nextOffset}
              limit={constants.LIST_LIMIT}
              onChangeOffset={(e) => {
                setQuery({
                  offset: e,
                  type: actualType,
                });
              }}
              loading={!!isLoading}
            />
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <Link to="/listing/">
              <Button primary>{__('Pridať nový listing')}</Button>
            </Link>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment noBottomGutter raised>
          <Tabber
            active={listingTypes.find(({ type: t }) => t === type)?.title}
            onClickItem={(itemName) => {
              history.push(
                `/listings/${
                  listingTypes.find(({ title }) => title === itemName)?.type
                }`,
              );
            }}
            items={listingTypes.map((tab) => tab.title)}
          >
            <ListingsFilter query={query} setQuery={setQuery} />
            <br />
            {render()}
          </Tabber>
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(C) as any as React.FC<IOwnProps>;
