import * as React from 'react';

const context = React.createContext<{
  onCloseApp: () => void;
}>({
  onCloseApp: () => {},
});

export const { Provider, Consumer } = context;
export default context;
